import { combineReducers } from 'redux';
import * as R from 'ramda';
import * as reducerUtils from '../../../common/utils/reducer';
import * as usersReducer from '../../core/reducers/users';
import {
  NETWORK_INITIALISE,
  SELECT_NETWORK,
  ADMIN_CREATE_TEAM,
  ADMIN_REMOVE_TEAM,
  ADMIN_UPDATE_TEAM,
  NETWORK_UPDATE_TEAM_USERS,
} from '../actions';

const addToStore = R.curry((state, team) => {
  team.users_count = team.member_count; // eslint-disable-line no-param-reassign

  return R.assoc(team.id, R.omit(['membership'], team), state);
});

const items = (state = {}, action) => {
  switch (action.type) {
    case NETWORK_INITIALISE:
      return R.reduce(addToStore, state, action.teams);
    case ADMIN_CREATE_TEAM:
      return addToStore(state, action.team);
    case ADMIN_REMOVE_TEAM:
      return R.omit([action.teamId], state);
    case ADMIN_UPDATE_TEAM:
      return reducerUtils.update(action.team.id, R.merge(R.__, action.team), state);
    case NETWORK_UPDATE_TEAM_USERS:
      return {
        ...state,
        [action.teamId]: {
          ...state?.[action.teamId],
          member_count: action.team.member_count,
          member_ids: action.team.member_ids
        }
      };
    case SELECT_NETWORK:
      return {};
    default: return state;
  }
};

const functions = (state = {}, action) => {
  switch (action.type) {
    case NETWORK_INITIALISE:
      return R.pipe(
        R.groupBy(R.prop('function_id')),
        R.mapObjIndexed(R.pluck('id')),
        R.omit(['null']),
      )(action.teams);
    case ADMIN_CREATE_TEAM: {
      if (!action.team.function_id) return state;

      return {
        ...state,
        [action.team.function_id]: [
          ...(state[action.team.function_id] || []),
          action.team.id,
        ],
      };
    }
    case ADMIN_REMOVE_TEAM:
      if (!action.functionId || !state[action.functionId]) return state;

      return {
        ...state,
        [action.functionId]: R.reject(R.equals(action.teamId), state[action.functionId]),
      };
    case SELECT_NETWORK: return {};
    default: return state;
  }
};

export const findById = R.curry((state, id) => {
  const team = state.network.teams.items[id];

  if (!team) return undefined;

  return {
    ...team,
    function: team.function_id ? state.organisation.functions.items[team.function_id] : null,
    users: R.pipe(
      R.map(usersReducer.findById(state)),
      R.reject(R.isNil),
    )(team.member_ids),
  };
});

const order = (state = [], action) => {
  switch (action.type) {
    case NETWORK_INITIALISE:
      return action.teams.map((team) => team.id);
    default: return state;
  }
};

export default combineReducers({ items, functions, order });
