import Socket from '../../../common/services/socket';
import Storage from '../../../common/services/storage';
import NotificationService from '../../../common/services/notification';
import Interval from '../../../common/services/interval';
import * as Analytics from '../../../../client/analytics';

export const LOGOUT_USER = 'core/LOGOUT_USER';

export default () => async (dispatch) => {
  // Reset all services/storage related to the user
  await Storage.clear();
  Socket.disconnect();
  Interval.clearAll();

  // Unset Google Analytics
  Analytics.reset();

  // Unsubscribe from notifications
  NotificationService.unsubscribe();

  // Wait with dispatching the logout action untill the route has been redirected
  // This fixes some weird bug in the router
  setTimeout(() => dispatch({ type: LOGOUT_USER, sync: true }), 100);
};
