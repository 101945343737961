import React from 'react';
import { connect } from 'react-redux';
import { Trans, withTranslation } from 'react-i18next';
import { parse } from 'qs';
import { pageWrapper, EEventNames } from '../../../../../client/analytics';
import Alert from '../../../../common/components/alert';
import LoginForm from '../../forms/login';

const errors = {
  PROFILE: 'profile',
};

const LoginContainer = ({ formValues = { username: '' }, location }) => {
  let alert;

  const query = parse(location.search, { ignoreQueryPrefix: true });

  if (query.error && query.error === errors.PROFILE) {
    alert = (
      <Alert type="error">
        <Trans i18nKey="authentication:alert_profile" />
      </Alert>
    );
  } else if (query.reset === '1') {
    alert = (
      <Alert type="success">
        <Trans i18nKey="authentication:alert_password_reset" />
      </Alert>
    );
  }

  return (
    <>
      <LoginForm
        alert={alert}
        username={query.username && query.username.replace(/\s/g, '+')}
        formValues={formValues}
      />
      <div className="Authentication__Form__Helpdesk">
        <Trans i18nKey="authentication:need_help" />
        <br />
        <a onClick={() => window.Intercom?.('show')} role="link">
          <Trans i18nKey="authentication:contact_helpdesk" />
        </a>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  formValues: (state.form.authenticate && state.form.authenticate.values) || { username: '' },
});

export default withTranslation()(connect(mapStateToProps)(pageWrapper(EEventNames.VISITED_LOGIN_PAGE)(LoginContainer)));
