export default (() => {
  let intervals = {};

  return {
    add(key, fn, interval) {
      if (intervals[key]) window.clearInterval(intervals[key]);

      intervals[key] = setInterval(fn, interval);

      return () => this.clear(key);
    },
    clear(key) {
      window.clearInterval(intervals[key]);

      intervals[key] = null;
    },
    clearAll() {
      const keys = Object.keys(intervals);

      keys.map((key) => window.clearInterval(intervals[key]));

      intervals = {};
    },
  };
})();
