import * as R from 'ramda';
import { combineReducers } from 'redux';
import * as reducerUtils from '../../../common/utils/reducer';
import mergeNamesText from '../../../common/utils/merged-names-text';
import * as usersReducer from '../../core/reducers/users';
import {
  CHAT_REQUEST_CONVERSATIONS,
  CHAT_RECEIVE_CONVERSATIONS,
  CHAT_OPEN_CONVERSATION,
  CHAT_RECEIVE_CONVERSATION,
  CHAT_POST_CONVERSATION,
  CHAT_RECEIVE_MESSAGES,
  CHAT_ARCHIVE_CONVERSATION,
  CHAT_UNARCHIVE_CONVERSATION,
  CHAT_ADD_MESSAGE,
  CHAT_UPDATE_CONVERSATION,
  CHAT_TOGGLE_CONVERSATION_NOTIFICATIONS,
  CHAT_REMOVE_PARTICIPANT,
  CHAT_ADD_PARTICIPANTS,
  CHAT_LEAVE_CONVERSATION,
  CHAT_ADD_GROUP_ADMIN,
  CHAT_REMOVE_GROUP_ADMIN,
  CHAT_REMOVE_MESSAGE,
  CHAT_REMOVE_CONVERSATION,
  CHAT_SET_USERS_STATUS,
} from '../actions';
import * as messagesReducer from './messages';
import { EConversationTypes } from '../definitions';

const getSource = R.omit(['last_message', 'participants']);
const addToStore = (state, conversation) => R.assoc(conversation.id, getSource(conversation), state);

const items = (state = {}, action) => {
  switch (action.type) {
    case CHAT_RECEIVE_CONVERSATIONS:
      return R.reduce(addToStore, state, action.items);
    case CHAT_RECEIVE_CONVERSATION:
      return addToStore(state, action.item);
    case CHAT_RECEIVE_MESSAGES:
      return reducerUtils.update(action.conversationId, R.assoc('loaded_messages', true), state);
    case CHAT_POST_CONVERSATION:
      return addToStore(state, action.item);
    case CHAT_ADD_MESSAGE:
      if (!action.conversation) return state;

      return addToStore(state, action.conversation);
    case CHAT_UPDATE_CONVERSATION:
      return reducerUtils.update(action.conversationId, R.merge(R.__, action.item), state);
    case CHAT_TOGGLE_CONVERSATION_NOTIFICATIONS:
      return reducerUtils.update(action.conversationId, R.assoc('is_muted', action.muted), state);
    case CHAT_REMOVE_PARTICIPANT:
      if (action.isMe) {
        // If someone removed you from the conversation
        return reducerUtils.update(action.conversationId, R.evolve({
          has_left: R.T,
          participant_ids: () => [],
          admin_ids: R.reject(R.equals(action.userId)),
        }), state);
      }

      return reducerUtils.update(action.conversationId, R.evolve({
        admin_ids: R.reject(R.equals(action.userId)),
        participant_ids: R.reject(R.equals(action.userId)),
      }), state);
    case CHAT_ADD_PARTICIPANTS:
      return reducerUtils.update(action.conversationId, R.evolve({
        participant_ids: R.concat(action.userIds),
      }), state);
    case CHAT_LEAVE_CONVERSATION:
      return reducerUtils.update(action.conversationId, R.evolve({
        has_left: R.T,
        participant_ids: () => [],
        admin_ids: [],
      }), state);
    case CHAT_ADD_GROUP_ADMIN:
      return reducerUtils.update(action.conversationId, R.evolve({
        admin_ids: R.append(action.userId),
      }), state);
    case CHAT_REMOVE_GROUP_ADMIN:
      return reducerUtils.update(action.conversationId, R.evolve({
        admin_ids: R.reject(R.equals(action.userId)),
      }), state);
    case CHAT_REMOVE_CONVERSATION:
      return R.omit([action.conversationId], state);
    default: return state;
  }
};

const ids = (state = [], action) => {
  switch (action.type) {
    case CHAT_REQUEST_CONVERSATIONS:
      if (action.clear) return [];

      return state;
    case CHAT_RECEIVE_CONVERSATIONS:
      if (action.archive) return state;

      return R.pipe(R.concat(R.pluck('id', action.items)), R.uniq)(state);
    case CHAT_ADD_MESSAGE:
      if (!action.conversation) return state;

      return R.pipe(R.append(action.conversation.id), R.uniq)(state);
    case CHAT_RECEIVE_CONVERSATION:
    case CHAT_POST_CONVERSATION:
      return R.pipe(R.append(action.item.id), R.uniq)(state);
    case CHAT_ARCHIVE_CONVERSATION:
    case CHAT_REMOVE_CONVERSATION:
      return R.reject(R.equals(action.conversationId), state);
    default: return state;
  }
};

const archive = (state = [], action) => {
  switch (action.type) {
    case CHAT_RECEIVE_CONVERSATIONS:
      if (!action.archive) return state;

      return R.pipe(R.concat(R.pluck('id', action.items)), R.uniq)(state);
    case CHAT_ARCHIVE_CONVERSATION:
      return R.append(action.conversationId, state);
    case CHAT_UNARCHIVE_CONVERSATION:
      return R.filter((id) => id !== action.payload, state);
    case CHAT_ADD_MESSAGE:
    case CHAT_REMOVE_CONVERSATION:
      return R.reject(R.equals(action.conversationId), state);
    default: return state;
  }
};

const update = (state = null, action) => {
  switch (action.type) {
    case CHAT_ADD_MESSAGE:
    case CHAT_REMOVE_MESSAGE:
      return new Date();
    case CHAT_UPDATE_CONVERSATION:
    case CHAT_REMOVE_PARTICIPANT:
    case CHAT_ADD_PARTICIPANTS:
    case CHAT_LEAVE_CONVERSATION:
      if (action.activities.length === 0) return state;

      return new Date();
    default: return state;
  }
};

const current = (state = null, action) => {
  switch (action.type) {
    case CHAT_OPEN_CONVERSATION:
      return action.conversationId;
    case CHAT_ARCHIVE_CONVERSATION:
    case CHAT_REMOVE_CONVERSATION:
      return state === action.conversationId ? null : state;
    default: return state;
  }
};

const related = (state = {}, action) => {
  switch (action.type) {
    case CHAT_RECEIVE_CONVERSATIONS:
      return action.related;
    case CHAT_SET_USERS_STATUS:
      return setRelatedUsersStatus(state, action);
    default:
      return state;
  }
};

function setRelatedUsersStatus(state, action) {
  const { statuses } = action;
  const users = state.users?.map((user) => {
    if (user.id in statuses) {
      const status = statuses[user.id];
      return {
        ...user,
        membership: {
          ...user?.membership,
          status
        }
      };
    }
    return user;
  });
  return {
    users: users || []
  };
}

const initialised = (state = false, action) => {
  switch (action.type) {
    case CHAT_RECEIVE_CONVERSATIONS:
      return true;
    default:
      return state;
  }
};

export const findById = R.curry((state, id) => {
  const conversation = state.chat.conversations.items[id];

  if (!conversation || !conversation.id) return undefined;

  const lastMessage = messagesReducer.findLastByConversation(state, id);
  const participants = R.pipe(
    R.map((userId) => usersReducer.findById(state, userId)),
    R.reject(R.isNil),
  )(conversation.participant_ids || []);

  const test = {
    ...conversation,
    participants,
    // only for private chats
    participant: conversation.conversation_type === EConversationTypes.PRIVATE
      ? R.head(R.reject(R.propEq('id', state.loggedUser.user.id), participants))
      : null,
    // If there is no name, the name will be the names of first two participants merged
    name: conversation.name || (mergeNamesText(
      R.reject(R.propEq('id', state.loggedUser.user.id), participants),
      2,
      'full_name',
      (name) => name,
    )).join(''),
    actual_name: conversation.name || '', // Don't want the generated name above in our form
    last_message: lastMessage,
    unread: state.notifications.chat.items[id],
    admins: conversation.conversation_type === EConversationTypes.GROUP
      ? (conversation.admin_ids || []).map((userId) => usersReducer.findById(state, userId))
      : null,
    is_admin: conversation.conversation_type === EConversationTypes.GROUP && conversation.admin_ids
      ? conversation.admin_ids.includes(state.loggedUser.user.id)
      : false,
    creator: usersReducer.findById(state, conversation.user_id), // TODO
  };

  return test;
});

export default combineReducers({
  items, current, ids, archive, update, initialised, related
});
