import { omit } from 'lodash';
import {
  HIDE_PLAN_PACKAGE_UPGRADE_MODAL,
  SHOW_PLAN_PACKAGE_UPGRADE_MODAL,
} from '../actions';

const initialState = null;

const planPackageModal = (state = initialState, action: any) => {
  switch (action.type) {
    case SHOW_PLAN_PACKAGE_UPGRADE_MODAL:
      return { ...omit(action, ['type']) };
    case HIDE_PLAN_PACKAGE_UPGRADE_MODAL:
      return null;
    default:
      return state;
  }
};

export default planPackageModal;
