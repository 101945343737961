import React, { useState, MouseEvent, MouseEventHandler } from 'react';
import { Dropdown, DropdownProps, DropdownItemProps } from 'semantic-ui-react';

import Icon, { IconTypes } from '../icon';
import SearchBar, { Props as SearchBarProps } from '../search-bar';
import { Checkbox, CheckboxOwnProps } from '../form/inputs/checkbox';


const getParent = (target: HTMLElement | null): HTMLElement | null => {
  if (!target || target.classList.contains('item')) return target;

  return getParent(target.parentElement);
};

interface Props extends Omit<DropdownProps, 'onClick'> {
  children: Array<React.ReactNode> | React.ReactNode;
  toggle?: React.ReactNode;
  alignRight?: boolean;
  className?: string;
  small?: boolean;
  defaultOpen?: boolean;
  onClick?: () => boolean;
}

const DropdownComponent = ({
  children,
  toggle,
  alignRight,
  className,
  small,
  defaultOpen,
  onOpen,
  onClose,
  onClick,
  ...props
}: Props) => {
  const [isOpen, setOpen] = useState(!!defaultOpen);

  if (!children) return null;

  // When only passed one item children won't return an array and remove any possible empty items
  const items = Array.isArray(children) ? children.filter((c) => !!c) : [children];
  const classNames = [];
  if (className) classNames.push(className);
  if (small) classNames.push('dropdown--small');

  // If there are no items
  if (items.length === 0) return null;

  return (
    <Dropdown
      trigger={toggle}
      {...props}
      className={classNames.join(' ')}
      open={isOpen}
      onClick={(event, data) => {
        const preventDefault = onClick && !onClick();
        if (preventDefault) return;
        setOpen(true);
        if (onOpen) onOpen(event, data);
      }}
      onClose={(event, data) => {
        setOpen(false);

        if (onClose) onClose(event, data);
      }}
      direction={alignRight ? 'left' : 'right'}
      icon={null}
    >
      <Dropdown.Menu
        onClick={(e: MouseEvent<HTMLDivElement> & { target: HTMLDivElement }) => {
          const parent = getParent(e.target);

          // If clicking anything else than an option in the dropdown, prevent it from closing
          if (parent?.getAttribute('role') === 'option') setOpen(false);

          e.stopPropagation();
        }}
      >
        {items}
      </Dropdown.Menu>
    </Dropdown>
  );
};

interface ItemProps extends DropdownItemProps {
  children: any;
  icon?: IconTypes;
  active?: boolean;
  danger?: boolean;
  className?: string;
  onClick?: MouseEventHandler;
}

DropdownComponent.Item = ({
  children,
  danger,
  className,
  icon,
  ...props
}: ItemProps) => {
  const classNames = [];
  if (className) classNames.push(className);
  if (danger) classNames.push('dropdown-item--danger');

  return (
    <Dropdown.Item {...props} className={classNames.join(' ')}>
      {icon && <Icon type={icon} />}
      {children}
    </Dropdown.Item>
  );
};

DropdownComponent.CheckboxItem = (props: CheckboxOwnProps) => (
  <div className="dropdown-checkbox">
    <Checkbox {...props} />
  </div>
);

DropdownComponent.SearchInput = (props: SearchBarProps) => (
  <div className="dropdown-input">
    <SearchBar {...props} />
  </div>
);

DropdownComponent.Header = Dropdown.Header;
DropdownComponent.Divider = Dropdown.Divider;

export default DropdownComponent;
