import { combineReducers } from 'redux';

import type { FileItem } from '../types';
import type {
  UploadFilesAction,
  ClearUploadQueueAction,
  ClearQueueItem,
} from '../actions';

export type UploadQueueReducerRelatedActions =
  | UploadFilesAction
  | ClearUploadQueueAction
  | ClearQueueItem;

const items = (state: FileItem[] = [], action: UploadQueueReducerRelatedActions) => {
  switch (action.type) {
    case 'core/UPLOAD_FILES':
      return action.items.reduce((acc, upload) => {
        acc.push(upload);

        return acc;
      }, [...state]);
    case 'core/CLEAR_QUEUE_ITEM':
      return state.filter((item) => item.id !== action.id);
    case 'core/CLEAR_UPLOAD_QUEUE':
      return [];
    default:
      return state;
  }
};

export default combineReducers({ items });
