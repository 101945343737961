import * as R from 'ramda';
import { ADMIN_REMOVE_ROLE } from '../../admin/actions';
import { NETWORK_RECEIVE_ROLES } from '../actions';

const reducer = (state = [], action) => {
  switch (action.type) {
    case NETWORK_RECEIVE_ROLES:
      return R.pluck('id', action.items);
    case ADMIN_REMOVE_ROLE:
      return R.reject(R.equals(action.id), state);
    default: return state;
  }
};

export default reducer;
