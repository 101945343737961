import React from 'react';
import { connect } from 'react-redux';
import { Switch } from 'react-router';
import { Trans, withTranslation } from 'react-i18next';
import Route from '../../common/components/route';
import Icon from '../../common/components/icon';
import Tooltip from '../../common/components/tooltip';
import LoginContainer from './containers/login';
import ForgotPasswordContainer from './containers/forgot-password';
import UnsubscribeContainer from './containers/unsubscribe';
import * as organisationSelector from '../organisation/selectors/organisation';

const LoginTemplate = ({ t, ...props }) => {
  const organisation = window.organisation || (process.env.SERVER && props.organisation);

  return (
    <div id="Login">
      <div
        className="Login__Image"
        style={{ backgroundImage: organisation?.theme.login_image_url && `url('${organisation.theme.login_image_url}` }}
      />
      <div className="Authentication__Container Authentication__Container--center">
        <div className="Authentication__Form">
          {(organisation && organisation.brand_icon) ? (
            <img className="Authentication__Form__Logo" src={organisation.brand_icon} alt={organisation.name} />
          ) : (
            <img className="Authentication__Form__Logo" src="/static/images/logo_large.png" alt="Oneteam" />
          )}

          <Switch>
            <Route path="/auth/login" component={LoginContainer} />
            <Route path="/auth/forgot" component={ForgotPasswordContainer} />
            <Route path="/auth/unsubscribe" component={UnsubscribeContainer} />
          </Switch>
        </div>
        <Route path="/auth/login">
          <div className="Authentication__Form__Consent">
            <Trans i18nKey="authentication:consent" components={[<a href={t('authentication:consent_url')} target="_blank" rel="noreferrer" />]} />
          </div>
        </Route>
        <Tooltip
          placement="top"
          title={t('authentication:login_encrypted_tooltip')}
        >
          <div className="Authentication__Form__Secure">
            <Icon type="lock" />
            <Trans i18nKey="authentication:secure_connection" />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  formValues: (state.form.authenticate && state.form.authenticate.values) || { username: '' },
  organisation: organisationSelector.selected(state),
});

export default withTranslation()(connect(mapStateToProps)(LoginTemplate));
