import moment from 'moment';

export const setup = (locale, i18n) => {
  moment.locale(locale);
  moment.updateLocale(locale, {
    calendar: {
      nextDay: `[${i18n.t('common:utils_date_tomorrow_at')}] LT`,
      nextWeek: `dddd [${i18n.t('common:utils_date_at')}] LT`,
      sameDay: function callback(now) {
        const diff = now.diff(this, 'seconds');

        if (diff > 0 && diff < 60) return `[${i18n.t('common:utils_date_just_now')}]`;

        return `[${this.fromNow()}]`;
      },
      lastDay: `dddd [${i18n.t('common:utils_date_at')}] LT`,
      lastWeek: `dddd [${i18n.t('common:utils_date_at')}] LT`,
      sameElse: function callback(now) {
        return now.year() !== this.year()
          ? `D MMMM YYYY [${i18n.t('common:utils_date_at')}] LT`
          : `D MMMM [${i18n.t('common:utils_date_at')}] LT`;
      },
    },
    relativeTime: {
      past: function callback(string) {
        return string === i18n.t('common:utils_date_just_now') ? string : `${string} ${i18n.t('common:utils_date_ago')}`;
      },
      s: function callback(number, withoutSuffix, key, isFuture) {
        return isFuture ? i18n.t('common:utils_date_in_seconds', { count: number }) : i18n.t('common:utils_date_just_now');
      },
    },
  });
};
