import React from 'react';

type Props = {
  percentage: number;
  size?: number;
  strokeWidth?: number;
};

export const CircularProgressBar = ({ percentage, size = 24, strokeWidth = 2 }: Props) => {
  // Don't ask me how it works
  const dashArray = Math.PI * size;

  return (
    <div className="CircularProgressBar">
      <svg
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          r={size / 2 - (strokeWidth / 2)}
          cx={size / 2}
          cy={size / 2}
          fill="transparent"
          strokeWidth={strokeWidth}
          strokeDasharray={dashArray}
          strokeDashoffset="0"
        />
        <circle
          className="CircularProgressBar__Bar"
          r={size / 2 - (strokeWidth / 2)}
          cx={size / 2}
          cy={size / 2}
          fill="transparent"
          strokeWidth={strokeWidth}
          strokeDasharray={dashArray}
          style={{ strokeDashoffset: dashArray - ((percentage / 100) * (dashArray - (strokeWidth * Math.PI))) }} // Don't ask
        />
      </svg>
    </div>
  );
};
