import * as R from 'ramda';
import * as usersReducer from '../../core/reducers/users';
import { SOCIAL_RECEIVE_MESSAGE_DETAIL } from '../actions';

export default (state = {}, action) => {
  switch (action.type) {
    case SOCIAL_RECEIVE_MESSAGE_DETAIL:
      return R.assoc(action.messageId, R.pluck('user_id', action.likes), state);
    default: return state;
  }
};

export const { findById } = usersReducer;
export const findByMessage = R.curry((state, messageId) => R.map(
  findById(state),
  state.social.likes[messageId] || [],
));
