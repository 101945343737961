import React from 'react';
import { Field, BaseFieldProps, WrappedFieldProps } from 'redux-form';
import { connect } from 'react-redux';
import * as R from 'ramda';
import TextInput from '../text';
import Dropdown from '../../../dropdown';
import Icon from '../../../icon';
import { User } from '../../../../types/objects';
import './styles.scss';

type DialCode = {
  country_code: string;
  dial_code: string;
  icon: string;
  name: string;
  name_eng: string;
};

type DialCodeInputProps = {
  dialCodes: DialCode[];
};

type Props = DialCodeInputProps & BaseFieldProps & {
  placeholder?: string;
};

// @ts-expect-error
const mapStateToProps = (state) => ({
  dialCodes: state.language.dialCodes,
});

export const DialCodeInput = ({ input: { value, onChange }, dialCodes }: WrappedFieldProps & DialCodeInputProps) => {
  const [search, setSearch] = React.useState<string | null>(null);

  const selectedOption = R.find(R.propEq('dial_code', value), dialCodes) as DialCode;

  const options = search
    ? dialCodes.filter((option: DialCode) => (
      option.name.toLowerCase().includes(search.toLowerCase())
      || option.name_eng.toLowerCase().includes(search.toLowerCase())
    ))
    : dialCodes;

  return (
    <Dropdown
      search
      toggle={(
        <div className="PhoneNumberInput__Toggle" role="button">
          <Icon type="arrow_drop_down" />
          {selectedOption && (
            <>
              <img src={selectedOption.icon} alt={selectedOption.name} />
              <div>{selectedOption.dial_code}</div>
            </>
          )}
        </div>
      )}
      scrolling
    >
      {/* @ts-expect-error */}
      <Dropdown.SearchInput debounce={false} filter={false} onSearch={setSearch} />
      {options.map((option) => (
        <Dropdown.Item
          selected={selectedOption?.dial_code === option.dial_code}
          key={option.country_code}
          onClick={() => onChange(option.dial_code)}
        >
          {option.name}
        </Dropdown.Item>
      ))}
    </Dropdown>
  );
};

const PhoneNumberInputField = ({ name, dialCodes, placeholder }: Props) => (
  <div className="PhoneNumberInput fs-mask">
    <Field
      name={`${name}.dial_code`}
      component={DialCodeInput}
      dialCodes={dialCodes}
    />
    <TextInput
      className="phoneInput"
      name={`${name}.number`}
      placeholder={placeholder}
      maxLength={12}
      // @ts-expect-error
      onKeyPress={(e: KeyboardEvent) => {
        if (Number.isNaN(parseInt(e.key))) return e.preventDefault();

        return true;
      }}
    />
  </div>
);

export const PhoneNumberInput = connect(mapStateToProps)(PhoneNumberInputField);

const transformPhoneNumber = (number: string | null | undefined) => {
  if (!number) return '';
  if (number.substr(0, 1) === '0') return number.substr(1);

  return number;
};

// @ts-expect-error
PhoneNumberInput.initialize = (user: User) => ({
  dial_code: user.phone?.dial_code || '+31', // default to Dutch
  number: user.phone?.dial_code ? user.phone.number : transformPhoneNumber(user.phone?.number), // If no dial code try to remove 0 at the beginning
});
