import { combineReducers } from 'redux';
import * as R from 'ramda';
import {
  ACADEMY_RECEIVE_MODULE,
  ACADEMY_ADD_SCREEN,
  ACADEMY_UPDATE_SCREEN,
} from '../actions';

const addToStore = R.curry((state, question) => R.assoc(question.id, R.omit(['multiple_choice'], question), state));

const items = (state = {}, action) => {
  switch (action.type) {
    case ACADEMY_RECEIVE_MODULE:
      return R.reduce((acc, screen) => {
        if (!screen.question) return acc;

        return addToStore(acc, screen.question);
      }, state, action.item.screens);
    case ACADEMY_ADD_SCREEN:
      if (!action.screen.question) return state;

      return addToStore(state, action.screen.question);
    case ACADEMY_UPDATE_SCREEN:
      if (!action.screen.question) return state;

      return addToStore(state, action.screen.question);
    default: return state;
  }
};

export const findById = R.curry((state, id) => state.academy.components.items[id]);

export default combineReducers({ items });
