import Api from '../../../common/services/api';

export const NETWORK_DELETE_USER = 'network/DELETE_USER';

const deleteNetworkUser = (userId) => (dispatch, getState) => {
  const { network: { selected } } = getState();

  dispatch({
    type: NETWORK_DELETE_USER,
    userId,
    networkId: selected
  });

  return Api.delete(`/v1/networks/${selected}/users/${userId}`);
};

export default deleteNetworkUser;
