import React from 'react';

type Props = {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
};

const FormRow = ({ children, className, style, onClick }: Props) => (
  <div className={className ? `Form__Row ${className}` : 'Form__Row'} style={style || undefined} onClick={onClick}>
    {children}
  </div>
);

export default FormRow;
