import { combineReducers } from 'redux';
import * as R from 'ramda';
import { ORGANISATION_INVITE_USER } from '../../organisation/actions';
import {
  ADMIN_APPROVE_ACCESS_REQUEST,
  ADMIN_REJECT_ACCESS_REQUEST,
} from '../../admin/actions';
import {
  SELECT_NETWORK,
  NETWORK_RECEIVE_USERS,
  NETWORK_DELETE_USER,
  NETWORK_INVITE_USER,
  NETWORK_REINVITE_USERS,
  NETWORK_RECEIVE_USER_COUNTS,
} from '../actions';

const items = (state = [], action) => {
  switch (action.type) {
    case NETWORK_RECEIVE_USERS: {
      const concatWithState = action.strategy === 'append' ? R.concat(state) : R.identity;

      return R.pipe(R.pluck('id'), concatWithState, R.uniq)(action.items);
    }
    case NETWORK_INVITE_USER:
    case ORGANISATION_INVITE_USER:
      if (action.existing === true || action.inCurrentNetwork === false) return state;

      return R.prepend(action.user.id, state);
    case NETWORK_DELETE_USER:
      return R.reject(R.equals(action.userId), state);
    case NETWORK_REINVITE_USERS:
      return [];
    case SELECT_NETWORK:
    default: return state;
  }
};

const all = (state = [], action) => {
  switch (action.type) {
    case NETWORK_RECEIVE_USERS:
      return R.pipe(R.pluck('id'), R.concat(state), R.uniq)(action.items);
    case NETWORK_INVITE_USER:
    case ORGANISATION_INVITE_USER:
      if (action.existing === true || action.inCurrentNetwork === false) return state;

      return R.prepend(action.user.id, state);
    case NETWORK_DELETE_USER:
      return R.reject(R.equals(action.userId), state);
    case SELECT_NETWORK:
    default: return state;
  }
};

const counts = (state = {
  total: 0,
  active: 0,
  inactive: 0,
  not_logged_in: 0,
  not_invited: 0,
  access_requests: 0,
}, action) => {
  switch (action.type) {
    case NETWORK_RECEIVE_USER_COUNTS:
      return action.counts;
    case NETWORK_INVITE_USER:
    case ORGANISATION_INVITE_USER:
      if (action.existing === true || action.inCurrentNetwork === false) return state;

      return {
        ...state,
        total: (state.total || 0) + 1,
        not_logged_in: (state.not_logged_in || 0) + 1,
      };
    case NETWORK_DELETE_USER:
      return { ...state, total: (state.total || 0) - 1 };
    case NETWORK_REINVITE_USERS:
      return {
        ...state,
        not_invited: 0,
      };
    case ADMIN_APPROVE_ACCESS_REQUEST:
    case ADMIN_REJECT_ACCESS_REQUEST:
      return {
        ...state,
        access_requests: state.access_requests - 1,
      };
    case SELECT_NETWORK:
      return {};
    default: return state;
  }
};

export default combineReducers({ items, all, counts });
