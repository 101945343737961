import Api from '../../../common/services/api';
import selectOrganisation from '../../organisation/actions/select-organisation';
import initialiseOrganisation from '../../organisation/actions/initialise';
import { NETWORK_INITIALISE } from './index';

export default (networkId, profile) => async (dispatch, getState) => {
  const { organisation } = getState();

  const { data: network } = await Api.get(`/v1/organisations/${organisation.selected.id}/networks/${networkId}`);

  const orgId = organisation.selected.id;

  const promises = [
    Api.get(`/v1/organisations/${orgId}/networks/${networkId}/teams`),
    Api.get(`/v3/networks/${networkId}/admins`),
    Api.get(`/v2/networks/${networkId}/functions`),
  ];

  if (organisation.selected?.id !== network.organisation_id) {
    // Selected network in different organisation than the current organisation
    promises.push(dispatch(selectOrganisation(network.organisation_id, false)));
  } else if (organisation.initialised !== network.organisation_id) {
    // Organisation is selected but not yet initialised (usually clean load)
    promises.push(dispatch(initialiseOrganisation(network.organisation_id, profile)));
  }

  const [
    { data: teams },
    { data: admins },
    { data: functions },
    redirect,
  ] = await Promise.all(promises);

  if (typeof redirect === 'string') return redirect;

  return dispatch({
    type: NETWORK_INITIALISE,
    network,
    teams,
    admins,
    functions,
  });
};
