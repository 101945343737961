import { combineReducers } from 'redux';
import * as R from 'ramda';
import { predicatesToFilters } from '@common/utils/predicates';
import {
  ORGANISATION_RECEIVE_MESSAGES,
  ORGANISATION_CREATE_MESSAGE,
  ORGANISATION_DELETE_MESSAGE,
  ORGANISATION_UPDATE_MESSAGE,
  ORGANISATION_RECEIVE_MESSAGE,
} from '../actions';
import * as messagesReducer from '../../social/reducers/messages';

const byId = (acc, organisationMessage) => R.assoc(
  organisationMessage.id,
  {
    ...R.omit(['message'], organisationMessage),
    processed: !organisationMessage.message.id.includes('scheduled-'),
    actions: organisationMessage.message.actions,
  },
  acc,
);

const items = (state = {}, action) => {
  switch (action.type) {
    case ORGANISATION_RECEIVE_MESSAGES:
      return R.reduce(byId, state, action.items);
    case ORGANISATION_RECEIVE_MESSAGE:
      return byId(state, action.item);
    case ORGANISATION_UPDATE_MESSAGE:
    case ORGANISATION_CREATE_MESSAGE:
      return byId(state, action.item);
    case ORGANISATION_DELETE_MESSAGE:
      return R.omit([action.messageId], state);
    default: return state;
  }
};

const ids = (state = [], action) => {
  switch (action.type) {
    case ORGANISATION_RECEIVE_MESSAGES:
      return action.append ? [...state, ...R.pluck('id', action.items)] : R.pluck('id', action.items);
    case ORGANISATION_CREATE_MESSAGE:
      return R.prepend(action.item.id, state);
    case ORGANISATION_DELETE_MESSAGE:
      return R.reject(R.equals(action.messageId), state);
    default: return state;
  }
};

export const findById = R.curry((state, id) => {
  const organisationMessage = state.admin.messages.items[id];

  if (!organisationMessage) return null;

  const message = messagesReducer.findById(state, organisationMessage.source_id) || {};
  message.actions = organisationMessage.actions; // Overwrite actions object returned by feed

  const filters = predicatesToFilters(organisationMessage.audience?.predicates, state);

  return {
    ...R.omit(['actions'], organisationMessage),
    message,
    filters,
  };
});

export default combineReducers({ ids, items });
