import { EParentTypes } from '../../../common/definitions';
// import type { Dispatch, StoreState } from '../../../common/types/store';
// import type { FeedObject } from '../types/objects';

export const SOCIAL_RECEIVE_REALTIME_MESSAGE = 'social/RECEIVE_MESSAGE/realtime';

// export default ({ data: object }: { data: FeedObject }) => (dispatch: Dispatch, getState: () => StoreState) => {
export default ({ data: object }) => (dispatch, getState) => {
  const state = getState();

  switch (object.parent_type) {
    case EParentTypes.ORGANISATION:
      if (state.organisation.selected !== object.parent_id) return false;
      break;
    case EParentTypes.NETWORK:
      if (state.network.selected !== object.parent_id) return false;
      break;
    case EParentTypes.TEAM:
      if (!state.network.teams.items[object.parent_id]) return false;
      break;
    default: return false;
  }

  return dispatch({ type: SOCIAL_RECEIVE_REALTIME_MESSAGE, item: object });
};
