import * as React from 'react';
import Icon, { IconTypes } from '../icon';
import Placeholder from '../image-placeholder';
import Image from '../image';

export type Props = {
  children?: any,
  name: any,
  imageSize: number,
  image?: string,
  meta?: any,
  icon?: string,
  infoIcon?: string,
  inverted?: boolean,
  className?: string,
  mask?: boolean,
  onClick?: React.MouseEventHandler<HTMLDivElement>,
  ImageWrapper?: Function,
  wrapperProps?: Object,
};

export type State = {
  fallback: boolean,
};

class ImageItemLarge extends React.Component<Props, State> {
  static props: Props;
  static defaultProps = {
    imageSize: 40,
  };

  constructor(props: Props) {
    super(props);

    this.state = { fallback: false };
  }

  UNSAFE_componentWillUpdate(nextProps: Props) {
    // eslint-disable-next-line react/no-will-update-set-state
    if (this.props.image !== nextProps.image) this.setState({ fallback: false });
  }

  createImage() {
    const { fallback } = this.state;
    const { image, icon, name, imageSize } = this.props;

    if (!image && !icon || fallback) return <Placeholder name={name} size={imageSize} />;
    if (icon) return <Icon style={{ fontSize: `${imageSize}px` }} type={icon as IconTypes} />;

    return (
      <Image
        size={imageSize}
        onError={() => this.setState({ fallback: true })}
        className="ImageItem__image fs-exclude"
        src={image}
        alt={name && name.props ? name.props.children : name}
      />
    );
  }

  render() {
    const {
      children, name, meta, inverted, className, infoIcon, mask, onClick,
    } = this.props;
    const { ImageWrapper, wrapperProps } = this.props;

    const image = this.createImage();

    const classNames = ['ImageItem', 'ImageItem--large'];
    if (onClick) classNames.push('ImageItem--clickable');
    if (className) classNames.push(className);

    return (
      <div className={classNames.join(' ')} onClick={onClick}>
        <div className="ImageItem__image__container">
          {ImageWrapper ? <ImageWrapper {...wrapperProps}>{image}</ImageWrapper> : image}
        </div>

        <div className="ImageItem__info">
          {inverted ? children : null}
          {typeof name === 'string'
            ? (
              <h5 className="ImageItem__info__name">
                <span className={`${mask && 'fs-exclude'}`}>{name}</span>
                {' '}
                {meta}
              </h5>
            )
            : (
              <div>
                {name}
                {' '}
                {meta}
              </div>
            )}
          {!inverted ? children : null}
        </div>

        {infoIcon && <Icon type={infoIcon as IconTypes} />}
      </div>
    );
  }
}

export default ImageItemLarge;
