import * as React from 'react';
import { Route } from 'react-router';
import baseLoadable from '@loadable/component';
import Spinner from '../spinner';
import Permission from '../permission';
import RouteNotFound from '../route-not-found';

export const loadable = (importFn) => baseLoadable(importFn, {
  fallback: (
    <div className="AsyncSpinner">
      <Spinner size="large" alignCenter />
    </div>
  ),
});

export const PermissionRoute = ({
  path, component, permission, exact = false,
}) => (
  <Permission {...permission} deniedComponent={<RouteNotFound />}>
    <Route path={path} component={component} exact={exact} />
  </Permission>
);

export const getRedirect = (items, hasPermissions) => {
  for (let i = 0; i < items.length; i++) {
    if (items[i].type !== 'divider' && hasPermissions(items[i].permissions)) return items[i].path;
  }
};

export default Route;
