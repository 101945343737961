import React, { Component } from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { EPredicateOperators, ESearchPredicateTypes } from '@common/definitions';
import Api from '../../../../services/api';
import SearchableList from '../../../list/search';
import Item from './item';

class UsersInput extends Component {
  static propTypes = {
    input: PropTypes.shape({
      value: PropTypes.object.isRequired,
      onChange: PropTypes.func.isRequired,
    }),
    isUserSelected: PropTypes.func,
    network: PropTypes.object,
    organisation: PropTypes.object,
    disabled: PropTypes.bool,
    isDisabled: PropTypes.func,
    defaultValue: PropTypes.array,
  };

  constructor() {
    super();
    this.state = {
      mounted: false,
    };

    this.checkIfUserIsSelected = this.checkIfUserIsSelected.bind(this);
    this.handleRequest = this.handleRequest.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  componentDidMount() {
    if (this.props.defaultValue) {
      this.props.input.onChange({
        add: this.props.defaultValue,
        remove: [],
      });
    }
    // Prevent the component from rendering before the default value is set
    this.setState({ mounted: true });
  }

  async handleRequest(offset, filter, limit = 15) {
    const { network, organisation } = this.props;
    const query = Api.utils.toQuery({
      offset,
      limit,
      q: filter.query,
    });
    const fetchSelected = offset === 0 && this.props.input.value.add && this.props.input.value.add.length > 0 && !filter.query;
    const promises = [
      network ?
        Api.get(`/v2/networks/${network.id}/users?${query}`) :
        Api.get(`/v2/organisations/${organisation.id}/users?${query}`),
    ];
    if (fetchSelected) {
      promises.push(this.getSelectedUsers());
    }
    const [allUsers, selectedUser] = await Promise.all(promises);
    const allUsersData = allUsers.data;
    const selectedUsersData = fetchSelected ? selectedUser.data : [];
    const filteredUsersData = allUsersData.filter((user) => !R.contains(user.id, this.props.input.value.add || []));
    return {
      ...allUsers,
      data: [
        ...selectedUsersData,
        ...filteredUsersData,
      ]
    };
  }

  getSelectedUsers = async () => {
    const { organisation } = this.props;
    const query = Api.utils.toQuery({
      include: ['networks', 'functions'],
    });
    const payload = {
      predicate_type: ESearchPredicateTypes.MATCH_ALL,
      predicates: [
        {
          comparison: EPredicateOperators.IN,
          attribute: 'users.id',
          value: this.props.input.value.add,
        },
      ],
    };
    return Api.post(`/v1/organisations/${organisation.id}/users/search?${query}`, payload);
  };

  checkIfUserIsSelected(item) {
    const { input: { value }, isUserSelected = () => false } = this.props;

    if (R.contains(item.id, value.remove || [])) return false;

    return R.contains(item.id, value.add || []) || isUserSelected(item);
  }

  handleAdd(userId) {
    const value = { ...this.props.input.value };
    if (R.contains(userId, value.remove || [])) {
      // User has been removed before, but it hasn't been saved yet
      value.remove = [...R.reject(R.equals(userId), value.remove)];
    } else {
      // value.add = R.append(userId, [...value.add]);

      // With new defaultValue prop we made the component controlled component.
      // This means that we need to handle the add value differently if the user has been added already.
      value.add = R.difference(R.append(userId, [...value.add]), this.props.defaultValue || []);
    }
    this.props.input.onChange(value);
  }

  handleRemove(userId) {
    const value = { ...this.props.input.value };
    const existingUsers = this.props.defaultValue || [];
    // Check if the user was in default value and has been added to the list.
    const newlyAddedUsers = R.difference(value.add || [], existingUsers);

    if (R.contains(userId, newlyAddedUsers || [])) {
      value.add = [...R.reject(R.equals(userId), value.add)];
    } else {
      value.remove = R.append(userId, [...value.remove]);
      value.add = R.difference(value.add || [], existingUsers);
    }

    this.props.input.onChange(value);
  }

  render() {
    const { disabled, isDisabled } = this.props;
    if (!this.state.mounted) return null;

    return (
      <div className="Form__UsersInput">
        <SearchableList
          data={{
            onFetch: this.handleRequest,
          }}
          renderRow={Item}
          rowProps={{
            disabled,
            isDisabled,
            isSelected: this.checkIfUserIsSelected,
            onAdd: this.handleAdd,
            onRemove: this.handleRemove,
          }}
        />
      </div>
    );
  }
}

export default (props) => <Field {...props} component={UsersInput} />;
