import { ThunkAction } from 'redux-thunk';
import * as R from 'ramda';

import { Api } from '@services/api';
import { Filters, ROOT_KEY, PERSONAL_ROOT_KEY } from './constants';
import { resolveFolderKey } from './utils';

import type { ListApiResponse } from '@common/types/util-types';
import type { Attachment } from '@common/types/objects';
import type { StoreState } from '@common/types/store';
import type { Document, Folder, ResponseDocument } from './types';

type FetchDocumentsApiResponse = ListApiResponse<ResponseDocument[]>;
type FetchDocumentApiResponse = ListApiResponse<ResponseDocument>;

export type FetchDocumentsAction = {
  type: 'documents/FETCH_DOCUMENTS';
  items: ResponseDocument[];
  nextCursor: string | null;
  folderKey: string;
  strategy: 'clear' | 'append';
};

type ActualFetchDocumentsAction = ThunkAction<
Promise<FetchDocumentsApiResponse | void>,
StoreState,
unknown,
FetchDocumentsAction
>;

export type FetchDocumentsFilters = {
  filter?: Filters | null;
  sort_key?: 'created_at' | 'updated_at' | 'name';
  order?: 'asc' | 'desc';
  folderId?: string;
  searchTerm?: string;
  userId?: string;
};

export const createPersonalFolderObj = (loggedUserId: string) => ({
  id: 'personal',
  type: 'personal_folder',
  name: 'personal',
  is_folder: true,
  personal_root_user_id: loggedUserId,
});

export const fetchDocuments = (
  nextCursor: string | null,
  filter: FetchDocumentsFilters,
  limit = 50,
  strategy: boolean,
): ActualFetchDocumentsAction => async (dispatch, getState) => {
  const state = getState();
  const { organisation: { selected }, loggedUser: { user: { id: loggedUserId } } } = state;

  // Store items either in a folder, a filter category or as root
  const folderKey = resolveFolderKey(filter, filter.folderId ? loggedUserId : filter.userId);

  const endpoint = (() => {
    const baseEndpoint = filter.userId
      ? `/v1/organisations/${selected.id}/users/${filter.userId}/documents`
      : `/v1/organisations/${selected.id}/documents`;

    if (filter.searchTerm) return `${baseEndpoint}/search`;
    if (filter.folderId === 'personal') return `${baseEndpoint}/me`;
    if (filter.folderId) return `${baseEndpoint}/folders/${filter.folderId}`;
    if (filter.filter) return `${baseEndpoint}/${filter.filter}`;

    return baseEndpoint;
  })();

  const sortBy = filter.sort_key && `${filter.sort_key}_${filter.order}`;

  const query = Api.utils.toQuery({
    limit: Math.min(filter.filter === Filters.RECENT ? 30 : 50, limit),
    cursor: nextCursor || true,
    sort_by: sortBy,
    q: filter.searchTerm,
    include_own_processing: true,
  });

  const request = await Api.get<FetchDocumentsApiResponse>(`${endpoint}?${query}`);

  const {
    data,
    meta: {
      has_personal_documents: hasPersonalDocuments,
      pagination: { next_cursor: newNextCursor },
    },
  } = request;

  if (hasPersonalDocuments && !filter.folderId && !nextCursor) {
    // @ts-expect-error
    data.unshift(createPersonalFolderObj(loggedUserId));
  }

  dispatch({
    type: 'documents/FETCH_DOCUMENTS',
    items: data,
    nextCursor: newNextCursor,
    folderKey,
    strategy: strategy ? 'clear' : 'append',
  });

  return request;
};

type UploadFileApiResponse = {
  data: ResponseDocument;
};

export type UploadFileAction = {
  type: 'documents/UPLOAD_FILE';
  item: ResponseDocument;
  folderKey: string;
};

type ActualUploadFileAction = ThunkAction<Promise<UploadFileAction | void>, StoreState, unknown, UploadFileAction>;

export const addFile = (
  parentFolderId: string | undefined,
  attachment: Attachment,
  userId?: string,
  notify_users: boolean = true,
  personalFolder: boolean = false,
): ActualUploadFileAction => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const endpoint = (() => {
    if (userId) return `/v1/organisations/${selected.id}/users/${userId}/documents`;
    return `/v1/organisations/${selected.id}/documents`;
  })();

  const payload = {
    name: attachment.file_name,
    file_id: attachment.id,
    is_folder: false,
    parent_folder_id: parentFolderId === 'personal' ? undefined : parentFolderId,
  };

  const { data } = await Api.post<UploadFileApiResponse>(endpoint, personalFolder ? payload : {
    ...payload,
    notify_users,
  });

  const folderKey = resolveFolderKey({ folderId: (data.parent_folder_id || undefined) }, userId);

  return dispatch({
    type: 'documents/UPLOAD_FILE',
    item: data,
    folderKey,
  });
};

export type ReplaceFileAction = {
  type: 'documents/REPLACE_FILE';
  documentId: string;
  item: ResponseDocument;
};

type ActualReplaceFileAction = ThunkAction<Promise<ReplaceFileAction | void>, StoreState, unknown, ReplaceFileAction>;

export const replaceFile = (
  documentId: string,
  attachment: Attachment,
): ActualReplaceFileAction => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const { data } = await Api.put<UploadFileApiResponse>(`/v1/organisations/${selected.id}/documents/${documentId}`, {
    file_id: attachment.id,
  });

  return dispatch({
    type: 'documents/REPLACE_FILE',
    documentId,
    item: data,
  });
};

export type CreateDocumentAction = {
  type: 'documents/CREATE_FOLDER';
  item: ResponseDocument;
  folderKey: string,
};

type ActualCreateDocumentAction = ThunkAction<Promise<CreateDocumentAction>, StoreState, unknown, CreateDocumentAction>;

export type CreateDocumentPayload = Pick<Folder, 'parent_folder_id' | 'is_folder' | 'name'> & {
  access_settings: {
    networkIds?: string[];
    functionIds?: string[];
  }
};

export const createFolder = (
  payload: CreateDocumentPayload,
  userId?: string,
): ActualCreateDocumentAction => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const endpoint = (() => {
    if (userId) return `/v1/organisations/${selected.id}/users/${userId}/documents`;

    return `/v1/organisations/${selected.id}/documents`;
  })();

  const { data } = await Api.post<FetchDocumentApiResponse>(
    endpoint,
    userId ? R.omit(['access_settings'], payload) : payload,
  );

  const folderKey = resolveFolderKey({ folderId: (data.parent_folder_id || undefined) }, userId);

  return dispatch({
    type: 'documents/CREATE_FOLDER',
    item: data,
    folderKey,
  });
};

export type DeleteDocumentAction = {
  type: 'documents/DELETE_DOCUMENT';
  documentId: string;
  folderKey: string;
};

type ActualDeleteDocumentAction = ThunkAction<Promise<DeleteDocumentAction>, StoreState, unknown, DeleteDocumentAction>;

export const deleteDocument = (documentId: string, userId?: string): ActualDeleteDocumentAction => async (
  dispatch,
  getState,
) => {
  const { organisation: { selected }, documents: { items } } = getState();

  const item = items[documentId];

  const endpoint = (() => {
    if (userId) return `/v1/organisations/${selected.id}/users/${userId}/documents/${documentId}`;
    return `/v1/organisations/${selected.id}/documents/${documentId}`;
  })();

  await Api.delete<FetchDocumentApiResponse>(endpoint);

  const rootKey = userId ? PERSONAL_ROOT_KEY : ROOT_KEY;

  return dispatch({
    type: 'documents/DELETE_DOCUMENT',
    documentId,
    folderKey: item?.parent_folder_id || rootKey,
  });
};

export type RemoveDocumentPermanentlyAction = {
  type: 'documents/REMOVE_DOCUMENT_PERMANENTLY';
  documentId: string;
  folderKey: string;
};

export type ActualRemoveDocumentPermanentlyAction = ThunkAction<
Promise<RemoveDocumentPermanentlyAction>,
StoreState,
unknown,
RemoveDocumentPermanentlyAction
>;

export const removeDocumentPermanently = (
  documentId: string,
  folderKey: string,
  userId: string | null,
): ActualRemoveDocumentPermanentlyAction => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  if (userId) {
    await Api.delete(`/v1/organisations/${selected.id}/users/${userId}/documents/${documentId}`);
  } else {
    await Api.delete(`/v1/organisations/${selected.id}/documents/${documentId}/trash`);
  }

  return dispatch({
    type: 'documents/REMOVE_DOCUMENT_PERMANENTLY',
    documentId,
    folderKey,
  });
};

export type UpdateDocumentAction = {
  type: 'documents/UPDATE_DOCUMENT';
  item: ResponseDocument;
  folderKey: string;
};

export type ActualUpdateDocumentAction = ThunkAction<Promise<UpdateDocumentAction>, StoreState, unknown, UpdateDocumentAction>;

export const updateDocument = (
  payload: Partial<CreateDocumentPayload>,
  documentId: string,
  userId?: string,
): ActualUpdateDocumentAction => async (
  dispatch,
  getState,
) => {
  const { organisation: { selected } } = getState();

  const endpoint = (() => {
    if (userId) return `/v1/organisations/${selected.id}/users/${userId}/documents/folders/${documentId}`;

    return `/v1/organisations/${selected.id}/documents/${documentId}`;
  })();

  const { data } = await Api.put<FetchDocumentApiResponse>(endpoint, payload);

  const rootKey = userId ? PERSONAL_ROOT_KEY : ROOT_KEY;

  return dispatch({
    type: 'documents/UPDATE_DOCUMENT',
    item: data,
    folderKey: data.parent_folder_id || rootKey,
  });
};

export type ToggleDocumentFavoriteStatus = {
  type: 'documents/TOGGLE_DOCUMENT_FAVORITE_STATUS';
  item: Document;
};

export type ActualToggleDocumentFavoriteStatus = ThunkAction<
Promise<ToggleDocumentFavoriteStatus>,
StoreState,
unknown,
ToggleDocumentFavoriteStatus
>;

export const toggleDocumentFavoriteStatus = (documentId: string): ActualToggleDocumentFavoriteStatus => async (
  dispatch,
  getState,
) => {
  const { organisation: { selected } } = getState();

  const { data } = await Api.post<{ data: Document }>(`/v1/organisations/${selected.id}/documents/${documentId}/favorite`);

  return dispatch({
    type: 'documents/TOGGLE_DOCUMENT_FAVORITE_STATUS',
    item: data,
  });
};

export type MarkDocumentAsViewed = {
  type: 'documents/MARK_DOCUMENT_AS_VIEWED';
  item: Document;
};

export type ActualMarkDocumentAsViewed = ThunkAction<Promise<MarkDocumentAsViewed>, StoreState, unknown, MarkDocumentAsViewed>;

// @ts-expect-error
export const markDocumentAsViewed = (documentId: string): ActualMarkDocumentAsViewed => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  if (documentId === 'personal') return;

  const { data } = await Api.post<{ data: Document }>(`/v1/organisations/${selected.id}/documents/${documentId}/open`);

  return dispatch({
    type: 'documents/MARK_DOCUMENT_AS_VIEWED',
    item: data,
  });
};

export type MoveDocumentAction = {
  type: 'documents/MOVE_DOCUMENT';
  item: Document;
  oldFolderKey: string;
  newFolderKey: string;
};

export type ActualMoveDocumentAction = ThunkAction<Promise<MoveDocumentAction>, StoreState, unknown, MoveDocumentAction>;

export const moveDocument = (documentId: string, newParentId: string | undefined | null): ActualMoveDocumentAction => async (
  dispatch,
  getState,
) => {
  const {
    organisation: { selected },
    documents,
  } = getState();

  const payload: Partial<CreateDocumentPayload> = {
    parent_folder_id: newParentId || null,
  };

  const { data } = await Api.put<{ data: Document }>(`/v1/organisations/${selected.id}/documents/${documentId}`, payload);

  const newFolderKey = resolveFolderKey({ folderId: data.parent_folder_id || undefined });

  return dispatch({
    type: 'documents/MOVE_DOCUMENT',
    item: data,
    oldFolderKey: resolveFolderKey({ folderId: documents.items[documentId].parent_folder_id || undefined }),
    newFolderKey,
  });
};

export type RestoreDocumentAction = {
  type: 'documents/RESTORE_DOCUMENT';
  item: Document;
  newFolderKey: string;
  oldFolderKey: Filters.TRASH;
};

export type ActualRestoreDocumentAction = ThunkAction<
Promise<RestoreDocumentAction>,
StoreState,
unknown,
RestoreDocumentAction
>;

export const restoreDocument = (
  documentId: string,
  newParentFolderId?: string | null,
): ActualRestoreDocumentAction => async (dispatch, getState) => {
  const {
    organisation: { selected },
    documents,
  } = getState();

  const payload = {
    parentFolderId: newParentFolderId,
  };

  await Api.post<{ success: boolean }>(`/v1/organisations/${selected.id}/documents/${documentId}/trash/restore`, payload);

  const oldDocument = documents.items[documentId];
  const newDocument = {
    ...oldDocument,
    parent_folder_id_before_removal: undefined,
    parent_folder_id: newParentFolderId !== undefined ? newParentFolderId : oldDocument.parent_folder_id_before_removal || null,
  };

  const newFolderKey = resolveFolderKey({ folderId: newDocument.parent_folder_id || undefined });

  return dispatch({
    type: 'documents/RESTORE_DOCUMENT',
    item: newDocument,
    oldFolderKey: Filters.TRASH,
    newFolderKey,
  });
};

export type RenameDocumentAction = {
  type: 'documents/RENAME_DOCUMENT';
  item: Document;
};

export type ActualRenameDocumentAction = ThunkAction<
Promise<RenameDocumentAction>,
StoreState,
unknown,
RenameDocumentAction>;

export const renameDocument = (
  documentId: string,
  name: string,
): ActualRenameDocumentAction => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const { data: item } = await Api.put<{ data: Document }>(`/v1/organisations/${selected.id}/documents/${documentId}`, { name });

  return dispatch({
    type: 'documents/RENAME_DOCUMENT',
    item,
  });
};
