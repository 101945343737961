import * as R from 'ramda';
import { combineReducers } from 'redux';
import { ORGANISATION_RECEIVE_MESSAGES, ORGANISATION_RECEIVE_MESSAGE, ORGANISATION_CREATE_MESSAGE, ORGANISATION_UPDATE_MESSAGE } from '../../admin/actions';
import { RECEIVE_FILE } from '../../core/actions';
import {
  SOCIAL_DELETE_MESSAGE,
  SOCIAL_RECEIVE_MESSAGE,
  SOCIAL_RECEIVE_FEED,
  SOCIAL_RECEIVE_IMPORTANT_MESSAGES,
  SOCIAL_RECEIVE_SCHEDULED_MESSAGES,
  SOCIAL_RECEIVE_UNREAD_MESSAGES,
  SOCIAL_POST_MESSAGE,
} from '../actions';

// : (items: Array<FeedObject>) => Array<FeedObject>
const isAttachment = (items) => R.filter(
  R.both(
    R.either(
      R.propEq('object_type', 'attachment'),
      R.propEq('object_type', 'other_attachment'),
    ),
    (item) => !!R.prop('source', item),
  ),
  items,
);

const addToStore = (acc, attachment) => R.assoc(attachment.source_id, attachment.source, acc);

const items = (state = {}, action) => {
  switch (action.type) {
    case SOCIAL_RECEIVE_FEED:
    case SOCIAL_RECEIVE_SCHEDULED_MESSAGES:
    case ORGANISATION_RECEIVE_MESSAGES:
    case SOCIAL_RECEIVE_IMPORTANT_MESSAGES:
    case SOCIAL_RECEIVE_UNREAD_MESSAGES:
      return R.pipe(
        R.pluck('children'),
        R.flatten,
        R.reject(R.isNil),
        isAttachment,
        R.reduce(addToStore, state),
      )(action.messages || action.items);
    case RECEIVE_FILE:
      return R.assoc(action.content.id, action.content, state);
    case SOCIAL_RECEIVE_MESSAGE:
    case SOCIAL_POST_MESSAGE:
    case ORGANISATION_RECEIVE_MESSAGE:
    case ORGANISATION_CREATE_MESSAGE:
    case ORGANISATION_UPDATE_MESSAGE:
      if (!action.message) return state;

      return R.pipe(isAttachment, R.reduce(addToStore, state))(action.message.children);
    default: return state;
  }
};

// : (attachments: Array<FeedObject>) => Array<string>
const getIds = R.pipe(isAttachment, R.pluck('source_id'));

const addToMessage = (acc, object) => R.assoc(
  object.source_id,
  object.children ? getIds(object.children) : [],
  acc,
);

const messages = (state = {}, action) => {
  switch (action.type) {
    case SOCIAL_RECEIVE_FEED:
    case SOCIAL_RECEIVE_SCHEDULED_MESSAGES:
    case SOCIAL_RECEIVE_IMPORTANT_MESSAGES:
    case SOCIAL_RECEIVE_UNREAD_MESSAGES:
    case ORGANISATION_RECEIVE_MESSAGES:
      return R.reduce(addToMessage, state, action.messages || action.items);
    case SOCIAL_DELETE_MESSAGE:
      return R.omit([action.messageId], state);
    case SOCIAL_RECEIVE_MESSAGE:
    case SOCIAL_POST_MESSAGE:
    case ORGANISATION_RECEIVE_MESSAGE:
    case ORGANISATION_CREATE_MESSAGE:
    case ORGANISATION_UPDATE_MESSAGE:
      if (!action.message) return state;

      return addToMessage(state, action.message);
    default: return state;
  }
};

export default combineReducers({ items, messages });

export const findById = R.curry((state, id) => state.social.attachments.items[id]);

export const findByMessage = R.curry((state, id) => R.map(findById(state), state.social.attachments.messages[id] || []));
