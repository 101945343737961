import * as R from 'ramda';
import moment from 'moment';
import { combineReducers } from 'redux';
import * as reducerUtil from '../../../common/utils/reducer';
import * as usersReducer from '../../core/reducers/users';
import {
  SOCIAL_POST_COMMENT,
  SOCIAL_POST_CONGRATULATION,
  SOCIAL_DELETE_MESSAGE,
  SOCIAL_RECEIVE_COMMENTS,
  SOCIAL_RECEIVE_CONGRATULATIONS,
  SOCIAL_DELETE_COMMENT,
  SOCIAL_UPDATE_COMMENT,
  SOCIAL_COMMENT_HIGH_FIVE,
  SOCIAL_TRANSLATE_COMMENT,
} from '../actions';

const addToStore = (acc, comment) => R.assoc(comment.id, comment, acc);

const items = (state = {}, action) => {
  switch (action.type) {
    case SOCIAL_RECEIVE_COMMENTS:
    case SOCIAL_RECEIVE_CONGRATULATIONS:
      return R.reduce(addToStore, state, action.comments);
    case SOCIAL_POST_COMMENT:
    case SOCIAL_POST_CONGRATULATION:
    case SOCIAL_UPDATE_COMMENT:
    case SOCIAL_COMMENT_HIGH_FIVE:
      return addToStore(state, action.comment);
    case SOCIAL_DELETE_COMMENT:
      return R.evolve({
        [action.commentId]: (comment) => ({
          ...comment,
          text: null,
          deleted_at: moment().toISOString(),
        }),
      }, state);
    case SOCIAL_TRANSLATE_COMMENT:
      return reducerUtil.update(action.commentId, (comment) => R.merge(comment, {
        translated_text: R.assoc(action.locale, action.translated_text, comment.translated_text || {}),
      }), state);
    default: return state;
  }
};

const addCommentId = R.curry((id, comments) => R.append(id, comments || []));

const messages = (state = {}, action) => {
  switch (action.type) {
    case SOCIAL_RECEIVE_COMMENTS:
    case SOCIAL_RECEIVE_CONGRATULATIONS:
      return R.assoc(action.messageId, R.pluck('id', action.comments), state);
    case SOCIAL_DELETE_MESSAGE:
      return R.omit([action.messageId], state);
    case SOCIAL_POST_COMMENT:
    case SOCIAL_POST_CONGRATULATION:
      return R.assoc(
        action.messageId,
        addCommentId(action.comment.id, state[action.messageId]),
        state,
      );
    case SOCIAL_UPDATE_COMMENT:
      if (!state[action.comment.message_id]) return;

      return R.assoc(action.comment.message_id, [...state[action.comment.message_id]], state);
    default: return state;
  }
};

export default combineReducers({ items, messages });

export const findById = R.curry((state, id) => {
  const comment = state.social.comments.items[id];

  if (!comment) return undefined;

  return { ...R.omit(['user_id'], comment), user: usersReducer.findById(state, comment.user_id) };
});

export const findByMessage = R.curry((state, id) => R.map(
  findById(state),
  state.social.comments.messages[id] || [],
));
