import Api from '../../../common/services/api';
import { createActivity } from '../utils/activity';
import { EConversationActivityTypes } from '../definitions';

export const CHAT_REMOVE_PARTICIPANT = 'chat/REMOVE_PARTICIPANT';

export default (conversationId, userId) => async (dispatch, getState) => {
  const { loggedUser: { user: { id: loggedUserId } }, organisation: { selected } } = getState();

  Api.put(`/v3/organisations/${selected.id}/conversations/${conversationId}/participants`, {
    remove: [userId],
  });

  return dispatch({
    type: CHAT_REMOVE_PARTICIPANT,
    conversationId,
    userId,
    activities: [
      createActivity(EConversationActivityTypes.USER_REMOVED, {
        actor_id: loggedUserId,
        user_id: userId,
      }),
    ],
  });
};
