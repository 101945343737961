import * as R from 'ramda';
import { ThunkAction } from 'redux-thunk';
import Api from '../../../common/services/api';
import { ETimelineTypes } from '../definitions';
import type { Dispatch, StoreState } from '../../../common/types/store';
import type { EventForUserDto } from '../../events/types';

export const SOCIAL_DELETE_MESSAGE = 'social/DELETE_MESSAGE';
export const SOCIAL_EDIT_MESSAGE = 'social/EDIT_MESSAGE';
export const SOCIAL_RECEIVE_CONGRATULATIONS = 'social/RECEIVE_CONGRATULATIONS';
export const SOCIAL_RECEIVE_COMMENTS = 'social/RECEIVE_COMMENTS';
export const SOCIAL_RECEIVE_MEMBERS = 'social/RECEIVE_MEMBERS';
export const SOCIAL_RECEIVE_MESSAGE_DETAIL = 'social/RECEIVE_MESSAGE_DETAIL';
export const SOCIAL_RECEIVE_MESSAGE = 'social/RECEIVE_MESSAGE';
export const SOCIAL_REQUEST_FEED = 'social/REQUEST_FEED';
export const SOCIAL_RECEIVE_FEED = 'social/RECEIVE_FEED';
export const SOCIAL_RECEIVE_IMPORTANT_MESSAGES = 'social/RECEIVE_IMPORTANT_MESSAGES';
export const SOCIAL_RECEIVE_UNREAD_MESSAGES = 'social/RECEIVE_UNREAD_MESSAGES';
export const SOCIAL_MESSAGE_HIGH_FIVE = 'social/HIGH_FIVE_MESSAGE/add';
export const SOCIAL_MESSAGE_UNHIGH_FIVE = 'social/HIGH_FIVE_MESSAGE/remove';
export const SOCIAL_COMMENT_HIGH_FIVE = 'social/HIGH_FIVE_COMMENT/add';
export const SOCIAL_COMMENT_UNHIGH_FIVE = 'social/HIGH_FIVE_COMMENT/remove';
export const SOCIAL_POST_COMMENT = 'social/POST_COMMENT';
export const SOCIAL_POST_CONGRATULATION = 'social/POST_CONGRATULATION';
export const SOCIAL_POST_MESSAGE = 'social/POST_MESSAGE';
export const SOCIAL_RECEIVE_REALTIME_MESSAGE = 'social/RECEIVE_MESSAGE/realtime';
export const SOCIAL_OBJECT_SEEN = 'social/OBJECT_SEEN';
export const SOCIAL_CLEAR_SEEN_QUEUE = 'social/CLEAR_SEEN_QUEUE';
export const SOCIAL_SELECT_CHANNEL = 'social/SELECT_CHANNEL';
export const SOCIAL_UPDATE_MESSAGE = 'social/UPDATE_MESSAGE';
export const SOCIAL_VOTE_POLL = 'social/VOTE_POLL';
export const SOCIAL_DELETE_COMMENT = 'social/DELETE_COMMENT';
export const SOCIAL_UPDATE_COMMENT = 'social/EDIT_COMMENT';
export const SOCIAL_RECEIVE_SCHEDULED_MESSAGES = 'social/RECEIVE_SCHEDULED_MESSAGES';
export const SOCIAL_MARK_AS_READ = 'social/MARK_AS_READ';
export const SOCIAL_CHANGE_FEED_ORDER = 'social/CHANGE_FEED_ORDER';
export const SOCIAL_TRANSLATE_MESSAGE = 'social/TRANSLATE_MESSAGE';
export const SOCIAL_TRANSLATE_COMMENT = 'social/TRANSLATE_COMMENT';
export const SOCIAL_HIDE_CHANNEL = 'social/HIDE_CHANNEL';
export const SOCIAL_MUTE_CHANNEL = 'social/MUTE_CHANNEL';
export const SOCIAL_TOGGLE_MESSAGE_IMPORTANT = 'social/TOGGLE_MESSAGE_IMPORTANT';
export const SOCIAL_TOGGLE_MESSAGE_COMMENTS = 'social/TOGGLE_MESSAGE_COMMENTS';

export const toggleImportant = (messageId: string, important: boolean) => async (dispatch: Dispatch) => {
  const { data: message } = await Api.put(`/v3/messages/${messageId}`, {
    is_important: important,
  });

  return dispatch({
    type: SOCIAL_TOGGLE_MESSAGE_IMPORTANT,
    messageId,
    message,
  });
};

export const toggleComments = (messageId: string, enabled: boolean) => async (dispatch: Dispatch) => {
  const { data: message } = await Api.put(`/v3/messages/${messageId}`, {
    can_comment: enabled,
  });

  return dispatch({
    type: SOCIAL_TOGGLE_MESSAGE_COMMENTS,
    messageId,
    message,
  });
};

export const markAsRead = (messageId: string) => async (dispatch: Dispatch) => {
  await Api.post(`/v1/messages/${messageId}/read`);

  return dispatch({
    type: SOCIAL_MARK_AS_READ,
    messageId,
  });
};

export const fetchFiles = (nextCursor: string | null, filter: { type?: string, id?: string } = {}, limit = 25) => (
  async (dispatch: Dispatch, getState: () => StoreState) => {
    const { organisation: { selected: { id: organisationId } } } = getState();
    const { type, id } = filter;

    let baseUrl;

    if (type === ETimelineTypes.FEED) {
      baseUrl = `/v1/networks/${id}/feed/attachments`;
    } else if (type === ETimelineTypes.NETWORK) {
      baseUrl = `/v1/networks/${id}/messages/attachments`;
    } else if (type === ETimelineTypes.TEAM) {
      baseUrl = `/v1/teams/${id}/messages/attachments`;
    } else if (type === ETimelineTypes.ORGANISATION) {
      baseUrl = `/v1/organisations/${id}/messages/attachments`;
    } else if (type === ETimelineTypes.CHANNEL) {
      baseUrl = `/v1/organisations/${organisationId}/channels/${id}/messages/attachments`;
    }

    if (!baseUrl) return;

    const queryObject: { cursor: string | boolean, limit: number } = {
      cursor: nextCursor || true,
      limit,
    };

    const query = Api.utils.toQuery(queryObject);

    return Api.get(`${baseUrl}?${query}`);
  }
);

export const changeFeedOrder = (value: string) => ({
  type: SOCIAL_CHANGE_FEED_ORDER,
  order: value,
  sync: true,
});

export const onUpdate = (messageId: string, payload: Object, scheduled = false) => async (dispatch: Dispatch) => {
  // @ts-expect-error
  const { data: message } = await Api.put(`/v3/messages/${messageId}`, payload);

  return dispatch({
    type: SOCIAL_EDIT_MESSAGE,
    messageId,
    message,
    scheduled,
  });
};

export const toggleMute = (messageId: string) => async (dispatch: Dispatch) => {
  const { data: message } = await Api.post(`/v1/messages/${messageId}/mute`);

  return dispatch({
    type: SOCIAL_EDIT_MESSAGE,
    messageId,
    message,
  });
};

export const translateMessage = (id: string) => async (dispatch: Dispatch, getState: () => StoreState) => {
  const state = getState();
  const locale = state?.loggedUser?.user?.translation_language?.locale;
  const messages = state?.social?.messages;

  const { data } = await Api.post('/v1/translate', {
    text: messages[id].text,
    target: locale,
  });

  return dispatch({
    type: SOCIAL_TRANSLATE_MESSAGE,
    messageId: id,
    locale,
    // @ts-expect-error
    translated_text: data[0],
  });
};

export const translateComment = (id: string) => async (dispatch: Dispatch, getState: () => StoreState) => {
  const { loggedUser: { user: { translation_language } }, social: { comments } } = getState();
  const { data } = await Api.post('/v1/translate', {
    // @ts-expect-error
    text: comments.items[id].text,
    target: translation_language.locale
  });

  return dispatch({
    type: SOCIAL_TRANSLATE_COMMENT,
    commentId: id,
    locale: translation_language.locale,
    // @ts-expect-error
    translated_text: data[0],
  });
};

export const highFiveComment = (id: string) => async (dispatch: Dispatch) => {
  const { data: comment } = await Api.put(`/v1/comments/${id}/like`);

  return dispatch({
    type: SOCIAL_COMMENT_HIGH_FIVE,
    id,
    comment,
  });
};

export const fetchCommentHighFives = (id: string) => () => Api.get(`/v1/comments/${id}/likes`)
  .then((response) => ({
    // @ts-expect-error
    data: R.map((highfive) => R.find(R.propEq('id', highfive.user_id), response.meta.related.users), response.data),
    meta: {
      pagination: {
        // @ts-expect-error
        total_count: response.data.length,
        offset: 0,
      },
    },
  }));

export const hideChannel = (
  type: string,
  id: string,
  hidden: boolean,
) => async (
  dispatch: Dispatch,
  getState: () => StoreState,
) => {
  const { organisation: { selected } } = getState();

  Api.put(`/v3/organisations/${selected.id}/users/me`, {
    settings: {
      feed: {
        [`${type}s`]: [{
          id,
          hide_from_feed_for_user: hidden,
        }],
      },
    },
  });

  return dispatch({
    type: SOCIAL_HIDE_CHANNEL,
    channelType: type,
    id,
    hidden,
  });
};

export const muteChannel = (
  type: string,
  id: string,
  muted: boolean,
) => async (
  dispatch: Dispatch,
  getState: () => StoreState,
) => {
  const { organisation: { selected } } = getState();

  Api.put(`/v3/organisations/${selected.id}/users/me`, {
    settings: {
      notifications: {
        [`${type}s`]: [{
          id,
          enabledNotifications: !muted,
        }],
      },
    },
  });

  return dispatch({
    type: SOCIAL_MUTE_CHANNEL,
    channelType: type,
    id,
    enabledNotifications: !muted,
  });
};

export type FetchEventsAction = {
  type: 'social/RECEIVE_EVENTS';
  items: EventForUserDto[];
};

type ActualFetchEventsAction = ThunkAction<Promise<FetchEventsAction>, StoreState, unknown, FetchEventsAction>;

export const fetchEvents = (): ActualFetchEventsAction => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const { data } = await Api
    .get(`/v1/organisations/${selected.id}/users/me/events?filter=AVAILABLE`) as { data: EventForUserDto[] };

  return dispatch({
    type: 'social/RECEIVE_EVENTS',
    items: data,
  });
};
