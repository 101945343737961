import { useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { toQuery } from '@services/api/utils';

type ParamValue = string | number | boolean | null | void;

export function parseSearchParams<T>(search: string) {
  const paramsObj = new URLSearchParams(search);

  return Array.from(paramsObj.entries()).reduce((acc, [key, value]) => ({
    ...acc,
    [key]: value,
  }), {}) as T;
}

export const useSearchParams = <T extends Record<string, ParamValue>>() => {
  const { search, pathname } = useLocation();
  const history = useHistory();

  const searchParams = useMemo<T>(() => parseSearchParams<T>(search), [search]);

  const setSearchParams = (params: T, overwritePathname?: string) => {
    // @ts-expect-error
    const newQuery = toQuery(params);

    history.push(`${overwritePathname || pathname}?${newQuery}`);
  };

  const setSearchParam = (name: keyof T, value: ParamValue, overwritePathname?: string) => setSearchParams({
    ...searchParams,
    [name]: value,
  }, overwritePathname);

  const resetSearchParams = () => history.push(pathname);

  return {
    searchParams,
    setSearchParams,
    setSearchParam,
    resetSearchParams,
  };
};
