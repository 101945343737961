import * as R from 'ramda';
import { SELECT_ORGANISATION } from '../../organisation/actions';
import {
  RECEIVE_NOTIFICATIONS,
  CLEAR_NOTIFICATIONS,
  CLEAR_NOTIFICATION,
  RECEIVE_NOTIFICATION,
} from '../actions';

export default (state = [], action) => {
  switch (action.type) {
    case RECEIVE_NOTIFICATIONS:
      return action.strategy === 'append'
        ? R.concat(state, action.items)
        : action.items;
    case CLEAR_NOTIFICATIONS:
      return state.map((notification) => ({ ...notification, seen: true }));
    case RECEIVE_NOTIFICATION:
      return [action.item, ...state];
    case CLEAR_NOTIFICATION: {
      const { sourceType, sourceId } = action;

      return state.map((notification) => ({
        ...notification,
        seen: notification.source_type === sourceType && notification.source_id === sourceId
          ? true
          : notification.seen,
      }));
    }
    case SELECT_ORGANISATION: return [];
    default: return state;
  }
};
