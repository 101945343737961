import * as R from 'ramda';
import * as usersReducer from '../../core/reducers/users';
import {
  FLEXCHANGE_RECEIVE_EXCHANGE,
  FLEXCHANGE_RECEIVE_EXCHANGE_DETAIL,
  FLEXCHANGE_ADD_COMMENT,
} from '../actions';

export const getSource = (comment) => R.merge(
  R.omit(['user'], comment),
  { user_id: comment.user ? comment.user.id : null },
);
const addToStore = (acc, comment) => R.assoc(comment.id, getSource(comment), acc);

export default (state = {}, action) => {
  switch (action.type) {
    case FLEXCHANGE_RECEIVE_EXCHANGE:
    case FLEXCHANGE_RECEIVE_EXCHANGE_DETAIL:
      return R.reduce(addToStore, state, action.comments);
    case FLEXCHANGE_ADD_COMMENT:
      return addToStore(state, action.comment);
    default: return state;
  }
};

export const findById = R.curry((state, id) => {
  const comment = state.flexchange.comments[id];

  if (!comment) return undefined;

  return {
    ...R.omit(['user_id'], comment),
    user: usersReducer.findById(state, comment.user_id),
  };
});
