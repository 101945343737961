import { useEffect } from 'react';

import { useDidComponentMount } from '@hooks/use-did-component-mount';

export const useOnUpdate: typeof useEffect = (callback, deps) => {
  const didMount = useDidComponentMount();

  useEffect(() => {
    if (didMount) return callback();
  }, deps);
};
