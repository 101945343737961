import { combineReducers } from 'redux';
import * as R from 'ramda';
import { EventForUserDto, EventForAdminDto } from './types';

import type { FetchEventsAction } from '../social/actions';
import type {
  ReceiveEventsAction,
  ReceiveEventAction,
  CancelEventAction,
  CreateEventAction,
  UpdateEventAction,
  AttendEventAction,
  AbsentEventAction,
  DeleteEventAction,
  DuplicateEventAction,
} from './actions';

import type { Event, EventSimpleDto, EventUserScopeFragment, EventAdminScopeFragment } from './types';
import type { StoreState } from '../../common/types/store';
import type { TypeOfItemFromArray } from '../../common/types/util-types';

type DefaultRelatedAction = {
  type: 'any_string',
  related?: {
    events?: EventSimpleDto[];
  };
};

type ExternalRelatedActions =
  | FetchEventsAction;

type OwnRelatedActions =
  | ReceiveEventsAction
  | ReceiveEventAction
  | AttendEventAction
  | CancelEventAction
  | CreateEventAction
  | UpdateEventAction
  | AbsentEventAction
  | DeleteEventAction
  | DefaultRelatedAction
  | DuplicateEventAction;

export type ReducerRelatedActionTypes = ExternalRelatedActions | OwnRelatedActions;

type CommonItemsState = Record<string, EventSimpleDto>;
type AllItemStates = CommonItemsState | AdminScopeState | UserScopeState;

// Scope keys below are type-bound. Don't change them until you are changing Event types.
export const eventAdminScopeKeys = <const>[
  'audience',
  'going_users',
];

export const eventUserScopeKeys = <const>[
  'going_users',
  'invited_users_count',
  'remaining_spots_count',
  'is_going',
  'registration_deadline',
];

const omitAdminScope = (item: EventForAdminDto) => R.omit(eventAdminScopeKeys, item);
const omitUserScope = (item: EventForUserDto) => R.omit(eventUserScopeKeys, item);
const pickAdminScope = (item: EventForAdminDto) => R.pick(eventAdminScopeKeys, item);
const pickUserScope = (item: EventForUserDto) => R.pick(eventUserScopeKeys, item);

type ReceiveItemsReturnType<I, S, F> = (
  I extends Array<any>
    ? F extends (i: never) => any
      ? Record<string, ReturnType<F>>
      : Record<string, TypeOfItemFromArray<I>>
    : S
  );

const receiveItems = <I, S extends AllItemStates, F>(
  items: I,
  state: S,
  processItem?: F,
): ReceiveItemsReturnType<I, S, F> => {
  if (Array.isArray(items)) {
    return items.reduce((acc, item) => R.assoc(
      item.id,
      typeof processItem === 'function' ? processItem(item) : item,
      acc,
    ), state);
  }

  return state as ReceiveItemsReturnType<never, S, never>;
};

const sharedItemsCases = <
  S,
  A extends ReducerRelatedActionTypes,
  >(state: S, action: A) => {
  switch (action.type) {
    case 'events/CANCEL_EVENT':
    case 'events/DELETE_EVENT':
      return R.omit([action.eventId], state);
    default:
      return state;
  }
};

const commonItems = (state: CommonItemsState = {}, action: ReducerRelatedActionTypes): typeof state => {
  switch (action.type) {
    case 'events/user/RECEIVE_EVENTS':
      return receiveItems(action.items, state, omitUserScope);
    case 'events/admin/RECEIVE_EVENTS':
      return receiveItems(action.items, state, omitAdminScope);
    case 'social/RECEIVE_EVENTS':
      return receiveItems(action.items, state, omitUserScope);
    case 'events/RECEIVE_EVENT':
      return receiveItems([action.item], state, omitAdminScope);
    case 'events/DUPLICATE_EVENT':
      return {
        ...state,
        ...receiveItems([action.response.data], state, omitAdminScope)
      };
    default:
      if ('related' in action && action.related?.events) {
        return action.related.events.reduce((acc, item) => R.assoc(item.id, item, acc), state);
      }

      return sharedItemsCases(state, action);
  }
};

type AdminScopeState = Record<string, EventAdminScopeFragment>;
const adminScope = (state: AdminScopeState = {}, action: ReducerRelatedActionTypes): typeof state => {
  switch (action.type) {
    case 'events/admin/RECEIVE_EVENTS':
      return receiveItems(action.items, state, pickAdminScope);
    case 'events/RECEIVE_EVENT':
      return receiveItems([action.item], state, pickAdminScope);
    case 'events/DUPLICATE_EVENT':
      return {
        ...state,
        ...receiveItems([action.response.data], state, pickAdminScope)
      };
    case 'events/ATTEND_EVENT': {
      const currentEvent = state[action.id];
      if (!currentEvent) return state;

      const newState = {
        ...state,
        [action.id]: {
          ...currentEvent,
          going_users: {
            count: (currentEvent.going_users?.count || 0) + 1,
            users_preview: [
              ...(currentEvent.going_users?.users_preview || []),
              action.user,
            ],
          },
        },
      };
      return newState;
    }
    case 'events/ABSENT_EVENT': {
      const currentEvent = state[action.id];
      if (!currentEvent) return state;

      const newState = {
        ...state,
        [action.id]: {
          ...currentEvent,
          going_users: {
            count: (currentEvent.going_users?.count || 1) - 1,
            users_preview: (currentEvent.going_users?.users_preview || []).filter(({ id }) => id !== action.userId),
          },
        },
      };
      return newState;
    }
    default:
      return sharedItemsCases(state, action);
  }
};

type UserScopeState = Record<string, EventUserScopeFragment>;
const userScope = (state: UserScopeState = {}, action: ReducerRelatedActionTypes): typeof state => {
  switch (action.type) {
    case 'events/user/RECEIVE_EVENTS':
      return receiveItems(action.items, state, pickUserScope);
    case 'social/RECEIVE_EVENTS':
      return receiveItems(action.items, state, pickUserScope);
    case 'events/RECEIVE_EVENT':
      return receiveItems([action.item], state, pickUserScope);
    case 'events/DUPLICATE_EVENT':
      return {
        ...state,
        ...receiveItems([action.response.data], state, pickUserScope)
      };
    case 'events/ATTEND_EVENT': {
      const currentEvent = state[action.id];
      if (!currentEvent) return state;

      return {
        ...state,
        [action.id]: {
          ...currentEvent,
          is_going: true,
          going_users: {
            count: (currentEvent.going_users?.count || 0) + 1,
            users_preview: [
              ...(currentEvent.going_users?.users_preview || []),
              action.user,
            ],
          },
        },
      };
    }
    case 'events/ABSENT_EVENT': {
      const currentEvent = state[action.id];
      if (!currentEvent) return state;

      return {
        ...state,
        [action.id]: {
          ...currentEvent,
          is_going: false,
          going_users: {
            count: (currentEvent.going_users?.count || 1) - 1,
            users_preview: (currentEvent.going_users?.users_preview || []).filter(({ id }) => id !== action.userId),
          },
        },
      };
    }
    default:
      return sharedItemsCases(state, action);
  }
};

const ids = (state: string[] = [], action: ReducerRelatedActionTypes): typeof state => {
  switch (action.type) {
    case 'events/user/RECEIVE_EVENTS':
    case 'events/admin/RECEIVE_EVENTS':
      // @ts-expect-error
      if (action.strategy === 'append') {
        return [...state, ...action.items.map((item) => item.id)];
      }

      return action.items.map((item) => item.id);
    case 'events/RECEIVE_EVENT':
      return [action.item.id];
    case 'events/DUPLICATE_EVENT':
      return state.map((id: string) => {
        if (id === action.originalEventId) {
          // let's put the duplicated event right before the original event, so
          // the positioning in the UI matches the order returned by the API
          return [action.response.data.id, id];
        }
        return id;
      }).flat();
    case 'events/CANCEL_EVENT':
      return state.filter((event) => event !== action.eventId);
    default:
      return state;
  }
};

const available = (state: string[] = [], action: ReducerRelatedActionTypes): typeof state => {
  switch (action.type) {
    case 'social/RECEIVE_EVENTS':
      return action.items.map((item) => item.id);
    case 'events/ABSENT_EVENT':
      return state.filter((id) => id !== action.id);
    default:
      return state;
  }
};

const countsInitialState = {
  upcoming: 0,
  draft: 0,
  past: 0,
  canceled: 0,
};

const counts = (state = countsInitialState, action: ReducerRelatedActionTypes): typeof state => {
  switch (action.type) {
    case 'events/admin/RECEIVE_EVENTS':
    case 'events/user/RECEIVE_EVENTS':
      return action.counts;
    case 'events/CANCEL_EVENT':
      return {
        ...state,
        canceled: state.canceled + 1,
        upcoming: state.upcoming - 1,
      };
    case 'events/DELETE_EVENT':
      return {
        ...state,
        upcoming: state.upcoming - 1,
      };
    default:
      return state;
  }
};

// @ts-expect-error
export const findById = (state: StoreState, id: string): Event => state.events.items.common[id];

const items = combineReducers({
  common: commonItems,
  admin: adminScope,
  user: userScope,
});

const eventsReducer = combineReducers({
  items,
  ids,
  available,
  counts,
});

export default eventsReducer;
