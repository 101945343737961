import * as R from 'ramda';
import * as moment from 'moment';

import clearNetwork from '@modules/network/actions/clear-network';
import Api from '@common/services/api';

export const ORGANISATION_CREATE_CHANNEL = 'organisation/CREATE_CHANNEL';
export const ORGANISATION_DELETE_CHANNEL = 'organisation/DELETE_CHANNEL';
export const ORGANISATION_RECEIVE_CHANNELS = 'organisation/RECEIVE_CHANNELS';
export const ORGANISATION_RECEIVE_MESSAGE = 'organisation/RECEIVE_MESSAGE';
export const ORGANISATION_RECEIVE_MESSAGES = 'organisation/RECEIVE_MESSAGES';
export const ORGANISATION_UPDATE_CHANNEL = 'organisation/UPDATE_CHANNEL';
export const ORGANISATION_UPDATE_MESSAGE = 'organisation/UPDATE_MESSAGE';
export const ORGANISATION_DELETE_MESSAGE = 'organisation/DELETE_MESSAGE';
export const ORGANISATION_CREATE_MESSAGE = 'organisation/CREATE_MESSAGE';
export const ORGANISATION_RESEND_INVITATION = 'organisation/RESEND_INVITATION';
export const ORGANISATION_DELETE_USER = 'organisation/DELETE_USER';
export const ORGANISATION_RESTORE_USER = 'organisation/ORGANISATION_RESTORE_USER';
export const ORGANISATION_UPDATE_USER = 'employees/UPDATE_USER';
export const ORGANISATION_INVITE_USER = 'organisation/INVITE_USER';
export const ORGANISATION_RECEIVE_IMPORTS = 'organisation/RECEIVE_IMPORTS';
export const ORGANISATION_PROCESS_IMPORT = 'organisation/PROCESS_IMPORT';
export const ORGANISATION_ADD_NETWORKS = 'organisation/ADD_NETWORKS';
export const ORGANISATION_UPDATE_ORGANISATION = 'organisation/UPDATE_ORGANISATION';
export const ORGANISATION_DELETE_FUNCTION = 'organisation/DELETE_FUNCTION';
export const ORGANISATION_ADD_FUNCTION = 'organisation/ADD_FUNCTION';
export const ORGANISATION_ADD_FUNCTIONS = 'organisation/ADD_FUNCTIONS';
export const ORGANISATION_RECEIVE_FUNCTION = 'organisation/RECEIVE_FUNCTION';
export const ORGANISATION_UPDATE_FUNCTION = 'organisation/UPDATE_FUNCTION';
export const ORGANISATION_UPDATE_FUNCTION_USERS = 'organisation/UPDATE_FUNCTION_USERS';
export const ORGANISATION_UPDATE_USER_ROLE = 'organisation/UPDATE_USER_ROLE';
export const ADMIN_CREATE_ROLE = 'admin/CREATE_ROLE';
export const ADMIN_UPDATE_ROLE = 'admin/UPDATE_ROLE';
export const ADMIN_REMOVE_ROLE = 'admin/REMOVE_ROLE';
export const ADMIN_REMOVE_ADMIN = 'admin/REMOVE_ADMIN';
export const ADMIN_COMPLETE_SETUP_STEP = 'admin/COMPLETE_SETUP_STEP';
export const ADMIN_REINVITE_USERS = 'admin/REINVITE_USERS';
export const ADMIN_ORDER_CHANNELS = 'admin/ORDER_CHANNELS';
export const ADMIN_RECEIVE_USER_COUNTS = 'admin/RECEIVE_USER_COUNTS';
export const ADMIN_RECEIVE_ACCESS_REQUESTS = 'admin/RECEIVE_ACCESS_REQUESTS';
export const ADMIN_APPROVE_ACCESS_REQUEST = 'admin/APPROVE_ACCESS_REQUEST';
export const ADMIN_REJECT_ACCESS_REQUEST = 'admin/REJECT_ACCESS_REQUEST';

export const restoreUser = (userId) => {
  return {
    userId,
    type: ORGANISATION_RESTORE_USER
  };
};

export const createChannel = (payload) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const { data: channel } = await Api.post(`/v1/organisations/${selected.id}/channels`, payload);

  return dispatch({
    type: ORGANISATION_CREATE_CHANNEL,
    channel,
  });
};

export const createMessage = (payload) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const { data: item, meta } = await Api.post(`/v1/organisations/${selected.id}/organisation_messages`, payload);

  return dispatch({
    type: ORGANISATION_CREATE_MESSAGE,
    item,
    message: item.message,
    meta,
  });
};

export const deleteChannel = (id) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  await Api.delete(`/v1/organisations/${selected.id}/channels/${id}`);

  dispatch({ type: ORGANISATION_DELETE_CHANNEL, id });
};

export const fetchChannels = () => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const request = await Api.get(`/v1/organisations/${selected.id}/channels?include=users`);

  dispatch({
    type: ORGANISATION_RECEIVE_CHANNELS,
    items: request.data,
    related: request.meta ? request.meta.related : {},
    pagination: { total_count: request.data.length },
  });

  return request;
};

export const fetchMessage = (messageId) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const { data: item, meta } = await Api.get(`/v1/organisations/${selected.id}/organisation_messages/${messageId}`);

  dispatch({
    type: ORGANISATION_RECEIVE_MESSAGE,
    item,
    message: item.message,
    meta
    // related: meta && meta.related,
  });
};

export const fetchMessages = (offset = 0, filter, limit = 25) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const query = Api.utils.toQuery({
    offset,
    limit,
    filter: filter?.filter || null,
    q: filter.q || undefined
  });

  const request = await Api.get(`/v1/organisations/${selected.id}/organisation_messages?${query}`);

  const { data: items, meta } = request;

  dispatch({
    type: ORGANISATION_RECEIVE_MESSAGES,
    items, // organisation messages containing audience
    messages: items.map((item) => item.message), // actual messages,
    append: offset !== 0,
    meta
  });

  return request;
};

export const updateChannel = (id, payload) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const { data: channel } = await Api.put(`/v1/organisations/${selected.id}/channels/${id}`, payload);

  return dispatch({
    type: ORGANISATION_UPDATE_CHANNEL,
    channel,
  });
};

export const updateMessage = (messageId, payload) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const { data: item } = await Api.put(`/v1/organisations/${selected.id}/organisation_messages/${messageId}`, payload);

  return dispatch({
    type: ORGANISATION_UPDATE_MESSAGE,
    item,
    message: item.message,
  });
};

export const deleteMessage = (messageId) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  await Api.delete(`/v1/organisations/${selected.id}/organisation_messages/${messageId}`);

  return dispatch({
    type: ORGANISATION_DELETE_MESSAGE,
    messageId,
  });
};

export const resendInvitation = (user, removeFromList = false) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  await Api.post(`/v2/organisations/${selected.id}/users/${user.id}/reinvite`);

  dispatch({
    type: ORGANISATION_RESEND_INVITATION,
    userId: user.id,
    removeFromList,
  });
};

export const deleteUser = (userId) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const response = await Api.delete(`/v2/organisations/${selected.id}/users/${userId}`);

  dispatch({ type: ORGANISATION_DELETE_USER, userId });

  return response;
};

const updateUserWhitelist = ['first_name', 'last_name', 'email', 'active_until', 'external_id'];

export const updateUser = (userId, values) => async (dispatch, getState) => {
  const { organisation: { selected: { id: organisationId } } } = getState();

  const payload = R.pick(updateUserWhitelist, values);
  if (values.date_of_birth) payload.date_of_birth = values.date_of_birth.format('YYYY-MM-DD');
  if (values.phone?.number) {
    payload.dial_code = values.phone.dial_code;
    payload.phone_num = values.phone.number;
  } else {
    payload.dial_code = null;
    payload.phone_num = null;
  }

  const promises = [];
  if (values.networks) promises.push(Api.put(`/v2/organisations/${organisationId}/users/${userId}/networks`, values.networks));
  if (values.functions) promises.push(Api.put(`/v2/organisations/${organisationId}/users/${userId}/functions`, values.functions));

  await Promise.all(promises);

  const { data: user } = await Api.put(`/v2/organisations/${organisationId}/users/${userId}`, payload);

  return dispatch({
    type: ORGANISATION_UPDATE_USER,
    user,
    links: {
      networks: values.networks,
      functions: values.functions,
    },
  });
};

const inviteUserWhitelist = [
  'first_name',
  'last_name',
  'email',
  'external_id',
  'phone_num',
  'dial_code',
  'date_of_birth',
  'function_ids',
  'onboarding',
  'onboarding_due_date',
  'active_until',
  'language_locale',
  'role_ids',
];

export const inviteUser = (values) => async (dispatch, getState) => {
  const { organisation: { selected, users: { items } }, network } = getState();

  const payload = R.pick(inviteUserWhitelist, values);
  payload.email = payload.email.trim();
  payload.networks = R.map((id) => ({ id }), values.network_ids);

  const { data: user } = await Api.post(`/v2/organisations/${selected.id}/users`, payload);
  user.network_ids = values.network_ids;

  const { roles } = user.scopes.organisation;

  const existing = R.findIndex(R.equals(user.id.toString()), items) !== -1;
  const inCurrentNetwork = values.network_ids.includes(network.selected);

  dispatch({
    type: ORGANISATION_INVITE_USER,
    user,
    existing,
    inCurrentNetwork,
    roles,
  });
};

export const fetchImports = () => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const { data: items } = await Api.get(`/v2/organisations/${selected.id}/imports`);

  dispatch({
    type: ORGANISATION_RECEIVE_IMPORTS,
    items,
  });
};

export const processImport = (id, { onboarding, shouldWelcome, shouldInvite }) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const { data } = await Api.post(`/v2/organisations/${selected.id}/imports/${id}/process`, {
    onboarding,
    should_welcome: shouldWelcome,
    should_send_invites: shouldInvite,
  });

  dispatch({
    type: ORGANISATION_PROCESS_IMPORT,
    item: data,
  });
};

export const reinviteUsers = (filter) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  await Api.post(`/v2/organisations/${selected.id}/users/reinvite?select=${filter}`);

  dispatch({
    type: ADMIN_REINVITE_USERS,
    filter,
  });
};

const pickNetworkValues = R.pick(['name']);

export const createNetwork = (values) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const payload = Array.isArray(values)
    ? values.map(pickNetworkValues)
    : pickNetworkValues(values);

  const { data } = await Api
    .post(`/v2/organisations/${selected.id}/networks`, payload);

  const { data: user } = await Api
    .get(window.subdomainId ? `/v3/users/me?whitelabel_organisation_id=${window.subdomainId}` : '/v3/users/me');

  // Add selected organisation as specific scope
  user.scopes.organisation = R.find(R.propEq('id', selected), user.scopes.organisations);

  return dispatch({
    type: ORGANISATION_ADD_NETWORKS,
    items: Array.isArray(data) ? data : [data],
    user,
  });
};

export const updateOrganisation = (values, selectedId) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();
  const organisationId = selectedId || selected.id;

  const payload = R.pick(
    [
      'name', 'settings', 'theme', 'default_language_locale',
      'access_request_links_enabled'
    ],
    values
  );

  const promises = [Api.put(`/v2/organisations/${organisationId}`, payload)];

  if (values.brand_icon) {
    const fd = new FormData();
    fd.append('brand_icon', values.brand_icon.file);

    promises.push(Api.post(`/v2/organisations/${organisationId}/picture`, fd));
  }

  if (values.theme_background_header) {
    const fd = new FormData();
    fd.append('theme_background_header', values.theme_background_header.file);

    promises.push(Api.post(`/v2/organisations/${organisationId}/theme/background_header`, fd));
  } else if (values.theme_background_header === false) {
    promises.push(Api.delete(`/v2/organisations/${organisationId}/theme/background_header`));
  }

  const [{ data: organisation }, picture] = await Promise.all(promises);

  if (picture) {
    organisation.brand_icon = `${picture.data.brand_icon}?update=${new Date().getTime()}`;
  }

  return dispatch({
    type: ORGANISATION_UPDATE_ORGANISATION,
    organisation,
  });
};

export const fetchFunction = (id) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const { data } = await Api.get(`/v2/organisations/${selected.id}/functions/${id}`);

  return dispatch({
    type: ORGANISATION_RECEIVE_FUNCTION,
    item: data,
  });
};

export const deleteFunction = (id) => (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  dispatch({ type: ORGANISATION_DELETE_FUNCTION, id });

  return Api.delete(`/v2/organisations/${selected.id}/functions/${id}`);
};

const pickFunctionValues = R.pick(['name']);

export const createFunction = (values) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const payload = Array.isArray(values)
    ? values.map(pickFunctionValues)
    : pickFunctionValues(values);

  const { data } = await Api.post(`/v2/organisations/${selected.id}/functions`, payload);

  if (Array.isArray(data)) {
    return dispatch({
      type: ORGANISATION_ADD_FUNCTIONS,
      items: data,
    });
  }

  return dispatch({
    type: ORGANISATION_ADD_FUNCTION,
    item: data,
  });
};

export const updateFunction = (values) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const payload = R.pick(['name'], values);

  const { data: item } = await Api
    .put(`/v2/organisations/${selected.id}/functions/${values.id}`, payload);

  return dispatch({
    type: ORGANISATION_UPDATE_FUNCTION,
    item,
  });
};

export const bulkUpdateFunctionUsers = (functionId, users) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  await Api.put(`/v2/organisations/${selected.id}/functions/${functionId}/users`, users);

  const { data: item } = await Api.get(`/v3/organisations/${selected.id}/functions/${functionId}`);

  return dispatch({
    type: ORGANISATION_UPDATE_FUNCTION_USERS,
    item,
  });
};

export const updateUserRoles = (userId, roles) => async (dispatch, getState) => {
  const { organisation: { selected, admins }, loggedUser } = getState();

  await Api.put(`/v1/organisations/${selected.id}/users/${userId}/roles`, roles);

  const addedAt = moment().format('YYYY-MM-DDTHH:mm:ss');
  const addRoles = R.map((roleId) => ({ id: roleId, memberships: { added_by: loggedUser.user.id, added_at: addedAt } }));

  const admin = R.find(R.propEq('id', userId), admins);
  const newRoles = admin
    ? R.concat(addRoles(roles.add || []), R.reject((role) => R.find(R.equals(role.id), roles.remove || []), admin.roles))
    : addRoles(roles.add);

  return dispatch({
    type: ORGANISATION_UPDATE_USER_ROLE,
    userId,
    roles: newRoles,
  });
};

export const createRole = (values) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const { data: item } = await Api.post(`/v1/organisations/${selected.id}/roles`, values);

  dispatch({
    type: ADMIN_CREATE_ROLE,
    item,
  });
};

export const updateRole = (id, values) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const { data: item } = await Api.put(`/v1/organisations/${selected.id}/roles/${id}`, values);

  dispatch({
    type: ADMIN_UPDATE_ROLE,
    item,
  });
};

export const removeAdmin = (userId) => async (dispatch, getState) => {
  const { organisation: { selected, admins } } = getState();

  const admin = R.find(R.propEq('id', userId), admins);

  if (!admin) throw new Error('Admin not found');

  Api.put(`/v1/organisations/${selected.id}/users/${userId}/roles`, {
    remove: R.pluck('id', admin.roles),
  });

  dispatch({
    type: ADMIN_REMOVE_ADMIN,
    userId,
  });
};

export const removeRole = (id) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  Api.delete(`/v1/organisations/${selected.id}/roles/${id}`);

  dispatch({
    type: ADMIN_REMOVE_ROLE,
    id,
  });
};

export const completeStep = (step) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  Api.put(`/v2/organisations/${selected.id}/setup_steps`, {
    [step]: true,
  });

  dispatch({
    type: ADMIN_COMPLETE_SETUP_STEP,
    step,
  });
};

export const updateChannelOrder = (order) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const { data: items } = await Api.put(`/v1/organisations/${selected.id}/channels`, {
    channels: order,
  });

  dispatch({
    type: ADMIN_ORDER_CHANNELS,
    items,
  });
};

export const getAccessCode = (networkId) => async (_, getState) => {
  const { organisation: { selected } } = getState();

  const path = networkId
    ? `/v1/organisations/${selected.id}/networks/${networkId}/access-requests/code`
    : `/v1/organisations/${selected.id}/access-requests/code`;

  const { data } = await Api.get(path);

  return data;
};

export const regenerateAccessCode = () => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const { data } = await Api.post(`/v1/organisations/${selected.id}/access-requests/code`);

  return data;
};

export const fetchAccessRequests = (cursor, filter) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const path = filter?.networkId
    ? `/v1/organisations/${selected.id}/networks/${filter.networkId}/access-requests`
    : `/v1/organisations/${selected.id}/access-requests`;

  const { data: items } = await Api.get(path);

  dispatch({
    type: ADMIN_RECEIVE_ACCESS_REQUESTS,
    items,
  });
};

export const fetchUserCounts = () => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const { data: counts } = await Api.get(`/v2/organisations/${selected.id}/users/counts`);

  dispatch({
    type: ADMIN_RECEIVE_USER_COUNTS,
    counts,
  });
};

export const approveAccessRequest = (id) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  await Api.post(`/v1/organisations/${selected.id}/access-requests/${id}/approve`);

  dispatch({
    type: ADMIN_APPROVE_ACCESS_REQUEST,
    id,
  });
};

export const rejectAccessRequest = (id) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  await Api.post(`/v1/organisations/${selected.id}/access-requests/${id}/reject`);

  dispatch({
    type: ADMIN_REJECT_ACCESS_REQUEST,
    id,
  });
};

export const deleteNetwork = (id) => async (dispatch, getState) => {
  const { organisation: { selected: { id: organisationId } }, network: { selected } } = getState();

  await Api.delete(`/v1/organisations/${organisationId}/networks/${id}`);

  if (id === selected) dispatch(clearNetwork());

  dispatch({
    type: 'admin/DELETE_NETWORK',
    id,
  });
};
