import { combineReducers } from 'redux';
import * as R from 'ramda';
import {
  SOCIAL_OBJECT_SEEN,
  SOCIAL_CLEAR_SEEN_QUEUE,
  SOCIAL_RECEIVE_FEED,
  SOCIAL_RECEIVE_SCHEDULED_MESSAGES,
  SOCIAL_RECEIVE_IMPORTANT_MESSAGES,
} from '../actions';

const addReadStatus = (acc, object) => R.assoc(object.id, object.seen, acc);

const objects = (state = {}, action) => {
  switch (action.type) {
    case SOCIAL_RECEIVE_FEED:
    case SOCIAL_RECEIVE_SCHEDULED_MESSAGES:
    case SOCIAL_RECEIVE_IMPORTANT_MESSAGES:
      return R.reduce(addReadStatus, state, action.items);
    case SOCIAL_OBJECT_SEEN:
      return { ...state, [action.objectId]: true };
    default: return state;
  }
};

const queue = (state = [], action) => {
  switch (action.type) {
    case SOCIAL_OBJECT_SEEN:
      return [...state, action.objectId];
    case SOCIAL_CLEAR_SEEN_QUEUE:
      return [];
    default: return state;
  }
};

export default combineReducers({ objects, queue });
