import * as R from 'ramda';
import { combineReducers } from 'redux';
import { SELECT_NETWORK } from '../../network/actions';
import { NETWORK_RECEIVE_ANALYTICS, NETWORK_RECEIVE_ANALYTICS_COUNTS, NETWORK_RECEIVE_ANALYTICS_USERS } from '../actions';

const data = (state = {}, action) => {
  switch (action.type) {
    case NETWORK_RECEIVE_ANALYTICS:
      return R.merge(state, R.indexBy(R.prop('metric_type'), action.metrics));
    case SELECT_NETWORK:
      return {};
    default: return state;
  }
};

const counts = (state = {}, action) => {
  switch (action.type) {
    case NETWORK_RECEIVE_ANALYTICS_COUNTS:
      return R.merge(state, R.indexBy(R.prop('metric_type'), action.metrics));
    case SELECT_NETWORK:
      return {};
    default: return state;
  }
};

const users = (state = {}, action) => {
  switch (action.type) {
    case NETWORK_RECEIVE_ANALYTICS_USERS:
      return R.merge(state, R.indexBy(R.prop('metricType'), action.metrics));
    case SELECT_NETWORK:
      return {};
    default: return state;
  }
};

export default combineReducers({ data, counts, users });
