import { combineReducers } from 'redux';
import {
  ORGANISATION_EDIT_FUNCTION,
  ORGANISATION_STOP_EDIT_FUNCTION,
} from '../actions';

const initialState = {
  search: { status: null, query: '' },
  editing: null,
  open: null,
};

const functions = (state = initialState, action) => {
  switch (action.type) {
    case ORGANISATION_EDIT_FUNCTION:
      return { ...state, editing: action.functionId };
    case ORGANISATION_STOP_EDIT_FUNCTION:
      return { ...state, editing: null };
    default:
      return state;
  }
};

export const organisation = combineReducers({ functions });
