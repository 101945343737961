import * as R from 'ramda';
import Api from '../../../common/services/api';
import { createActivity } from '../utils/activity';
import { EConversationActivityTypes } from '../definitions';

export const CHAT_UPDATE_CONVERSATION = 'chat/UPDATE_CONVERSATION';

export default (id, values) => async (dispatch, getState) => {
  const { loggedUser: { user: { id: loggedUserId } }, organisation: { selected } } = getState();

  let data;

  if (values.group_img) {
    const payload = new FormData();
    payload.append('image', values.group_img);
    payload.append('extension', 'jpg');

    ({ data } = await Api.post(`/v3/organisations/${selected.id}/conversations/${id}/group-image`, payload));
  }

  if (values.name || values.description) {
    ({ data } = await Api.put(`/v3/organisations/${selected.id}/conversations/${id}`, R.pick(['name', 'description'], values)));
  }

  if (data) {
    dispatch({
      type: CHAT_UPDATE_CONVERSATION,
      conversationId: id,
      item: data,
      activities: [
        values.name && createActivity(EConversationActivityTypes.CHANGED_NAME, {
          actor_id: loggedUserId,
          new_name: values.name,
        }),
      ].filter((a) => !!a),
    });
  }
};
