type ClassNames = (string | Record<string, boolean | void> | void)[];

export const combineClassNames = (...classNames: ClassNames) => classNames.reduce<string>((acc, className) => {
  if (typeof className === 'string') {
    if (!acc) {
      return className;
    }

    return `${acc} ${className}`;
  }

  if (className && typeof className === 'object' && !Array.isArray(className)) {
    return Object.entries(className).reduce<string>((
      conditionalAcc,
      [conditionalClassName, condition]: [string, boolean | void],
    ) => {
      if (condition) {
        if (!conditionalAcc) {
          return conditionalClassName;
        }

        return `${conditionalAcc} ${conditionalClassName}`;
      }

      return conditionalAcc;
    }, acc);
  }

  return acc;
}, '');
