import type { Mixpanel as MixpanelType } from 'mixpanel-browser';

let Module: MixpanelType | undefined;

// Prevent Google Analytics file to be loaded when no GA_ID is set
if (process.env.MIXPANEL_TOKEN && typeof document !== 'undefined') {
  Module = require('mixpanel-browser') as MixpanelType;

  Module.init(process.env.MIXPANEL_TOKEN, {}, '');
}

export const Mixpanel = Module;
