import React from 'react';
import { Trans } from 'react-i18next';

type GetTransFnReturnValue = {
  i18nKey: string | string[];
} & (
  {
    values: Record<string, string>;
    components?: JSX.Element[];
  } |
  {}
);

type GetTransFn = (...args: any[]) => GetTransFnReturnValue;

export const getTrans = (fn: GetTransFn) => {
  return function trans(...args: any[]) {
    const props = fn(...args);
    return (
      <Trans {...props} />
    );
  };
};
