import * as React from 'react';
import { Switch } from 'react-router';
import { loadable, PermissionRoute } from '../../common/components/route';
import { EComponentTypes, EPermissions } from '../../common/definitions';

require('./styles.scss');

const SurveyReportContainer = loadable(() => import('../survey/containers/report'));
const SurveyCompareReportContainer = loadable(() => import('../survey/containers/compare'));

const ReportTemplate = () => (
  <div className="ReportTemplate">
    <Switch>
      <PermissionRoute path="/report/surveys/:id/compare/:questionId" component={SurveyCompareReportContainer} permission={{ name: [EPermissions.ORGANISATION_SURVEYS_VIEW], component: EComponentTypes.SURVEYS }} />
      <PermissionRoute path="/report/surveys/:id" component={SurveyReportContainer} permission={{ name: [EPermissions.ORGANISATION_SURVEYS_VIEW], component: EComponentTypes.SURVEYS }} />
    </Switch>
  </div>
);

export default ReportTemplate;
