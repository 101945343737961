import React, { useEffect, useMemo, useState } from 'react';

import { combineClassNames } from '@utils/combineClassNames';
import { Message } from '../../message';

export type TextInputOwnProps = JSX.IntrinsicElements['input'] & {
  name?: string;
  type?: 'text' | 'email' | 'password';
  key?: string;
  className?: string;
  warning?: string | boolean;
  error?: string | boolean;
  mask?: boolean;
  autoGrow?: number;
  maxLength?: number;
  ref?: React.MutableRefObject<HTMLInputElement | undefined>,
};

export const TextInput = React.forwardRef<HTMLInputElement | undefined, TextInputOwnProps>(({
  key = 'text-input',
  type = 'text',
  value,
  className,
  error,
  warning,
  mask,
  autoGrow,
  maxLength,
  ...otherProps
}, ref) => {
  const fullClassName = combineClassNames('Form__control', className, {
    'Form__control--invalid': !warning && !!error,
    'Form__control--warning': !!warning,
    'fs-mask': mask,
  });

  const [width, setWidth] = useState('');
  const canvas = useMemo(() => {
    return autoGrow ? document.createElement('canvas') : undefined;
  }, [autoGrow]);

  useEffect(() => {
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.font = `${autoGrow}px Inter`;
        const metrics = ctx.measureText(`${value}`);
        setWidth(metrics.width > 0 ? `${Math.ceil(metrics.width)}px` : 'auto');
      }
    }
  }, [canvas, value, ref]);

  const inputElement = (
    <input
      ref={ref as React.ForwardedRef<HTMLInputElement>}
      style={{ width }}
      className={fullClassName}
      key={key}
      type={type}
      value={value}
      maxLength={maxLength}
      {...otherProps}
    />
  );

  if (maxLength && typeof value === 'string') {
    return (
      <>
        <div
          className={combineClassNames('Form__TextInput Form__TextInput--max-length', {
            'Form__TextInput--invalid': !warning && !!error,
          })}
        >
          {inputElement}
          <small>
            {value?.length || 0}/{maxLength}
          </small>
        </div>
        <Message error={error} warning={warning} />
      </>
    );
  }
  return (
    <>
      {inputElement}
      <Message error={error} warning={warning} />
    </>
  );
});
