import { combineReducers } from 'redux';
import * as R from 'ramda';
import {
  SURVEYS_RECEIVE_SURVEY,
  SURVEYS_CREATE_QUESTION,
  SURVEYS_UPDATE_SCREEN,
  SURVEYS_UPDATE_SCREEN_ORDER,
  SURVEYS_SAVE_SCREEN_ORDER,
} from '../../survey/actions';
import {
  ACADEMY_RECEIVE_MODULE,
  ACADEMY_ADD_SCREEN,
  ACADEMY_UPDATE_SCREEN,
  ACADEMY_REMOVE_SCREEN,
  ACADEMY_BULK_UPDATE_SCREENS,
} from '../actions';

import type { LooseObject } from '@common/types/util-types';
import type {
  CreateQuestionAction,
  FetchFormAction,
  DuplicateScreenAction,
  DeleteQuestionAction,
  UpdateScreenOrderAction,
  SaveScreenOrderAction,
} from '../../forms/actions';

const addScreenToStore = R.curry((state, screen) => R.assoc(
  screen.id,
  {
    ...state[screen.id],
    ...R.omit(['components'], screen),
    component_ids: screen.components.map((c: any) => c.id),
  },
  state,
));
export const addScreensToStore = (state: any, screens: any[]) => {
  return R.reduce(addScreenToStore, state, screens);
};

// @ts-expect-error
const setIndex = (state, item) => R.assoc(item.id, R.assoc('index', item.index, state[item.id]), state);
// @ts-expect-error
const setInitialIndex = (state, id) => R.assoc(id, R.assoc('initial_index', state[id].index, state[id]), state);

type ScreenReducerForeignActions =
  | CreateQuestionAction
  | DeleteQuestionAction
  | DuplicateScreenAction
  | FetchFormAction
  | UpdateScreenOrderAction
  | SaveScreenOrderAction;

type ScreenReducerAnyAction = LooseObject<{ type: Exclude<string, ScreenReducerForeignActions['type']> }>;

type ScreenReducerActions = ScreenReducerForeignActions | ScreenReducerAnyAction;

const items = (state = {}, action: ScreenReducerActions) => {
  switch (action.type) {
    case ACADEMY_UPDATE_SCREEN:
    case ACADEMY_ADD_SCREEN:
    case SURVEYS_CREATE_QUESTION:
    case SURVEYS_UPDATE_SCREEN:
    case 'forms/CREATE_QUESTION':
    case 'forms/DUPLICATE_SCREEN':
      return addScreenToStore(state, action.screen);
    case ACADEMY_RECEIVE_MODULE:
    case SURVEYS_RECEIVE_SURVEY:
    case 'forms/RECEIVE_FORM':
    case 'forms/CREATE_FORM':
    case 'forms/DUPLICATE_FORM':
      // @ts-expect-error
      return addScreensToStore(state, action.item.screens);
    case ACADEMY_REMOVE_SCREEN:
    case 'forms/DELETE_QUESTION':
      // @ts-expect-error
      return R.omit([action.id], state);
    case ACADEMY_BULK_UPDATE_SCREENS:
      // console.log("debug ACADEMY_BULK_UPDATE_SCREENS action", action);
      // console.log("debug ACADEMY_BULK_UPDATE_SCREENS state", state);
      // @ts-expect-error
      const newState = R.reduce(addScreenToStore, state, action.screens);
      // console.log("debug ACADEMY_BULK_UPDATE_SCREENS newState", newState);
      return newState;
    case SURVEYS_UPDATE_SCREEN_ORDER:
    case 'forms/UPDATE_SCREEN_ORDER':
      // @ts-expect-error
      return R.reduce(setIndex, state, action.items);
    case SURVEYS_SAVE_SCREEN_ORDER:
    case 'forms/SAVE_SCREEN_ORDER':
      // @ts-expect-error
      return R.reduce(setInitialIndex, state, action.order);
    default: return state;
  }
};

export const findById = R.curry((state, id) => {
  const screen = state.academy.screens.items[id];

  // console.log("debug screen.findById before format", screen);

  if (!screen) return undefined;

  const question = screen.question_id && state.academy.questions.items[screen.question_id];

  // Overwrite title of screen with question text
  if (question) {
    screen.title = question.parameters.text;
  }

  const result = {
    ...R.omit(['component_ids', 'question_id'], screen),
    components: R.pipe(
      // @ts-expect-error
      R.map((componentId) => state.academy.components.items[componentId]),
      R.reject(R.isNil),
      R.sortBy(R.prop('index')),
    )(screen.component_ids || []),
  };

  // console.log("debug screen.findById after format", result);

  return result;
});

export default combineReducers({ items });
