import React from 'react';
import { Icon, IconTypes } from '@common/components/icon';
import { combineClassNames } from '@common/utils/combineClassNames';

type BaseProps = {
  children: React.ReactNode;
};

export const ModalSubNavigation = ({ children }: BaseProps) => (
  <div className="Modal__SubNavigation">
    {children}
  </div>
);

export const ModalSubNavigationTitle = ({ children }: BaseProps) => (
  <h3>{children}</h3>
);

type ItemProps = {
  icon?: IconTypes;
  image?: string;
  selected?: boolean;
  onClick: () => void;
};

export const ModalSubNavigationItem = ({
  children,
  icon,
  image,
  selected,
  onClick,
}: BaseProps & ItemProps) => (
  <div
    className={combineClassNames('Modal__SubNavigationItem', {
      'Modal__SubNavigationItem--selected': selected,
    })}
    onClick={onClick}
    role="button"
  >
    {icon && <Icon type={icon} />}
    {image && <img src={image} alt="Icon" />}
    {children}
  </div>
);
