export const MINUTE = 60;
export const SECOND = 1000;

export const SECONDS_IN_MINUTE = MINUTE * SECOND;
export const HOUR_IN_MINUTES = 60;
export const DAY_IN_MINUTES = HOUR_IN_MINUTES * 24;

export const DAY_IN_MILLISECONDS = DAY_IN_MINUTES * MINUTE * 1000;

export enum EParentTypes {
  ORGANISATION = 'organisation',
  NETWORK = 'network',
  TEAM = 'team',
  CHANNEL = 'channel',
  EVENT = 'event',
}

export enum WhiteLabelInvitationMode {
  LINK = 'link',
  CODE = 'code',
}

export const ELevels = {
  ORGANISATION: 2,
  NETWORK: 1,
};

export enum EStatus {
  LIVE = 'live',
  DRAFT = 'draft',
  ARCHIVED = 'archived',
}

export enum EPlanLevels {
  PROFESSIONAL = 3,
  ESSENTIAL = 2,
  STARTER = 1,
}

export enum ENotification {
  ON = 1,
  OFF = 2,
}

export enum EPlanPackageConfig {
  ADVANCED_USER_ROLES = 'advanced_user_roles',
  ACADEMY = 'academy_courses',
  ACADEMY_ONBOARDING = 'courses_onboarding',
  ACADEMY_AUDIENCE_SELECTOR = 'audience_selector_course',
  ACADEMY_LEARNING_PATHS = 'learning_paths',
  ACADEMY_QUIZ_MODULES = 'quiz_modules',
  ACADEMY_DYNAMIC_DEADLINES = 'dynamic_deadlines',
  ACADEMY_PHASING = 'phasing',
  ACADEMY_HINTS_FEEDBACK = 'quiz_feedback_and_hints',
  EVENTS = 'events',
  DOCUMENTS_USER_SPECIFIC = 'user_specific_documents',
  FOLDER_SPECIFIC_PERMISSIONS = 'folder_specific_permissions',
  FORMS_ADVANCED_PERMISSIONS = 'advanced_form_permissions',
  FORMS_AUDIENCE_SELECTOR = 'audience_selector_form',
  FORMS_EMAIL_FORWARDING = 'email_forwarding',
  FORMS_SPECIAL_INPUT = 'special_input',
  FORMS_SUBMISSION_STATUS = 'submission_status',
  FORMS = 'forms',
  ORGANISATION_GROUPS = 'organisation_groups',
  PULSE_SURVEYS = 'pulse_surveys',
  PULSE_SURVEYS_AUDIENCE_SELECTOR = 'audience_selector_pulse_survey',
  PULSE_SURVEYS_ANALYTICS_PER_CATEGORY = 'analytics_per_category',
  PULSE_SURVEYS_ENPS_TRACKING = 'enps_tracking',
  SURVEYS = 'surveys',
  SURVEYS_AUDIENCE_SELECTOR = 'audience_selector_survey',
  SURVEYS_AUDIENCE_SELECTOR_ONBOARDING = 'surveys_onboarding',
  SURVEYS_ADVANCED_ANALYTICS = 'advanced_analytics',
  SURVEYS_RESULTS_EXPORT = 'results_export',
  THEMING = 'theming',
}

export const ENotificationActivityTypes = {
  FORM_SUBMISSION_CREATED: 'form_submission_created',
  FORM_SUBMISSION_COMMENT_CREATED: 'form_submission_comment_created',
  NEW_COURSE: 'new_course',
  COURSE_ALMOST_DUE: 'course_almost_due'
};

export const ESourceTypes = {
  MESSAGE: 'message',
  EXCHANGE: 'exchange',
  CONVERSATION: 'conversation',
  ONBOARDING: 'onboarding',
  SCHEDULE: 'schedule',
  COURSE: 'course',
  EVENT: 'event',
  ACCESS_REQUEST: 'access_request',
  FORM_SUBMISSION: 'form_submission',
  SURVEY: 'survey'
};

export const EExplanationVideos = {
  TIMELINE: 'timeline',
  FLEXCHANGE: 'flexchange',
  ONBOARDING: 'onboarding',
  ORGANISATION_MESSAGES: 'organisation-messages',
};

export const EComponentTypes = <const>{
  SOCIAL: 'SOCIAL',
  FLEXCHANGE: 'FLEXCHANGE',
  SCHEDULE: 'SCHEDULE',
  CHAT: 'CHAT',
  ONBOARDING: 'ONBOARDING',
  CHANNELS: 'CHANNELS',
  ACADEMY: 'ACADEMY',
  ANALYTICS: 'ANALYTICS',
  SURVEYS: 'SURVEYS',
  SURVEYS_PREMIUM: 'SURVEYS_PREMIUM',
  PULSE_SURVEYS: 'PULSE_SURVEYS',
  GIPHY: 'GIPHY',
  EVENTS: 'EVENTS',
  DOCUMENTS: 'DOCUMENTS',
  TRANSLATION: 'TRANSLATION',
  FEED_TRANSLATION: 'FEED_TRANSLATION',
  FORMS: 'FORMS',
  FORMS_PREMIUM: 'FORMS_PREMIUM',
  HOME: 'HOME',
};

export const ENetworkSpecificComponentTypes = <const>{
  SCHEDULE: EComponentTypes.SCHEDULE,
  FLEXCHANGE: EComponentTypes.FLEXCHANGE,
  CHAT: EComponentTypes.CHAT,
  SOCIAL: EComponentTypes.SOCIAL
};

export enum EPrivacyLevels {
  NOBODY,
  NETWORK,
  ORGANISATION,
}

export const EFileTypes = <const>{
  UNKNOWN: 'null',
  IMAGE: 'image',
  GIF: 'gif',
  VIDEO: 'video',
  AUDIO: 'audio',
  DOCUMENT: 'document',
  SPREADSHEET: 'spreadsheet',
  PRESENTATION: 'presentation',
  EXTERNAL_VIDEO: 'external_video',
  PDF: 'pdf',
};

export enum EAttachmentParentTypes {
  WELCOME_MESSAGE = 'welcome_message',
  ORGANISATION_MESSAGE = 'organisation_message',
  DEFAULT_MESSAGE = 'default_message',
  PRIVATE_MESSAGE = 'private_message',
  FEED_MESSAGE = 'feed_message',
}

export type FileTypes = typeof EFileTypes[keyof typeof EFileTypes];

export const fileTypesStyles = {
  [EFileTypes.UNKNOWN]: '/static/images/files/unknown.png',
  [EFileTypes.IMAGE]: '/static/images/files/jpg.png',
  [EFileTypes.GIF]: '/static/images/files/jpg.png',
  [EFileTypes.VIDEO]: '/static/images/files/mp4.png',
  [EFileTypes.AUDIO]: '/static/images/files/audio.png',
  [EFileTypes.DOCUMENT]: '/static/images/files/doc.png',
  [EFileTypes.SPREADSHEET]: '/static/images/files/xls.png',
  [EFileTypes.PRESENTATION]: '/static/images/files/presentation.png',
  [EFileTypes.EXTERNAL_VIDEO]: '/static/images/files/mp4.png',
  [EFileTypes.PDF]: '/static/images/files/pdf.png',
};

export enum EPredicateFields {
  NETWORK = 'membership.networks.id',
  FUNCTION = 'membership.functions.id',
  USER = 'users.id',
  LANGUAGE = 'translation_language_locale',
  DAYS_IN_SERVICE = 'days_in_service',
  DAYS_BEFORE_OUT_OF_SERVICE = 'days_before_out_of_service',
  DATE = 'date',
}

export enum EPredicateOperators {
  IN = 'in',
  EQ = 'eq',
  GT = 'gt',
  GTE = 'gte',
  BETWEEN = 'between',
  LT = 'lt',
  LTE = 'lte',
}

export enum ESearchPredicateTypes {
  MATCH_ANY = 'match_any',
  MATCH_ALL = 'match_all',
}

export const EMetricTypes = {
  PRIVATE_MESSAGES_CREATED: 'private_message.created',
  FEED_MESSAGES_CREATED: 'feed_message.created',
  HIGHFIVES_CREATED: 'highfive.created',
  COMMENTS_CREATED: 'comment.created',
  USERS_ACTIVE: 'users.active',
  USERS_INVITED: 'users.invited',
  USERS_REGISTERED: 'users.registered',
  USERS_PENDING: 'users.pending',
  EXCHANGES_CREATED: 'exchanges.created',
  EXCHANGES_APPROVED: 'exchanges.approved',
  EXCHANGES_TOTAL_APPROVED_TIME_IN_SECONDS: 'exchanges.total_approved_time_in_seconds',
};

export const EPermissions = <const>{
  // User management
  ORGANISATION_USERS_CREATE: 'organisation::users.create',
  ORGANISATION_USERS_UPDATE: 'organisation::users.update',
  ORGANISATION_USERS_REMOVE: 'organisation::users.remove',
  ORGANISATION_USERS_VIEW: 'organisation::users.view',

  // Channel management
  ORGANISATION_CHANNELS_CREATE: 'organisation::channels.create',
  ORGANISATION_CHANNELS_UPDATE: 'organisation::channels.update',
  ORGANISATION_CHANNELS_REMOVE: 'organisation::channels.remove',
  ORGANISATION_CHANNELS_VIEW: 'organisation::channels.view',

  // Organisation messages management
  ORGANISATION_ORGANISATION_MESSAGES_CREATE: 'organisation::organisation-messages.create',
  ORGANISATION_ORGANISATION_MESSAGES_UPDATE: 'organisation::organisation-messages.update',
  ORGANISATION_ORGANISATION_MESSAGES_REMOVE: 'organisation::organisation-messages.remove',
  ORGANISATION_ORGANISATION_MESSAGES_VIEW: 'organisation::organisation-messages.view',

  // Academy management
  ORGANISATION_ONBOARDING_COURSES_CREATE: 'organisation::onboarding-courses.create',
  ORGANISATION_ONBOARDING_COURSES_UPDATE: 'organisation::onboarding-courses.update',
  ORGANISATION_ONBOARDING_COURSES_REMOVE: 'organisation::onboarding-courses.remove',
  ORGANISATION_ONBOARDING_COURSES_VIEW: 'organisation::onboarding-courses.view',
  ORGANISATION_ONBOARDING_STATISTICS: 'organisation::onboarding.statistics',
  ORGANISATION_ONBOARDING_COURSES_STATISTICS: 'organisation::onboarding-courses.statistics',

  ORGANISATION_ACADEMY_COURSES_CREATE: 'organisation::academy-courses.create',
  ORGANISATION_ACADEMY_COURSES_UPDATE: 'organisation::academy-courses.update',
  ORGANISATION_ACADEMY_COURSES_REMOVE: 'organisation::academy-courses.remove',
  ORGANISATION_ACADEMY_COURSES_VIEW: 'organisation::academy-courses.view',
  ORGANISATION_ACADEMY_COURSES_STATISTICS: 'organisation::academy-courses.statistics',

  // Analytics
  ORGANISATION_USER_STATISTICS_VIEW: 'organisation::user-statistics.view',
  ORGANISATION_USER_STATISTICS_EXPORT: 'organisation::user-statistics.export',
  ORGANISATION_COMMUNICATION_STATISTICS_VIEW: 'organisation::communication-statistics.view',
  ORGANISATION_COMMUNICATION_STATISTICS_EXPORT: 'organisation::communication-statistics.export',
  ORGANISATION_EXCHANGE_STATISTICS_VIEW: 'organisation::exchange-statistics.view',
  ORGANISATION_EXCHANGE_STATISTICS_EXPORT: 'organisation::exchange-statistics.export',

  // Location management
  ORGANISATION_TEAMS_CREATE: 'organisation::teams.create',
  ORGANISATION_TEAMS_UPDATE: 'organisation::teams.update',
  ORGANISATION_TEAMS_REMOVE: 'organisation::teams.remove',
  ORGANISATION_TEAMS_VIEW: 'organisation::teams.view',

  ORGANISATION_NETWORK_ADMINS_ADD: 'organisation::network-admins.add',
  ORGANISATION_NETWORK_ADMINS_REMOVE: 'organisation::network-admins.remove',
  ORGANISATION_NETWORK_ADMINS_VIEW: 'organisation::network-admins.view',

  ORGANISATION_NETWORKS_UPDATE: 'organisation::networks.update',
  ORGANISATION_NETWORKS_CREATE: 'organisation::networks.create',
  ORGANISATION_NETWORKS_UPDATE_COMPONENTS: 'organisation::networks.update-components',

  // Organisation management
  ORGANISATION_ORGANISATIONS_UPDATE: 'organisation::organisations.update',

  ORGANISATION_ORGANISATIONS_FUNCTIONS_CREATE: 'organisation::organisation-functions.create',
  ORGANISATION_ORGANISATIONS_FUNCTIONS_UPDATE: 'organisation::organisation-functions.update',
  ORGANISATION_ORGANISATIONS_FUNCTIONS_REMOVE: 'organisation::organisation-functions.remove',
  ORGANISATION_ORGANISATIONS_FUNCTIONS_VIEW: 'organisation::organisation-functions.view',

  ORGANISATION_ORGANISATION_ADMINS_ADD: 'organisation::organisation-admins.add',
  ORGANISATION_ORGANISATION_ADMINS_REMOVE: 'organisation::organisation-admins.remove',
  ORGANISATION_ORGANISATION_ADMINS_VIEW: 'organisation::organisation-admins.view',

  ORGANISATION_ROLES_CREATE: 'organisation::roles.create',
  ORGANISATION_ROLES_UPDATE: 'organisation::roles.update',
  ORGANISATION_ROLES_REMOVE: 'organisation::roles.remove',
  ORGANISATION_ROLES_VIEW: 'organisation::roles.view',

  // Communication management
  ORGANISATION_FEED_MESSAGES_MARK_IMPORTANT: 'organisation::feed-messages.mark-important',
  ORGANISATION_FEED_MESSAGES_READ_STATISTICS: 'organisation::feed-messages.read-statistics',
  ORGANISATION_FEED_MESSAGES_REMOVE: 'organisation::feed-messages.remove',

  // Flexchange management
  NETWORK_FUNCTION_MEMBERSHIPS_EXCHANGES_VIEW_ALL: 'network:function-membership:exchanges.view-all',
  NETWORK_FUNCTION_MEMBERSHIPS_EXCHANGES_REVIEW: 'network:function-membership:exchanges.review',

  // Schedule management
  NETWORK_FUNCTION_MEMBERSHIPS_SCHEDULES_VIEW_ALL: 'network:function-membership:schedules.view-all',
  NETWORK_FUNCTION_MEMBERSHIPS_SCHEDULES_CREATE: 'network:function-membership:schedules.create',
  NETWORK_FUNCTION_MEMBERSHIPS_SCHEDULES_UPDATE: 'network:function-membership:schedules.update',
  NETWORK_FUNCTION_MEMBERSHIPS_SCHEDULES_REMOVE: 'network:function-membership:schedules.remove',

  // User management
  NETWORK_USERS_CREATE: 'network::users.create',
  NETWORK_USERS_UPDATE: 'network::users.update',
  NETWORK_USERS_REMOVE: 'network::users.remove',
  NETWORK_USERS_VIEW: 'network::users.view',

  // Analytics
  NETWORK_USER_STATISTICS_VIEW: 'network::user-statistics.view',
  NETWORK_USER_STATISTICS_EXPORT: 'network::user-statistics.export',
  NETWORK_COMMUNICATION_STATISTICS_VIEW: 'network::communication-statistics.view',
  NETWORK_COMMUNICATION_STATISTICS_EXPORT: 'network::communication-statistics.export',
  NETWORK_EXCHANGE_STATISTICS_VIEW: 'network::exchange-statistics.view',
  NETWORK_EXCHANGE_STATISTICS_EXPORT: 'network::exchange-statistics.export',

  // Academy management
  NETWORK_ONBOARDING_STATISTICS: 'network::onboarding.statistics',
  NETWORK_ELEARNING_STATISTICS: 'network::academy-courses.statistics',

  // Location management
  NETWORK_TEAMS_CREATE: 'network::teams.create',
  NETWORK_TEAMS_UPDATE: 'network::teams.update',
  NETWORK_TEAMS_REMOVE: 'network::teams.remove',
  NETWORK_TEAMS_VIEW: 'network::teams.view-all',

  NETWORK_NETWORK_ADMINS_ADD: 'network::network-admins.add',
  NETWORK_NETWORK_ADMINS_REMOVE: 'network::network-admins.remove',
  NETWORK_NETWORK_ADMINS_VIEW: 'network::network-admins.view',

  NETWORK_NETWORKS_UPDATE: 'network::networks.update',
  NETWORK_NETWORKS_UPDATE_COMPONENTS: 'network::networks.update-components',

  // Communication management
  NETWORK_FEED_MESSAGES_MARK_IMPORTANT: 'network::feed-messages.mark-important',
  NETWORK_FEED_MESSAGES_READ_STATISTICS: 'network::feed-messages.read-statistics',
  NETWORK_FEED_MESSAGES_REMOVE: 'network::feed-messages.remove',

  // Flexchange management
  NETWORK_EXCHANGES_VIEW_ALL: 'network::exchanges.view-all',
  NETWORK_EXCHANGES_REVIEW: 'network::exchanges.review',

  // Schedule management
  NETWORK_SCHEDULES_VIEW_ALL: 'network::schedules.view-all',
  NETWORK_SCHEDULES_CREATE: 'network::schedules.create',
  NETWORK_SCHEDULES_UPDATE: 'network::schedules.update',
  NETWORK_SCHEDULES_REMOVE: 'network::schedules.remove',

  // Global admin rights
  GLOBAL_NETWORK_ADMINS_ADD: 'network::global-network-admins.add',
  GLOBAL_NETWORK_ADMINS_REMOVE: 'network::global-network-admins.remove',
  GLOBAL_ORGANISATION_ADMINS_ADD: 'organisation::global-organisation-admins.add',
  GLOBAL_ORGANISATION_ADMINS_REMOVE: 'organisation::global-organisation-admins.remove',

  // Surveys management
  ORGANISATION_SURVEYS_VIEW: 'organisation::surveys.view-all',
  ORGANISATION_SURVEYS_CREATE: 'organisation::surveys.create',
  ORGANISATION_SURVEYS_UPDATE: 'organisation::surveys.update',
  ORGANISATION_SURVEYS_REMOVE: 'organisation::surveys.remove',

  // Setup
  ORGANISATION_SETUP_STEPS_UPDATE: 'organisation::organisation-setup-steps.update',

  // Events
  ORGANISATION_EVENTS_CREATE: 'organisation::events.create',
  ORGANISATION_EVENTS_VIEW_ALL: 'organisation::events.view-all',
  ORGANISATION_EVENTS_UPDATE: 'organisation::events.update',
  ORGANISATION_EVENTS_REMOVE: 'organisation::events.remove',
  ORGANISATION_EVENTS_POST_MESSAGE: 'organisation::events.create-feed-message',

  // Documents
  ORGANISATION_DOCUMENTS_CREATE: 'organisation::documents.create',
  ORGANISATION_DOCUMENTS_VIEW_ALL: 'organisation::documents.view-all',
  ORGANISATION_DOCUMENTS_UPDATE: 'organisation::documents.update',
  ORGANISATION_DOCUMENTS_REMOVE: 'organisation::documents.remove',
  NETWORK_DOCUMENTS_CREATE: 'network::documents.create',
  NETWORK_DOCUMENTS_VIEW_ALL: 'network::documents.view-all',
  NETWORK_DOCUMENTS_UPDATE: 'network::documents.update',
  NETWORK_DOCUMENTS_REMOVE: 'network::documents.remove',

  // Personal documents
  ORGANISATION_PERSONAL_DOCUMENTS_VIEW_ALL: 'organisation::personal-documents.view-all',
  ORGANISATION_PERSONAL_DOCUMENTS_CREATE: 'organisation::personal-documents.create',

  // Forms
  ORGANISATION_FORMS_VIEW_ALL: 'organisation::forms.view-all',
  ORGANISATION_FORMS_CREATE: 'organisation::forms.create',
  ORGANISATION_FORMS_UPDATE: 'organisation::forms.update',
  ORGANISATION_FORMS_REMOVE: 'organisation::forms.remove',
  ORGANISATION_FORMS_VIEW_REPORT: 'organisation::forms.view-report',

  // Forms creator (can manage only the forms user creates)
  ORGANISATION_CREATOR_FORMS_VIEW: 'organisation:creator:forms.view-created',
  ORGANISATION_CREATOR_FORMS_CREATE: 'organisation:creator:forms.create',
  ORGANISATION_CREATOR_FORMS_UPDATE: 'organisation:creator:forms.update-created',
  ORGANISATION_CREATOR_FORMS_REMOVE: 'organisation:creator:forms.remove-created',
  ORGANISATION_CREATOR_FORMS_REPORT: 'organisation:creator:forms.view-report-created'
};

export type Permissions = typeof EPermissions[keyof typeof EPermissions];

export const ECompanyDashboardPermissions = [
  EPermissions.ORGANISATION_USER_STATISTICS_VIEW,
  EPermissions.ORGANISATION_COMMUNICATION_STATISTICS_VIEW,
  EPermissions.ORGANISATION_EXCHANGE_STATISTICS_VIEW,
  EPermissions.ORGANISATION_ORGANISATION_MESSAGES_VIEW,
  EPermissions.ORGANISATION_CHANNELS_VIEW,
  EPermissions.ORGANISATION_ONBOARDING_COURSES_VIEW,
  EPermissions.ORGANISATION_ACADEMY_COURSES_VIEW,
  EPermissions.ORGANISATION_ORGANISATIONS_FUNCTIONS_VIEW,
  EPermissions.ORGANISATION_USERS_VIEW,
  EPermissions.ORGANISATION_ORGANISATION_ADMINS_VIEW,
  EPermissions.ORGANISATION_ROLES_VIEW,
  EPermissions.ORGANISATION_ORGANISATIONS_UPDATE,
  EPermissions.ORGANISATION_SURVEYS_VIEW,
  EPermissions.ORGANISATION_EVENTS_VIEW_ALL,
  EPermissions.ORGANISATION_FORMS_VIEW_ALL,
  EPermissions.ORGANISATION_CREATOR_FORMS_VIEW
];

export const EOrganisationMessagesPermissions = [
  EPermissions.ORGANISATION_ORGANISATION_MESSAGES_CREATE,
  EPermissions.ORGANISATION_ORGANISATION_MESSAGES_UPDATE,
  EPermissions.ORGANISATION_ORGANISATION_MESSAGES_REMOVE,
  EPermissions.ORGANISATION_ORGANISATION_MESSAGES_VIEW
];

export const EDismissTypes = {
  SURVEY_RELEASE: 'survey_release',
  FORMS_RELEASE: 'forms_release',
};

export const PaymentStatus = {
  OVERDUE: 'overdue',
  PAID: 'paid'
};
