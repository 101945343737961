import { combineReducers } from 'redux';
import { CORE_INITIALISE } from '../actions';

const available = (state = [], action) => {
  switch (action.type) {
    case CORE_INITIALISE:
      return action.languages;
    default: return state;
  }
};

const translations = (state = [], action) => {
  switch (action.type) {
    case CORE_INITIALISE:
      return action.translations;
    default: return state;
  }
};

const dialCodes = (state = [], action) => {
  switch (action.type) {
    case CORE_INITIALISE:
      return action.dialCodes;
    default: return state;
  }
};

export default combineReducers({ available, translations, dialCodes });
