import * as R from 'ramda';
import { AnyAction, combineReducers } from 'redux';
import moment from 'moment';
import * as usersReducer from '../../core/reducers/users';
import {
  CHAT_RECEIVE_CONVERSATIONS,
  CHAT_RECEIVE_CONVERSATION,
  CHAT_RECEIVE_MESSAGES,
  CHAT_ADD_MESSAGE,
  CHAT_UPDATE_CONVERSATION,
  CHAT_REMOVE_PARTICIPANT,
  CHAT_ADD_PARTICIPANTS,
  CHAT_LEAVE_CONVERSATION,
  CHAT_ADD_GROUP_ADMIN,
  CHAT_REMOVE_MESSAGE,
  CHAT_REMOVE_CONVERSATION,
  CHAT_UPDATE_MESSAGE,
  CHAT_ADD_EMOJI_REACTION,
  CHAT_REMOVE_EMOJI_REACTION,
  CHAT_GET_EMOJI_REACTION_USERS,
  CHAT_SET_USERS_STATUS,
} from '../actions';
import * as attachmentsReducer from './attachments';
import { createTextForActivity } from '../utils/activity';

import type { MessageEmojiReaction, SimpleUser, StoredEmojiReaction } from '@common/types/objects';
import _ from 'lodash';
import { StoreState } from '@common/types/store';

// @ts-expect-error
export const objectToSource = (object) => ({
  ...object.source,
  type: object.object_type,
  source_type: object.source.type,
  user_id: object.user_id,
  created_at: object.created_at,
  deleted_at: object.deleted_at,
  object_id: object.id,
  parent_source_id: object.parent_message && object.parent_message.source_id || null,
});

// @ts-expect-error
const addToStore = (acc, object) => R.pipe(
  R.assoc(object.source_id, objectToSource(object)),
  (
    object.parent_message
    && !acc[object.parent_message.source_id]
    && R.assoc(object.parent_message.source_id, objectToSource(object.parent_message))
  ) || R.identity,
)(acc);

// @ts-expect-error
const findLastMessages = R.pipe(R.pluck('last_message'), R.filter(R.is(Object)));

const items = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case CHAT_RECEIVE_CONVERSATIONS:
      // @ts-expect-error
      return R.reduce(addToStore, state, findLastMessages(action.items));
    case CHAT_RECEIVE_CONVERSATION:
      if (!action.item.last_message) return state;

      return addToStore(state, action.item.last_message);
    case CHAT_RECEIVE_MESSAGES:
      return R.reduce(addToStore, state, action.items.reverse());
    case CHAT_ADD_MESSAGE:
      return addToStore(state, action.item);
    case CHAT_UPDATE_CONVERSATION:
    case CHAT_REMOVE_PARTICIPANT:
    case CHAT_ADD_PARTICIPANTS:
    case CHAT_LEAVE_CONVERSATION:
    case CHAT_ADD_GROUP_ADMIN:
      // @ts-expect-error
      return R.reduce((acc, activity) => R.assoc(activity.id, activity, acc), state, action.activities);
    case CHAT_REMOVE_MESSAGE:
      return R.evolve({
        [action.messageId]: (message) => ({
          ...message,
          text: '',
          deleted_at: moment().toISOString(),
        }),
      }, state);
    case CHAT_REMOVE_CONVERSATION:
      return R.omit(action.messageIds, state);
    default: return state;
  }
};

// @ts-expect-error
const getMessageIds = (messages) => R.pluck('source_id', messages);
// @ts-expect-error
const addMessageToConversation = (state, conversation) => {
  if (!conversation.last_message) return state;

  return state[conversation.id]
    ? R.evolve({
      [conversation.id]: R.pipe(
        R.append(conversation.last_message.source_id),
        R.uniq,
      ),
    }, state)
    : R.assoc(conversation.id, getMessageIds([conversation.last_message]), state);
};
const addMessagesToConversation = R.curry((messages, ids = []) => R.pipe(
  // @ts-expect-error
  R.concat(R.pluck('source_id', messages)),
  R.uniq,
)(ids || []));

type EmojiReactionsState = Record<string, StoredEmojiReaction[]>;
type MinimalEmojiMessage = {
  id: string;
  reactions: MessageEmojiReaction;
}; // to remove after full TS conversion

const emojiReactions = (state: EmojiReactionsState = {}, action: AnyAction): typeof state => {
  switch (action.type) {
    case CHAT_RECEIVE_MESSAGES:
      return action.items.reduce((acc: EmojiReactionsState, { id, reactions }: MinimalEmojiMessage) => ({
        ...acc,
        [id]: reactions,
      }), state);
    case CHAT_ADD_MESSAGE:
    case CHAT_UPDATE_MESSAGE:
      return {
        ...state,
        [action.item.id]: action.item.reactions,
      };
    case CHAT_REMOVE_MESSAGE:
      return R.omit([action.messageId], state);
    case CHAT_REMOVE_CONVERSATION:
      return R.omit(action.messageIds, state);
    case CHAT_ADD_EMOJI_REACTION: {
      const messageReactions = state[action.messageId];
      if (!messageReactions) return state;

      const existingEmoji = messageReactions.find((newReaction) => newReaction.short_name === action.newReactionEmoji.short_name);

      if (existingEmoji) {
        const newSelectedEmoji: StoredEmojiReaction = {
          ...existingEmoji,
          count: existingEmoji.count + 1,
          is_selected_by_user: true,
        };

        if (newSelectedEmoji.userIds && !newSelectedEmoji.usersNextCursor) {
          newSelectedEmoji.userIds = [
            ...newSelectedEmoji.userIds,
            action.loggedUserId,
          ];
        }

        const newReactions = R.clone(messageReactions);
        newReactions.splice(messageReactions.indexOf(existingEmoji), 1, newSelectedEmoji);

        return {
          ...state,
          [action.messageId]: newReactions,
        };
      }

      const newSelectedEmoji: StoredEmojiReaction = {
        ...action.newReactionEmoji,
        is_selected_by_user: true,
        count: 1,
      };

      return {
        ...state,
        [action.messageId]: [
          ...messageReactions,
          newSelectedEmoji,
        ],
      };
    }

    case CHAT_REMOVE_EMOJI_REACTION: {
      const messageReactions = state[action.messageId];
      if (!messageReactions) return state;

      const newMessageReactions = messageReactions
        .map((reaction) => {
          if (reaction.short_name === action.reactionEmoji.short_name) {
            return {
              ...reaction,
              userIds: reaction.userIds?.filter((id) => id !== action.loggedUserId),
              count: reaction.count - 1,
              is_selected_by_user: false,
            };
          }
          return reaction;
        })
        .filter((reaction) => reaction.count > 0);

      return {
        ...state,
        [action.messageId]: newMessageReactions,
      };
    }

    case CHAT_GET_EMOJI_REACTION_USERS: {
      const messageReactions = state[action.messageId];
      const reactionToModify = messageReactions?.find((reaction) => reaction.short_name === action.reactionShortName);
      if (!reactionToModify) return state;

      const newUserIds = action.reactionUsers.map((user: SimpleUser) => user.id);

      const newReaction = {
        ...reactionToModify,
        userIds: action.strategy === 'clear' ? newUserIds : [
          ...(reactionToModify?.userIds || []),
          ...newUserIds,
        ],
      };

      const newMessageReactions = R.clone(messageReactions);
      newMessageReactions.splice(messageReactions.indexOf(reactionToModify), 1, newReaction);

      return {
        ...state,
        [action.messageId]: newMessageReactions,
      };
    }

    default:
      return state;
  }
};

const conversations = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case CHAT_RECEIVE_CONVERSATIONS:
      return R.reduce(addMessageToConversation, state, action.items);
    case CHAT_RECEIVE_CONVERSATION:
      return addMessageToConversation(state, action.item);
    case CHAT_RECEIVE_MESSAGES:
      return R.assoc(
        action.conversationId,
        // @ts-expect-error
        addMessagesToConversation(action.items, state[action.conversationId]),
        state,
      );
    case CHAT_ADD_MESSAGE:
      return R.assoc(
        action.conversationId,
        // @ts-expect-error
        addMessagesToConversation([action.item], state[action.conversationId]),
        state,
      );
    case CHAT_UPDATE_CONVERSATION:
    case CHAT_REMOVE_PARTICIPANT:
    case CHAT_ADD_PARTICIPANTS:
    case CHAT_LEAVE_CONVERSATION:
    case CHAT_ADD_GROUP_ADMIN:
      return R.assoc(
        action.conversationId,
        // @ts-expect-error
        [...state[action.conversationId], ...R.pluck('id', action.activities)],
        state,
      );
    case CHAT_REMOVE_CONVERSATION:
      return R.omit([action.conversationId], state);
    default: return state;
  }
};

const findUser = (state: StoreState, userId: string) => {
  const user = usersReducer.findById(state, userId);
  if (!user) {
    const metas = Object.values(state.chat.messages.meta);
    for (let i = 0; i < metas.length; i++) {
      const meta = metas[i];
      const metaUser = meta?.related?.users?.find((u: any) => {
        return u?.id === userId;
      });
      if (metaUser) {
        return metaUser;
      }
    }
  }
  return user;
};

export const findById = R.curry((state, id) => {
  const m = state.chat.messages.items[id];

  if (!m) return null;

  const message = { ...m };

  const user = usersReducer.findById(state, message.user_id);

  // We store activities as messages
  if (message.type === 'conversation_activity') {
    // Add user object
    if (message.meta_data && message.meta_data.user_id) {
      message.meta_data.user = findUser(state, message.meta_data.user_id);
    }

    // Add actor object
    if (message.meta_data && message.meta_data.actor_id) {
      message.meta_data.actor = findUser(state, message.meta_data.actor_id);
    }

    message.text = createTextForActivity(message, state.loggedUser.user.id);
  }

  if (message.parent_source_id) {
    message.parent_message = findById(state, message.parent_source_id);
  }

  const reactions = (state.chat.messages.emojiReactions[id] || []).map(({
    userIds,
    ...emojiReaction
  }: StoredEmojiReaction): MessageEmojiReaction => ({
    ...emojiReaction,
    users: userIds?.map((userId: string) => state.users.items[userId]),
  }));

  return {
    ...R.omit(['user_id'], message),
    user,
    reactions,
    attachments: attachmentsReducer.findByMessage(state, id),
    timestamp: new Date(message.created_at),
  };
});

export const findByConversation = R.curry((state, id) => {
  const result = R.pipe(
    // @ts-expect-error
    R.reject(R.isNil),
    R.map(findById(state)),
    (messages) => messages.sort((a, b) => {
      // @ts-expect-error
      if (a.created_at === b.created_at) {
        // @ts-expect-error
        return a.object_id > b.object_id ? 1 : -1;
      }
      // @ts-expect-error
      return a.created_at > b.created_at ? 1 : -1;
    }),
  // @ts-expect-error
  )(state.chat.messages.conversations[id] || []);

  return result;
});

// TODO: create last message reducer instead of always looping/sorting an array of ids
export const findLastByConversation = R.curry((state, id) => {
  if (!state.chat.messages.conversations[id]) return undefined;

  const messages = state.chat.messages.conversations[id];
  if (!messages || messages.length <= 0) {
    return null;
  }
  const lastMessageId = messages.reduce((selectedId: string, currentId: string) => {
    const selectedMessage = state.chat.messages.items[selectedId];
    const currentMessage = state.chat.messages.items[currentId];
    if (!selectedMessage) return currentId;
    if (!currentMessage) return selectedId;

    const selectedDate = (new Date(selectedMessage.created_at)).getTime();
    const currentDate = (new Date(currentMessage.created_at)).getTime();
    if (
      (selectedDate > currentDate) ||
      // ids are counters, so we look for the biggest id in order to
      // determine the most recent/last message
      (selectedDate === currentDate && parseInt(selectedId) > parseInt(currentId))
    ) {
      return selectedId;
    }
    return currentId;
  });

  return findById(state, lastMessageId);
});

const meta = (
  state: Record<string, any> = {},
  action: Record<string, any>
) => {
  switch (action.type) {
    case CHAT_RECEIVE_MESSAGES:
      return {
        ...state,
        [action.conversationId]: action.meta
      };
    case CHAT_SET_USERS_STATUS:
      return updateMetaUsersStatus(state, action);
    default:
      return state;
  }
};

function updateMetaUsersStatus(
  state: Record<string, any>,
  action: Record<string, any>
) {
  const { statuses } = action;
  return _.mapValues(state, (conversationMeta: any) => {
    return {
      ...conversationMeta,
      related: {
        ...conversationMeta?.related,
        users: conversationMeta?.related?.users?.map((user: any) => {
          if (user.id in statuses) {
            return {
              ...user,
              membership: {
                ...user?.membership,
                status: statuses[user.id] || null
              }
            };
          }
          return user;
        })
      }
    };
  });
}

// @ts-expect-error
export default combineReducers({ items, conversations, emojiReactions, meta });
