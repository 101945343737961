import { combineReducers } from 'redux';
import {
  SELECT_NETWORK,
  ADMIN_START_EDIT_TEAM,
  ADMIN_START_EDIT_TEAM_STOP,
  SET_INITIALIZING
} from '../actions';

const initState = {
  initializing: false
};

const global = (state = initState, action) => {
  switch (action.type) {
    case SET_INITIALIZING:
      return { ...state, initializing: action.value };
    default:
      return state;
  }
};

const initialState = {
  search: { status: null, query: '' },
  editing: null,
  open: null
};

const teams = (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_START_EDIT_TEAM:
      return { ...state, editing: action.teamId };
    case ADMIN_START_EDIT_TEAM_STOP:
      return { ...state, editing: null };
    case SELECT_NETWORK:
      return initialState;
    default: return state;
  }
};

export default combineReducers({ teams, global });
