import * as R from 'ramda';
import { combineReducers } from 'redux';
import { ORGANISATION_RECEIVE_IMPORTS, ORGANISATION_PROCESS_IMPORT } from '../actions';
import { SELECT_ORGANISATION } from '../../organisation/actions';

const items = (state = [], action) => {
  switch (action.type) {
    case ORGANISATION_RECEIVE_IMPORTS:
      return action.items;
    case ORGANISATION_PROCESS_IMPORT:
      return R.append(action.item, state);
    case SELECT_ORGANISATION:
      return [];
    default: return state;
  }
};

export const findAll = (state) => state.admin.imports.items;

export default combineReducers({ items });
