import Api from '../../../common/services/api';

export const CHAT_RECEIVE_CONVERSATION = 'chat/RECEIVE_CONVERSATION';

export default (conversationId) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const { data: item, meta } = await Api.get(`/v3/organisations/${selected.id}/conversations/${conversationId}`);

  item.unread = item.last_message
    ? item.last_message.user_id !== selected && item.last_message.seen === false
    : false;

  dispatch({
    type: CHAT_RECEIVE_CONVERSATION,
    conversationId,
    item,
    related: meta.related,
  });
};
