import { combineReducers, Reducer } from 'redux';
import * as R from 'ramda';
import {
  LEARNING_FETCH_COURSES,
  LEARNING_FETCH_COURSE,
  LEARNING_UPDATE_COURSE,
  LEARNING_UPDATE_COURSES_ORDER,
  LEARNING_REMOVE_COURSE,
  LEARNING_CREATE_COURSE,
  LEARNING_DUPLICATE_COURSE,
  LEARNING_DUPLICATE_MODULE,
  ACADEMY_UPDATE_MODULE
} from '../actions';
import { predicatesToFilters } from '@common/utils/predicates';
import type { StoreState } from '@common/types/store';
import type { ChangeCourseTypeAction } from '../actions';
import type { Course } from '../types/objects';

const addToStore = R.curry((state, course) => R.assoc(course.id, course, state));

// TODO: It's a temporary solution. To be replaced by real types;
type TypeToLooseAction<T> = {
  type: T;
  items: unknown[];
  [key: string]: unknown;
};

export type ReducerRelatedActionTypes =
  | ChangeCourseTypeAction
  | TypeToLooseAction<typeof LEARNING_FETCH_COURSES>
  | TypeToLooseAction<typeof LEARNING_FETCH_COURSE>
  | TypeToLooseAction<typeof LEARNING_UPDATE_COURSE>
  | TypeToLooseAction<typeof LEARNING_UPDATE_COURSES_ORDER>
  | TypeToLooseAction<typeof LEARNING_REMOVE_COURSE>
  | TypeToLooseAction<typeof LEARNING_CREATE_COURSE>
  | TypeToLooseAction<typeof LEARNING_DUPLICATE_COURSE>
  | TypeToLooseAction<typeof LEARNING_DUPLICATE_MODULE>
  | TypeToLooseAction<typeof ACADEMY_UPDATE_MODULE>;

type ItemsState = Record<string, Course>;

export const items: Reducer<ItemsState, ReducerRelatedActionTypes> = (state = {}, action) => {
  // console.log("debug action", action);
  switch (action.type) {
    case LEARNING_FETCH_COURSES:
      return R.reduce(addToStore, state, action.items.map(R.assoc('sections', [])));
    case LEARNING_FETCH_COURSE:
    case LEARNING_UPDATE_COURSE:
    case LEARNING_CREATE_COURSE:
    case LEARNING_DUPLICATE_COURSE:
      return addToStore(state, action.item);
    case LEARNING_UPDATE_COURSES_ORDER:
      return {
        ...R.reduce((acc, course) => R.evolve({
          // @ts-expect-error
          [course.id]: R.assoc('index', course.index),
        }, acc), state, action.items),
      };
    case LEARNING_REMOVE_COURSE:
      // @ts-expect-error
      return R.omit([action.courseId], state);
    case LEARNING_DUPLICATE_MODULE:
      return R.evolve({
        // @ts-expect-error
        [action.courseId]: R.evolve({
          sections: R.append(R.omit(['modules'], {
            // @ts-expect-error
            ...action.section,
            // @ts-expect-error
            module_ids: R.pluck('id', action.section.modules),
          })),
        }),
      }, state);
    case ACADEMY_UPDATE_MODULE:
      if (!action.courseId) return state;

      return R.evolve({
        // @ts-expect-error
        [action.courseId]: R.clone,
      }, state);
    case 'academy/CHANGE_COURSE_TYPE':
      return R.omit([action.courseId], state);
    default: return state;
  }
};

export const findById = R.curry((state: StoreState, id) => {
  // @ts-expect-error
  const course = state.learning.courses.items[id];

  if (!course) return;

  const filters = predicatesToFilters(course.settings.visibility.predicates, state);

  return { ...course, filters };
});

export const findByIdWithModules = R.curry((state, id) => {
  const course = findById(state, id);

  if (!course) return;

  return {
    ...course,
    // @ts-expect-error
    sections: course.sections ? course.sections.map((section) => ({
      ...section,
      module_ids: section.module_ids || [],
      // Fix for incorrect module index returned
      // @ts-expect-error
      modules: (section.module_ids || []).map((moduleId, index) => ({ ...state.academy.modules.items[moduleId], index })),
    })) : [],
  };
});

export default combineReducers({ items });
