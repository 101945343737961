import * as R from 'ramda';
import { createSelector } from 'reselect';
import { ExtendedUser } from '@common/types/objects';
import memoize from '@common/utils/memoize';
import { MENTIONS_REGEX } from '@common/constants';
import type { StoreState } from '@common/types/store';
import * as usersReducer from '../reducers/users';

const getUserIds = (_: any, text: string) => text;

export const getStoredUsers = (state: StoreState) => state.users.items;

export const byIds = memoize.createItemSelector(
  [getUserIds],
  (state, text) => {
    if (!text) return [];
    // @ts-expect-error
    const userIds = R.map((format) => format.match(/\d+/)[0], text.match(MENTIONS_REGEX) || []);

    return R.reduce((acc, id) => R.assoc(id, usersReducer.findById(state, id), acc), {}, R.uniq(userIds));
  },
);

const getUser = (state: StoreState, id: string) => state.users.detail[id];
const getOrganisationScope = (state: StoreState, id: string) => state.users.scopes.organisation[id];

// do not rely on role.memberships[], see comments on PD-8672
const getNetworkScopes = (state: StoreState, id: string) => state.users.scopes.networks[id];

const getFunctionScopes = (state: StoreState, id: string) => state.users.scopes.functions[id];
const getTeamScopes = (state: StoreState, id: string) => state.users.scopes.teams[id];

export const byId = createSelector(
  [getUser, getOrganisationScope, getNetworkScopes, getFunctionScopes, getTeamScopes],
  (user, organisationScope, networkScopes, functionScopes, teamScopes): ExtendedUser | null => {
    if (!user) return null;

    return {
      ...user,
      should_load: user.can_chat === undefined,
      scopes: {
        // @ts-expect-error
        organisation: organisationScope,
        // @ts-expect-error
        networks: networkScopes,
        // @ts-expect-error
        functions: functionScopes,
        // @ts-expect-error
        teams: teamScopes,
      },
    };
  },
);
