import { combineReducers } from 'redux';
import { SOCIAL_CHANGE_FEED_ORDER } from '../actions';
import { EFeedSortTypes } from '../definitions';

const order = (state = EFeedSortTypes.LAST_CREATED, action) => {
  switch (action.type) {
    case SOCIAL_CHANGE_FEED_ORDER:
      return action.order;
    default: return state;
  }
};

export default combineReducers({ order });
