import * as R from 'ramda';

import Storage from '../storage';
import { refreshToken } from '../../utils/authentication';
import { BASE_URL } from '../../constants';
import { getI18n } from 'react-i18next';

export const getApiUrl = (endpoint: string): string => BASE_URL + endpoint;

export const getAccessToken = async (clearToken = false) => {
  const accessToken = Storage.getCookie('access_token');

  if (accessToken && !clearToken) return accessToken;

  return refreshToken();
};

export type Headers = {
  Accept: string;
  'X-API-Token'?: string;
  'Content-Type'?: string;
};

const defaultHeaders: Headers = {
  Accept: 'application/json'
};

export const getApiHeaders = async (
  payload?: unknown,
  requireAuth = true,
  clearToken?: boolean,
): Promise<Headers> => {
  const headers: any = {
    ...defaultHeaders,
  };
  const i18n = getI18n();
  if (i18n?.language) {
    headers['Accept-Language'] = i18n.language.toLowerCase();
  }
  // Disable for authenticate calls
  if (requireAuth) {
    headers['X-API-Token'] = await getAccessToken(clearToken);
  }

  if (R.not(R.is(FormData, payload))) headers['Content-Type'] = 'application/json';

  return headers;
};

export const logApiError = async (body: unknown, extra: Record<string, unknown>): Promise<void> => {
  if (!window.Raven) return;

  window.Raven.captureException(body, { level: 'warning', response: body, extra });
};

type ParameterTypes = (string | number)[] | string | number | boolean;
type Parameters = Record<string, ParameterTypes | undefined | null>;

// TODO this function is badly named, serializing values is the opposite of
// parsing
const parseQueryValues = (key: string, values: ParameterTypes) => {
  if (Array.isArray(values)) {
    if (values.length === 0) return false;

    return values
      .map((value) => `${key}[]=${encodeURI(value.toString())}`)
      .join('&');
  }

  return `${key}=${encodeURI(values.toString())}`;
};

export const toQuery = (parameters: Parameters) => Object.entries(parameters)
  .reduce((acc, [key, value]) => {
    if (!value) return acc;

    const parsedValues = parseQueryValues(key, value);
    const and = acc ? '&' : '';

    if (!parsedValues) return acc;

    return `${acc}${and}${parsedValues}`;
  }, '');
