import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { Trans, withTranslation } from 'react-i18next';

import { Group, Row, TextInput } from '@common/components/form';
import { Button } from '@common/components/button';
import Alert from '@common/components/alert';
import updateProfile from '../../../core/actions/update-profile';
import validate from './errors';

class ResetPasswordForm extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    invalid: PropTypes.bool,
  };

  constructor() {
    super();

    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = { formAlert: null };
  }

  async handleSubmit(values, dispatch) {
    const { onSuccess } = this.props;

    try {
      await dispatch(updateProfile(values, true));

      onSuccess();
    } catch (error) {
      return this.setState({
        formAlert: {
          type: 'warning',
          message: this.props.t('authentication:reset_password_error'),
        },
      });
    }
  }

  render() {
    const { formAlert } = this.state;
    const { handleSubmit, submitting, invalid, t } = this.props;

    const alert = formAlert && <Alert type={formAlert.type}>{formAlert.message}</Alert>;

    return (
      <div className="Authentication__Form">
        <h2><Trans i18nKey="authentication:change_password" /></h2>
        <div className="Authentication__Form__Description"><Trans i18nKey="authentication:change_password_explanation" /></div>
        <form className="Form" onSubmit={handleSubmit(this.handleSubmit)}>
          {alert}
          <Row>
            <Group>
              <TextInput
                mask
                name="password_new"
                placeholder={t('authentication:reset_password_new')}
                type="password"
                autoComplete="new-password"
              />
            </Group>
          </Row>
          <Row>
            <Group>
              <TextInput
                mask
                name="password_repeat"
                placeholder={t('authentication:reset_password_repeat')}
                type="password"
                autoComplete="new-password"
              />
            </Group>
          </Row>

          <Button
            type="primary"
            size="fill"
            isLoading={submitting}
            disabled={invalid}
            buttonType="submit"
          >
            <Trans i18nKey="authentication:reset_password_save" />
          </Button>
        </form>
      </div>
    );
  }
}

export default withTranslation()(reduxForm({
  form: 'reset-password',
  validate,
  initialValues: {
    password_new: '',
    password_repeat: '',
  },
})(ResetPasswordForm));
