import React from 'react';
import { combineClassNames } from '@common/utils/combineClassNames';

type Props = {
  status: number;
  processing?: boolean;
  error?: boolean;
  size?: 'large';
  showPercentage?: boolean;
};

export const ProgressBar = React.memo<Props>(({
  status,
  processing,
  error,
  size,
  showPercentage,
}: Props) => {
  const className = ['ProgressBar__Status'];
  if (error) className.push('ProgressBar__Status--error');
  if (!error && processing) className.push('ProgressBar__Status--processing');
  if (size) className.push(`ProgressBar--size-${size}`);

  const barClassName = combineClassNames('ProgressBar', {
    'ProgressBar--full': status >= 100
  });

  return (
    <div className="ProgressBar__Container">
      <div className={barClassName}>
        <div className={className.join(' ')} style={{ width: `${status}%` }} />
      </div>
      {
        showPercentage && (
          <span>
            {status}
            %
          </span>
        )
      }
    </div>
  );
});
