import React from 'react';
import { combineClassNames } from '@utils/combineClassNames';

type Props = {
  children: React.ReactNode;
  name?: string;
  className?: string;
};

type SubProps = {
  children: React.ReactNode;
};

const ContainerComponent = ({ children, name, className }: Props) => {
  const classNames = combineClassNames('Container', className, {
    'Container--no-scroll': (
      Array.isArray(children) &&
      children.some((c) => c && c.type.scrollable)
    )
  });

  return <div className={classNames} id={name}>{children}</div>;
};

type ContentProps = {
  className?: string;
  horizontal?: boolean;
};

ContainerComponent.Content = ({ className, children, horizontal = false }: SubProps & ContentProps) => {
  const classNames = combineClassNames('Container__Content', className, {
    'Container__Content--horizontal': horizontal,
  });

  return <div className={classNames}>{children}</div>;
};

// @ts-expect-error
ContainerComponent.Content.Bar = ({ children }: SubProps) => (
  <div className="Container__Content__Bar">
    {children}
  </div>
);

// @ts-expect-error
ContainerComponent.Content.Balloon = ({ children }: SubProps) => (
  <div className="Container__Content__Balloon">
    {children}
  </div>
);

ContainerComponent.SideBar = ({
  children,
  className,
  size,
}: SubProps & { className?: string, size?: 'small' | 'medium' | 'large' | 'xlarge' }) => {
  const classNames = combineClassNames('Container__SideBar', className, {
    [`Container__SideBar--size-${size}`]: !!size,
  });

  return <div className={classNames}>{children}</div>;
};

ContainerComponent.Column = ({ children }: SubProps) => (
  <div className="Container__Column">{children}</div>
);

ContainerComponent.Scrollable = ({ children }: SubProps) => (
  <div className="Container__Scrollable">{children}</div>
);

// @ts-expect-error
ContainerComponent.Scrollable.scrollable = true;

export default ContainerComponent;
export const Container = ContainerComponent;
