import {
  ADMIN_RECEIVE_ACCESS_REQUESTS,
  ADMIN_APPROVE_ACCESS_REQUEST,
  ADMIN_REJECT_ACCESS_REQUEST,
} from '../actions';
import type { AccessRequest } from '../types';

// @ts-expect-error
export default (state: AccessRequest[] = [], action) => {
  switch (action.type) {
    case ADMIN_RECEIVE_ACCESS_REQUESTS:
      return action.items;
    case ADMIN_APPROVE_ACCESS_REQUEST:
    case ADMIN_REJECT_ACCESS_REQUEST:
      return state.filter((request) => request.id !== action.id);
    default: return state;
  }
};
