import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useAppSelector } from '@common/hooks';
import { selected } from '@modules/organisation/selectors/organisation';
import getCurrentPlanPackage from '@common/utils/data/get-current-plan-package';
import { EPlanPackageConfig } from '@common/definitions';
import {
  getTranslationKeyCTA, getTranslationKeyPlanName, getTranslationKeyTitle, getUpgradePlanPackage,
} from './utils';

import UpsellModal from '../upsell-modal';

type UpgradePlanPackageModalProps = {
  show: boolean;
  value?: number | boolean;
  packageId: `${EPlanPackageConfig}`;
  onHide: () => void;
};

const UpgradePlanPackageModal = ({
  packageId, value, show, onHide,
}: UpgradePlanPackageModalProps) => {
  const { t } = useTranslation();
  const organisation = useAppSelector(selected);
  const current = getCurrentPlanPackage(organisation);
  const upgrade = getUpgradePlanPackage(packageId, current?.level, organisation, value);
  const planValue = current && current[packageId];

  return (
    <UpsellModal
      title={t(getTranslationKeyTitle(packageId), { planValue })}
      link={{
        name: t('pricing:link_text'),
        url: t('pricing:link'),
      }}
      intercom={{
        buttonText: t('pricing:button_customer_success'),
      }}
      show={show}
      onHide={onHide}
    >
      {(upgrade && (
        <Trans
          i18nKey={getTranslationKeyCTA(packageId)}
          values={{
            planValue,
            nextValue: upgrade[packageId] === true ? t('pricing:access_true') : upgrade[packageId],
            nextPlanName: `<strong>${t(getTranslationKeyPlanName(upgrade.level))}</strong>`,
          }}
        />
      )) || t('pricing:not_allowed_no_upgrade_cta')}
    </UpsellModal>
  );
};

export default UpgradePlanPackageModal;
