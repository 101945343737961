import * as React from 'react';
import { withTranslation } from 'react-i18next';
import AsyncList from './async';
import SearchBar from '../search-bar';

const SearchableListComponent = withTranslation()(({ data, t, ...props }) => {
  const [query, setQuery] = React.useState(null);

  return (
    <>
      <SearchBar placeholder={t('common:search_list_search_placeholder')} onSearch={setQuery} />
      <AsyncList
        {...props}
        data={{
          ...data,
          filter: {
            ...(data.filter || {}),
            query,
          },
        }}
      />
    </>
  );
});

export default SearchableListComponent;
