import * as R from 'ramda';
import moment from 'moment';
import { parseCookie } from '@common/utils/authentication';

export default (() => {
  let storage;

  return {
    setDriver(driver) {
      storage = driver;
    },
    getDriver() {
      return storage;
    },
    hasDriver() {
      return !!storage;
    },
    get(key) {
      return storage ? storage.getItem(key) || null : null;
    },
    getObject(key, prop, defaultValue) {
      try {
        const item = JSON.parse(storage.getItem(key)) || defaultValue;

        return prop ? item[prop] : item;
      } catch (e) {
        return null;
      }
    },
    getCookie(key) {
      if (typeof document === 'undefined') return;

      const cookies = R.pipe(
        R.split(/;\s|;/),
        R.reduce((acc, cookie) => {
          const [name, value] = cookie.split(/=(.*)/);

          return R.assoc(name, value, acc);
        }, {}),
      )(document && document.cookie || '');

      return cookies[key] || null;
    },
    set(key, value) {
      return storage ? storage.setItem(key, value) : false;
    },
    setObject(key, value) {
      if (!storage) return false;

      if (!R.is(Object, value) && !Array.isArray(value)) {
        throw new Error('Cannot set object when value is a string');
      }

      return storage.setItem(key, JSON.stringify(value));
    },
    setCookie(name, value, expiration) {
      let expiresAt;

      if (!document) return false;

      if (expiration) {
        const [duration, input] = expiration;

        expiresAt = moment()
          .add(duration, input)
          .utc()
          .locale('en-en')
          .format('ddd, D MMM YYYY HH:mm:ss z');
      }

      const cookie = expiresAt
        ? `${name}=${value}; expires=${expiresAt}; path=/`
        : `${name}=${value}; path=/`;

      document.cookie = cookie;

      return cookie;
    },
    remove(key) {
      return storage ? storage.removeItem(key) : false;
    },
    removeCookie(name) {
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;
      document.cookie = `${name}=; domain=.oneteam.io; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;
    },
    async clear() {
      if (!storage) return false;

      const seen = storage.getItem('seen');
      const locale = storage.getItem('locale');
      storage.clear();
      if (seen !== null) {
        storage.setItem('seen', seen);
      }
      if (locale !== null) {
        storage.setItem('locale', locale);
      }

      const expires = 'expires=Thu, 01 Jan 1970 00:00:00 UTC;';
      if (window.cookieStore) {
        // this logic removes cookies set by other domains as well, but we
        // need the cookieStore object (which Firefox and otherrs do not have)
        const cookies = await window.cookieStore.getAll();
        cookies.forEach((cookie) => {
          const path = ('path=' + cookie.path + '; ');
          const domain = cookie.domain ? ('domain=' + cookie.domain + '; ') : '';
          const name = (cookie.name + '=; ');
          const cookieUnset = (name + path + domain + expires);
          // although we are assignin a different string at each operation
          // assigning to cookie has magin behavior, it will detect the name of
          // the cookie and inject its value into the cookie string
          document.cookie = cookieUnset;
        });
      } else {
        const cookies = parseCookie(document?.cookie || '');
        Object.keys(cookies).forEach((name) => {
          document.cookie = `${name}=; Path=/; ${expires}`;
        });
      }
      return true;
    },
  };
})();
