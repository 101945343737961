import * as R from 'ramda';
import { combineReducers } from 'redux';
import {
  ORGANISATION_RECEIVE_CHANNELS,
  ORGANISATION_DELETE_CHANNEL,
  ORGANISATION_CREATE_CHANNEL,
  ORGANISATION_UPDATE_CHANNEL,
  ADMIN_ORDER_CHANNELS,
} from '../../admin/actions';
import { ORGANISATION_INITIALISE } from '../actions';

const addToStore = R.curry((state, item) => R.assoc(item.id, item, state));

const items = (state = {}, action) => {
  switch (action.type) {
    case ORGANISATION_RECEIVE_CHANNELS:
      return R.reduce(addToStore, state, action.items);
    case ORGANISATION_CREATE_CHANNEL:
    case ORGANISATION_UPDATE_CHANNEL:
      return addToStore(state, action.channel);
    case ORGANISATION_INITIALISE:
      return R.reduce(addToStore, state, action.channels);
    case ORGANISATION_DELETE_CHANNEL:
      return R.omit([action.id], state);
    case ADMIN_ORDER_CHANNELS:
      return {
        ...R.reduce((acc, channel) => R.evolve({
          [channel.id]: R.assoc('index', channel.index),
        }, acc), state, action.items),
      };
    default: return state;
  }
};

const ids = (state = [], action) => {
  switch (action.type) {
    case ORGANISATION_RECEIVE_CHANNELS:
      return R.pluck('id', action.items);
    case ORGANISATION_CREATE_CHANNEL:
      return R.append(action.channel.id, state);
    case ORGANISATION_DELETE_CHANNEL:
      return R.reject(R.equals(action.id), state);
    case ORGANISATION_UPDATE_CHANNEL:
    case ADMIN_ORDER_CHANNELS:
      return [...state];
    default: return state;
  }
};

const pagination = (state = {}, action) => {
  switch (action.type) {
    case ORGANISATION_RECEIVE_CHANNELS:
      return action.pagination;
    case ORGANISATION_DELETE_CHANNEL:
      return R.reject(R.equals(action.id), state);
    default: return state;
  }
};

export default combineReducers({ items, ids, pagination });
