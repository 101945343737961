import fetchConversation from './fetch-conversation';

export const CHAT_OPEN_CONVERSATION = 'chat/OPEN_CONVERSATION';

export default (conversationId) => async (dispatch, getState) => {
  const state = getState();

  dispatch({
    type: CHAT_OPEN_CONVERSATION,
    conversationId,
    sync: true,
  });

  if (!state.chat.conversations.items[conversationId]) dispatch(fetchConversation(conversationId));
};
