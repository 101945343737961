import * as R from 'ramda';
import Api from '../../services/api';
import { getApiUrl } from '../../services/api/utils';
import intervalService from '../../services/interval';

export const FileType = {
  IMAGE: 'image',
  VIDEO: 'video',
  EXTERNAL_VIDEO: 'external_video',
  PDF: 'pdf',
};

const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

export const isValidFile = (file, maxFileSize, size = 'MB') => {
  if (!file) return false;
  if (sizes.indexOf(size) === -1) return true;

  return file.size < maxFileSize * (1024 ** sizes.indexOf(size));
};

export const getImageBase64 = (file) => new Promise((resolve, reject) => {
  try {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onloadend = () => resolve(fileReader.result);
    fileReader.onerror = () => reject(fileReader.error);
  } catch (err) {
    reject(err);
  }
});

export const getYoutubeVideoId = (url) => {
  const data = url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/);

  if (data[2]) return data[2].split(/[^0-9a-z_-]/i)[0];

  return null;
};

const createEmitter = (() => {
  let callback = null;

  return {
    on: (callbackFn) => (callback = callbackFn),
    emit: (progress) => (callback && callback(progress)),
  };
});

export const upload = async (endpoint, payload, skipProcessing = false) => {
  const progressEmitter = createEmitter();

  const headers = await Api.getHeaders(payload);

  const promise = new Promise((resolve, reject) => {
    const req = new XMLHttpRequest();
    req.open('POST', getApiUrl(endpoint));

    // Set each header on the request
    R.mapObjIndexed((value, key) => req.setRequestHeader(key, value), headers);

    req.addEventListener('error', reject);
    req.addEventListener('abort', reject);
    req.upload.addEventListener('progress', (e) => progressEmitter.emit(e.loaded / e.total * 100));
    req.addEventListener('load', () => {
      const { data } = JSON.parse(req.responseText);

      if (req.status !== 200) return reject(req);

      progressEmitter.emit(100);

      // If the file needs no processing
      if (skipProcessing || data.completed !== false) return resolve(data);

      const pollingId = `polling-${data.id}`;

      // Check if the file has finished processing each 5 seconds
      intervalService.add(pollingId, () => {
        Api
          .get(`/v2/files/${data.id}`)
          .then(({ data: attachment }) => {
            if (attachment.completed) {
              intervalService.clear(pollingId);
              resolve(attachment);
            }
          })
          .catch((err) => {
            intervalService.clear(pollingId);
            reject(err);
          });
      }, 5000);
    });

    req.send(payload);
  });

  return {
    onProgress: progressEmitter.on,
    promise,
  };
};

export const canPreviewAttachment = (attachment) => {
  if (attachment && attachment.file_type) return attachment.file_type.startsWith('image');

  return false;
};

export const isProcessingAttachments = R.pipe(
  R.reject(R.isNil),
  R.reject(R.propEq('completed', true)),
  (processingFiles) => R.length(processingFiles) > 0,
);

const oneB = 1;
const oneKB = oneB * 1000;
const oneMB = oneKB * 1000;
const oneGB = oneMB * 1000;
const oneTB = oneGB * 1000;

function getOneDecimalFigureDivider(bytes) {
  if (bytes < oneKB) return oneB / 10;
  if (bytes < oneMB) return oneKB / 10;
  if (bytes < oneGB) return oneMB / 10;
  if (bytes < oneTB) return oneGB / 10;
  return oneTB / 10;
}

function getSizeUnit(bytes) {
  if (bytes < oneKB) return 'B';
  if (bytes < oneMB) return 'KB';
  if (bytes < oneGB) return 'MB';
  if (bytes < oneTB) return 'GB';
  return 'TB';
}

export function bytesToSize(bytes) {
  if (typeof bytes !== 'number') return '-';

  const divider = getOneDecimalFigureDivider(bytes);
  const unit = getSizeUnit(bytes);

  // this formats the number so it comes out with one decimal figure, if necessary
  return (Math.floor(bytes / divider) / 10) + ' ' + unit;
}
