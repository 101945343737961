import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { parse } from 'qs';
import { Trans, withTranslation } from 'react-i18next';
import MobileDetect from 'mobile-detect';
import { pageWrapper, EEventNames } from '../../../../../client/analytics';
import Api from '../../../../common/services/api';
import { isPhone } from '../../../../common/utils/responsive';
import { Button } from '../../../../common/components/button';
import Alert from '../../../../common/components/alert';
import { Row, Group, TextInput } from '../../../../common/components/form';

class UnsubscribeForm extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = { error: null, success: false };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRedirect = this.handleRedirect.bind(this);

    const mobileDetect = new MobileDetect(window?.navigator?.userAgent || '');
    this.isMobile = !!mobileDetect.phone();
  }

  UNSAFE_componentWillMount() {
    const query = parse(this.props.location.search, { ignoreQueryPrefix: true });

    if (query.email) this.props.initialize({ email: query.email });
  }

  async handleSubmit({ email }) {
    const { t } = this.props;

    if (email) {
      try {
        await Api.post('/v2/users/unsubscribe', { email }, false);

        this.setState({ error: null, success: true });
      } catch (err) {
        this.setState({ error: t('authentication:unsubscribe_invalid_email_error') });
      }
    } else {
      this.setState({ error: t('authentication:unsubscribe_invalid_email_error') });
    }
  }

  handleRedirect() {
    // If you're on a phone we need to direct and refresh to show the download page
    // else we can just redirect to the root route which will navigate to the correct page
    if (isPhone) {
      window.location.href = '/';
    } else {
      this.props.history.push('/');
    }
  }

  render() {
    const { error, success } = this.state;
    const { handleSubmit, t } = this.props;

    return (
      <>
        <h2><Trans i18nKey="authentication:unsubscribe_email" /></h2>

        {error ? <Alert type="warning">{error}</Alert> : null}
        {success ? (
          <Alert>
            <Trans i18nKey="authentication:unsubscribe_success_alert" />
          </Alert>
        ) : null}

        <form className="Form" onSubmit={handleSubmit(this.handleSubmit)}>
          <Row>
            <Group>
              <TextInput name="email" autoComplete="email" placeholder={t('authentication:unsubscribe_email_label')} />
            </Group>
          </Row>
          <Button size="fill" buttonType="submit" type="primary"><Trans i18nKey="authentication:unsubscribe_email_submit" /></Button>
        </form>
        {!this.isMobile && (
          <div className="Authentication__Form__Action">
            <a onClick={this.handleRedirect} className="Authentication__forgot-password align-center">
              <Trans i18nKey="authentication:unsubscribe_email_done" />
            </a>
          </div>
        )}
      </>
    );
  }
}

export default withTranslation()(reduxForm({
  form: 'unsubscribe',
  initialValues: {
    email: '',
  },
})(pageWrapper(EEventNames.VISITED_UNSUBSCRIBE_PAGE)(UnsubscribeForm)));
