import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '../modal';
import Video from '../video';
import { Button } from '../button';
import { Icon } from '../icon';

import './upsell-modal.scss';

type UpsellModalProps = PropsWithChildren<{
  title: string;
  video?: string;
  link?: { name: string, url: string };
  intercom?: { buttonText: string, message?: string };
  show: boolean;
  onHide: () => void;
}>;

const UpsellModal = ({
  title, children, video, link, intercom, show, onHide,
}: UpsellModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      fullWidthContent
      size="small"
      show={show}
      onClose={onHide}
      content={(
        <div className="UpsellModal__Content">
          {link && (
            <div className="UpsellModal__Header">
              <Icon
                className="UpsellModal__CloseBtn"
                type="close"
                onClick={onHide}
              />
            </div>
          )}
          {(video && (
            <div className="UpgradePlanPackageModal__Video">
              <Video path={video} />
            </div>
          )) || (
            <img
              className="UpsellModal__Image"
              src="/static/images/plan-package-locked.png"
              alt="Package Plan Locked"
            />
          )}
          <h2 className="UpsellModal__Title">
            {title}
          </h2>
          <p className="UpsellModal__Text">
            {children}
          </p>
          {intercom && (
            <Button
              center
              type="primary"
              size="large"
              onClick={() => window.Intercom?.('showNewMessage', intercom.message)}
            >
              {intercom.buttonText}
            </Button>
          )}
          {(link && (
            <a
              className="UpsellModal__Link"
              href={link.url}
              target="_blank"
              rel="noreferrer"
            >
              {link.name}
            </a>
          )) || (
            <a
              className="UpsellModal__Link"
              onClick={onHide}
            >
              {t('common:cancel')}
            </a>
          )}
        </div>
      )}
    />
  );
};

export default UpsellModal;
