import { createSelector } from 'reselect';

import type { StoreState } from '@common/types/store';
import type { FileItem } from '../types';

const getQueue = (state: StoreState) => state.uploadQueue.items;

export const getUploadQueue = createSelector(
  [getQueue],
  (queue): FileItem[] => queue,
);
