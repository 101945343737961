import * as usersReducer from '../../core/reducers/users';
import * as exchangeUtil from './exchange';

export const Types = {
  COMMENT: 'comment',
  ACTIVITY: 'activity',
};

export const ActivityTypes = {
  EXCHANGE_CREATED: 'exchange_created',
  EXCHANGE_ACCEPTED: 'exchange_accepted',
  EXCHANGE_APPROVED: 'exchange_approved',
  EXCHANGE_REJECTED: 'exchange_rejected',
  EXCHANGE_COMMENT: 'exchange_comment',
};

export const getActivityValues = (state, activity) => {
  const { meta_data: metaData } = activity;

  switch (activity.activity_type) {
    case ActivityTypes.EXCHANGE_CREATED:
      return {
        user: activity.user,
        placedIn: exchangeUtil.getCreatedInValues(state, metaData.created_in),
      };
    case ActivityTypes.EXCHANGE_ACCEPTED:
      return {
        user: activity.user,
      };
    case ActivityTypes.EXCHANGE_APPROVED: {
      return {
        approver: activity.user,
        approvedUser: usersReducer.findById(state, metaData.approved_user_id.toString()),
      };
    }
    case ActivityTypes.EXCHANGE_REJECTED: {
      return {
        rejecter: activity.user,
        rejectedUser: usersReducer.findById(state, metaData.rejected_user_id.toString()),
      };
    }
    default: return {};
  }
};
