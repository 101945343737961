import { EPlanPackageConfig, EPlanLevels } from '@common/definitions';
import { Organisation } from '@common/types/objects';

type PackageId = `${EPlanPackageConfig}`;

const LEVEL_NAMES = {
  [EPlanLevels.STARTER]: 'pricing:name_level_0',
  [EPlanLevels.ESSENTIAL]: 'pricing:name_level_1',
  [EPlanLevels.PROFESSIONAL]: 'pricing:name_level_2',
};

const PLAN_PACKAGE_NOT_AVAILABLE_TITLE = {
  [EPlanPackageConfig.ADVANCED_USER_ROLES]: 'pricing:advanced_user_roles_not_allowed_title',
  [EPlanPackageConfig.ACADEMY]: 'pricing:academy_courses_permissions_not_allowed_title',
  [EPlanPackageConfig.ACADEMY_ONBOARDING]: 'pricing:courses_onboarding_permissions_not_allowed_title',
  [EPlanPackageConfig.ACADEMY_LEARNING_PATHS]: 'pricing:learning_paths_permissions_not_allowed_title',
  [EPlanPackageConfig.ACADEMY_AUDIENCE_SELECTOR]: 'pricing:audience_selector_course_permissions_not_allowed_title',
  [EPlanPackageConfig.ACADEMY_QUIZ_MODULES]: 'pricing:quiz_modules_permissions_not_allowed_title',
  [EPlanPackageConfig.ACADEMY_HINTS_FEEDBACK]: 'pricing:quiz_feedback_and_hints_permissions_not_allowed_title',
  [EPlanPackageConfig.ACADEMY_DYNAMIC_DEADLINES]: 'pricing:dynamic_deadlines_permissions_not_allowed_title',
  [EPlanPackageConfig.ACADEMY_PHASING]: 'pricing:phasing_permissions_not_allowed_title',
  [EPlanPackageConfig.DOCUMENTS_USER_SPECIFIC]: 'pricing:user_specific_documents_not_allowed_title',
  [EPlanPackageConfig.EVENTS]: 'pricing:events_not_allowed_title',
  [EPlanPackageConfig.FOLDER_SPECIFIC_PERMISSIONS]: 'pricing:folder_specific_permissions_not_allowed_title',
  [EPlanPackageConfig.FORMS]: 'pricing:forms_not_allowed_title',
  [EPlanPackageConfig.FORMS_ADVANCED_PERMISSIONS]: 'pricing:forms_advanced_permissions_not_allowed_title',
  [EPlanPackageConfig.FORMS_AUDIENCE_SELECTOR]: 'pricing:forms_audience_selector_not_allowed_title',
  [EPlanPackageConfig.FORMS_EMAIL_FORWARDING]: 'pricing:forms_email_forwarding_not_allowed_title',
  [EPlanPackageConfig.FORMS_SPECIAL_INPUT]: 'pricing:forms_special_input_not_allowed_title',
  [EPlanPackageConfig.FORMS_SUBMISSION_STATUS]: 'pricing:forms_submission_status_not_allowed_title',
  [EPlanPackageConfig.ORGANISATION_GROUPS]: 'pricing:organisation_groups_not_allowed_title',
  [EPlanPackageConfig.PULSE_SURVEYS]: 'pricing:pulse_surveys_not_allowed_title',
  [EPlanPackageConfig.PULSE_SURVEYS_AUDIENCE_SELECTOR]: 'pricing:pulse_surveys_audience_selector_not_allowed_title',
  [EPlanPackageConfig.PULSE_SURVEYS_ANALYTICS_PER_CATEGORY]: 'pricing:pulse_surveys_analytics_per_category_not_allowed_title',
  [EPlanPackageConfig.PULSE_SURVEYS_ENPS_TRACKING]: 'pricing:pulse_surveys_enps_tracking_not_allowed_title',
  [EPlanPackageConfig.SURVEYS]: 'pricing:surveys_not_allowed_title',
  [EPlanPackageConfig.SURVEYS_AUDIENCE_SELECTOR]: 'pricing:audience_selector_survey_not_allowed_title',
  [EPlanPackageConfig.SURVEYS_AUDIENCE_SELECTOR_ONBOARDING]: 'pricing:surveys_onboarding_not_allowed_title',
  [EPlanPackageConfig.SURVEYS_ADVANCED_ANALYTICS]: 'pricing:advanced_analytics_not_allowed_title',
  [EPlanPackageConfig.SURVEYS_RESULTS_EXPORT]: 'pricing:results_export_not_allowed_title',
  [EPlanPackageConfig.THEMING]: 'pricing:theming_not_allowed_title',
};

const PLAN_PACKAGE_NOT_AVAILABLE_CTA = {
  [EPlanPackageConfig.ADVANCED_USER_ROLES]: 'pricing:advanced_user_roles_not_allowed_cta',
  [EPlanPackageConfig.ACADEMY]: 'pricing:academy_courses_permissions_not_allowed_cta',
  [EPlanPackageConfig.ACADEMY_ONBOARDING]: 'pricing:courses_onboarding_permissions_not_allowed_cta',
  [EPlanPackageConfig.ACADEMY_LEARNING_PATHS]: 'pricing:learning_paths_permissions_not_allowed_cta',
  [EPlanPackageConfig.ACADEMY_AUDIENCE_SELECTOR]: 'pricing:audience_selector_course_permissions_not_allowed_cta',
  [EPlanPackageConfig.ACADEMY_QUIZ_MODULES]: 'pricing:quiz_modules_permissions_not_allowed_cta',
  [EPlanPackageConfig.ACADEMY_HINTS_FEEDBACK]: 'pricing:quiz_feedback_and_hints_permissions_not_allowed_cta',
  [EPlanPackageConfig.ACADEMY_DYNAMIC_DEADLINES]: 'pricing:dynamic_deadlines_permissions_not_allowed_cta',
  [EPlanPackageConfig.ACADEMY_PHASING]: 'pricing:phasing_permissions_not_allowed_cta',
  [EPlanPackageConfig.DOCUMENTS_USER_SPECIFIC]: 'pricing:user_specific_documents_not_allowed_cta',
  [EPlanPackageConfig.EVENTS]: 'pricing:events_not_allowed_cta',
  [EPlanPackageConfig.FOLDER_SPECIFIC_PERMISSIONS]: 'pricing:folder_specific_permissions_not_allowed_cta',
  [EPlanPackageConfig.FORMS]: 'pricing:forms_not_allowed_cta',
  [EPlanPackageConfig.FORMS_ADVANCED_PERMISSIONS]: 'pricing:forms_advanced_permissions_not_allowed_cta',
  [EPlanPackageConfig.FORMS_AUDIENCE_SELECTOR]: 'pricing:forms_audience_selector_not_allowed_cta',
  [EPlanPackageConfig.FORMS_EMAIL_FORWARDING]: 'pricing:forms_email_forwarding_not_allowed_cta',
  [EPlanPackageConfig.FORMS_SPECIAL_INPUT]: 'pricing:forms_special_input_not_allowed_cta',
  [EPlanPackageConfig.FORMS_SUBMISSION_STATUS]: 'pricing:forms_submission_status_not_allowed_cta',
  [EPlanPackageConfig.ORGANISATION_GROUPS]: 'pricing:organisation_groups_not_allowed_cta',
  [EPlanPackageConfig.PULSE_SURVEYS]: 'pricing:pulse_surveys_not_allowed_cta',
  [EPlanPackageConfig.PULSE_SURVEYS_AUDIENCE_SELECTOR]: 'pricing:pulse_surveys_audience_selector_not_allowed_cta',
  [EPlanPackageConfig.PULSE_SURVEYS_ANALYTICS_PER_CATEGORY]: 'pricing:pulse_surveys_analytics_per_category_not_allowed_cta',
  [EPlanPackageConfig.PULSE_SURVEYS_ENPS_TRACKING]: 'pricing:pulse_surveys_enps_tracking_not_allowed_cta',
  [EPlanPackageConfig.SURVEYS]: 'pricing:surveys_not_allowed_cta',
  [EPlanPackageConfig.SURVEYS_AUDIENCE_SELECTOR]: 'pricing:audience_selector_survey_not_allowed_cta',
  [EPlanPackageConfig.SURVEYS_AUDIENCE_SELECTOR_ONBOARDING]: 'pricing:surveys_onboarding_not_allowed_cta',
  [EPlanPackageConfig.SURVEYS_ADVANCED_ANALYTICS]: 'pricing:advanced_analytics_not_allowed_cta',
  [EPlanPackageConfig.SURVEYS_RESULTS_EXPORT]: 'pricing:results_export_not_allowed_cta',
  [EPlanPackageConfig.THEMING]: 'pricing:theming_not_allowed_cta',
};

export const getTranslationKeyPlanName = (level: EPlanLevels) => {
  return LEVEL_NAMES[level];
};

export const getTranslationKeyTitle = (packageId: PackageId) => {
  return PLAN_PACKAGE_NOT_AVAILABLE_TITLE[packageId] || 'pricing:default_not_allowed_title';
};

export const getTranslationKeyCTA = (packageId: PackageId) => {
  return PLAN_PACKAGE_NOT_AVAILABLE_CTA[packageId] || 'pricing:default_not_allowed_cta';
};

export const getUpgradePlanPackage = (
  packageId: PackageId,
  currentLevel: number = 0,
  organisation: Organisation,
  value?: number | boolean,
) => {
  const upgrades = organisation.plan_package_list?.filter(
    ({ level }) => level > currentLevel,
  ) || [];
  return upgrades.find((plan) => {
    if (typeof plan[packageId] === 'number' && typeof value === 'number') {
      return +plan[packageId] > value;
    }
    return !!plan[packageId];
  });
};
