import { combineReducers } from 'redux';
import * as R from 'ramda';
import {
  SURVEYS_RECEIVE_SURVEY,
  SURVEYS_UPDATE_SCREEN,
  SURVEYS_CREATE_QUESTION,
} from '../../survey/actions';
import {
  ACADEMY_RECEIVE_MODULE,
  ACADEMY_UPDATE_SCREEN,
  ACADEMY_ADD_SCREEN,
} from '../actions';

const addToStore = R.curry((state, component) => R.assoc(component.id, component, state));
export const addScreenComponentsToStore = (state, screens) => {
  return R.reduce(
    (acc, screen) => R.reduce(addToStore, acc, screen.components),
    state,
    screens
  );
};

const items = (state = {}, action) => {
  switch (action.type) {
    case ACADEMY_RECEIVE_MODULE:
    case SURVEYS_RECEIVE_SURVEY:
    case 'forms/RECEIVE_FORM':
    case 'forms/CREATE_FORM':
    case 'forms/DUPLICATE_FORM':
      return addScreenComponentsToStore(state, action.item.screens);
    case ACADEMY_UPDATE_SCREEN:
    case SURVEYS_UPDATE_SCREEN:
    case SURVEYS_CREATE_QUESTION:
    case ACADEMY_ADD_SCREEN:
    case 'forms/CREATE_QUESTION':
    case 'forms/DUPLICATE_SCREEN':
      return R.reduce(addToStore, state, action.screen.components);
    default: return state;
  }
};

export const findById = R.curry((state, id) => state.academy.components.items[id]);

export default combineReducers({ items });
