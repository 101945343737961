import * as R from 'ramda';
import { combineReducers } from 'redux';
import { SOCIAL_RECEIVE_FEED } from '../../social/actions';
import {
  FLEXCHANGE_RECEIVE_EXCHANGES,
  FLEXCHANGE_ADD_EXCHANGE,
  FLEXCHANGE_ACCEPT_EXCHANGE,
  FLEXCHANGE_DECLINE_EXCHANGE,
  FLEXCHANGE_APPROVE_EXCHANGE,
  FLEXCHANGE_REJECT_EXCHANGE,
} from '../actions';
import { EExchangeStatus, isExpired } from '../utils/exchange';

const isExchange = R.propEq('object_type', 'exchange');
const toIds = R.pluck('id');
const gtZero = R.gt(R.__, 0);
const isOpen = (r) => R.and(R.equals(r.response, true), R.isNil(r.is_approved));
const hasStatus = R.curry((status, source) => R.propEq('response_status', status, source));
const isNotExpired = (source) => R.not(isExpired(source));
const notResponded = (source) => R.isNil(source.response_status);
const isApproved = (source) => R.not(R.isNil(source.approved_user_id));
const hasOpenResponses = ({ responses }) => R.pipe(R.filter(isOpen), R.length, gtZero)(responses);
const isNotApprovedAnd = (fn) => R.both(fn, R.both(isNotExpired, R.complement(isApproved)));

const createStatusReducer = (filter) => (state = [], action) => {
  switch (action.type) {
    case SOCIAL_RECEIVE_FEED:
    case FLEXCHANGE_RECEIVE_EXCHANGES:
      return R.pipe(R.filter(isExchange), R.pluck('source'), R.filter(filter), toIds)(action.items);
    case FLEXCHANGE_ADD_EXCHANGE:
    case FLEXCHANGE_ACCEPT_EXCHANGE:
    case FLEXCHANGE_DECLINE_EXCHANGE:
    case FLEXCHANGE_REJECT_EXCHANGE:
    case FLEXCHANGE_APPROVE_EXCHANGE:
      return filter(action.exchange.source)
        ? R.pipe(R.append(action.exchangeId), R.uniq)(state)
        : R.filter(R.complement(R.equals(action.exchangeId)), state);
    default: return state;
  }
};

const OPEN = createStatusReducer(isNotApprovedAnd(notResponded));
const ACCEPTED = createStatusReducer(isNotApprovedAnd(hasStatus(EExchangeStatus.ACCEPTED)));
const APPROVED = createStatusReducer(isApproved);
const AWAITING_APPROVAL = createStatusReducer(isNotApprovedAnd(hasOpenResponses));

export default combineReducers({ OPEN, ACCEPTED, APPROVED, AWAITING_APPROVAL });
