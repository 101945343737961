import { openInNewTab } from '../open-in-new-tab';

export const downloadURL = (url: string, fileName = '') => {
  const link = document.createElement('a');
  link.setAttribute('download', fileName);
  link.setAttribute('href', url);
  link.click();
  link.remove();
};

export const downloadCanvas = (element: HTMLCanvasElement, fileName = '') => openInNewTab(element.toDataURL(), true, fileName);

export const downloadFile = async (src: string, fileName = '', requestOptions?: RequestInit) => {
  if (fetch) {
    await fetch(src, requestOptions)
      .then(async (r) => {
        if (!r.ok) throw new Error(r.statusText);

        return r;
      })
      .then(async (r) => downloadURL(URL.createObjectURL(await r.blob()), fileName));
  }
};
