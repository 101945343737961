import React from 'react';
import { Trans } from 'react-i18next';

export type MessageProps = Record<string, string | number> | {
  warning?: string | boolean;
  error?: string | boolean;
};

export const Message = ({ error, warning, ...props }: MessageProps) => {
  let message;
  let className = '';
  if (error && typeof error === 'string') {
    message = error;
    className = 'Form__control__error';
  }
  if (warning && typeof warning === 'string') {
    message = warning;
    className = 'Form__control__warning';
  }

  if (!message) return null;

  return (
    <small className={className}>
      <Trans i18nKey={message} values={props}>{message}</Trans>
    </small>
  );
};
