import * as React from 'react';
import * as R from 'ramda';
import { Mixpanel as MixpanelModule } from './mixpanel';

const Mixpanel = MixpanelModule || {
  init: R.F,
  track: R.F,
  identify: R.F,
  reset: R.F,
  register: R.F,
};

let lastIdentity = null;

export const track = (eventName, properties) => Mixpanel.track(eventName, properties);

export const register = (properties) => Mixpanel.register(properties);

export const identify = (userId) => {
  if (userId === lastIdentity) return;

  Mixpanel.identify(userId);
  lastIdentity = userId;
};

export const reset = () => {
  Mixpanel.reset();
  lastIdentity = null;
};

export const pageWrapper = (eventName, propsFn) => (Page) => (props) => {
  React.useEffect(() => {
    Mixpanel.track(eventName, propsFn && propsFn(props));
  }, []);

  return <Page {...props} />;
};

// export const TrackComponent = ({ eventName, properties, children }: { eventName: string, properties?: Object, children: any }) => {
export const TrackComponent = ({ eventName, properties, children }) => {
  React.useEffect(() => {
    Mixpanel.track(eventName, properties);
  }, []);

  return children;
};

export const EEventNames = {
  VIEWED_FEED: 'Viewed Feed',
  VISITED_EMPLOYEES_PAGE: 'Visited Employees Page',
  VIEWED_INTEGRATED_SCHEDULES: 'Viewed Integrated Schedules',
  VIEWED_SCHEDULES: 'Viewed Schedules',
  VIEWED_FILES: 'Viewed Files',
  VIEWED_IMPORTANT_MESSAGES: 'Viewed Important Messages',
  SEARCHED_MESSAGES: 'Searched Messages',
  VIEWED_MESSAGE_DETAIL: 'Viewed Message Detail',
  VISITED_FLEXCHANGE_PAGE: 'Visited Flexchange Page',
  VISITED_NETWORK_USER_DETAIL_PAGE: 'Visited Network User Detail Page',
  VISITED_NETWORK_GENERAL_SETTINGS_PAGE: 'Visited Network General Settings Page',
  VISITED_NETWORK_COMPONENTS_SETTINGS_PAGE: 'Visited Network Components Settings Page',
  VISITED_NETWORK_ADMINS_SETTINGS_PAGE: 'Visited Network Admins Settings Page',
  VISITED_NETWORK_TEAMS_SETTINGS_PAGE: 'Visited Network Teams Settings Page',
  VISITED_NETWORK_FUNCTIONS_SETTINGS_PAGE: 'Visited Network Functions Settings Page',
  VISITED_PROFILE_PRIVACY_SETTINGS_PAGE: 'Visited Profile Privacy Settings Page',
  VISITED_PROFILE_GENERAL_SETTINGS_PAGE: 'Visited Profile General Settings Page',
  VISITED_PROFILE_LANGUAGE_SETTINGS_PAGE: 'Visited Profile Language Settings Page',
  VISITED_PROFILE_PASSWORD_SETTINGS_PAGE: 'Visited Profile Password Settings Page',
  VISITED_PROFILE_PICTURE_SETTINGS_PAGE: 'Visited Profile Picture Settings Page',
  VISITED_COMMUNICATION_ANALYTICS_PAGE: 'Visited Communication Analytics Page',
  VISITED_EMPLOYEES_ANALYTICS_PAGE: 'Visited Employees Analytics Page',
  VISITED_FLEXCHANGE_ANALYTICS_PAGE: 'Visited Flexchange Analytics Page',
  VISITED_ONBOARDING_ANALYTICS_PAGE: 'Visited Onboarding Analytics Page',
  VISITED_ORGANISATION_MESSAGES_PAGE: 'Visited Organization Messages Page',
  VISITED_ORGANISATION_GROUPS_PAGE: 'Visited Organization Groups Page',
  VISITED_ONBOARDING_PAGE: 'Visited Onboarding Page',
  VISITED_ONBOARDING_STATISTICS_PAGE: 'Visited Onboarding Statistics Page',
  VISITED_ACADEMY_PAGE: 'Visited Academy Page',
  VISITED_COURSE_PAGE: 'Visited Course Page',
  VISITED_COURSE_STATISTICS_PAGE: 'Visited Course Statistics Page',
  VISITED_ORGANISATION_NETWORKS_PAGE: 'Visited Organization Networks Page',
  VISITED_ORGANISATION_FUNCTIONS_PAGE: 'Visited Organization Functions Page',
  VISITED_SURVEYS_PAGE: 'Visited Surveys Page',
  VISITED_SURVEY_DETAIL_PAGE: 'Visited Survey Detail Page',
  VISITED_SURVEY_RESPONSES_PAGE: 'Visited Survey Responses Page',
  VISITED_SURVEY_RESPONSE_DETAIL_PAGE: 'Visited Survey Response Detail Page',
  VISITED_SURVEY_PUBLISH_PAGE: 'Visited Survey Publish Page',
  VISITED_SURVEY_EDIT_PAGE: 'Visited Survey Edit Page',
  VISITED_ORGANISATION_USERS_PAGE: 'Visited Organization Users Page',
  VISITED_ORGANISATION_USER_DETAIL_PAGE: 'Visited Organization User Detail Page',
  VISITED_ORGANISATION_ADMINS_PAGE: 'Visited Organization Admins Page',
  VISITED_ORGANISATION_ROLES_PAGE: 'Visited Organization Roles Page',
  VISITED_ORGANISATION_ROLE_DETAIL_PAGE: 'Visited Organization Role Detail Page',
  VISITED_ORGANISATION_MESSAGE_DETAIL_PAGE: 'Visited Organization Message Detail Page',
  VISITED_ORGANISATION_SETTINGS_BRANDING_PAGE: 'Visited Organization Settings Branding Page',
  VISITED_ORGANISATION_SETTINGS_LANGUAGE_PAGE: 'Visited Organization Settings Language Page',
  VISITED_ORGANISATION_SETTINGS_SHORTCUTS_PAGE: 'Visited Organization Settings Shortcuts Page',
  VISITED_ORGANISATION_USERS_IMPORT_PAGE: 'Visited Organization Users Import Page',
  VISITED_FORGOT_PASSWORD_PAGE: 'Visited Forgot Password Page',
  VISITED_LOGIN_PAGE: 'Visited Login Page',
  VISITED_FREE_TRIAL_PAGE: 'Visited Free Trial Page',
  VISITED_MAIL_RECEIVED_PAGE: 'Visited Mail Received Page',
  VISITED_USER_ONBOARDING_PAGE: 'Visited User Onboarding Page',
  VISITED_SELECT_ORGANISATION_PAGE: 'Visited Select Organization Page',
  VISITED_REGISTRATION_PAGE: 'Visited Registration Page',
  VISITED_UNSUBSCRIBE_PAGE: 'Visited Unsubscribe Page',
  VIEWED_CHAT_CONVERSATION: 'Viewed Chat Conversation',
  VIEWED_ARCHIVED_CHAT_CONVERSATIONS: 'Viewed Archived Chat Conversations',
  VIEWED_CHAT_CONVERSATIONS: 'Viewed Chat Conversations',
  VIEWED_ALL_PERSONAL_DOCUMENTS: 'Viewed All Personal Documents',
  VIEWED_DOCUMENT: 'Viewed Document',
  VIEWED_ALL_DOCUMENTS: 'Viewed All Documents',
  VIEWED_RECENT_DOCUMENTS: 'Viewed Recent Documents',
  VIEWED_FAVORITED_DOCUMENTS: 'Viewed Favorited Documents',
  VIEWED_DOCUMENT_DETAILS: 'Viewed Document Details',
  ORDERED_DOCUMENTS: 'Ordered Documents',
  OPENED_FILE_IN_CHAT: 'Opened File In Chat',
  OPENED_FILE_IN_TIMELINE: 'Opened File In Timeline',
  VIEWED_SCHEDULED_MESSAGES: 'Viewed Scheduled Messages',
  OPENED_VIDEO: 'Opened Video',
  SORTED_TIMELINE: 'Sorted Timeline',
  VIEWED_GROUP_MEMBERS_LIST: 'Viewed Group Members List',
  VIEWED_NOT_SEEN_USERS_LIST: 'Viewed Not Seen Users List',
  VIEWED_SEEN_USERS_LIST: 'Viewed Seen Users List',
  APP_OPEN: 'App Open',
  OPENED_SHORTCUT: 'Opened Shortcut',
  VIEWED_NOTIFICATIONS_LIST: 'Viewed Notifications List',
  OPENED_NOTIFICATION: 'Opened Notification',
  CONTACTED_SALES: 'Contacted Sales',
  VIEWED_INTEGRATED_SHIFT: 'Viewed Integrated Shift',
  OPENED_SCHEDULE: 'Opened Schedule',
  VIEWED_BIRTHDAY_OVERVIEW: 'Viewed Birthday Overview',
  WATCHED_INTRODUCTION_VIDEO: 'Setup: Watched Introduction Video',
  INSTALL_MOBILE_APP_STEP_COMPLETED: 'Setup: Install Mobile App Step Completed',
  CREATE_ORGANIZATION_GROUP_STEP_COMPLETED: 'Setup: Create Organization Group Step Completed',
  CREATE_ONBOARDING_COURSE_STEP_COMPLETED: 'Setup: Create Onboarding Course Step Completed',
  CREATE_ELEARNING_COURSE_STEP_COMPLETED: 'Setup: Create E-Learning Course Step Completed',
  CREATE_SURVEY_STEP_COMPLETED: 'Setup: Create Survey Step Completed',
  INVITE_CO_WORKERS_STEP_COMPLETED: 'Setup: Invite Co-Workers Step Completed',
  COMPANY_STRUCTURE_COMMUNITY_STEP_COMPLETED: 'Setup: Company Structure Community Step Completed',
  COMPANY_STRUCTURE_FUNCTION_GROUP_STEP_COMPLETED: 'Setup: Company Structure Function Group Step Completed',
  VISITED_SURVEY_SUMMARY_PAGE: 'Visited Survey Summary Page',
  COMPARED_SURVEY_QUESTION: 'Compared Survey Question',
  GENERATED_SURVEY_SUMMARY_REPORT: 'Generated Survey Summary Report',
  DOWNLOADED_SURVEY_SUMMARY_REPORT: 'Downloaded Survey Summary Report',
  GENERATED_SURVEY_COMPARISON_REPORT: 'Generated Survey Comparison Report',
  DOWNLOADED_SURVEY_COMPARISON_REPORT: 'Downloaded Survey Comparison Report',
  DOWNLOADED_DOCUMENT: 'Downloaded Document',
  VIEWED_ACCESS_REQUESTS_FOR_ORGANISATION: 'Viewed Access Requests For Organization',
  VIEWED_ACCESS_REQUESTS_FOR_NETWORK: 'Viewed Access Requests For Community',
};
