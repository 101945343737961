import * as R from 'ramda';
import {
  NETWORK_INITIALISE,
  NETWORK_DELETE_USER,
  NETWORK_RECEIVE_ADMINS,
  NETWORK_REMOVE_ADMIN,
  NETWORK_UPDATE_USER_ROLE,
  NETWORK_INVITE_USER,
} from '../actions';

const formatForStore = (admin) => ({ id: admin.id, roles: admin.roles.map((role) => ({ id: role.id, memberships: role.memberships })) });

export default (state = [], action) => {
  switch (action.type) {
    case NETWORK_INITIALISE:
      return action.admins.map(formatForStore);
    case NETWORK_RECEIVE_ADMINS:
      return action.items.map(formatForStore);
    case NETWORK_UPDATE_USER_ROLE:
      return state.map((admin) => (admin.id === action.userId
        ? { ...admin, roles: action.roles }
        : admin
      ));
    case NETWORK_REMOVE_ADMIN:
    case NETWORK_DELETE_USER:
      return R.reject(R.propEq('id', action.userId), state);
    case NETWORK_INVITE_USER:
      if (action.roles.length > 0) {
        return R.prepend({
          id: action.user.id,
          roles: action.roles.map((role) => ({
            id: role.id,
            memberships: role.memberships,
          })),
        }, state);
      }

      return state;
    default: return state;
  }
};
