import { useState, useEffect } from 'react';

export const useCarouselIndex = (max: number, min = 0, initial?: number) => {
  const [page, setPage] = useState(initial || min);

  const setWithBoundaries = (newPage: number) => {
    if (newPage > max) return setPage(max);
    if (newPage < min) return setPage(min);

    setPage(newPage);
  };

  useEffect(() => {
    if (page !== min && page !== initial) {
      setWithBoundaries(initial || min);
    }
  }, [max, min]);

  return {
    page,
    nextPage: () => setWithBoundaries(page + 1),
    previousPage: () => setWithBoundaries(page - 1),
    setPage: setWithBoundaries,
  };
};
