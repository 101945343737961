import * as R from 'ramda';
import { predicatesToFilters } from '@common/utils/predicates';
import memoize from '@common/utils/memoize';

const getIds = (state) => state.organisation.channels.ids;
const getChannels = (state) => state.organisation.channels.items;
const getChannel = (state, id) => state.organisation.channels.items[id];
const getOrganisation = (state) => state.organisation.selected;

const createChannelObj = (state, c, organisation) => {
  if (!c) return null;

  const channel = c;
  if (!channel.theme.logo_url) channel.theme.logo_url = organisation.brand_icon;
  const filters = predicatesToFilters(channel.predicates || [], state);
  channel.filters = filters;
  return channel;
};

export const item = memoize.createSelector(
  [getChannel, getOrganisation],
  createChannelObj,
);

export const list = memoize.createSelector(
  [getIds, getChannels, getOrganisation],
  (state, ids, channels, organisation) => R.pipe(
    R.map((id) => createChannelObj(state, channels[id], organisation)),
    R.sortBy(R.prop('index')),
  )(ids),
);
