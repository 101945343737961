import React from 'react';
import moment, { Moment } from 'moment';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';

import { combineClassNames } from '@common/utils/combineClassNames';
import { Message } from '@common/components/form/message';
import Icon from '@common/components/icon';

export type DateInputProps = Omit<ReactDatePickerProps, 'value' | 'onChange' | 'minDate' | 'maxDate'> & {
  value?: Moment | Date | undefined | null;
  onChange?: (date: Moment | null) => void;
  placeholder?: string;
  disabled?: boolean;
  showYearDropdown?: boolean;
  showMonthDropdown?: boolean;
  minDate?: Moment;
  maxDate?: Moment;
  warning?: string | boolean;
  error?: string | boolean;
  arrowPosition?: number;
};

const CustomInput = React.forwardRef<HTMLInputElement | undefined>((inputProps, ref) => (
  <>
    <input
      {...inputProps}
      ref={ref as React.ForwardedRef<HTMLInputElement>}
      onKeyPress={(e) => {
        if (!/^[-0-9]+$/.test(e.key)) {
          e.preventDefault();
        }
      }}
    />
    <Icon type="calendar_today" />
  </>
));

const toNativeDate = (date: DateInputProps['value']) => (date ? moment(date).toDate() : null);

export const DateInput = ({
  onChange,
  value,
  disabled,
  placeholder,
  minDate,
  maxDate,
  error,
  warning,
  arrowPosition = 24,
  ...props
}: DateInputProps) => (
  <>
    <DatePicker
      className={combineClassNames({
        'Form__control': true,
        'Form__control--warning': !!warning,
        'Form__control--invalid': !warning && !!error,
      })}
      customInput={<CustomInput />}
      dateFormat="dd-MM-yyyy"
      disabled={disabled}
      selected={toNativeDate(value)}
      minDate={toNativeDate(minDate)}
      maxDate={toNativeDate(maxDate)}
      dropdownMode="select"
      {...props}
      onChange={(date) => onChange && onChange(date ? moment(date) : null)}
      placeholderText={placeholder}
      popperModifiers={[{
        name: 'arrow',
        options: {
          padding: ({ popper }) => ({
            right: popper.width - arrowPosition,
          }),
        },
      }]}
    />
    <Message error={error} warning={warning} />
  </>
);
