import {
  createStore,
  compose,
  applyMiddleware,
  combineReducers,
} from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';
import * as R from 'ramda';
import coreReducers from './modules/core/reducers';
import organisationReducers from './modules/organisation/reducers';
import networkReducers from './modules/network/reducers';
import chatReducer from './modules/chat/reducers';
import flexchangeReducers from './modules/flexchange/reducers';
import scheduleReducers from './modules/schedules/reducers';
import socialReducer from './modules/social/reducers';
import surveyReducer from './modules/survey/reducers';
import formsReducer from './modules/forms/reducers';
import eventsReducer from './modules/events/reducer.ts';
import { documentsReducer } from './modules/documents/reducer';
import adminReducer from './modules/admin/reducers';
import analyticsReducer from './modules/analytics/reducers';
import academyReducer, { learningReducer } from './modules/learning/reducers';
import { LOGOUT_USER } from './modules/authentication/actions';

const reducers = {
  organisation: organisationReducers,
  network: networkReducers,
  social: socialReducer,
  chat: chatReducer,
  flexchange: flexchangeReducers,
  academy: academyReducer,
  learning: learningReducer,
  schedules: scheduleReducers,
  analytics: analyticsReducer,
  admin: adminReducer,
  survey: surveyReducer,
  forms: formsReducer,
  events: eventsReducer,
  documents: documentsReducer,
  ...coreReducers,
};

const info = (state = { maintenance: false, links: {} }, action) => {
  switch (action.type) {
    case 'INFO':
      return {
        maintenance: action.maintenance,
        links: action.links,
      };
    default:
      return state;
  }
};

export const combinedReducers = combineReducers({
  ...reducers,
  form: formReducer,
  toastr: toastrReducer,
  info,
});

export const rootReducer = (state, action) => {
  const newState = action.type === LOGOUT_USER
    ? combinedReducers({}, action)
    : combinedReducers(state, action);

  let unread = R.sum(R.pluck('total', R.values(newState.notifications)));

  if (!process.env.SERVER) {
    if (window.location.href?.indexOf('/auth') !== -1) unread = 0;

    const currentTitle = R.last(document.title.split(')'));
    document.title = unread > 0 ? `(${unread}) ${currentTitle}` : currentTitle;
  }

  return newState;
};

const DEBUG_REDUX = parseInt(process.env.DEBUG_REDUX, 10);
const enableLogging = (process.env.NODE_ENV !== 'production' || process.env.API_ENV === 'testing');

const configureStore = (initialState = {}, extraMiddlewares = []) => {
  if (!process.env.SERVER && enableLogging && DEBUG_REDUX) {
    const logger = createLogger();

    // Adds extra information about store actions when checking performance
    const userTiming = () => (next) => (action) => {
      if (performance.mark === undefined) return next(action);

      performance.mark(`${action.type}_start`);

      const result = next(action);

      performance.mark(`${action.type}_end`);
      performance.measure(`${action.type}`, `${action.type}_start`, `${action.type}_end`);

      return result;
    };

    extraMiddlewares.push(logger, userTiming);
  }

  // Redux dev tools
  const composeEnhancers = enableLogging ? composeWithDevTools({ trace: true }) : compose;

  return createStore(
    rootReducer,
    initialState,
    composeEnhancers(
      applyMiddleware(thunkMiddleware, ...extraMiddlewares),
    ),
  );
};

export default configureStore;
