import moment, { Moment } from 'moment';
import { TFunction } from 'i18next';
import { ELanguages } from '@common/types/objects';
import {
  getDaysDistanceFromToday, isCurrentYear, isInPast, isToday, isTomorrow, isWithinDays
} from './time';

export enum TPositionInTime {
  TODAY = 'today',
  TOMORROW = 'tomorrow',
  WITHIN_WEEK = 'within_week',
  PAST = 'past',
  THIS_YEAR = 'this_year',
  FUTURE = 'future',
}

export const getMinTimeValue = (date?: Moment | null) => {
  const now = moment();
  return date?.isSame(now, 'day') ?
    now.add(1, 'hour').minute(0).format('HH:mm') :
    undefined;
};

export const getPositionInTime = (date?: string) => {
  if (!date) return undefined;
  const expirationDate = new Date(date);

  if (isInPast(expirationDate))
    return TPositionInTime.PAST;
  if (isToday(expirationDate))
    return TPositionInTime.TODAY;
  if (isTomorrow(expirationDate))
    return TPositionInTime.TOMORROW;
  if (isWithinDays(expirationDate, 7))
    return TPositionInTime.WITHIN_WEEK;
  if (expirationDate.getFullYear() === (new Date()).getFullYear())
    return TPositionInTime.THIS_YEAR;
  return TPositionInTime.FUTURE;
};

const getDayAndMonthText = (date: Date, locale: ELanguages) => {
  return `${
    date.toLocaleDateString(locale, { day: 'numeric' })
  } ${
    date.toLocaleDateString(locale, { month: 'long' })
  }`;
};

export const getDeadlineDateLabel = (date: Date | string, locale: ELanguages, t: TFunction) => {
  const daysDiff = getDaysDistanceFromToday(date);
  if (daysDiff === 0) {
    return t('dates:today').toLowerCase();
  }
  if (daysDiff === 1) {
    return t('dates:tomorrow').toLowerCase();
  }

  const expirationDate = new Date(date);

  if (daysDiff > 1 && daysDiff < 7) { // within the future week
    return expirationDate.toLocaleDateString(locale, { weekday: 'long' });
  }
  if (isCurrentYear(expirationDate)) {
    return getDayAndMonthText(expirationDate, locale);
  }
  return `${getDayAndMonthText(expirationDate, locale)} ${expirationDate.getFullYear()}`;
};

export const getDeadlineDateTimeLabel = (date: Date | string, locale: ELanguages, t: TFunction) => {
  const day = getDeadlineDateLabel(date, locale, t);
  const at = t('dates:at');
  const time = (new Date(date)).toLocaleTimeString(locale, { hour: 'numeric', minute: '2-digit' });
  return `${day} ${at} ${time}`;
};

export const dateAndTimeToDate = (time: string = '00:00', date?: Moment | null) => {
  const [startHour, startMinute] = time.split(':');
  return moment(date)
    .hour(parseInt(startHour) || 0)
    .minute(parseInt(startMinute) || 0)
    .seconds(0);
};

export const dateAndTimeToStr = (...args: Parameters<typeof dateAndTimeToDate>) => {
  return dateAndTimeToDate(...args).toISOString();
};

export const stringToTimePickerValue = (dateString?: string) => {
  return (dateString && moment(dateString).format('HH:mm')) || '';
};
