import * as R from 'ramda';
import { SOCIAL_RECEIVE_FEED } from '../../social/actions';
import { FLEXCHANGE_RECEIVE_EXCHANGES } from '../actions';
import { EExchangeTypes } from '../definitions';
import { EObjectTypes } from '../../social/definitions';

const isExchange = R.propEq('object_type', EObjectTypes.EXCHANGE);
const isCreatedInFunction = (e) => R.equals(e.source.created_in.type, EExchangeTypes.FUNCTION);
const createdRows = (e) => R.map((id) => ({ id: e.source.id, function_id: id }), e.source.created_in.ids);

const exchangesSendToFunctions = R.pipe(
  R.filter(isExchange),
  R.filter(isCreatedInFunction),
  R.map(createdRows),
  R.flatten,
);

const addToState = (acc, exchange) => R.assoc(
  exchange.function_id,
  R.append(exchange.id, acc[exchange.function_id] || []),
  acc,
);

export default (state = {}, action) => {
  switch (action.type) {
    case SOCIAL_RECEIVE_FEED:
    case FLEXCHANGE_RECEIVE_EXCHANGES:
      return R.reduce(addToState, state, exchangesSendToFunctions(action.items));
    default: return state;
  }
};
