import { Api } from '@common/services/api';
import { createActivity } from '../utils/activity';
import { EConversationActivityTypes } from '../definitions';
import { ThunkAction } from 'redux-thunk';

import type { Dispatch, StoreState } from '@common/types/store';
import type { AllowedEmoji, SimpleUser, MinimalEmojiReaction } from '@common/types/objects';
import type { ListApiResponse, PartialBy } from '@common/types/util-types';

export const CHAT_ADD_MESSAGE = 'chat/ADD_MESSAGE';
export const CHAT_ADD_PARTICIPANTS = 'chat/ADD_PARTICIPANTS';
export const CHAT_ARCHIVE_CONVERSATION = 'chat/ARCHIVE_CONVERSATION';
export const CHAT_RECEIVE_CONVERSATION = 'chat/RECEIVE_CONVERSATION';
export const CHAT_REQUEST_CONVERSATIONS = 'chat/REQUEST_CONVERSATIONS';
export const CHAT_RECEIVE_CONVERSATIONS = 'chat/RECEIVE_CONVERSATIONS';
export const CHAT_RECEIVE_MESSAGES = 'chat/RECEIVE_MESSAGES';
export const CHAT_FILTER_CONVERSATIONS = 'chat/FILTER_CONVERSATIONS';
export const CHAT_LEAVE_CONVERSATION = 'chat/LEAVE_CONVERSATION';
export const CHAT_OPEN_CONVERSATION = 'chat/OPEN_CONVERSATION';
export const CHAT_POST_CONVERSATION = 'chat/POST_CONVERSATION';
export const CHAT_RECEIVE_ACTIVITY = 'chat/RECEIVE_ACTIVITY';
export const CHAT_RECEIVE_MESSAGE = 'chat/RECEIVE_MESSAGE';
export const CHAT_REMOVE_PARTICIPANT = 'chat/REMOVE_PARTICIPANT';
export const CHAT_MESSAGE_SEEN = 'chat/MESSAGE_SEEN';
export const CHAT_TOGGLE_CONVERSATION_NOTIFICATIONS = 'chat/TOGGLE_CONVERSATION_NOTIFICATIONS';
export const CHAT_UPDATE_CONVERSATION = 'chat/UPDATE_CONVERSATION';
export const CHAT_CLEAR_NOTIFICATIONS = 'chat/CLEAR_NOTIFICATIONS';
export const CHAT_ADD_GROUP_ADMIN = 'chat/ADD_GROUP_ADMIN';
export const CHAT_REMOVE_GROUP_ADMIN = 'chat/REMOVE_GROUP_ADMIN';
export const CHAT_REMOVE_MESSAGE = 'chat/REMOVE_MESSAGE';
export const CHAT_UNARCHIVE_CONVERSATION = 'chat/UNARCHIVE_CONVERSATION';
export const CHAT_REMOVE_CONVERSATION = 'chat/REMOVE_CONVERSATION';
export const CHAT_UPDATE_MESSAGE = 'chat/UPDATE_MESSAGE';
export const CHAT_ADD_EMOJI_REACTION = 'chat/ADD_EMOJI_REACTION';
export const CHAT_REMOVE_EMOJI_REACTION = 'chat/REMOVE_EMOJI_REACTION';
export const CHAT_GET_EMOJI_REACTION_USERS = 'chat/GET_EMOJI_REACTION_USERS';
export const CHAT_SET_USERS_STATUS = 'chat/CHAT_SET_USERS_STATUS';

// @ts-ignore
export const addGroupAdmin = (conversationId, userId) => (dispatch, getState) => {
  const { loggedUser: { user: { id: loggedUserId } }, organisation: { selected } } = getState();

  Api.put(`/v3/organisations/${selected.id}/conversations/${conversationId}/admins`, {
    add: [userId],
  });

  dispatch({
    type: CHAT_ADD_GROUP_ADMIN,
    conversationId,
    userId,
    activities: [
      createActivity(EConversationActivityTypes.ADMIN_ADDED, {
        actor_id: loggedUserId,
        user_id: userId,
      }),
    ],
  });
};

// @ts-ignore
export const removeGroupAdmin = (conversationId, userId) => (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  Api.put(`/v3/organisations/${selected.id}/conversations/${conversationId}/admins`, {
    remove: [userId],
  });

  dispatch({
    type: CHAT_REMOVE_GROUP_ADMIN,
    conversationId,
    userId,
  });
};

// @ts-ignore
export const removeMessage = (conversationId: string, messageId: string) => (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  Api.delete(`/v3/organisations/${selected.id}/conversations/${conversationId}/messages/${messageId}`);

  dispatch({
    type: CHAT_REMOVE_MESSAGE,
    conversationId,
    messageId,
  });
};

const unarchiveConversationAction = (conversationId: string) => ({
  type: CHAT_UNARCHIVE_CONVERSATION,
  payload: conversationId,
});

export const unarchiveConversation = (conversationId: string) => async (dispatch: Dispatch, getState: () => StoreState) => {
  const { organisation: { selected } } = getState();

  const url = `/v3/organisations/${selected.id}/conversations/${conversationId}/unarchive`;
  await Api.post(url);

  return dispatch(unarchiveConversationAction(conversationId));
};

export const removeConversation = (conversationId: string) => async (dispatch: Dispatch, getState: () => StoreState) => {
  const {
    organisation: { selected },
    chat: { messages: { conversations: conversationMessages } },
  } = getState();

  await Api.delete(`/v3/organisations/${selected.id}/conversations/${conversationId}`);

  return dispatch({
    type: CHAT_REMOVE_CONVERSATION,
    messageIds: conversationMessages[conversationId],
    conversationId,
  });
};

export const updateMessage = (newMessage: Object) => ({
  type: CHAT_UPDATE_MESSAGE,
  item: newMessage,
});

// @ts-ignore
export const socketUpdateMessage = ({ data }: Object) => updateMessage(data);

type AddMessageEmojiReactionAction = {
  type: typeof CHAT_ADD_EMOJI_REACTION;
  messageId: string;
  loggedUserId: string;
  newReactionEmoji: PartialBy<AllowedEmoji, 'unicode_full_name'>;
};

type ActualAddMessageEmojiReactionAction = ThunkAction<
Promise<AddMessageEmojiReactionAction>,
StoreState,
unknown,
AddMessageEmojiReactionAction
>;

export const addMessageEmojiReactionAction = (
  conversationId: string,
  messageId: string,
  { short_name, character }: PartialBy<AllowedEmoji, 'unicode_full_name'>,
): ActualAddMessageEmojiReactionAction => async (dispatch, getState) => {
  const {
    organisation: { selected },
    loggedUser: { user: { id: loggedUserId } },
  } = getState();

  const payload = { short_name };

  await Api.post(`/v3/organisations/${selected.id}/conversations/${conversationId}/messages/${messageId}/reactions`, payload);

  return dispatch({
    type: CHAT_ADD_EMOJI_REACTION,
    messageId,
    loggedUserId,
    newReactionEmoji: { short_name, character },
  });
};

type RemoveMessageEmojiReactionAction = {
  type: typeof CHAT_REMOVE_EMOJI_REACTION;
  messageId: string;
  loggedUserId: string;
  reactionEmoji: PartialBy<AllowedEmoji, 'unicode_full_name'>;
};

type ActualRemoveMessageEmojiReactionAction = ThunkAction<
Promise<RemoveMessageEmojiReactionAction>,
StoreState,
unknown,
RemoveMessageEmojiReactionAction
>;

export const removeMessageEmojiReactionAction = (
  conversationId: string,
  messageId: string,
  { short_name, character }: PartialBy<AllowedEmoji, 'unicode_full_name'>,
): ActualRemoveMessageEmojiReactionAction => async (dispatch, getState) => {
  const {
    organisation: { selected },
    loggedUser: { user: { id: loggedUserId } },
  } = getState();

  await Api.delete(
    `/v3/organisations/${selected.id}/conversations/${conversationId}/messages/${messageId}/reactions/${short_name}`,
  );

  return dispatch({
    type: CHAT_REMOVE_EMOJI_REACTION,
    messageId,
    loggedUserId,
    reactionEmoji: { short_name, character },
  });
};

type MessageEmojiReactionUsersApiResponse = ListApiResponse<(SimpleUser & { reactions: MinimalEmojiReaction[] })[]>;

type GetMessageEmojiReactionUsersAction = {
  type: typeof CHAT_GET_EMOJI_REACTION_USERS;
  reactionUsers: MessageEmojiReactionUsersApiResponse['data'];
  reactionShortName: string;
  nextCursor?: string | null;
  strategy: 'clear' | 'append';
};

type ActualGetMessageEmojiReactionUsersAction = ThunkAction<
Promise<MessageEmojiReactionUsersApiResponse>,
StoreState,
unknown,
GetMessageEmojiReactionUsersAction
>;

export type GetMessageEmojiReactionUsersActionFilter = {
  conversationId: string,
  messageId: string,
  reactionShortName: string,
};

export const getMessageEmojiReactionUsersAction = (
  nextCursor: string | null,
  { reactionShortName, messageId, conversationId }: GetMessageEmojiReactionUsersActionFilter,
  strategy: boolean,
): ActualGetMessageEmojiReactionUsersAction => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const query = Api.utils.toQuery({
    short_name: reactionShortName,
    cursor: nextCursor || true,
  });

  const response = await Api.get<MessageEmojiReactionUsersApiResponse>(
    `/v3/organisations/${selected.id}/conversations/${conversationId}/messages/${messageId}/reactions/users?${query}`,
  );

  const { data, meta } = response;

  dispatch({
    type: CHAT_GET_EMOJI_REACTION_USERS,
    strategy: strategy ? 'clear' : 'append',
    nextCursor: meta.pagination.next_cursor,
    reactionUsers: data,
    reactionShortName,
    messageId,
  });

  return response;
};
