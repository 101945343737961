import Api from '../../../common/services/api';

export const CORE_ACCEPT_DOCUMENT = 'core/ACCEPT_DOCUMENT';

export default (document) => async (dispatch) => {
  await Api.put('/v1/users/me', { [`accepted_${document}`]: true });

  dispatch({
    type: CORE_ACCEPT_DOCUMENT,
    accepted: {
      [`accepted_${document}`]: true,
    },
  });
};
