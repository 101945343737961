import { EFileTypes } from '@common/definitions';

const EFileTypeRegexes = {
  IMAGE: /\.(jpe?g|png|bmp|svg)$/i,
  PDF: /\.(pdf)$/i,
  VIDEO: /\.(mp4|mov|avi|m4v|3gp|qt|mpe?g)/i,
  AUDIO: /\.(wav|mp3|aac)$/i,
  DOCUMENT: /\.(doc|docx|docm|dotx|dotm|pages|csv|txt)$/i,
  SPREADSHEET: /\.(xls|xlsx|xlsm|xltx|xltm|numbers)$/i,
  PRESENTATION: /\.(ppt|pptx|pps|ppsx|pptm|potx|potm|key|odp)$/i,
  GIF: /\.(gif|x-xbitmap|gi_)$/i,
};

export const getFileTypeFromPath = (path: string) => {
  if (EFileTypeRegexes.IMAGE.test(path)) return EFileTypes.IMAGE;
  if (EFileTypeRegexes.GIF.test(path)) return EFileTypes.GIF;
  if (EFileTypeRegexes.VIDEO.test(path)) return EFileTypes.VIDEO;
  if (EFileTypeRegexes.AUDIO.test(path)) return EFileTypes.AUDIO;
  if (EFileTypeRegexes.PDF.test(path)) return EFileTypes.PDF;
  if (EFileTypeRegexes.DOCUMENT.test(path)) return EFileTypes.DOCUMENT;
  if (EFileTypeRegexes.SPREADSHEET.test(path)) return EFileTypes.SPREADSHEET;
  if (EFileTypeRegexes.PRESENTATION.test(path)) return EFileTypes.PRESENTATION;

  return EFileTypes.UNKNOWN;
};
