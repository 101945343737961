import React from 'react';
import { omit } from 'lodash';
import { UseControllerProps, useController } from 'react-hook-form';
import { Field, BaseFieldProps, WrappedFieldProps } from 'redux-form';

import { DateInput, DateInputProps } from './date-input';

/**
 * By default the form validation mode is set to `mode: 'onSubmit'`.
 * This means the validation will be shown after submitting the form.
 * You probably want to set this option to `all` to have immediate feedback when a field does not pass the added rules.
 * useForm({ mode: 'all' });
 */
export const DateInputHookForm = (props: Omit<DateInputProps, 'ref'> & UseControllerProps) => {
  const { field, fieldState } = useController(props);
  return (
    <DateInput
      {...props}
      // Not possible to pass ref to react-datepicker
      {...omit(field, ['ref'])}
      // Change to ISO date string
      onChange={(e) => {
        field.onChange(e?.isValid() ? e?.toISOString() : e);
      }}
      error={fieldState.error?.message || fieldState.error?.message === ''}
    />
  );
};

const ReduxFormField = (props: WrappedFieldProps & DateInputProps) => (
  <DateInput
    error={props.meta.touched && props.meta.error}
    warning={props.meta.touched && props.meta.warning}
    {...props.input}
    // redux-form onBlur does not take dateFormat ("dd-MM-yyyy") into account and
    // switches the month("MM") with the day ("dd"), causing a "RangeError: Invalid time value" error
    // when selecting a day above 12 and than blurring the field
    onBlur={() => props.input.onBlur(props.input.value)}
    {...omit(props, ['meta', 'input'])}
  />
);

export const DatePickerInput = ({ name, ...props }: BaseFieldProps & DateInputProps) => (
  <Field
    {...props}
    name={name}
    component={ReduxFormField}
  />
);
