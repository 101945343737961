export const openInNewTab = (src: string, download?: boolean, fileName?: string) => {
  if (!document) return;

  const a = document.createElement('a');
  a.setAttribute('target', '_blank');
  a.setAttribute('rel', 'noreferrer noopenner');
  a.setAttribute('href', src);
  if (fileName) a.setAttribute('download', fileName);

  a.click();
  if (download) window.focus();

  a.remove();
};
