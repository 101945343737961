import * as validationUtil from '../../../../common/utils/validation';

export default function validateErrors(values) {
  const errors = {};

  if (!values?.password_new || (values?.password_new && !validationUtil.test('PASSWORD', values.password_new))) {
    errors.password_new = 'authentication:password_requirements';
  }

  if (
    values?.password_new && values?.password_repeat &&
    values.password_new !== values.password_repeat
  ) {
    errors.password_repeat = 'authentication:password_must_equal';
  }

  return errors;
}
