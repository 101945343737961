import { createSelector } from 'reselect';

const getFunction = (state) => state.organisation.functions.items[state.ui.organisation.functions.editing];
const getMembership = (state) => state.loggedUser.memberships.functions[state.ui.organisation.functions.editing];
const getFunctions = (state) => state.organisation.functions.items;
const getMemberships = (state) => state.loggedUser.memberships.functions;

const createFunctionObj = (item, membership) => ({
  ...item,
  membership,
});

export const editing = createSelector(
  [getFunction, getMembership],
  (item, membership) => createFunctionObj(item, membership),
);

export const list = createSelector(
  [getFunctions, getMemberships],
  (items, memberships) => Object.values(items)
    .map((item) => createFunctionObj(item, memberships[item.id]))
    .sort((a, b) => a.name - b.name),
);
