import { useEffect, useRef, useCallback } from 'react';
import { throttle } from 'lodash-es';

type Settings = {
  onBottomTriggerOffset?: number;
  triggerRenderOnBottom?: boolean;
};

export const useOnScrollBottom = (
  target: HTMLElement | Document | null | undefined,
  callback: (() => Promise<any>) | undefined,
  { onBottomTriggerOffset = 2000 }: Settings = <Settings>{},
) => {
  const isFetching = useRef(false);

  const handleScroll = async (e: Event) => {
    const isDocument = target === document;
    if ((isDocument && !document.scrollingElement) || !e.target) return;

    const DOMtarget = e.target as HTMLElement;
    const element = isDocument ? document.documentElement : DOMtarget;

    const { offsetHeight, clientHeight, scrollHeight } = element;

    const actualScrollHeight = offsetHeight - (scrollHeight - clientHeight);
    const fromBottom = element.getBoundingClientRect().bottom - actualScrollHeight;

    const isScrollOnBottom = fromBottom <= onBottomTriggerOffset;

    if (isScrollOnBottom && !isFetching.current) {
      isFetching.current = true;
      await callback?.();
      isFetching.current = false;
    }
  };

  const throttledIdleCallbackHandler = useCallback(throttle((e: Event, debounceCallback: typeof handleScroll) => {
    requestAnimationFrame(() => debounceCallback(e));
  }, 1000, { leading: true }), []);

  useEffect(() => {
    const listener: EventListener = (e) => {
      throttledIdleCallbackHandler(e, handleScroll);
    };

    if (target && callback) {
      target.addEventListener('scroll', listener, { passive: true });
    }

    return () => {
      throttledIdleCallbackHandler?.cancel();

      if (target && callback) {
        target.removeEventListener('scroll', listener);
      }
    };
  }, [target, callback, handleScroll]);
};
