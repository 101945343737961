import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { Trans, withTranslation } from 'react-i18next';
import { Row, Group, TextInput } from '../../../../common/components/form';
import { Button } from '../../../../common/components/button';
import Alert from '../../../../common/components/alert';
import resetPassword from '../../actions/reset-password';
import validate from './validate';

class ForgotPasswordForm extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    formValues: PropTypes.object,
    submitting: PropTypes.bool,
    invalid: PropTypes.bool,
    username: PropTypes.string,
  };

  static defaultProps = {
    formValues: { username: '' },
  };

  constructor() {
    super();

    this.state = { warning: null };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { username } = this.props;

    if (username) {
      this.props.initialize({ username });
    }
  }

  async handleSubmit(values, dispatch) {
    const { history } = this.props;

    try {
      await dispatch(resetPassword(values));

      history.push(`/auth/login?username=${values.username}&reset=1`);
    } catch (err) {
      this.setState({
        warning: this.props.t('authentication:forgot_password_error'),
      });
    }
  }

  render() {
    const { warning } = this.state;
    const { submitting, invalid, handleSubmit, t } = this.props;
    const { username } = this.props.formValues;

    return (
      <>
        <h2><Trans i18nKey="authentication:forgot_password_header" /></h2>
        <form className="Form" onSubmit={handleSubmit(this.handleSubmit)}>
          {warning ? <Alert type="warning">{warning}</Alert> : null}

          <Row>
            <Group>
              <TextInput mask name="username" placeholder={t('authentication:forgot_password_email_label')} type="text" autoComplete="username" />
            </Group>
          </Row>

          <Button
            type="primary"
            size="fill"
            isLoading={submitting}
            buttonType="submit"
            disabled={invalid}
          >
            <Trans i18nKey="authentication:forgot_password_submit" />
          </Button>

          <div className="Authentication__Form__Action">
            <Link to={`/auth/login${username ? `?username=${username}` : ''}`}>
              <Trans i18nKey="authentication:forgot_password_back" />
            </Link>
          </div>
        </form>
      </>
    );
  }
}

export default withTranslation()(reduxForm({
  form: 'forgot-password',
  initialValues: {
    username: '',
  },
  validate,
})(ForgotPasswordForm));
