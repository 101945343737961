import React from 'react';
import { parse } from 'qs';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { pageWrapper, EEventNames } from '../../../../../client/analytics';
import ForgotPasswordForm from '../../forms/forgot-password';

const ForgotPasswordContainer = ({ form, location, history }) => {
  const query = parse(location.search, { ignoreQueryPrefix: true });

  return (
    <>
      <ForgotPasswordForm
        history={history}
        formValues={form.values}
        initialValues={{
          username: query.username && query.username.replace(/\s/g, '+'),
        }}
      />
      <div className="Authentication__Form__Helpdesk">
        <Trans i18nKey="authentication:need_help" />
        <br />
        <a onClick={() => (window.Intercom && window.Intercom('show'))}><Trans i18nKey="authentication:contact_helpdesk" /></a>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  form: state.form['forgot-password'] || {},
});

export default connect(mapStateToProps)(pageWrapper(EEventNames.VISITED_FORGOT_PASSWORD_PAGE)(ForgotPasswordContainer));
