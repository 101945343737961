import { Api } from '@services/api';
import type { ThunkAction } from 'redux-thunk';
import type { Dispatch, StoreState } from '@common/types/store';

export const ACADEMY_ADD_CATEGORY = 'academy/ADD_CATEGORY';
export const ACADEMY_ADD_COMPONENT = 'academy/ADD_COMPONENT';
export const ACADEMY_ADD_MODULE = 'academy/ADD_MODULE';
export const ACADEMY_ADD_SCREEN = 'academy/ADD_SCREEN';
export const ACADEMY_BULK_UPDATE_CATEGORIES = 'academy/BULK_UPDATE_CATEGORIES';
export const ACADEMY_BULK_UPDATE_COURSES = 'academy/BULK_UPDATE_COURSES';
export const ACADEMY_BULK_UPDATE_SCREENS = 'academy/BULK_UPDATE_SCREENS';
export const ACADEMY_CREATE_SCREEN_DRAFT = 'academy/CREATE_SCREEN_DRAFT';
export const ACADEMY_RECEIVE_CATEGORIES = 'academy/RECEIVE_CATEGORIES';
export const ACADEMY_RECEIVE_MODULE = 'academy/RECEIVE_MODULE';
export const ACADEMY_RECEIVE_MODULES = 'academy/RECEIVE_MODULES';
export const ACADEMY_RECEIVE_SEGMENT_USERS = 'academy/RECEIVE_SEGMENT_USERS';
export const ACADEMY_RECEIVE_STATISTICS = 'academy/RECEIVE_STATISTICS';
export const ACADEMY_OPEN_SEGMENT = 'academy/OPEN_SEGMENT';
export const ACADEMY_REMOVE_CATEGORY = 'academy/REMOVE_CATEGORY';
export const ACADEMY_REMOVE_COMPONENT = 'academy/REMOVE_COMPONENT';
export const ACADEMY_REMOVE_MODULE = 'academy/REMOVE_MODULE';
export const ACADEMY_REMOVE_SCREEN = 'academy/REMOVE_SCREEN';
export const ACADEMY_RESET_DRAFT = 'academy/RESET_DRAFT';
export const ACADEMY_UPDATE_CATEGORY = 'academy/UPDATE_CATEGORY';
export const ACADEMY_UPDATE_COMPONENTS_ORDER = 'academy/UPDATE_COMPONENTS_ORDER';
export const ACADEMY_UPDATE_MODULE = 'academy/UPDPATE_MODULE';
export const ACADEMY_UPDATE_SCREEN = 'academy/UPDATE_SCREEN';
export const LEARNING_FETCH_COURSES = 'learning/FETCH_COURSES';
export const LEARNING_FETCH_COURSE = 'learning/FETCH_COURSE';
export const LEARNING_UPDATE_COURSE = 'learning/UPDPATE_COURSE';
export const LEARNING_CREATE_COURSE = 'learning/CREATE_COURSE';
export const LEARNING_UPDATE_COURSES_ORDER = 'learning/UPDATE_COURSES_ORDER';
export const LEARNING_REMOVE_COURSE = 'learning/REMOVE_COURSE';
export const LEARNING_RECEIVE_ONBOARDING_STATISTICS = 'learning/RECEIVE_ONBOARDING_STATISTICS';
export const LEARNING_RECEIVE_COURSE_STATISTICS = 'learning/RECEIVE_COURSE_STATISTICS';
export const LEARNING_UPDATE_ONBOARDING_SETTINGS = 'learning/UPDATE_ONBOARDING_SETTINGS';
export const LEARNING_DUPLICATE_MODULE = 'learning/DUPLICATE_MODULE';
export const LEARNING_DUPLICATE_COURSE = 'learning/DUPLICATE_COURSE';

export const updateOnboardingSettings = (payload: Object) => async (dispatch: Dispatch, getState: () => StoreState) => {
  const { organisation: { selected } } = getState();

  // @ts-expect-error
  await Api.put(`/v1/organisations/${selected.id}/onboarding/settings`, payload);

  return dispatch({
    type: LEARNING_UPDATE_ONBOARDING_SETTINGS,
    payload,
  });
};

export const duplicateCourse = (id: string) => async (dispatch: Dispatch, getState: () => StoreState) => {
  const { organisation: { selected } } = getState();

  const { data } = await Api.post(`/v2/organisations/${selected.id}/courses/${id}/duplicate`);

  return dispatch({
    type: LEARNING_DUPLICATE_COURSE,
    item: data,
  });
};

export const duplicateModule = (id: string, courseId: string) => async (dispatch: Dispatch, getState: () => StoreState) => {
  const { organisation: { selected } } = getState();

  const { data } = await Api.post(`/v2/organisations/${selected.id}/modules/${id}/duplicate`);

  return dispatch({
    type: LEARNING_DUPLICATE_MODULE,
    courseId,
    section: data,
  });
};

export type ChangeCourseApiResponse = {
  success: boolean;
};

export type ChangeCourseTypeAction = {
  type: 'academy/CHANGE_COURSE_TYPE';
  courseId: string;
};

export type ActualChangeCourseType = ThunkAction<
Promise<ChangeCourseTypeAction>,
StoreState,
unknown,
ChangeCourseTypeAction
>;

export const changeCourseTypeAction = (courseId: string): ActualChangeCourseType => async (
  dispatch,
  getState,
) => {
  const { organisation: { selected } } = getState();

  await Api.post<ChangeCourseApiResponse>(`/v2/organisations/${selected.id}/courses/${courseId}/move`);

  return dispatch({
    type: 'academy/CHANGE_COURSE_TYPE',
    courseId,
  });
};
