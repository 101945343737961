import { combineReducers } from 'redux';
import * as R from 'ramda';
import { SELECT_NETWORK } from '../../network/actions';
import { FLEXCHANGE_ADD_EXCHANGE } from '../../flexchange/actions';
import { RECEIVE_SHIFTS, SELECT_SHIFT, DESELECT_SHIFT } from '../actions';

const items = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_SHIFTS:
      return R.indexBy(R.prop('id'), action.items);
    case FLEXCHANGE_ADD_EXCHANGE: {
      const { source } = action.exchange;

      if (!source.shift_id) return state;

      return R.evolve({
        [source.shift_id]: R.assoc('exchange_id', parseInt(source.id, 10)),
      }, state);
    }
    case SELECT_NETWORK: return [];
    default: return state;
  }
};

const selected = (state = null, action) => {
  switch (action.type) {
    case SELECT_SHIFT:
      return action.shiftId;
    case DESELECT_SHIFT:
      return null;
    default: return state;
  }
};

export default combineReducers({ items, selected });
