import { useEffect, useCallback } from 'react';

export const useOnEvent = <EventTypes extends keyof GlobalEventHandlersEventMap>(
  eventType: EventTypes,
  listener: (e: GlobalEventHandlersEventMap[EventTypes]) => void,
  deps: unknown[] = [],
) => {
  const persistedListener = useCallback(listener, deps);

  useEffect(() => {
    document.addEventListener(eventType, persistedListener);

    return () => document.removeEventListener(eventType, persistedListener);
  }, [persistedListener]);
};
