import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

class Tooltip extends React.Component {
  static defaultProps = {
    placement: 'top',
  };

  static props;

  render() {
    const {
      title, children, placement, disabled, fullTitle = false, ...props
    } = this.props;

    if (disabled) return children;

    const trigger = React.cloneElement(children, props);

    const content = (
      typeof title === 'string' && title.length > 150 && !fullTitle
    ) ? `${title.substr(0, 150)}...` : title;

    return (
      <OverlayTrigger
        placement={placement}
        trigger="hover"
        overlay={(
          <div className="Tooltip">
            {(placement === 'bottom' || placement === 'left') && (
              <div className={`Tooltip__Arrow Tooltip__Arrow--${placement}`} />
            )}
            <div className="Tooltip__Content">{content}</div>
            {placement === 'top' && (
              <div className={`Tooltip__Arrow Tooltip__Arrow--${placement}`} />
            )}
          </div>
        )}
      >
        {trigger}
      </OverlayTrigger>
    );
  }
}

export default Tooltip;
