import * as R from 'ramda';
import { combineReducers } from 'redux';
import { CHAT_RECEIVE_MESSAGES, CHAT_ADD_MESSAGE, CHAT_RECEIVE_CONVERSATIONS } from '../actions';

const isAttachment = R.filter(R.either(
  R.propEq('object_type', 'attachment'),
  R.propEq('object_type', 'other_attachment'),
));
const addToStore = (acc, attachment) => R.assoc(attachment.source_id, attachment.source, acc);

const items = (state = {}, action) => {
  switch (action.type) {
    case CHAT_RECEIVE_CONVERSATIONS:
      return R.pipe(
        R.pluck('last_message'),
        R.reject(R.isNil),
        R.pluck('children'),
        R.flatten,
        isAttachment,
        R.reduce(addToStore, state),
      )(action.items);
    case CHAT_RECEIVE_MESSAGES:
      return R.pipe(
        R.pluck('children'),
        R.flatten,
        isAttachment,
        R.reduce(addToStore, state),
      )(action.items);
    case CHAT_ADD_MESSAGE:
      return R.pipe(isAttachment, R.reduce(addToStore, state))(action.item.children);
    default: return state;
  }
};

const getIds = R.pipe(isAttachment, R.pluck('source_id'));
const addToMessage = (acc, object) => R.assoc(object.source_id, getIds(object.children), acc);

const messages = (state = {}, action) => {
  switch (action.type) {
    case CHAT_RECEIVE_CONVERSATIONS:
      return R.pipe(
        R.pluck('last_message'),
        R.reject(R.isNil),
        R.reduce(addToMessage, state),
      )(action.items);
    case CHAT_RECEIVE_MESSAGES:
      return R.reduce(addToMessage, state, action.items);
    case CHAT_ADD_MESSAGE:
      return addToMessage(state, action.item);
    default: return state;
  }
};

export default combineReducers({ items, messages });

export const findById = R.curry((state, id) => state.chat.attachments.items[id]);
export const findByMessage = R.curry((state, id) => R.map(
  findById(state),
  state.chat.attachments.messages[id] || [],
));
