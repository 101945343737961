import React from 'react';
import { Field } from 'redux-form';
import { connect } from 'react-redux';

import { Select, SelectProps } from '@common/components/form/inputs/select';
import type { StoreState } from '../../../../types/store';
import type { Language } from '../../../../types/objects';

type OwnProps = {
  input: {
    value: string | Array<string>;
    onChange: SelectProps['onChange'];
    onFocus?: Function;
    onBlur?: Function;
  },
  meta: {
    visited?: boolean;
    invalid?: boolean;
  },
};

type Props = OwnProps & {
  options: Array<Object>;
};

type OptionProps = {
  option: Language;
  isSelected: boolean;
  onSelect: (option: string) => void;
};

/* eslint-disable react/prefer-stateless-function */
const LanguageOptionComponent = ({ option, isSelected, onSelect }: OptionProps) => (
  <div
    key={option.locale}
    className={`Select-option${isSelected ? ' is-selected' : ''} LanguageOption`}
    onClick={!isSelected ? () => onSelect(option.locale) : undefined}
  >
    <img src={option.icon} alt={option.name} />
    <div>
      <h4>{option.name}</h4>
      <small>{option.name_eng}</small>
    </div>
  </div>
);

const LanguageOption = LanguageOptionComponent;

const LanguageSelect = ({ input: { value, onChange, onFocus, onBlur }, meta: { visited, invalid }, options }: Props) => (
  <div style={{ width: 250 }}>
    <Select
      valueKey="locale"
      labelKey="name"
      className={(visited && invalid) ? 'Form__control--invalid' : undefined}
      options={options}
      value={value}
      onChange={onChange}
      // @ts-expect-error
      OptionComponent={LanguageOption}
      onFocus={onFocus && (() => onFocus())}
      onBlur={onBlur && (() => onBlur())}
      clearable={false}
      searchable
    />
  </div>
);

const mapStateToProps = (state: StoreState, props: Props) => ({
  // @ts-expect-error
  options: props.translations ? state.language.translations : state.language.available,
});

// @ts-expect-error
const ConnectedLanguageInput = connect<Props, OwnProps>(mapStateToProps)(LanguageSelect);

export const LanguageInput = ConnectedLanguageInput;

export default (props: { name: string, translations: boolean } & Props) => (
  <Field
    {...props}
    component={LanguageInput}
  />
);
