import React from 'react';
import { omit } from 'lodash';
import { Field, BaseFieldProps, WrappedFieldProps } from 'redux-form';
import { UseControllerProps, useController } from 'react-hook-form';

import { Checkbox } from './checkbox-input';
import type { CheckboxOwnProps } from './checkbox-input';

type ReduxFormFieldProps = Omit<CheckboxOwnProps, 'value'> & {
  values?: string[];
  reverse?: boolean;
  group?: boolean;
};

/**
 * By default the form validation mode is set to `mode: 'onSubmit'`.
 * This means the validation will be shown after submitting the form.
 * You probably want to set this option to `all` to have immediate feedback when a field does not pass the added rules.
 * useForm({ mode: 'all' });
 */
export const CheckboxInputHookForm = (props: Omit<CheckboxOwnProps, 'ref' | 'onChange' | 'value'> & UseControllerProps) => {
  const { field, fieldState } = useController(props);
  return (
    <Checkbox
      {...props}
      {...field}
      error={fieldState.error?.message || fieldState.error?.message === ''}
    />
  );
};

const ReduxFormField = ({ input, meta, ...props }: WrappedFieldProps & ReduxFormFieldProps) => {
  return (
    <Checkbox
      error={meta.touched && meta.error}
      {...input}
      {...omit(props, ['meta', 'input'])}
      onChange={props.onChange || input.onChange}
    />
  );
};

export default ({ name, ...props }: BaseFieldProps & ReduxFormFieldProps) => (
  <Field
    name={name}
    component={ReduxFormField}
    props={props}
  />
);
