import * as R from 'ramda';
import { createSelector } from 'reselect';
import memoize from '@common/utils/memoize';
import { EComponentTypes, ELevels } from '@common/definitions';
import * as usersSelector from '../../core/selectors/users';
import * as usersReducer from '../../core/reducers/users';
import * as networksReducer from '../../core/reducers/networks';
import * as channelSelector from './channel';
import * as functionSelector from './function';
import type { StoreState } from '@common/types/store';
import type { Network, Organisation, Role, UIRole } from '@common/types/objects';
import * as networkSelectors from '@modules/network/selectors/network';
import { ComponentTypesValues, hasEnabledComponent } from '@common/utils/has-enabled-component';

const getSelectedOrganisation = (state: StoreState) => state.organisation.selected;
// @ts-expect-error
const getOrganisationNetworks = (state: StoreState): Network[] => state.organisation.networks.items;
const getAdmins = (state: StoreState) => state.organisation.admins;
// @ts-expect-error
const getSetupProgress = (state: StoreState) => state.organisation.setup;
const getRoles = (state: StoreState): Record<string, Role> => state.organisation.roles;
const getWindowLocation = () => window && window.location && window.location.href;

export const getWhiteLabelConfig = (state: StoreState) => state.organisation.selected.whitelabel_config;

export const selected = createSelector(
  [getSelectedOrganisation],
  (organisation) => organisation,
);

export const getCurrentOrgId = createSelector(
  [selected],
  (organisation) => organisation.id,
);

export const networks = createSelector(
  [
    (state: StoreState) => state,
    getOrganisationNetworks
  ],
  (state, items): Network[] => {
    return R.pipe(
      // @ts-expect-error
      R.map(networksReducer.findById(state)),
      R.reject(R.isNil),
      R.sortBy(R.prop('name')),
    // @ts-expect-error
    )(items);
  }
);

export const admins = memoize.createSelector(
  [getAdmins, getRoles],
  // @ts-expect-error
  (state, orgAdmins, orgRoles) => R.map((admin) => {
    // @ts-expect-error
    const roles = admin.roles
      // @ts-expect-error
      .map(({ id, memberships }) => (orgRoles[id]
        // @ts-expect-error
        ? R.assoc('memberships', memberships, orgRoles[id])
        : false))
        // @ts-expect-error
      .filter((r) => !!r);

    // @ts-expect-error
    const lastAddedRole = R.sortBy(R.descend(R.prop('memberships.added_at')), roles)[0] || null;

    return {
      // @ts-expect-error
      ...usersReducer.findById(state, admin.id),
      last_modified: lastAddedRole && {
        // @ts-expect-error
        added_by: usersReducer.findById(state, lastAddedRole.memberships.added_by),
        // @ts-expect-error
        added_at: lastAddedRole.memberships.added_at,
      },
      roles,
    };
  }, orgAdmins),
);

export const roles = createSelector(
  [getRoles, usersSelector.getStoredUsers],
  (roleItems, users) => ({
    organisation: R.sortBy(
      R.prop('name'),
      Object.values(roleItems)
        .filter(({ level }) => level === ELevels.ORGANISATION)
        .map((role): UIRole => ({
          ...role,
          last_updated_by: users[role.last_updated_by],
        })),
    ),
    network: R.sortBy(
      R.prop('name'),
      Object.values(roleItems)
        .filter(({ level }) => level === ELevels.NETWORK)
        .map((role): UIRole => ({
          ...role,
          last_updated_by: users[role.last_updated_by],
        })),
    ),
  }),
);

export const userRoles = createSelector(
  [roles, usersSelector.byId],
  (rolesObject, user) => {
    if (!user?.scopes) return { organisation: [], networks: {} };

    return {
      organisation: user.scopes.organisation.roles
        ?.map((role) => rolesObject.organisation.find(({ id }) => role.id === id))
        .filter((role): role is UIRole => !!role),

      // do not rely on role.memberships[], see comments on PD-8672
      networks: user.scopes.networks.reduce<Record<string, UIRole[]>>((acc, network) => ({
        ...acc,
        [network.id]: network.roles
          ?.map((role) => rolesObject.network.find((({ id }) => role.id === id)))
          .filter((role): role is UIRole => !!role),
      }), {}),
    };
  },
);

// Can cause a 'ReferenceError' in a build (not dev server)
// See https://linear.app/oneteam/issue/ONE-1327/importing-organisation-selector-too-soon-can-cause-a-referenceerror
export const functions = functionSelector.list;
export const channels = channelSelector.list;

export const isDemoExpired = memoize.createSelector(
  [getSelectedOrganisation, getWindowLocation],
  (_, organisation) => {
    // @ts-expect-error
    if (!organisation || !organisation.trial) return false;
    // @ts-expect-error
    return new Date(organisation.trial.expires_at) < new Date();
  },
);

export const setup = memoize.createSelector(
  [getSetupProgress],
  (_, progress) => {
    // @ts-expect-error
    const steps = R.values(progress);
    const completedSteps = R.filter((step) => step, steps);
    const percentage = Math.round(completedSteps.length / steps.length * 100);

    return {
      percentage,
      steps: progress,
    };
  },
);

export const hasAnyEnabledComponents = createSelector(
  [
    selected,
    networkSelectors.selected,
    (state: StoreState, components: ComponentTypesValues[]) => components
  ],
  (org: Organisation, net: Network, components: ComponentTypesValues[]) => {
    return !!components.find((component) => hasEnabledComponent(component, net, org));
  }
);

const ELEARNING_COMPONENT = [EComponentTypes.ACADEMY];
export const isElearningEnabled = (state: StoreState) => {
  return hasAnyEnabledComponents(state, ELEARNING_COMPONENT);
};
