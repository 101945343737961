import { combineClassNames } from '@common/utils/combineClassNames';
import React, { useState } from 'react';
import Icon from '../icon';

type AlertTypes = 'warning' | 'error' | 'info' | 'success';

type Props = {
  type?: AlertTypes;
  title?: string;
  children?: any;
  className?: string;
  size?: 'small' | 'default';
  show?: boolean;
  defaultOpen?: boolean;
};

const getIcon = (type: AlertTypes) => {
  switch (type) {
    case 'info':
      return <Icon type="info__filled" />;
    case 'error':
      return <Icon type="close_round__filled" />;
    case 'warning':
      return <Icon type="warning" />;
    case 'success':
      return <Icon type="check_circle__filled" />;
    default:
      return null;
  }
};

export const Alert = ({
  children,
  type = 'info',
  size = 'default',
  className,
  title,
  defaultOpen,
}: Props) => {
  const [show, setShow] = useState(defaultOpen === false ? false : (!title || !!children));

  const classNames = combineClassNames('Alert', className, `Alert--type-${type}`, `Alert--size-${size}`, {
    'Alert--toggle': !!title,
  });

  return (
    <div className={classNames}>
      {title && (
        <div className="Alert__Title" onClick={children && (() => setShow(!show))}>
          {getIcon(type)}
          {title}
        </div>
      )}
      {show && (
        <div className="Alert__Content">
          {!title ? getIcon(type) : null}
          {children}
        </div>
      )}
    </div>
  );
};
