import React from 'react';
import { Trans } from 'react-i18next';
import Container from '../container';
import Alert from '../alert';

const RouteNotFoundComponent = () => (
  <Container name="notFound">
    <Container.Content>
      <div className="AsyncSpinner">
        <Alert type="warning">
          <b><Trans i18nKey="common:route_not_found_text" /></b>
        </Alert>
      </div>
    </Container.Content>
  </Container>
);

export default RouteNotFoundComponent;
