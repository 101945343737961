import * as React from 'react';
import Image from '../image';
import Icon, { IconTypes } from '../icon';

export type Props = {
  name: any,
  imageSize?: number,
  image?: string | null,
  icon?: IconTypes,
  placeholderColor?: string,
  meta?: any,
  className?: string,
  mask?: boolean,
  onClick?: React.MouseEventHandler<HTMLDivElement>,
};

const ImageItem = React.memo(({
  name, meta, className, image, icon, imageSize = 30, placeholderColor, mask, onClick,
}: Props) => (
  <div className={`ImageItem ${className || ''}`} onClick={onClick}>
    {icon && (
      <div className="ImageItem__Icon" style={{ width: imageSize, height: imageSize }}>
        <Icon type={icon} />
      </div>
    )}
    {!icon && (
      <Image
        exclude
        size={imageSize}
        className="ImageItem__image"
        src={image}
        alt={name}
        placeholderColor={placeholderColor}
      />
    )}
    {typeof name === 'string'
      ? <h5 className={`ImageItem__name${mask ? ' fs-exclude' : ''}`}>{name}</h5>
      : name}
    {meta && <small>{meta}</small>}
  </div>
));

export default ImageItem;
