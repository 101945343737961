import React, { useState } from 'react';
import * as R from 'ramda';
import { withTranslation } from 'react-i18next';
import ImagePlaceholder from '../image-placeholder';


const Image = React.memo(withTranslation()(({
  src, size, alt, className, rounded, placeholderColor, exclude, t, ...props
}) => {
  const [fallback, setFallback] = useState(!src);

  if (fallback || !src) {
    return <ImagePlaceholder size={size || 40} name={alt} color={placeholderColor} {...R.omit(['tReady', 'i18n'], props)} />;
  }

  const style = size && { width: size, height: size };

  const classNames = ['Image'];
  if (exclude) classNames.push('fs-exclude');
  if (rounded) classNames.push('Image--rounded');
  if (className) classNames.push(className);

  return (
    <div className={classNames.join(' ')} style={style}>
      <img
        {...R.omit(['tReady', 'i18n'], props)}
        src={src}
        onError={() => setFallback(true)}
        alt={alt || t('common:image_alt_fallback')}
      />
    </div>
  );
}));

export default Image;
