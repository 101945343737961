import * as React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import Modal from '../../../../common/components/modal';
import { Button } from '../../../../common/components/button';
import Confirm from '../../../../common/components/confirm-button';

class LegalDocumentComponent extends React.Component {
  constructor() {
    super();

    this.setReference = (ref) => (this.modal = ref);

    this.handleClose = this.handleClose.bind(this);
  }

  static props;
  modal;

  handleClose(fn) {
    return () => (this.modal ? this.modal.handleClose(fn) : fn());
  }

  render() {
    const { path, onReject, onAccept, t } = this.props;

    return (
      <Modal
        ref={this.setReference}
        borderless
        disableRootClose
        className="LegalDocument"
        backdropClassName="LegalDocument"
        content={(
          <div className="Form">
            <iframe
              title="LegalDocument"
              className="LegalDocument__Frame"
              src={path}
              frameBorder="0"
              scrolling="auto"
            />
            <div className="LegalDocuments__Footer">
              <Confirm
                title={t('core:legal_document_decline_title')}
                description={t('core:legal_document_decline_description')}
                onConfirm={this.handleClose(onReject)}
              >
                <Button
                  style={{ flex: 1 }}
                  type="warning"
                  size="large"
                >
                  <Trans i18nKey="core:legal_document_decline_button" />
                </Button>
              </Confirm>
              <Button
                style={{ flex: 1 }}
                type="primary"
                size="large"
                onClick={this.handleClose(onAccept)}
              >
                <Trans i18nKey="core:legal_document_approve_button" />
              </Button>
            </div>
          </div>
        )}
      />
    );
  }
}

export default withTranslation()(LegalDocumentComponent);
