import Storage from '../services/storage';
import { BASE_URL } from '../constants';

export const parseCookie = (str) => {
  return str
    .split(';')
    .map((v) => v.split('='))
    .reduce((acc, v) => {
      const value = v[1] ? decodeURIComponent(v[1].trim()) : null;
      acc[decodeURIComponent(v[0].trim())] = value;
      return acc;
    }, {});
};

const requestNewToken = async () => {
  try {
    const refreshToken = Storage.getCookie('refresh_token');

    if (!refreshToken) throw new Error('No refresh token specified.');

    const response = await fetch(`${BASE_URL}/v2/delegate?refresh_token=${refreshToken}`, {
      credentials: 'include',
    });

    if (!response.ok) throw new Error('Couldn\'t refresh token.');

    const { data: { access_token: accessToken } } = await response.json();

    Storage.setCookie('access_token', accessToken, [59, 'minutes']); // 59 minutes

    return accessToken;
  } catch (err) {
    await Storage.clear();
    window.location.replace('/auth/login');

    throw err;
  }
};

export const refreshToken = (() => {
  let promise = false;

  return () => {
    if (!promise) {
      promise = requestNewToken()
        .then((token) => {
          promise = false;

          return token;
        });
    }

    return promise;
  };
})();

/**
 * Checks wether the user is authenticated or not
 * @function isAuthenticated
 * @returns {boolean} User is authenticated
 */
export function isAuthenticated() {
  return !!Storage.getCookie('refresh_token');
}
