import * as React from 'react';

type Props = React.PropsWithChildren<{
  flex?: number | string;
  size?: string;
  style?: Record<string, string | number>;
}>;

const FormGroup = ({ children, flex, size, style }: Props): JSX.Element => {
  const className = ['Form__Group'];
  if (size) className.push(`Form__Group--size-${size}`);

  return <div className={className.join(' ')} style={{ flex, ...style }}>{children}</div>;
};

export default FormGroup;
