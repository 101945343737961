import { EEventNames } from '@services/analytics';
import { Filters } from '../constants';

export const getPageTrackingEventName = (filter: Filters | undefined) => {
  switch (filter) {
    case Filters.FAVORITES:
      return EEventNames.VIEWED_FAVORITED_DOCUMENTS;
    case Filters.RECENT:
      return EEventNames.VIEWED_RECENT_DOCUMENTS;
    case Filters.TRASH:
      return undefined;
    default:
      return EEventNames.VIEWED_ALL_DOCUMENTS;
  }
};
