import * as Analytics from '../../../client/analytics';

export const { EEventNames } = Analytics;

export const { track } = Analytics;

export const { register } = Analytics;

export const capitalise = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const getExtension = (path) => {
  const removeQuery = path.split('?');
  const split = removeQuery[0].split('.');

  return split[split.length - 1];
};
