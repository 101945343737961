import { ThunkAction } from 'redux-thunk';
import * as R from 'ramda';

import Api from '../../../common/services/api';
import Storage from '@services/storage';
import * as fileUtil from '@common/utils/file';
import createTempId from '@common/utils/create-temp-id';

import type { StoreState } from '@common/types/store';
import type {
  Attachment,
  FunctionLink,
  Network,
  NetworkScope,
  Role,
} from '@common/types/objects';
import type { FileItem } from '../types';

export const CORE_ACCEPT_DOCUMENT = 'core/ACCEPT_DOCUMENT';
export const CLEAR_NOTIFICATION = 'core/CLEAR_NOTIFICATION';
export const CLEAR_NOTIFICATIONS = 'core/CLEAR_NOTIFICATIONS';
export const CLEAR_UNREAD = 'core/CLEAR_UNREAD';
export const RECEIVE_FILE = 'core/RECEIVE_FILE';
export const NETWORK_RECEIVE_USER_FUNCTIONS = 'core/RECEIVE_USER_FUNCTIONS';
export const RECEIVE_NOTIFICATIONS = 'core/RECEIVE_NOTIFICATIONS';
export const RECEIVE_PROFILE = 'core/RECEIVE_PROFILE';
export const CORE_RECEIVE_USER = 'core/RECEIVE_USER';
export const CORE_INITIALISE = 'core/INITIALISE';
export const CORE_INTEGRATION_AUTHENTICATED = 'core/INTEGRATION_AUTHENTICATED';
export const RECEIVE_NOTIFICATION = 'core/RECEIVE_NOTIFICATION';
export const UPDATE_PROFILE = 'core/UPDATE_PROFILE';
export const CORE_RECEIVE_LANGUAGES = 'core/RECEIVE_LANGUAGES';
export const CORE_REFRESH_REDEEM_CODE = 'core/REFRESH_REDEEM_CODE';
export const CORE_DISMISS = 'core/DISMISS';
export const SET_USER_STATUS = 'core/SET_USER_STATUS';
export const UNSET_USER_STATUS = 'core/UNSET_USER_STATUS';
export const SHOW_PLAN_PACKAGE_UPGRADE_MODAL = 'core/SHOW_PLAN_PACKAGE_UPGRADE_MODAL';
export const HIDE_PLAN_PACKAGE_UPGRADE_MODAL = 'core/HIDE_PLAN_PACKAGE_UPGRADE_MODAL';

export type SetUserStatusAction = {
  type: typeof SET_USER_STATUS,
  emoji: string,
  text: string,
  expires_at: string,
  userId: string
};

// @ts-expect-error
export const refreshRedeemCode = (userId: string) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const { data: code } = await Api.post(`/v1/organisations/${selected.id}/users/${userId}/redeem-code/request`);

  dispatch({
    type: CORE_REFRESH_REDEEM_CODE,
    userId,
    code,
  });
};

// @ts-expect-error
export const dismiss = (name: string) => (dispatch) => {
  Storage.setCookie(`dismiss_${name}`, true);

  dispatch({
    type: CORE_DISMISS,
    name,
  });
};

export type UploadFilesAction = {
  type: 'core/UPLOAD_FILES';
  items: FileItem[];
};

export type ClearQueueItem = {
  type: 'core/CLEAR_QUEUE_ITEM';
  id: string;
};

type ActualUploadFileAction = ThunkAction<Promise<UploadFilesAction>, StoreState, unknown, UploadFilesAction | ClearQueueItem>;

// Upload file and add to the file upload queue popup
export const uploadFiles = (
  files: File[],
  onUpload: (item: Attachment) => void,
  skipProcessing = false,
): ActualUploadFileAction => async (dispatch, getState) => {
  const { organisation: { selected: { id: organisationId } } } = getState();

  const uploads = await Promise.all(files.map((async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    const tempId = createTempId(); // Create an ID for the item in the queue

    const uploadFile = async (payload: FormData) => {
      const { promise, onProgress } = await fileUtil.upload(`/v2/organisations/${organisationId}/files`, payload, skipProcessing);

      return {
        onProgress,
        promise: promise.then((attachment: Attachment) => {
          onUpload(attachment);

          setTimeout(() => {
            dispatch({
              type: 'core/CLEAR_QUEUE_ITEM',
              id: tempId,
            });
          }, 4000);

          return attachment;
        }),
      };
    };

    return {
      id: tempId,
      file,
      ...await uploadFile(formData),
      onRetry: () => uploadFile(formData), // Need to preserve all the callbacks passed to the action
    };
  })));

  return dispatch({
    type: 'core/UPLOAD_FILES',
    items: uploads,
  });
};

export type ClearUploadQueueAction = {
  type: 'core/CLEAR_UPLOAD_QUEUE';
};

export const clearUploadQueue = (): ClearUploadQueueAction => ({
  type: 'core/CLEAR_UPLOAD_QUEUE',
});

export type AddUserToCommunityAction = {
  type: 'core/ADD_USER_TO_COMMUNITY';
  userId: string;
  networks: NetworkScope[];
};

type ActualAddUserToCommunityAction = ThunkAction<
Promise<AddUserToCommunityAction>,
StoreState,
unknown,
AddUserToCommunityAction
>;

export const addUserToCommunity = (
  userId: string,
  network: Network,
  roles?: Role[],
): ActualAddUserToCommunityAction => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  await Api.put(`/v2/organisations/${selected.id}/users/${userId}/networks`, { add: [network.id] });

  if (roles && !R.isEmpty(roles)) {
    await Api.put(`/v1/networks/${network.id}/users/${userId}/roles`, { add: roles.map(({ id }) => id) });
  }

  type GetApiResponse = { data: { scopes: { networks: NetworkScope[] } } };
  const { data } = await Api.get<GetApiResponse>(`/v2/organisations/${selected.id}/users/${userId}`);

  return dispatch({
    type: 'core/ADD_USER_TO_COMMUNITY',
    userId,
    networks: data.scopes.networks,
  });
};

export type RemoveUserFromCommunity = {
  type: 'core/REMOVE_USER_FROM_COMMUNITY';
  userId: string;
  networkId: string;
};

type ActualRemoveUserFromCommunity = ThunkAction<
Promise<RemoveUserFromCommunity>,
StoreState,
unknown,
RemoveUserFromCommunity>;

export const removeUserFromCommunity = (
  userId: string,
  networkId: string,
): ActualRemoveUserFromCommunity => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  await Api.put(`/v2/organisations/${selected.id}/users/${userId}/networks`, { remove: [networkId] });

  return dispatch({
    type: 'core/REMOVE_USER_FROM_COMMUNITY',
    userId,
    networkId,
  });
};

export type AddUserToFunctionGroup = {
  type: 'core/ADD_USER_TO_FUNCTION_GROUP';
  functionGroup: FunctionLink;
  userId: string;
};

type ActualAddUserToFunctionGroup = ThunkAction<
Promise<AddUserToFunctionGroup>,
StoreState,
unknown,
AddUserToFunctionGroup
>;

export const addUserToFunctionGroup = (
  userId: string,
  functionGroup: FunctionLink,
  networkId: string | undefined,
): ActualAddUserToFunctionGroup => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const endpoint = networkId
    ? `/v2/networks/${networkId}/users/${userId}/functions`
    : `/v2/organisations/${selected.id}/users/${userId}/functions`;

  await Api.put(endpoint, { add: [functionGroup.id] });

  return dispatch({
    type: 'core/ADD_USER_TO_FUNCTION_GROUP',
    userId,
    functionGroup,
  });
};

export type RemoveUserFromFunctionGroup = {
  type: 'core/REMOVE_USER_FROM_FUNCTION_GROUP';
  functionGroupId: string;
  userId: string;
};

type ActualRemoveUserFromFunctionGroup = ThunkAction<
Promise<RemoveUserFromFunctionGroup>,
StoreState,
unknown,
RemoveUserFromFunctionGroup
>;

export const removeUserFromFunctionGroup = (
  userId: string,
  functionGroupId: string,
  networkId: string | undefined,
): ActualRemoveUserFromFunctionGroup => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const endpoint = networkId
    ? `/v2/networks/${networkId}/users/${userId}/functions`
    : `/v2/organisations/${selected.id}/users/${userId}/functions`;

  await Api.put(endpoint, { remove: [functionGroupId] });

  return dispatch({
    type: 'core/REMOVE_USER_FROM_FUNCTION_GROUP',
    functionGroupId,
    userId,
  });
};
