import React from 'react';
import { Trans } from 'react-i18next';

import './message.scss';

export type MessageProps = Record<string, string | number> | {
  warning?: string | boolean;
  error?: string | boolean;
};

export type ErrorType = {
  translationKey: string;
  props: Record<string, string | number>;
};

type MessagesProps = {
  errors: ErrorType[];
};

export const Messages = ({ errors }: MessagesProps) => {
  return (
    <div className="Messages">
      <ul>
        {errors.map(({ translationKey, props }) => (
          <li>
            <Message error={translationKey} {...props} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export const Message = ({ error, warning, ...props }: MessageProps) => {
  let message;
  let className = '';
  if (error && typeof error === 'string') {
    message = error;
    className = 'Form__control__error';
  }
  if (warning && typeof warning === 'string') {
    message = warning;
    className = 'Form__control__warning';
  }

  if (!message) return null;

  return (
    <small className={className}>
      <Trans i18nKey={message} values={props}>{message}</Trans>
    </small>
  );
};
