import PropTypes from 'prop-types';
import React, { Component } from 'react';

import ImageItem from '@common/components/image-item';
import { Checkbox } from '@common/components/form/inputs/checkbox';

class UsersInputItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    onAdd: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    isSelected: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    isDisabled: PropTypes.func,
  };

  constructor(props) {
    super();

    this.state = { selected: props.isSelected(props.item) };

    this.handleToggle = this.handleToggle.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.selected !== nextState.selected;
  }

  handleToggle() {
    const { item, onAdd, onRemove, disabled } = this.props;

    if (disabled) return false;

    this.setState({ selected: !this.state.selected });

    return this.state.selected
      ? onRemove(item.id)
      : onAdd(item.id);
  }

  render() {
    const { selected } = this.state;
    const { item, isDisabled } = this.props;
    const disabled = isDisabled ? isDisabled(item) : this.props.disabled;

    return (
      <Checkbox
        label={<ImageItem mask image={item.profile_img} name={item.full_name} />}
        value={selected}
        disabled={disabled}
        onChange={!disabled ? this.handleToggle : undefined}
      />
    );
  }
}

export default UsersInputItem;
