import * as R from 'ramda';
import Api from '../../common/services/api';

export const NETWORK_RECEIVE_ANALYTICS = 'network/RECEIVE_ANALYTICS';
export const NETWORK_RECEIVE_ANALYTICS_COUNTS = 'network/RECEIVE_ANALYTICS_COUNTS';
export const NETWORK_RECEIVE_ANALYTICS_USERS = 'network/RECEIVE_ANALYTICS_USERS';

const formatDate = (date) => date.format('YYYY-MM-DD');

export const fetchAnalytics = (metrics, fromDate, toDate, comparisonFromDate, comparisonToDate, networkId, groupByNetwork = false) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const getQuery = (fromDateValue, toDateValue) => {
    if (!fromDateValue) return Api.utils.toQuery({ metrics });

    const query = {
      metrics,
      from_date: formatDate(fromDateValue),
      to_date: formatDate(toDateValue),
    };

    if (groupByNetwork && !networkId) query.network_grouping = true;

    return Api.utils.toQuery(query);
  };

  const baseUrl = networkId ? `/v1/networks/${networkId}/analytics` : `/v1/organisations/${selected.id}/analytics`;

  const [current, old] = await Promise.all([
    Api.get(`${baseUrl}?${getQuery(fromDate, toDate)}`),
    comparisonFromDate && !groupByNetwork && Api.get(`${baseUrl}?${getQuery(comparisonFromDate, comparisonToDate)}`),
  ]);

  let { data } = current;

  if (old) {
    data = data.map((metric) => {
      const comparison = R.find(R.propEq('metric_type', metric.metric_type), old.data);

      return {
        ...metric,
        old_total: comparison.total || 0,
        timeseries: (metric.timeseries || []).map((timestamp, i) => ({
          date: timestamp.date,
          current_count: timestamp.count,
          old_count: (comparison && comparison.timeseries[i] ? comparison.timeseries[i].count : 0),
          old_date: (comparison && comparison.timeseries[i] ? comparison.timeseries[i].date : null),
        })),
      };
    });
  }

  if (groupByNetwork) {
    data = R.pipe(
      networkId ? R.map(R.assoc('network_id', networkId)) : R.identity,
      R.groupBy(R.prop('network_id')),
      R.mapObjIndexed((arr, id) => {
        const result = { network_id: id };
        arr.forEach((metric) => {
          result[metric.metric_type] = metric.total;
        });

        return result;
      }),
      (obj) => [{
        metric_type: groupByNetwork,
        values: R.values(obj),
      }],
    )(data);
  }

  dispatch({
    type: NETWORK_RECEIVE_ANALYTICS,
    metrics: data,
  });
};

export const fetchAnalyticsUsers = (metrics, fromDate, toDate, networkId) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const getQuery = (fromDateValue, toDateValue) => {
    if (!fromDateValue) return Api.utils.toQuery({ metrics });

    const query = {
      metrics,
      from_date: formatDate(fromDateValue),
      to_date: formatDate(toDateValue),
      return_camel_case: true,
    };

    return Api.utils.toQuery(query);
  };

  const baseUrl = networkId ? `/v1/networks/${networkId}/analytics/users` : `/v1/organisations/${selected.id}/analytics/users`;

  const { data } = await Api.get(`${baseUrl}?${getQuery(fromDate, toDate)}`);

  dispatch({
    type: NETWORK_RECEIVE_ANALYTICS_USERS,
    metrics: data,
  });
};

export const fetchAnalyticsCounts = (metrics, networkId) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const query = Api.utils.toQuery({
    metrics,
  });

  const baseUrl = networkId ? `/v1/networks/${networkId}/analytics/counts` : `/v1/organisations/${selected.id}/analytics/counts`;

  const { data } = await Api.get(`${baseUrl}?${query}`);

  dispatch({
    type: NETWORK_RECEIVE_ANALYTICS_COUNTS,
    metrics: data,
  });
};
