import { useEffect, useState, Dispatch, SetStateAction } from 'react';

export const useDebounceOnChangeStateless = <T>(
  value: T,
  fn: (val: T) => void | Promise<void>,
  delay = 500,
) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      fn(value);
    }, delay);

    return () => clearTimeout(timeout);
  }, [value]);
};

export const useDebounceOnChange = <T>(
  initialValue: T,
  fn: (val: T) => void | Promise<void>,
  delay = 500,
): [Dispatch<SetStateAction<T>>, T] => {
  const [value, setValue] = useState(initialValue);
  useDebounceOnChangeStateless(value, fn, delay);

  return [setValue, value];
};
