import * as R from 'ramda';
import { combineReducers } from 'redux';
import * as reducerUtil from '../../../common/utils/reducer';
import * as usersReducer from '../../core/reducers/users';
import {
  FLEXCHANGE_RECEIVE_EXCHANGE_DETAIL,
  FLEXCHANGE_RECEIVE_EXCHANGE,
  FLEXCHANGE_ACCEPT_EXCHANGE,
  FLEXCHANGE_REJECT_EXCHANGE,
  FLEXCHANGE_APPROVE_EXCHANGE,
  FLEXCHANGE_ADD_COMMENT,
} from '../actions';
import * as commentsReducer from './comments';
import { Types, ActivityTypes, getActivityValues } from '../utils/activity';

const getSource = (activity) => R.merge(
  R.omit(['user'], activity.data),
  { type: Types.ACTIVITY, user_id: activity.data.user.id },
);
const addActivityToStore = (acc, object) => R.assoc(object.data.id, getSource(object), acc);

const items = (state = {}, action) => {
  switch (action.type) {
    case FLEXCHANGE_RECEIVE_EXCHANGE:
    case FLEXCHANGE_RECEIVE_EXCHANGE_DETAIL:
      return R.reduce(addActivityToStore, state, action.activities);
    case FLEXCHANGE_ACCEPT_EXCHANGE:
    case FLEXCHANGE_REJECT_EXCHANGE:
    case FLEXCHANGE_APPROVE_EXCHANGE:
    case FLEXCHANGE_ADD_COMMENT:
      return addActivityToStore(state, action.activity);
    default: return state;
  }
};

const exchanges = (state = {}, action) => {
  switch (action.type) {
    case FLEXCHANGE_RECEIVE_EXCHANGE:
    case FLEXCHANGE_RECEIVE_EXCHANGE_DETAIL:
      return R.assoc(action.exchangeId, R.map(R.path(['data', 'id']), action.activities), state);
    case FLEXCHANGE_ACCEPT_EXCHANGE:
    case FLEXCHANGE_REJECT_EXCHANGE:
    case FLEXCHANGE_APPROVE_EXCHANGE:
    case FLEXCHANGE_ADD_COMMENT:
      return reducerUtil.update(action.exchangeId, R.append(action.activity.data.id), state);
    default: return state;
  }
};

const createActivity = R.curry((state, object) => {
  const activity = {
    ...R.pick(['id', 'activity_type', 'date', 'meta_data'], object),
    type: Types.ACTIVITY,
    user: usersReducer.findById(state, object.user_id),
  };

  activity.values = getActivityValues(state, activity);

  return activity;
});

const createComment = R.curry((state, activity) => {
  const comment = commentsReducer.findById(state, activity.meta_data.comment_id);

  if (!comment) return null;

  return {
    ...R.pick(['id', 'text', 'user'], comment),
    type: Types.COMMENT,
    date: comment.created_at,
  };
});

export const findById = R.curry((state, id) => {
  const activity = state.flexchange.activities.items[id];

  return R.cond([
    [R.propEq('activity_type', ActivityTypes.EXCHANGE_COMMENT), createComment(state)],
    [R.T, createActivity(state)],
  ])(activity);
});

export const findIdsByExchange = R.curry((state, exchangeId) => (
  state.flexchange.activities.exchanges[exchangeId] || []
));

export default combineReducers({ items, exchanges });
