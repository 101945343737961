import * as R from 'ramda';
import { combineReducers } from 'redux';
import { SOCIAL_POST_CONGRATULATION } from '../../social/actions';
import { NETWORK_RECEIVE_BIRTHDAYS_FOR_DATE } from '../actions';

const date = (state = null, action) => {
  switch (action.type) {
    case NETWORK_RECEIVE_BIRTHDAYS_FOR_DATE:
      if (!action.isToday) return state;

      return action.date;
    default: return state;
  }
};

const dates = (state = {}, action) => {
  switch (action.type) {
    case NETWORK_RECEIVE_BIRTHDAYS_FOR_DATE:
      return R.assoc(action.date, R.pluck('id', action.items), state);
    default: return state;
  }
};

const congratulations = (state = {}, action) => {
  switch (action.type) {
    case NETWORK_RECEIVE_BIRTHDAYS_FOR_DATE:
      return R.assoc(action.date, action.meta.comments_count, state);
    case SOCIAL_POST_CONGRATULATION:
      return R.evolve({
        [action.messageId]: (value) => value + 1,
      }, state);
    default: return state;
  }
};

export default combineReducers({ date, dates, congratulations });
