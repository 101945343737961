import React from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { Switch, Redirect, RouteComponentProps } from 'react-router';
import { Trans, withTranslation } from 'react-i18next';
import * as FullStory from '@fullstory/browser';

import * as AnalyticsService from '../../common/services/analytics';
import Route, { loadable, PermissionRoute } from '../../common/components/route';
import Spinner from '../../common/components/spinner';
import ExpirationWall from '../../common/components/expiration-wall';
import ReportTemplate from '../report/template';
import LegalDocument from './components/legal-document';
import UpgradePlanPackageModal from '@common/components/upgrade-plan-package-modal/upgrade-plan-package-modal';

import * as organisationSelector from '../organisation/selectors/organisation';
import * as networkSelector from '../network/selectors/network';
import * as userSelector from './selectors/logged-user';
import { ECompanyDashboardPermissions } from '../../common/definitions';
import type { StoreState } from '../../common/types/store';
import { HIDE_PLAN_PACKAGE_UPGRADE_MODAL } from './actions';

const { EEventNames } = AnalyticsService;

require('./styles.scss');

// Routes
const NetworkTemplate = loadable(() => import('../network/template'));
const AdminTemplate = loadable(() => import('../admin/template'));

const documentLookup: Record<string, string> = {
  privacy: 'https://assets.oneteam.io/globals/statements/(locale)/privacy-policy.html',
};

let isFullStoryInitialised = false;

const mapStateToProps = (state: StoreState) => ({
  initialised: state.app.organisationInitialised,
  planPackageModal: state.ui.planPackageModal,
  loggedUser: userSelector.selected(state),
  network: networkSelector.selected(state),
  organisation: organisationSelector.selected(state),
  documents: userSelector.documentsToAccept(state),
  isDemoExpired: organisationSelector.isDemoExpired(state),
  permissions: state.permissions,
});

const mapDispatchToProps = {
  initialiseCore: require('./actions/initialise').default,
  logout: require('../authentication/actions/logout').default,
  acceptDocument: require('./actions/accept-document').default,
};

type RouteProps = RouteComponentProps;

type Props = RouteProps & ConnectedProps<typeof connector>;

const connector = connect(mapStateToProps, mapDispatchToProps);

const CoreTemplate = ({
  history,
  initialised,
  isDemoExpired,
  loggedUser,
  organisation,
  network,
  documents,
  permissions,
  initialiseCore,
  acceptDocument,
  logout,
  planPackageModal,
}: Props) => {
  const dispatch = useDispatch();
  // Initialise application
  React.useEffect(() => {
    if (!organisation) return history.replace('/auth/organisations');

    AnalyticsService.register({ organisationId: organisation.id });
    AnalyticsService.track(EEventNames.APP_OPEN);

    (async () => {
      try {
        await initialiseCore(history);
      } catch (err) {
        history.push('/auth/organisations');
      }
    })();

    return () => {
      if (window.Intercom) window.Intercom('shutdown');

      AnalyticsService.register({ organisationId: undefined });
    };
  }, []);

  // Set up FullStory
  React.useEffect(() => {
    if (!organisation.trial || process.env.API_ENV !== 'production') return;

    if (!isFullStoryInitialised) {
      FullStory.init({ orgId: '117STQ', devMode: process.env.API_ENV !== 'production' });
      isFullStoryInitialised = true;
    } else {
      FullStory.restart();
    }

    return () => {
      if (isFullStoryInitialised) FullStory.shutdown();
    };
  }, [organisation?.trial]);

  const handleLogout = () => {
    logout();
    history.push('/auth/login');
  };

  if (!initialised) {
    return (
      <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div className="AsyncSpinner">
          <div>
            <Trans i18nKey="core:template_loading" />
            <Spinner size="large" centered />
          </div>
        </div>
      </div>
    );
  }

  // logged user might not exist in SSR
  const docLocale = loggedUser?.language?.locale === 'nl' ? 'nl' : 'en';
  // Take the first document in queue, so we display 1 document at a time
  const documentToDisplay = (
    documents[0] &&
    documentLookup[documents[0]].replace('(locale)', docLocale)
  );
  const isAdmin = ECompanyDashboardPermissions.some((permission) => permissions.organisation.includes(permission));

  return (
    <div id="Core">
      {documentToDisplay && (
        <LegalDocument
          key={documentToDisplay}
          // @ts-expect-error
          path={documentToDisplay}
          onReject={() => handleLogout()}
          onAccept={() => acceptDocument(documents[0])}
        />
      )}
      {isDemoExpired && (
        // @ts-expect-error
        <ExpirationWall organisation={organisation} />
      )}
      {planPackageModal && (
        <UpgradePlanPackageModal
          show
          onHide={() => dispatch({ type: HIDE_PLAN_PACKAGE_UPGRADE_MODAL })}
          {...planPackageModal}
        />
      )}
      <Switch>
        <Route path="/report" component={ReportTemplate} />
        {isAdmin && (
          <PermissionRoute
            path="/admin"
            component={AdminTemplate}
            permission={{ name: ECompanyDashboardPermissions }}
          />
        )}
        {isAdmin && <Redirect exact from="/" to="/admin" />}
        {network && <Route path="/" component={NetworkTemplate} />}
        <Redirect to="/auth/organisations" />
      </Switch>
    </div>
  );
};

export default withTranslation()(connector(CoreTemplate));
