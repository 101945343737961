import { combineReducers } from 'redux';
import { NETWORK_CLEAR, SELECT_NETWORK, NETWORK_INITIALISE } from '../actions';
import selected from './selected';
import users from './users';
import teams from './teams';
import functions from './functions';
import admins from './admins';
import birthdays from './birthdays';
import roles from './roles';

const initialised = (state = null, action) => {
  switch (action.type) {
    case SELECT_NETWORK:
    case NETWORK_CLEAR:
      return null;
    case NETWORK_INITIALISE:
      return action.network.id;
    default:
      return state;
  }
};

const reducer = combineReducers({
  initialised,
  selected,
  users,
  admins,
  teams,
  functions,
  birthdays,
  roles,
});

export default (state, action) => {
  switch (action.type) {
    case NETWORK_CLEAR: return reducer(undefined, action);
    default: return reducer(state, action);
  }
};
