import { useSelector } from 'react-redux';

import type { FieldState } from 'redux-form';

type FormState<T extends Record<string, unknown>> = {
  active: string;
  values?: T;
  initial?: T;
  fields?: {
    [key: string]: FieldState | undefined;
  };
};

type ReduxFormStore<T extends Record<string, unknown>> = { form: Record<string, FormState<T>> };

export const useFormValues = <T extends Record<string, unknown>>(name: string): T | Record<string, never> => {
  const form = useSelector<ReduxFormStore<T>, FormState<T> | undefined>((state) => state.form[name]);

  return form?.values || {};
};
