import { combineReducers } from 'redux';
import { SELECT_NETWORK } from '../../network/actions';
import exchanges from './exchanges';
import days from './days';
import responses from './responses';
import activities from './activities';
import comments from './comments';
import status from './status';
import functions from './functions';

const flexchangeReducer = combineReducers({
  exchanges,
  days,
  responses,
  activities,
  comments,
  status,
  functions,
});

export default (state, action) => {
  switch (action.type) {
    case SELECT_NETWORK: return flexchangeReducer(undefined, action);
    default: return flexchangeReducer(state, action);
  }
};
