import { combineReducers } from 'redux';
import { SELECT_ORGANISATION, ORGANISATION_INITIALISE } from '../actions';
import { ADMIN_COMPLETE_SETUP_STEP, ORGANISATION_UPDATE_ORGANISATION } from '../../admin/actions';
import networks from './networks';
import admins from './admins';
import functions from './functions';
import channels from './channels';
import users from './users';
import roles from './roles';

const initialised = (state = null, action) => {
  switch (action.type) {
    case SELECT_ORGANISATION:
      return action.organisationId;
    default:
      return state;
  }
};

const selected = (state = null, action) => {
  switch (action.type) {
    case ORGANISATION_INITIALISE:
    case ORGANISATION_UPDATE_ORGANISATION:
      return {
        ...state,
        ...action.organisation
      };
    default: return state;
  }
};

const setup = (state = null, action) => {
  switch (action.type) {
    case ORGANISATION_INITIALISE:
      return action.setupProgress || null;
    case ADMIN_COMPLETE_SETUP_STEP:
      return { ...state, [action.step]: true };
    default:
      return state;
  }
};

const billing = (state = null, action) => {
  switch (action.type) {
    case ORGANISATION_INITIALISE:
      return action.billing;
    default:
      return state;
  }
};

const reducers = combineReducers({
  initialised,
  selected,
  networks,
  admins,
  functions,
  channels,
  users,
  roles,
  setup,
  billing,
});

export default (state, action) => {
  switch (action.type) {
    case SELECT_ORGANISATION: return reducers(undefined, action);
    default: return reducers(state, action);
  }
};
