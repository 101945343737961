import { SET_INITIALIZING } from '.';
import Storage from '../../../common/services/storage';
import initialiseNetwork from './initialise';

export const SELECT_NETWORK = 'network/SELECT_NETWORK';

export default (networkId, redirect = true, wait = true) => async (dispatch, getState) => {
  const { network: { selected } } = getState();

  if (networkId !== selected) {
    dispatch({ type: SET_INITIALIZING, value: true });
    dispatch({ type: SELECT_NETWORK, networkId, sync: true });

    const promise = dispatch(initialiseNetwork(networkId));

    // Don't always have to wait for the network to be initialised
    if (wait) await promise;

    Storage.setObject('currentNetwork', { id: networkId });
    Storage.setCookie('network_id', networkId);
    dispatch({ type: SET_INITIALIZING, value: false });
  }

  if (redirect) return `/networks/${networkId}`;
};
