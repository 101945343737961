import addMessage from './add-message';
import fetchConversation from './fetch-conversation';

export const CHAT_RECEIVE_MESSAGE = 'chat/RECEIVE_MESSAGE';

const getReceiveMessageAction = (socketEvent) => {
  return ({ data: object, conversation }) => async (dispatch, getState) => {
    const {
      organisation: { selected },
      ui: { chat: chatUI },
      loggedUser: { user },
      chat: { conversations, messages },
    } = getState();

    // Don't add messages from other organisations
    if (conversation.organisation_id !== selected.id) return;

    if (!conversations?.items[object.parent_id]) {
      await dispatch(fetchConversation(object.parent_id));
    }

    const isSelected = object.parent_id === chatUI.selectedConversationId;
    const isOpen = window.location.pathname.endsWith('/conversations');
    const isFocused = document.hasFocus ? document.hasFocus() : true;

    // the message can come from the same user if he/she used a different device to send it
    const fromLoggedUser = (typeof user?.id === 'string') && (user.id === object.user_id);

    const messageWasRead = fromLoggedUser || (isSelected && isOpen && isFocused);

    const alreadyHaveMsg = !!Object.keys(messages?.items || {}).find((msgId) => msgId === object.id);

    const mostRecentMessage = messages?.conversations?.[object.parent_id]?.map((convoId) => {
      return messages?.items?.[convoId];
    }).filter(Boolean).reduce((mostRecent, current) => {
      return new Date(mostRecent.created_at) > new Date(current.created_at) ? mostRecent : current;
    });
    const mostRecentMessageIsByLoggedUser = (
      mostRecentMessage?.user_id === user?.id && (typeof user?.id === 'string')
    );

    // Add message to chat
    dispatch(addMessage(
      object.parent_id,
      object,
      messageWasRead,
      conversation,
      alreadyHaveMsg,
      mostRecentMessageIsByLoggedUser,
      socketEvent
    ));
  };
};

export default getReceiveMessageAction;
