export const FLEXCHANGE_ACCEPT_EXCHANGE = 'flexchange/ACCEPT_EXCHANGE';
export const FLEXCHANGE_APPROVE_EXCHANGE = 'flexchange/APPROVE_EXCHANGE';
export const FLEXCHANGE_CLOSE_EXCHANGE = 'flexchange/CLOSE_EXCHANGE';
export const FLEXCHANGE_DECLINE_EXCHANGE = 'flexchange/DECLINE_EXCHANGE';
export const FLEXCHANGE_DELETE_EXCHANGE = 'flexchange/DELETE_EXCHANGE';
export const FLEXCHANGE_REQUEST_EXCHANGE = 'flexchange/REQUEST_EXCHANGE';
export const FLEXCHANGE_RECEIVE_EXCHANGE = 'flexchange/RECEIVE_EXCHANGE';
export const FLEXCHANGE_RECEIVE_EXCHANGES = 'flexchange/RECEIVE_EXCHANGES';
export const FLEXCHANGE_RECEIVE_RECEIVERS = 'flexchange/RECEIVE_RECEIVERS';
export const FLEXCHANGE_FILTER_EXCHANGES = 'flexchange/FILTER_EXCHANGES';
export const FLEXCHANGE_GO_NEXT_WEEK = 'flexchange/GO_NEXT_WEEK';
export const FLEXCHANGE_GO_PREVIOUS_WEEK = 'flexchange/GO_PREVIOUS_WEEK';
export const FLEXCHANGE_GO_TODAY = 'flexchange/GO_TODAY';
export const FLEXCHANGE_OPEN_EXCHANGE = 'flexchange/OPEN_EXCHANGE';
export const FLEXCHANGE_RECEIVE_EXCHANGE_DETAIL = 'flexchange/RECEIVE_EXCHANGE_DETAIL';
export const FLEXCHANGE_ADD_COMMENT = 'flexchange/POST_EXCHANGE_COMMENT';
export const FLEXCHANGE_ADD_EXCHANGE = 'flexchange/POST_EXCHANGE';
export const FLEXCHANGE_REJECT_EXCHANGE = 'flexchange/REJECT_EXCHANGE';
export const FLEXCHANGE_UPDATE_EXCHANGE = 'flexchange/UPDATE_EXCHANGE';
