import * as R from 'ramda';
import moment from 'moment';
import { SOCIAL_RECEIVE_FEED } from '../../social/actions';
import {
  FLEXCHANGE_RECEIVE_EXCHANGES,
  FLEXCHANGE_RECEIVE_EXCHANGE,
  FLEXCHANGE_ADD_EXCHANGE,
  FLEXCHANGE_DELETE_EXCHANGE,
} from '../actions';

const isExchange = R.propEq('object_type', 'exchange');
const getDate = (object) => moment(object.source.date).format('YYYY-MM-DD');
const groupByDate = R.groupBy(getDate);
const toIds = R.pluck('source_id');
const isAboveZero = R.gte(R.__, 0);

const removeExchangeFromDay = R.curry((exchangeId, day) => {
  const index = R.findIndex(R.equals(exchangeId), day);
  const removeFromDay = R.remove(R.__, 1, day);

  return R.ifElse(isAboveZero, removeFromDay, R.always(day))(index);
});

export default (state = {}, action) => {
  switch (action.type) {
    case SOCIAL_RECEIVE_FEED:
    case FLEXCHANGE_RECEIVE_EXCHANGES:
      return R.merge(state, R.pipe(
        R.filter(isExchange),
        groupByDate,
        R.map(toIds),
      )(action.items));
    case FLEXCHANGE_RECEIVE_EXCHANGE:
    case FLEXCHANGE_ADD_EXCHANGE: {
      const date = getDate(action.exchange);
      return R.assoc(date, R.uniq(R.append(action.exchange.source_id, state[date] || [])), state);
    }
    case FLEXCHANGE_DELETE_EXCHANGE:
      return R.map(removeExchangeFromDay(action.exchangeId), state);
    default: return state;
  }
};

export const findByDate = R.curry((state, date) => state.flexchange.days[date.format('YYYY-MM-DD')] || []);
