import { combineReducers } from 'redux';
import { SELECT_NETWORK } from '../../network/actions';
import feed from './feed';
import messages from './messages';
import comments from './comments';
import attachments from './attachments';
import likes from './likes';
import polls from './polls';
import seen from './seen';
import scheduled from './scheduled';

const socialReducer = combineReducers({
  feed, messages, comments, attachments, likes, polls, seen, scheduled,
});

export default (state, action) => {
  switch (action.type) {
    case SELECT_NETWORK: return socialReducer(undefined, action);
    default: return socialReducer(state, action);
  }
};
