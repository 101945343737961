import * as R from 'ramda';
import { combineReducers } from 'redux';
import * as reducerUtil from '../../../common/utils/reducer';
import * as usersReducer from '../../core/reducers/users';
import { SOCIAL_RECEIVE_FEED } from '../../social/actions';
import {
  FLEXCHANGE_RECEIVE_EXCHANGES,
  FLEXCHANGE_RECEIVE_EXCHANGE,
  FLEXCHANGE_ADD_EXCHANGE,
  FLEXCHANGE_UPDATE_EXCHANGE,
  FLEXCHANGE_DELETE_EXCHANGE,
  FLEXCHANGE_ACCEPT_EXCHANGE,
  FLEXCHANGE_DECLINE_EXCHANGE,
  FLEXCHANGE_APPROVE_EXCHANGE,
  FLEXCHANGE_REJECT_EXCHANGE,
} from '../actions';
import * as responsesReducer from './responses';
import * as activitiesReducer from './activities';
import * as exchangeUtil from '../utils/exchange';
import { EObjectTypes } from '../../social/definitions';

const isExchange = R.propEq('object_type', EObjectTypes.EXCHANGE);
const getSource = (object) => R.omit(['responses', 'user', 'comments'], object.source);
const addExchangeToStore = (acc, object) => R.assoc(object.source.id, getSource(object), acc);

const updateExchange = R.curry((object, old) => R.merge(old, getSource(object)));

const items = (state = {}, action) => {
  switch (action.type) {
    case SOCIAL_RECEIVE_FEED:
    case FLEXCHANGE_RECEIVE_EXCHANGES:
      return R.pipe(R.filter(isExchange), R.reduce(addExchangeToStore, state))(action.items);
    case FLEXCHANGE_RECEIVE_EXCHANGE:
    case FLEXCHANGE_ADD_EXCHANGE:
      return addExchangeToStore(state, action.exchange);
    case FLEXCHANGE_UPDATE_EXCHANGE:
    case FLEXCHANGE_ACCEPT_EXCHANGE:
    case FLEXCHANGE_DECLINE_EXCHANGE:
    case FLEXCHANGE_REJECT_EXCHANGE:
    case FLEXCHANGE_APPROVE_EXCHANGE:
      return reducerUtil.update(action.exchange.source_id, updateExchange(action.exchange), state);
    case FLEXCHANGE_DELETE_EXCHANGE:
      return R.omit([action.exchangeId], state);
    default: return state;
  }
};

export default combineReducers({ items });

const isReplacement = R.and(R.propEq('is_approved', null), R.propEq('response', true));
const sortByDate = R.sort(R.descend(R.prop('date')));
const findActivities = R.curry((state, ids) => R.map(activitiesReducer.findById(state), ids));

export const findById = R.curry((state, id) => {
  const exchange = state.flexchange.exchanges.items[id];

  if (!exchange) return undefined;

  const activityIds = activitiesReducer.findIdsByExchange(state, id);
  const responseIds = responsesReducer.findIdsByExchange(state, id);

  const activities = R.pipe(findActivities(state), R.reject(R.isNil), sortByDate)(activityIds);
  const responses = R.map(responsesReducer.findById(state), responseIds);
  const replacements = R.filter(isReplacement, responses);

  return {
    ...exchange,
    activities,
    responses,
    replacements,
    created_in: R.assoc(
      'values',
      exchangeUtil.getCreatedInValues(state, exchange.created_in),
      exchange.created_in,
    ),
    status: exchangeUtil.getExchangeStatus(exchange),
    user: usersReducer.findById(state, exchange.user_id),
    approved_by: usersReducer.findById(state, exchange.approved_by_id),
    approved_user: usersReducer.findById(state, exchange.approved_user_id),
  };
});
