import * as R from 'ramda';
import * as reducerUtils from '../../../common/utils/reducer';
import { ADMIN_CREATE_ROLE, ADMIN_UPDATE_ROLE, ADMIN_REMOVE_ROLE } from '../../admin/actions';
import { NETWORK_RECEIVE_ROLES } from '../../network/actions';
import { ADMIN_RECEIVE_ROLES } from '../actions';

const reducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_RECEIVE_ROLES:
    case NETWORK_RECEIVE_ROLES:
      return R.reduce((acc, item) => R.assoc(item.id, item, acc), state, action.items);
    case ADMIN_CREATE_ROLE:
      return {
        ...state,
        [action.item.id]: action.item,
      };
    case ADMIN_REMOVE_ROLE:
      return R.omit([action.id], state);
    case ADMIN_UPDATE_ROLE:
      return reducerUtils.update(action.item.id, () => action.item, state);
    default: return state;
  }
};

export default reducer;
