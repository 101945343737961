import * as R from 'ramda';
import { combineReducers } from 'redux';
import setIn from 'redux-form/lib/structure/plain/setIn';
import getIn from 'redux-form/lib/structure/plain/getIn';
import {
  CHANGE,
  ARRAY_PUSH,
  ARRAY_INSERT,
  ARRAY_REMOVE,
  ARRAY_SWAP,
} from 'redux-form/lib/actionTypes';
import {
  ACADEMY_CREATE_SCREEN_DRAFT,
  ACADEMY_ADD_COMPONENT,
  ACADEMY_REMOVE_COMPONENT,
  ACADEMY_UPDATE_COMPONENTS_ORDER,
  ACADEMY_RESET_DRAFT,
} from '../actions';
import { EComponentTypes } from '../definitions';
import { EQuestionTypes } from '../../survey/definitions';
import { FileType, getYoutubeVideoId } from '../../../common/utils/file';

/**
 * ONE-803 we would like to remove this reducer
 * It relies on 'redux-form/lib/actionTypes' wich makes this state tightly coupled
 * with redux-form. This library is deprated by the author and we don't want to have
 * to rely on it for future implementation.
 * This reducer is also called by other components that use redux-form, but
 * don't rely on or do anything with the draft state.
 */

const moduleReducer = (state = null, action) => {
  switch (action.type) {
    case ACADEMY_CREATE_SCREEN_DRAFT:
      return action.module || state;
    case ACADEMY_RESET_DRAFT:
      return null;
    default:
      return state;
  }
};

const setIndex = (state, item) => R.assoc(item.id, R.assoc('index', item.index, state[item.id]), state);

const getComponentId = (action) => {
  const [, ...ids] = action.meta.form.split('-');
  return ids.join('-'); // fixes issue where id is like "temp-x"
};

const screensReducer = (state = {}, action) => {
  switch (action.type) {
    case ACADEMY_CREATE_SCREEN_DRAFT:
      return R.assoc(action.screen.id, action.screen, state);
    case ACADEMY_ADD_COMPONENT:
      if (!state || action.contentType !== 'screen') return state;
      return R.evolve({
        [action.screenId]: R.evolve({ component_ids: R.append(action.component.id) }),
      }, state);
    case ACADEMY_REMOVE_COMPONENT:
      if (!state || action.feedbackType) return state;

      return R.evolve({
        [action.screenId]: R.evolve({ component_ids: R.reject(R.equals(action.componentId)) }),
      }, state);
    case ACADEMY_UPDATE_COMPONENTS_ORDER:
      if (action.feedbackType) return state;
      return R.evolve({
        [action.screenId]: R.clone,
      }, state); // update selector
    case 'surveys/DELETE_QUESTION':
    case 'forms/DELETE_QUESTION':
      return R.omit([action.id], state);
    case ACADEMY_RESET_DRAFT:
      return {};
    default:
      return state;
  }
};

const actionsWhitelist = [
  CHANGE, ARRAY_INSERT, ARRAY_PUSH, ARRAY_REMOVE, ARRAY_SWAP,
];

const reduxFormReducer = (state, action) => {
  // console.log('debug reduxFormReducer state, action', state, action);
  const form = action?.meta?.form || '';
  if (
    !actionsWhitelist.includes(action.type)
    // TODO this is a quick fix, see https://flex-appeal.atlassian.net/browse/PD-8118
    // on how to solve it properly, filed as technical debt
    || !(
      // the forms that have a phone preview have "component-" prefix
      form.startsWith('component-') ||
      // checklist questions in surveys and forms have this prefix
      form.startsWith('question-') // this fixes PD-8141
    )
  ) {
    return state;
  }

  const id = getComponentId(action);
  const currentValue = R.clone(getIn(state[id], action.meta.field));

  const setProperty = (field, payload) => ({
    ...state,
    [id]: setIn(state[id], field, payload),
  });

  switch (action.type) {
    case CHANGE:
      return setProperty(action.meta.field, action.payload);
    case ARRAY_INSERT:
      return setProperty(action.meta.field, R.insert(action.meta.index, action.payload, currentValue));
    case ARRAY_PUSH:
      return setProperty(action.meta.field, R.append(action.payload, currentValue));
    case ARRAY_REMOVE:
      return setProperty(action.meta.field, R.remove(action.meta.index, 1, currentValue));
    case ARRAY_SWAP: {
      if (!currentValue) return state;

      const { indexA, indexB } = action.meta;

      [currentValue[indexA], currentValue[indexB]] = [currentValue[indexB], currentValue[indexA]];

      return setProperty(action.meta.field, currentValue);
    }
    default:
      return state;
  }
};

const attachmentWhitelist = [
  'id', 'meta_data', 'file_name', 'file_type', 'path', 'thumb_path', 'organisation_id', 'raw_path', 'raw_thumb_path'
];

export const formatComponent = (component, action) => {
  switch (component.type) {
    case EComponentTypes.EXTERNAL_VIDEO:
      if (action.meta.field === 'youtube_url') {
        const videoId = getYoutubeVideoId(action.payload);

        if (!videoId) return null;

        return {
          ...component,
          parameters: {
            attachment: {
              file_type: FileType.EXTERNAL_VIDEO,
              platform: 'youtube',
              video_id: videoId,
              thumb_path: `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`,
            },
          },
        };
      }
      break;
    case EComponentTypes.VIDEO:
    case EComponentTypes.IMAGE:
    case EComponentTypes.PDF:
      if (action.payload.processing) return null;

      return {
        ...component,
        parameters: {
          ...component.parameters,
          attachment_id: action.payload.id,
          attachment: R.pick(attachmentWhitelist, action.payload),
        },
      };
    default:
      return setIn(component, action.meta.field, action.payload);
  }
};

const updateQuestionFeedback = (state, {
  type, feedbackType, contentType, screenId, questionId, component, componentId, items,
}) => {
  const newComponents = (() => {
    const currentComponents = state[screenId || questionId].parameters[feedbackType || contentType];
    switch (type) {
      case ACADEMY_ADD_COMPONENT:
        return [
          ...currentComponents,
          component,
        ];
      case ACADEMY_REMOVE_COMPONENT:
        return [
          ...currentComponents.filter((item) => item.id !== componentId),
        ];
      case ACADEMY_UPDATE_COMPONENTS_ORDER:
        return items.map((itemId, index) => ({
          ...state[questionId].parameters[feedbackType].find(({ id }) => id === itemId),
          index,
        }));
      case CHANGE:
        return [
          ...currentComponents.filter((item) => item.id !== componentId),
          component,
        ];
      default:
        return currentComponents;
    }
  })();
  return R.assoc(screenId || questionId, {
    ...state[screenId || questionId],
    parameters: {
      ...state[screenId || questionId].parameters,
      [feedbackType || contentType]: newComponents,
    },
  }, state);
};

const getFeedbackComponent = (questionComponent, componentId) => {
  const type = questionComponent.parameters.correct_feedback.find((correct) => correct.id === componentId) ?
    'correct_feedback' : 'incorrect_feedback';
  return {
    type,
    component: questionComponent.parameters[type].find((item) => item.id === componentId),
  };
};

const componentsReducer = (state = {}, action) => {
  switch (action.type) {
    case ACADEMY_CREATE_SCREEN_DRAFT:
      return R.reduce((acc, component) => R.assoc(component.id, component, acc), state, action.components);
    case CHANGE: {
      if (!action.meta.form) return state;
      const id = getComponentId(action);
      if (!state[id]) {
        const questionId = Object.keys(state).find((_id) => state[_id].type === EComponentTypes.ELEARNING_QUESTION);
        if (!questionId) return state;
        const { type, component } = getFeedbackComponent(state[questionId], id);
        if (!component) return state;
        const newComponent = formatComponent(component, action);
        if (newComponent) {
          return updateQuestionFeedback(state, {
            ...action,
            component: newComponent,
            componentId: id,
            questionId,
            contentType: type,
          });
        }
        return state;
      }

      const newComponent = formatComponent(state[id], action);
      if (newComponent) {
        return {
          ...state,
          [id]: newComponent,
        };
      }
      return state;
    }
    case ACADEMY_ADD_COMPONENT:
      if (state[action.screenId]) return updateQuestionFeedback(state, action);
      return R.assoc(action.component.id, action.component, state);
    case ACADEMY_REMOVE_COMPONENT:
      if (action.questionId) return updateQuestionFeedback(state, action);
      return R.omit([action.componentId], state);
    case ACADEMY_UPDATE_COMPONENTS_ORDER:
      if (action.questionId) return updateQuestionFeedback(state, action);
      return R.reduce(setIndex, state, action.items);
    case ACADEMY_RESET_DRAFT:
      return {};
    default:
      return reduxFormReducer(state, action);
  }
};

const questionsReducer = (state = {}, action) => {
  switch (action.type) {
    case ACADEMY_CREATE_SCREEN_DRAFT: {
      const { question } = action.screen;

      if (!question) return state;

      return R.assoc(question.id, {
        ...question,
        parameters: {
          text: '',
          ...question.parameters,
        },
      }, state);
    }
    case ACADEMY_RESET_DRAFT:
      return {};
    default:
      return state;
  }
};

const questionTypes = [
  ...Object.values(EQuestionTypes),
  EComponentTypes.ELEARNING_QUESTION,
];

export const getScreen = (state, id, academy = false) => {
  const screen = { ...state.academy.draft.screens[id] };

  if (!screen) return undefined;

  const question = state.academy.draft.questions[screen.question_id];

  if (academy) {
    screen.published = state.form.screen && state.form.screen.fields && state.form.screen.fields.id
      ? state.form.screen.values.published
      : screen.published;
    screen.points = question && state.form.screen?.values.points;
  }

  const components = R.pipe(
    R.map((componentId) => state.academy.draft.components[componentId]),
    // R.reject(R.propEq('type', EComponentTypes.ELEARNING_QUESTION)),
    R.sortBy(R.prop('index')),
    R.reject(R.isNil),
    R.sort((a, b) => {
      if (questionTypes.includes(a.type)) return 1;
      if (questionTypes.includes(b.type)) return -1;

      return a.index - b.index;
    }),
  )(screen.component_ids || []);

  return {
    ...R.omit(['component_ids', 'question_id'], screen),
    question,
    components,
  };
};

export const getComponent = (state, id) => state.academy.draft.components[id] || null;

export const getComponentFeedback = (state, questionId, item) => {
  const question = state.academy.draft.components[questionId];
  if (!question) return null;
  const { component } = getFeedbackComponent(question, item.id);
  return component;
};

export default combineReducers({
  module: moduleReducer,
  screens: screensReducer,
  components: componentsReducer,
  questions: questionsReducer,
});
