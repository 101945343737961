import { combineReducers } from 'redux';
import * as R from 'ramda';
import moment from 'moment';
import * as reducerUtils from '../../../common/utils/reducer';
import { ACADEMY_RECEIVE_SEGMENT_USERS } from '../../learning/actions';
import { CHAT_POST_CONVERSATION } from '../../chat/actions';
import {
  SELECT_ORGANISATION,
  ORGANISATION_INITIALISE,
  ORGANISATION_UPDATE_USER,
  ORGANISATION_DELETE_USER,
  ORGANISATION_RESEND_INVITATION,
  ORGANISATION_INVITE_USER,
  ORGANISATION_RECEIVE_USERS,
  ORGANISATION_RECEIVE_ADMINS,
  ORGANISATION_UPDATE_USER_ROLE,
} from '../../organisation/actions';
import {
  NETWORK_INITIALISE,
  NETWORK_RECEIVE_USERS,
  NETWORK_UPDATE_USER,
  NETWORK_RESEND_INVITATION,
  NETWORK_RECEIVE_BIRTHDAYS_FOR_DATE,
  NETWORK_INVITE_USER,
  NETWORK_UPDATE_USER_TEAMS,
  NETWORK_RECEIVE_ADMINS,
  NETWORK_UPDATE_USER_ROLE,
} from '../../network/actions';
import {
  RECEIVE_PROFILE,
  UPDATE_PROFILE,
  CORE_RECEIVE_USER,
  CORE_INTEGRATION_AUTHENTICATED,
  CORE_REFRESH_REDEEM_CODE,
  SET_USER_STATUS,
  SetUserStatusAction,
  UNSET_USER_STATUS,
} from '../actions';
import type { StoreState } from '../../../common/types/store';
import type {
  LoggedUser,
  User,
  ExtendedUser,
  Network,
  FunctionLink,
} from '../../../common/types/objects';
import type {
  AddUserToCommunityAction,
  RemoveUserFromCommunity,
  AddUserToFunctionGroup,
  RemoveUserFromFunctionGroup,
} from '../actions';
import { NetworkUpdateUserRoleAction } from '@modules/network/actions/action-types';
import { NETWORK_DELETE_USER } from '@modules/network/actions/delete-user';
import {
  ORGANISATION_RECEIVE_MESSAGES,
  ORGANISATION_RECEIVE_MESSAGE
} from '@modules/admin/actions';
import {
  OrganisationReiveMessagesAction,
  OrganisationReiveMessageAction
} from '@modules/admin/actions/types';

/* eslint-disable max-len */
const whitelist: Array<string> = ['id', 'first_name', 'last_name', 'full_name', 'email', 'username', 'phone_num', 'phone', 'profile_img', 'date_of_birth', 'last_active', 'last_login', 'external_id', 'can_chat', 'bio', 'statistics'];

// @ts-ignore
const addInvitedAt: () => Object = R.merge(R.__, { invited_at: moment().toISOString() });

const addToStore: (state: Object, user: User) => Object = R.curry((state, user: User) => R.assoc(user.id, state[user.id] ? { ...state[user.id], ...R.pick(whitelist, user) } : R.pick(whitelist, user), state));

// @ts-ignore
const addScopesToStore: (key: string, state: Object, user: ExtendedUser) => Object = R.curry((key: string, state, user: ExtendedUser) => R.assoc(user.id, user.scopes[key], state));

const updateInvitedAt: (state: Object, userId: string) => Object = R.curry((state, userId: string) => reducerUtils.update(userId, addInvitedAt, state));

const updateWhitelist = [
  'first_name', 'last_name', 'full_name', 'phone_num', 'phone', 'profile_img',
  'email', 'username', 'role_type', 'date_of_birth', 'bio',
];
/* eslint-enable max-len */

type DefaultAction = {
  type: string,
  userId: string,
  networkId: string,
  user: ExtendedUser,
  item: Object,
  items: Array<User>,
  admins: Array<User>,
  teams: {
    add: Array<string>,
    remove: Array<string>,
  },
  related: {
    users: Array<User>,
  },
};

type Action =
  | AddUserToCommunityAction
  | RemoveUserFromCommunity
  | AddUserToFunctionGroup
  | RemoveUserFromFunctionGroup
  | SetUserStatusAction
  | NetworkUpdateUserRoleAction
  | OrganisationReiveMessagesAction
  | OrganisationReiveMessageAction
  | DefaultAction;

const itemsReducer = (state = {}, action: Action) => {
  switch (action.type) {
    case ORGANISATION_INVITE_USER:
    case NETWORK_INVITE_USER:
    case CORE_RECEIVE_USER:
      return addToStore(state, action.user);

    case ORGANISATION_RECEIVE_USERS:
    case NETWORK_RECEIVE_USERS:
    case ACADEMY_RECEIVE_SEGMENT_USERS:
    case NETWORK_RECEIVE_BIRTHDAYS_FOR_DATE:
    case NETWORK_RECEIVE_ADMINS:
    case ORGANISATION_RECEIVE_ADMINS:
      return R.reduce(addToStore, state, action.items);

    case NETWORK_INITIALISE:
    case ORGANISATION_INITIALISE:
      return R.reduce(addToStore, state, action.admins);

    case NETWORK_UPDATE_USER:
    case ORGANISATION_UPDATE_USER:
    case UPDATE_PROFILE:
      if (!action.user) return state;

      return reducerUtils.update(
        action.user.id,
        R.merge(R.__, R.pick(updateWhitelist, action.user)),
        state,
      );

    case ORGANISATION_DELETE_USER:
      return R.omit([action.userId], state);

    case CHAT_POST_CONVERSATION:
      // @ts-ignore
      return R.reduce(addToStore, state, action.item.participants);

    case NETWORK_RESEND_INVITATION:
    case ORGANISATION_RESEND_INVITATION:
      return updateInvitedAt(state, action.userId);

    case ORGANISATION_RECEIVE_MESSAGES:
    case ORGANISATION_RECEIVE_MESSAGE:
      if ('meta' in action) {
        const users = action.meta?.related?.users?.map((user) => {
          return { [user.id]: user };
        });
        return {
          ...state,
          ...Object.assign({}, ...(users || []))
        };
      }
      return state;

    default: {
      // @ts-ignore
      if (action.related && action.related.users) {
        return R.pipe(
          // @ts-ignore
          R.reject((user) => !!state[user.id]),
          R.reduce(addToStore, state),
        // @ts-ignore
        )(action.related.users);
      }

      return state;
    }
  }
};

const detailReducer = (state = {}, action: Action) => {
  switch (action.type) {
    case RECEIVE_PROFILE:
    case CORE_RECEIVE_USER:
      return addToStore(state, action.user);

    case NETWORK_UPDATE_USER:
    case ORGANISATION_UPDATE_USER:
    case UPDATE_PROFILE:
      if (!action.user) return state;

      return reducerUtils.update(
        action.user.id,
        R.merge(R.__, R.pick(updateWhitelist, action.user)),
        state,
      );
    default: return state;
  }
};

const statusReducer = (state = {}, action: Action) => {
  switch (action.type) {
    case CORE_RECEIVE_USER:
    case ORGANISATION_UPDATE_USER:
    case NETWORK_UPDATE_USER:
      if (!action.user) return state;

      return R.assoc(action.user.id, true, state);
    case SELECT_ORGANISATION:
      return {};
    default:
      return state;
  }
};

const organisationReducer = (
  state: Record<string, any> = {},
  action: Record<string, any>
) => {
  switch (action.type) {
    case CORE_RECEIVE_USER:
    case ORGANISATION_UPDATE_USER:
    case NETWORK_UPDATE_USER:
    // TODO
    // case ORGANISATION_INITIALISE:
      return addScopesToStore('organisation', state, action.user);
    case CORE_INTEGRATION_AUTHENTICATED:
      return reducerUtils.update(action.userId, R.assoc('integration_auth', true), state);
    case CORE_REFRESH_REDEEM_CODE:
      if (!state[action.userId]) return state;

      return reducerUtils.update(action.userId, R.assoc('redeem_code', action.code), state);
    case SELECT_ORGANISATION:
      return {};
    case ORGANISATION_UPDATE_USER_ROLE:
      return {
        ...state,
        [action.userId]: {
          ...(state[action.userId] || {}),
          roles: action.roles,
        },
      };
      case SET_USER_STATUS:
        return setUserStatus(state, action);
      case UNSET_USER_STATUS:
        return unsetUserStatus(state, action);
    default:
      return state;
  }
};
function setUserStatus(state: any, action: any) {
  const scope = state[action.userId];
  if (!scope) return state;
  return {
    ...state,
    [action.userId]: {
      ...scope,
      status: {
        emoji: action.emoji,
        expires_at: action.expires_at,
        text: action.text
      }
    }
  };
}
function unsetUserStatus(state: any, action: any) {
  const scope = state[action.userId];
  if (!scope) return state;
  return {
    ...state,
    [action.userId]: {
      ...scope,
      status: null
    }
  };
}

const networksReducer = (state: Record<string, Network[]> = {}, action: Action) => {
  switch (action.type) {
    case CORE_RECEIVE_USER:
    case ORGANISATION_UPDATE_USER:
    case NETWORK_UPDATE_USER:
      return addScopesToStore('networks', state, action.user);
    case 'core/ADD_USER_TO_COMMUNITY':
      return {
        ...state,
        // @ts-ignore
        [action.userId]: action.networks,
      };
    case 'core/REMOVE_USER_FROM_COMMUNITY':
      return {
        ...state,
        [action.userId]: (state[action.userId] || []).filter(({ id }) => id !== action.networkId),
      };
    case SELECT_ORGANISATION:
      return {};
    case NETWORK_UPDATE_USER_ROLE:
      if (Array.isArray(state[action.userId]) && ('roles' in action)) {
        return {
          ...state,
          [action.userId]: state[action.userId].map((network: any) => {
            if (network.id === action.networkId) {
              return {
                ...network,
                roles: action.roles
              };
            }
            return network;
          })
        };
      }
      return state;
    case NETWORK_DELETE_USER:
      if (Array.isArray(state[action.userId])) {
        return {
          [action.userId]: state[action.userId].filter((network: any) => {
            return network.id !== action.networkId;
          })
        };
      }
      return state;
    default:
      return state;
  }
};

const teamsReducer = (state = {}, action: Action) => {
  switch (action.type) {
    case CORE_RECEIVE_USER:
      return addScopesToStore('teams', state, action.user);
    case NETWORK_UPDATE_USER_TEAMS:
      return R.evolve({
        [action.userId]: R.pipe(
          R.concat(R.__, action.teams.add.map((id) => ({ id, network_id: action.networkId }))),
          // @ts-ignore
          R.reject((team) => R.includes(team.id, action.teams.remove)),
        ),
      }, state);
    case SELECT_ORGANISATION:
      return {};
    default:
      return state;
  }
};

const functionsReducer = (state: Record<string, FunctionLink[]> = {}, action: Action): typeof state => {
  switch (action.type) {
    case CORE_RECEIVE_USER:
    case ORGANISATION_UPDATE_USER:
    case NETWORK_UPDATE_USER:
      // @ts-ignore
      return addScopesToStore('functions', state, action.user);
    case SELECT_ORGANISATION:
      return {};
    case 'core/ADD_USER_TO_FUNCTION_GROUP':
      return {
        ...state,
        [action.userId]: [
          ...(state[action.userId] || []),
          // @ts-ignore
          action.functionGroup,
        ],
      };
    case 'core/REMOVE_USER_FROM_FUNCTION_GROUP':
      return {
        ...state,
        // @ts-ignore
        [action.userId]: (state[action.userId] || []).filter(({ id }) => id !== action.functionGroupId),
      };
    default:
      return state;
  }
};

const scopesReducer = combineReducers({
  status: statusReducer,
  // @ts-ignore
  networks: networksReducer,
  organisation: organisationReducer,
  teams: teamsReducer,
  functions: functionsReducer,
});

// @ts-ignore
export const findById: (state: StoreState, id?: string) => User | LoggedUser = R.curry((state: StoreState, id: string) => {
  const user = state.users.items[id];

  if (!user) return undefined;

  return user;
});

export const findByIdWithScopes = (state: StoreState, id: string | null): ExtendedUser | undefined => {
  if (!id) return;

  const user = state.users.detail[id];

  if (!user) return undefined;

  const byOrganisation = state.organisation.selected
    ? R.propEq('organisation_id', state.organisation.selected.id)
    : R.T;

  const organisationScope = id === state.loggedUser.user.id
    ? {
      // @ts-ignore
      ...state.loggedUser.scopes.organisations[state.organisation.selected],
      ...state.users.scopes.organisation[id],
    }
    : state.users.scopes.organisation[id];

  // do not rely on role.memberships[], see comments on PD-8672
  const networkScopes = R.filter(byOrganisation, state.users.scopes.networks[id] || []);

  const functionScopes = R.filter(byOrganisation, state.users.scopes.functions[id] || []);
  const teamScopes = R.filter(
    (team) => R.contains(team.network_id, R.pluck('id', networkScopes)),
    state.users.scopes.teams[id] || [],
  );

  return {
    ...user,
    scopes: {
      status: state.users.scopes.status[id] || false,
      organisation: organisationScope || {},
      // @ts-ignore
      networks: networkScopes,
      // @ts-ignore
      teams: teamScopes,
      // @ts-ignore
      functions: functionScopes,
    },
  };
};

export default combineReducers({
  items: itemsReducer,
  detail: detailReducer,
  scopes: scopesReducer
});
