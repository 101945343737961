import * as R from 'ramda';
import { combineReducers } from 'redux';
import { FLEXCHANGE_ACCEPT_EXCHANGE, FLEXCHANGE_DECLINE_EXCHANGE } from '../../flexchange/actions';
import {
  SOCIAL_REQUEST_FEED,
  SOCIAL_RECEIVE_FEED,
  SOCIAL_RECEIVE_IMPORTANT_MESSAGES,
  SOCIAL_RECEIVE_UNREAD_MESSAGES,
  SOCIAL_POST_MESSAGE,
  SOCIAL_DELETE_MESSAGE,
  SOCIAL_TOGGLE_MESSAGE_IMPORTANT,
  SOCIAL_MARK_AS_READ,
} from '../actions';
import { EObjectTypes } from '../definitions';

const objectWhitelist = ['id', 'object_type', 'object_id', 'parent_type', 'parent_id', 'user_id', 'created_at'];

const toObject = (object) => R.merge(
  R.pick(objectWhitelist, object),
  { source_id: object.source.id },
);

const findIndex = (type, id) => R.findIndex(R.and(
  R.propEq('object_type', type),
  R.propEq('source_id', id),
));

const byObject = (object) => `${object.object_type}-${object.source_id}`;

const items = (state = [], action) => {
  switch (action.type) {
    case SOCIAL_REQUEST_FEED:
      if (action.clear) return [];

      return state;
    case SOCIAL_RECEIVE_FEED: {
      const strategy = action.strategy === null
        ? R.identity
        : R.concat(state);

      return R.pipe(
        R.map(toObject),
        strategy,
        R.uniqBy(byObject),
      )(action.items);
    }
    case SOCIAL_POST_MESSAGE:
      if (!action.append || action.scheduled) return state;

      return R.prepend(toObject(action.message), state);
    case SOCIAL_DELETE_MESSAGE:
      return R.remove(findIndex(EObjectTypes.MESSAGE, action.messageId)(state), 1, state);
    case FLEXCHANGE_ACCEPT_EXCHANGE:
    case FLEXCHANGE_DECLINE_EXCHANGE:
      return R.remove(findIndex(EObjectTypes.EXCHANGE, action.exchangeId)(state), 1, state);
    default: return state;
  }
};

const important = (state = [], action) => {
  switch (action.type) {
    case SOCIAL_REQUEST_FEED:
      if (action.clear) return [];

      return state;
    case SOCIAL_RECEIVE_IMPORTANT_MESSAGES: {
      const strategy = action.strategy === null
        ? R.identity
        : R.concat(state);

      return R.pipe(
        R.map(toObject),
        strategy,
        R.uniqBy(byObject),
      )(action.items);
    }
    case SOCIAL_POST_MESSAGE:
      if (!action.append || action.scheduled) return state;

      return R.prepend(toObject(action.message), state);
    case SOCIAL_DELETE_MESSAGE:
    case SOCIAL_TOGGLE_MESSAGE_IMPORTANT:
      // We can remove item from the list for both marking as important and removing as important
      // When we mark as important the message shouldn't exist and nothing will happen
      return R.remove(findIndex(EObjectTypes.MESSAGE, action.messageId)(state), 1, state);
    default: return state;
  }
};

const unread = (state = [], action) => {
  switch (action.type) {
    // case SOCIAL_REQUEST_FEED:
    //   if (action.clear) return [];

    //   return state;
    case SOCIAL_RECEIVE_UNREAD_MESSAGES: {
      return R.map(toObject, action.items);
    }
    case SOCIAL_DELETE_MESSAGE:
    case SOCIAL_MARK_AS_READ:
    case SOCIAL_TOGGLE_MESSAGE_IMPORTANT:
      return R.remove(findIndex(EObjectTypes.MESSAGE, action.messageId)(state), 1, state);
    default: return state;
  }
};

export default combineReducers({ items, important, unread });
