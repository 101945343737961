import { combineReducers } from 'redux';
import { SELECT_ORGANISATION } from '../../organisation/actions';
// import categories from './categories';
import modules from './modules';
import screens from './screens';
import components from './components';
import statistics from './statistics';
import ui from './ui';
import draft from './draft';
import questions from './questions';
import courses from './courses';

const academyReducer = combineReducers({
  // categories,
  modules,
  screens,
  components,
  ui,
  draft,
  questions,
});

const learningReducerT = combineReducers({
  courses,
  statistics,
});

export const learningReducer = (state, action) => {
  switch (action.type) {
    case SELECT_ORGANISATION:
      return learningReducerT(undefined, action);
    default: return learningReducerT(state, action);
  }
};

export default (state, action) => {
  switch (action.type) {
    case SELECT_ORGANISATION:
      return academyReducer(undefined, action);
    default: return academyReducer(state, action);
  }
};
