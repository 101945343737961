import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import * as AlertService from '@common/services/alert';
import { useThunkDispatch } from '@common/hooks';
import Container from '@common/components/container';
import { useSearchParams } from '@hooks/use-search-params';
import { SignupForm } from '../../forms/signup';
import { getAccessData } from '../../actions';
import * as organisationSelector from '../../../organisation/selectors/organisation';
import type { StoreState } from '@common/types/store';
import type { AccessData } from '../../types';

const mapStateToProps = (state: StoreState) => ({
  info: state.info,
  organisation: organisationSelector.selected(state),
});

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector>;

export const UnconnectedSignupContainer = ({ info, organisation }: Props) => {
  const thunkDispatch = useThunkDispatch();
  const { t } = useTranslation();
  const { searchParams: { id } } = useSearchParams<{ id: string }>();
  const [data, setData] = useState<null | AccessData>(null);
  const [isSuccessful, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [isExpired, setIsExpired] = useState(!id);

  useEffect(() => {
    (async () => {
      try {
        const newData = await thunkDispatch(getAccessData(id));

        setData(newData);
      } catch (err) {
        setIsError(true);
        setIsExpired(true);
      }
    })();
  }, [id]);

  const handleSuccess = (isNewUser: boolean) => {
    setIsSuccess(isNewUser);
    setIsError(!isNewUser);
    setIsNew(isNewUser);
  };

  type ErrorResponse = {
    status_code: number;
    type: 'not_found' | 'duplicate_user_error' | 'permission_error';
  };

  const handleError = ({ status_code: statusCode, type }: ErrorResponse) => {
    if (type === 'duplicate_user_error') {
      // Already an user in the database with this email address
      setIsSuccess(true);

      return;
    }

    if (type === 'permission_error' || statusCode === 404) {
      // Couldn't find access code, code is expired or access requests is disabled
      setIsError(true);
      setIsExpired(true);

      return;
    }

    // Any other error will throw a generic warning
    AlertService.warning(t('core:signup_submit_warning'));
  };

  const orgContext = data || organisation;

  return (
    <Container name="Signup">
      {(isError || isSuccessful) && (
        <Container.Content>
          {isError && (
            <>
              <div className="Signup__Bubble Signup__Bubble--warning">
                <img
                  alt="Signup icon"
                  src="/static/images/icons/clock.png"
                />
              </div>
              <h2>{t(isExpired ? 'core:signup_link_expired' : 'core:signup_waiting_confirmation')}</h2>
              <h3>{t(isExpired ? 'core:signup_generate_new_link' : 'core:signup_wait_for_admin')}</h3>
            </>
          )}
          {isSuccessful && (
            <>
              <div className="Signup__Bubble Signup__Bubble--success">
                <img
                  alt="Signup icon"
                  src={isNew ? '/static/images/icons/bell.png' : '/static/images/icons/wave.png'}
                />
              </div>
              <h2>{t(isNew ? 'core:signup_thank_you' : 'core:signup_existing_account')}</h2>
              <h3>{t(isNew ? 'core:signup_administrator_notified' : 'core:signup_already_in')}</h3>
            </>
          )}
          <div className="Signup__Download">
            <b>{t('core:signup_download_app')}</b>
            <br />
            <a href={info.links.android_app_store} target="_blank" rel="noreferrer">
              <img src="/static/images/play_store.png" alt="Play Store" />
            </a>
            <a href={info.links.ios_app_store} target="_blank" rel="noreferrer">
              <img src="/static/images/app_store.png" alt="AppStore" />
            </a>
          </div>
        </Container.Content>
      )}
      {(!isSuccessful && !isError) && (
        <Container.Content horizontal>
          <div
            className="Signup__Image"
            style={{
              // @ts-expect-error
              backgroundImage: orgContext?.theme?.login_image_url && `url('${orgContext.theme.login_image_url}')`,
              backgroundPosition: '40% 50%',
            }}
          />
          <div className="Signup__Form">
            {(orgContext?.brand_icon) ? (
              <img
                className="Authentication__Form__Logo"
                src={orgContext?.brand_icon}
                alt={orgContext?.name}
              />
            ) : (
              <img
                className="Authentication__Form__Logo"
                src="/static/images/logo.png"
                alt="Oneteam"
              />
            )}

            <h2>{t('core:signup_get_to_know_you')}</h2>
            <h3>{t('core:signup_join_organisation', { name: orgContext?.name.trim() || 'Oneteam' })}</h3>

            <SignupForm
              id={id}
              networks={data?.networks || []}
              functions={data?.functions || []}
              onSuccess={handleSuccess}
              onError={handleError}
            />
          </div>
        </Container.Content>
      )}
    </Container>
  );
};

export const SignupContainer = connector(UnconnectedSignupContainer);
