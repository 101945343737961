import React, { useState, ReactNode } from 'react';
import BootstrapModal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { Button } from '../button';
import { Checkbox } from '../form/inputs/checkbox/checkbox-input';
import { TextInput } from '../form/inputs/text/text-input';

const { Footer } = BootstrapModal;

type Props = {
  className?: string;
  children?: ReactNode;
  title: ReactNode;
  onConfirm: (checked: boolean) => any | Promise<any>;
  cancelText?: string;
  confirmText?: string;
  confirmString?: string;
  description?: any;
  check?: string;
  onCancel?: Function;
  onEnter?: Function;
  onExit?: Function;
  danger?: boolean;
  isLoading?: boolean;
};

export const ConfirmButton = ({
  children,
  title,
  description,
  check,
  cancelText,
  confirmText,
  confirmString,
  onConfirm,
  onCancel,
  danger,
  onEnter,
  onExit,
  isLoading,
  className
}: Props) => {
  const { t } = useTranslation();
  const [shouldShow, setShouldShow] = useState(!children);
  const [checked, setChecked] = useState(false);
  const [confirmValue, setConfirmValue] = useState('');

  // @ts-expect-error
  const trigger = children && React.cloneElement(React.Children.only(children), {
    onClick: () => setShouldShow(true),
  });

  const handleClose = () => {
    setShouldShow(false);

    if (onCancel) onCancel();
  };

  let modalClassName = 'ConfirmButton';
  if (className) modalClassName += ` ${className}`;

  return (
    <>
      {trigger}
      {/* @ts-expect-error */}
      <BootstrapModal
        key="modal"
        show={shouldShow}
        onHide={handleClose}
        onEnter={onEnter}
        onExited={onExit}
        className={modalClassName}
        backdropClassName="ConfirmButton"
        size={!confirmString ? 'small' : undefined}
      >
        <div>
          <h3>{title}</h3>
          {description && <div className="ConfirmButton__description">{description}</div>}
          {check && (
            <Checkbox
              label={check}
              value={checked}
              onChange={(value) => setChecked(!!value)}
            />
          )}
          {confirmString && (
            <TextInput
              value={confirmValue}
              onChange={(e) => setConfirmValue(e.target.value)}
            />
          )}
          <Footer className="ConfirmButton__footer">
            <Button
              size="large"
              type={danger ? 'warning' : 'primary'}
              onClick={() => {
                setShouldShow(false);
                onConfirm(checked);
              }}
              isLoading={isLoading}
              disabled={!!(confirmString && confirmString !== confirmValue)}
            >
              {confirmText || t('common:confirm_button_confirm_text')}
            </Button>
            <Button type="transparent" size="large" onClick={handleClose}>
              {cancelText || t('common:confirm_button_cancel')}
            </Button>
          </Footer>
        </div>
      </BootstrapModal>
    </>
  );
};
