import * as R from 'ramda';
import Storage from '@services/storage';

import clearNetwork from '../../network/actions/clear-network';
import initialiseOrganisation from './initialise';

export const SELECT_ORGANISATION = 'auth/SELECT_ORGANISATION';

export default (organisationId, redirect = true) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  if (selected !== organisationId) {
    dispatch({
      type: SELECT_ORGANISATION,
      organisationId,
      sync: true,
    });

    try {
      const action = await dispatch(initialiseOrganisation(organisationId));

      const { organisation } = action;

      // If selected a new organisation but not a community, clear currently selected community
      if (redirect) dispatch(clearNetwork());

      Storage.setObject('currentOrganisation', R.pick(['id', 'name', 'brand_icon'], organisation));
      Storage.setCookie('organisation_id', organisation.id);

      return action;
    } catch (err) {
      return '/auth/organisations';
    }
  }

  return '/admin';
};
