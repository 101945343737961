import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { combineClassNames } from '@utils/combineClassNames';
import { getFileTypeFromPath } from '@utils/file';
import { useToggle } from '@common/hooks';

import Icon from '@common/components/icon';
import { CircularProgressBar } from '@common/components/progress-bar';

import { fileTypesStyles } from '@common/definitions';
import * as uploadQueueSelector from '../../selectors/upload-queue';
import * as actions from '../../actions';

import type { Attachment } from '@common/types/objects';
import type { StoreState } from '@common/types/store';
import type { FileItem } from '../../types';

const rowHeight = 52;
const maxItemsToDisplay = 3;

type ItemProps = {
  item: FileItem;
};

const UploadItem = ({ item }: ItemProps) => {
  const { t } = useTranslation();
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const [isCompleted, setIsCompleted] = React.useState(false);
  const [isError, setIsError] = React.useState(false);

  const waitForPromise = async (uploadPromise: Promise<Attachment>) => {
    try {
      await uploadPromise;

      setIsCompleted(true);
    } catch (err) {
      setIsError(true);
    }
  };

  React.useEffect(() => {
    item.onProgress(setUploadProgress);
    waitForPromise(item.promise);
  }, [item]);

  const handleRetry = async () => {
    // Reset state
    setIsCompleted(false);
    setIsError(false);
    setUploadProgress(0);

    // Get new promise and onProgress emitter
    const retry = await item.onRetry();
    retry.onProgress(setUploadProgress);
    waitForPromise(retry.promise);
  };

  const fileType = getFileTypeFromPath(item.file.name);

  const icon = fileTypesStyles[fileType];

  return (
    <div className="UploadQueueItem">
      <img src={icon} alt={fileType} />
      <div className="UploadQueueItem__Name">{item.file.name}</div>

      <div className="UploadQueueItem__Status">
        {isCompleted && (
          <Icon type="check" className="UploadQueueItem__Completed" />
        )}
        {isError && (
          <div className="UploadQueueItem__Error" onClick={handleRetry} role="button">
            {t('core:upload_failed')}
            <Icon type="warning" />
          </div>
        )}
        {!isCompleted && !isError && (
          <CircularProgressBar percentage={uploadProgress} />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  items: uploadQueueSelector.getUploadQueue(state),
});

const mapDispatchToProps = {
  clearUploadQueue: actions.clearUploadQueue,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ContainerConnectedProps = ConnectedProps<typeof connector>;

type Props = ContainerConnectedProps;

export const UnwrappedUploadQueue = ({ items = [], clearUploadQueue }: Props) => {
  const { t } = useTranslation();
  const [isOpen, toggleOpen] = useToggle(true);

  if (items.length === 0) return null;

  return (
    <div className={combineClassNames('UploadQueuePopup', { 'UploadQueuePopup--open': isOpen })}>
      <div className="UploadQueuePopup__Header">
        {t('core:uploading_items', { count: items.length })}
        <div className="pull-right">
          <Icon
            type={isOpen ? 'expand_more' : 'expand_less'}
            onClick={toggleOpen}
          />
          <Icon
            type="close"
            onClick={clearUploadQueue}
          />
        </div>
      </div>
      <div
        className="UploadQueuePopup__Content"
        style={{ height: Math.min(items.length * rowHeight, rowHeight * maxItemsToDisplay) }} // This is so we can animate the height property
      >
        {items.map((item) => (
          <UploadItem
            key={item.id}
            item={item}
          />
        ))}
      </div>
    </div>
  );
};

export const UploadQueue = connector(UnwrappedUploadQueue);
