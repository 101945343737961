import * as R from 'ramda';
import { combineReducers } from 'redux';
import {
  ORGANISATION_ADD_FUNCTION,
  ORGANISATION_RECEIVE_FUNCTION,
  ORGANISATION_UPDATE_FUNCTION,
  ORGANISATION_UPDATE_FUNCTION_USERS,
  ORGANISATION_DELETE_FUNCTION,
  ORGANISATION_UPDATE_USER,
} from '../../admin/actions';
import { ORGANISATION_INITIALISE } from '../actions';

const addToStore = R.curry((state, item) => R.assoc(item.id, R.merge(state[item.id], item), state));

const incrementUsersCount = R.evolve({ users_count: R.inc });
const decrementUsersCount = R.evolve({ users_count: R.dec });

const items = (state = {}, action) => {
  switch (action.type) {
    case ORGANISATION_INITIALISE:
      return R.reduce(addToStore, state, action.functions);
    case ORGANISATION_ADD_FUNCTION:
    case ORGANISATION_RECEIVE_FUNCTION:
    case ORGANISATION_UPDATE_FUNCTION:
    case ORGANISATION_UPDATE_FUNCTION_USERS:
      return addToStore(state, action.item);
    // case ORGANISATION_ADD_FUNCTIONS:
    //   return R.reduce(addToStore, state, action.items);
    case ORGANISATION_DELETE_FUNCTION:
      return R.omit([action.id], state);
    case ORGANISATION_UPDATE_USER:
      if (!action.links.functions) return state;

      return R.pipe(
        ({ add, remove }) => [
          R.reduce((acc, id) => R.assoc(id, incrementUsersCount, acc), {}, add),
          R.reduce((acc, id) => R.assoc(id, decrementUsersCount, acc), {}, remove),
        ],
        R.mergeAll,
        R.evolve(R.__, state),
      )(action.links.functions);
    default: return state;
  }
};

export const findById = R.curry((state, id) => {
  const organisationFunction = state.organisation.functions.items[id];

  if (!organisationFunction) return null;

  return { organisationFunction };
});

export default combineReducers({ items });
