import { combineReducers } from 'redux';
import moment from 'moment';

import { omit } from '@utils/ramda-type-fixes';

import type { ResponseDocument } from './types';
import { RECEIVE_PROFILE } from '@modules/core/actions';
import type {
  FetchDocumentsAction,
  UploadFileAction,
  ReplaceFileAction,
  CreateDocumentAction,
  DeleteDocumentAction,
  UpdateDocumentAction,
  ToggleDocumentFavoriteStatus,
  MarkDocumentAsViewed,
  RemoveDocumentPermanentlyAction,
  RestoreDocumentAction,
  RenameDocumentAction,
  MoveDocumentAction,
} from './actions';
import { createPersonalFolderObj } from './actions';
import { SELECT_ORGANISATION } from '../organisation/actions';
import { Filters } from './constants';

export type DocumentsReducerRelatedActions =
  | FetchDocumentsAction
  | UploadFileAction
  | ReplaceFileAction
  | CreateDocumentAction
  | DeleteDocumentAction
  | ToggleDocumentFavoriteStatus
  | MarkDocumentAsViewed
  | RemoveDocumentPermanentlyAction
  | MoveDocumentAction
  | RestoreDocumentAction
  | RenameDocumentAction
  | UpdateDocumentAction;

type DocumentItemsState = Record<string, ResponseDocument>;

export const documentItemsReducer = (state: DocumentItemsState = {}, action: DocumentsReducerRelatedActions): typeof state => {
  switch (action.type) {
    case 'documents/FETCH_DOCUMENTS':
      return action.items.reduce((acc, curr): DocumentItemsState => ({
        ...acc,
        [curr.id]: curr,
      }), state);
    case 'documents/UPLOAD_FILE':
    case 'documents/REPLACE_FILE':
    case 'documents/CREATE_FOLDER':
    case 'documents/UPDATE_DOCUMENT':
    case 'documents/MOVE_DOCUMENT':
    case 'documents/TOGGLE_DOCUMENT_FAVORITE_STATUS':
    case 'documents/MARK_DOCUMENT_AS_VIEWED':
    case 'documents/RESTORE_DOCUMENT':
    case 'documents/RENAME_DOCUMENT':
      // @ts-expect-error
      return {
        ...state,
        [action.item.id]: action.item,
      };
    case 'documents/DELETE_DOCUMENT':
      if (!state[action.documentId]) return state;

      return {
        ...omit([action.documentId], state),
        [action.documentId]: {
          ...state[action.documentId],
          parent_folder_id_before_removal: state[action.documentId].parent_folder_id,
          deleted_at: moment().format(),
        },
      };
    case 'documents/REMOVE_DOCUMENT_PERMANENTLY':
      return omit([action.documentId], state);
    // @ts-expect-error
    case RECEIVE_PROFILE:
      return {
        ...state,
        // @ts-expect-error
        personal: createPersonalFolderObj(action.user.id),
      };
    default:
      return state;
  }
};

export const idsReducer = (state: Record<string, string[]> = {}, action: DocumentsReducerRelatedActions): typeof state => {
  switch (action.type) {
    case 'documents/FETCH_DOCUMENTS': {
      const newIds = action.items.map(({ id }) => id);

      return {
        ...state,
        [action.folderKey]: action.strategy === 'append'
          ? [...state[action.folderKey], ...newIds]
          : newIds,
      };
    }
    case 'documents/UPLOAD_FILE': {
      if (!state[action.folderKey]) return state;

      return {
        ...state,
        [action.folderKey]: [
          ...state[action.folderKey],
          action.item.id,
        ],
      };
    }
    case 'documents/CREATE_FOLDER':
      if (!state[action.folderKey]) return state;

      return {
        ...state,
        [action.folderKey]: [action.item.id, ...state[action.folderKey]],
      };
    case 'documents/DELETE_DOCUMENT': {
      if (!state[action.folderKey]) return state;

      const trash = state[Filters.TRASH] ? {
        ...state,
        [Filters.TRASH]: [
          action.documentId,
          ...state[Filters.TRASH],
        ],
      } : {};

      return {
        ...state,
        ...trash,
        [action.folderKey]: state[action.folderKey].filter((id) => id !== action.documentId),
      };
    }
    case 'documents/TOGGLE_DOCUMENT_FAVORITE_STATUS': {
      if (!state[Filters.FAVORITES]) return state;

      if (!state[Filters.FAVORITES].includes(action.item.id)) {
        return {
          ...state,
          [Filters.FAVORITES]: [
            ...state[Filters.FAVORITES],
            action.item.id,
          ],
        };
      }

      return {
        ...state,
        [Filters.FAVORITES]: state[Filters.FAVORITES].filter((id) => id !== action.item.id),
      };
    }

    case 'documents/REMOVE_DOCUMENT_PERMANENTLY':
      if (!state[action.folderKey]) return state;

      return {
        ...state,
        [action.folderKey]: state[action.folderKey].filter((id) => id !== action.documentId),
      };

    case 'documents/MOVE_DOCUMENT':
    case 'documents/RESTORE_DOCUMENT': {
      const newFolderIds = state[action.newFolderKey];
      const clonedNewFolderIds = [...(newFolderIds || []), action.item.id];

      const updatedNewFolderIds = newFolderIds
        ? { [action.newFolderKey]: clonedNewFolderIds }
        : {};

      const oldFolderIds = state[action.oldFolderKey];
      const updatedOldFolderIds = oldFolderIds
        ? { [action.oldFolderKey]: oldFolderIds.filter((id) => id !== action.item.id) }
        : {};

      return {
        ...state,
        ...updatedNewFolderIds,
        ...updatedOldFolderIds,
      };
    }
    default:
      return state;
  }
};

type DocumentNextCursorState = Record<string, string | null>;
export const nextCursorReducer = (state: DocumentNextCursorState = {}, action: DocumentsReducerRelatedActions): typeof state => {
  switch (action.type) {
    case 'documents/FETCH_DOCUMENTS':
      return {
        ...state,
        [action.folderKey]: action.nextCursor,
      };
    default:
      return state;
  }
};

const combinedReducer = combineReducers({
  items: documentItemsReducer,
  ids: idsReducer,
  nextCursors: nextCursorReducer,
});

// Clears the whole documents reducer on selecting a new organisation
export const documentsReducer = (state: unknown, action: { type: typeof SELECT_ORGANISATION | string }) => {
  switch (action.type) {
    case SELECT_ORGANISATION:
      return combinedReducer(undefined, action);
    default:
      // @ts-ignore
      return combinedReducer(state, action);
  }
};
