import PropTypes from 'prop-types';
import React, { Component } from 'react';

class ModalContentWrapper extends Component { // eslint-disable-line
  static propTypes = {
    children: PropTypes.any.isRequired,
    onSubmit: PropTypes.func,
    className: PropTypes.string,
  };

  render() {
    const { children, onSubmit, className } = this.props;

    return (
      <form
        className={`Form${className ? ` ${className}` : ''}`}
        onSubmit={onSubmit}
        autoComplete="false"
      >
        {children}
      </form>
    );
  }
}

export default ModalContentWrapper;
