import * as React from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import * as AnalyticsService from '../../services/analytics';
import Modal from '../modal';
import { Button } from '../button';
import { Organisation } from '../../types/objects';

export type Props = {
  info: {
    book_sales_meeting?: string,
  },
  organisations: Array<Organisation>,
  logout: () => void,
  onSwitch: () => void,
};

const { EEventNames } = AnalyticsService;

const OrganisationExpiredWall = ({ info, organisations, logout, onSwitch }: Props) => {
  const handleBookMeeting = () => {
    // @ts-expect-error
    window.open(info.links.book_sales_meeting, '_blank');

    AnalyticsService.track(EEventNames.CONTACTED_SALES);
  };

  const handleLogout = () => {
    logout();
    window.location.href = '/auth/login';
  };

  const handleSwitchOrganisation = onSwitch || (() => (window.location.href = '/auth/organisations'));

  return (
    <Modal
      disableRootClose
      list
      size="small"
      className="ExpirationWall"
      content={(
        <div>
          <div className="ExpirationWall__Header">
            <img src="/static/images/expired.png" alt="Expired" />
          </div>
          <h3><Trans i18nKey="common:expired_title" /></h3>
          <small><Trans i18nKey="common:expired_description" /></small>
          {/* @ts-expect-error */}
          {info.links.book_sales_meeting && (
            <Button
              size="fill"
              type="primary"
              icon="calendar_today"
              onClick={handleBookMeeting}
            >
              <Trans i18nKey="common:book_appointment" />
            </Button>
          )}
          {Object.keys(organisations).length > 1 && (
            <Button size="fill" onClick={handleSwitchOrganisation}><Trans i18nKey="common:switch_organisation" /></Button>
          )}
          <Button size="fill" onClick={handleLogout}><Trans i18nKey="common:log_out" /></Button>
        </div>
      )}
    />
  );
};

// @ts-expect-error
const mapStateToProps = (state) => ({
  info: state.info,
  organisations: state.loggedUser.organisations,
});

const mapDispatchToProps = {
  logout: require('../../../modules/authentication/actions/logout').default,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationExpiredWall);
