import * as R from 'ramda';
import { ORGANISATION_INITIALISE, ORGANISATION_RECEIVE_ADMINS } from '../actions';
import {
  ADMIN_REMOVE_ADMIN,
  ORGANISATION_DELETE_USER,
  ORGANISATION_UPDATE_USER_ROLE,
  ORGANISATION_INVITE_USER,
} from '../../admin/actions';

const formatForStore = (admin) => ({ id: admin.id, roles: admin.roles.map((role) => ({ id: role.id, memberships: role.memberships })) });

export default (state = [], action) => {
  switch (action.type) {
    case ORGANISATION_INITIALISE:
      return action.admins.map(formatForStore);
    case ORGANISATION_RECEIVE_ADMINS:
      return action.items.map(formatForStore);
    case ORGANISATION_UPDATE_USER_ROLE:
      return state.map((admin) => (admin.id === action.userId
        ? { ...admin, roles: action.roles }
        : admin
      ));
    case ADMIN_REMOVE_ADMIN:
    case ORGANISATION_DELETE_USER:
      return R.reject(R.propEq('id', action.userId), state);
    case ORGANISATION_INVITE_USER:
      if (action.roles.length > 0) {
        return R.prepend({
          id: action.user.id,
          roles: action.roles.map((role) => ({
            id: role.id,
            memberships: role.memberships,
          })),
        }, state);
      }

      return state;
    default: return state;
  }
};
