import * as R from 'ramda';
import { combineReducers } from 'redux';
import { ORGANISATION_INITIALISE, ORGANISATION_ADD_NETWORKS } from '../actions';
import { NETWORK_UPDATE_NETWORK } from '../../network/actions';

const items = (state = [], action) => {
  switch (action.type) {
    case ORGANISATION_INITIALISE:
      return R.pluck('id', action.networks);
    case NETWORK_UPDATE_NETWORK:
      if (!R.contains(action.network.id, state)) return state;

      return [...state];
    case ORGANISATION_ADD_NETWORKS:
      return R.concat(state, R.pluck('id', action.items));
    case 'admin/DELETE_NETWORK':
      return R.reject(R.equals(action.id), state);
    default: return state;
  }
};

export default combineReducers({ items });
