import * as R from 'ramda';
import Api from '../../../common/services/api';

export const NETWORK_UPDATE_FUNCTION_USERS = 'network/UPDATE_FUNCTION_USERS';
export const NETWORK_UPDATE_TEAM_USERS = 'network/UPDATE_TEAM_USERS';
export const NETWORK_CLEAR = 'network/CLEAR';
export const ADMIN_CREATE_TEAM = 'admin/CREATE_TEAM';
export const NETWORK_DELETE_USER = 'network/DELETE_USER';
export const NETWORK_RECEIVE_BIRTHDAYS_FOR_DATE = 'network/RECEIVE_BIRTHDAYS_FOR_DATE';
export const NETWORK_RECEIVE_BIRTHDAYS = 'network/RECEIVE_BIRTHDAYS';
export const NETWORK_INITIALISE = 'network/INITIALISE';
export const NETWORK_INVITE_USER = 'network/INVITE_USER';
export const NETWORK_RECEIVE_USERS = 'network/RECEIVE_USERS';
export const ADMIN_REMOVE_TEAM = 'admin/REMOVE_TEAM';
export const NETWORK_RESEND_INVITATION = 'network/RESEND_INVITATION';
export const SELECT_NETWORK = 'network/SELECT_NETWORK';
export const SET_INITIALIZING = 'network/SET_INITIALIZING';
export const NETWORK_UPDATE_NETWORK = 'network/UPDATE_NETWORK';
export const ADMIN_UPDATE_TEAM = 'admin/UPDATE_TEAM';
export const ADMIN_START_EDIT_TEAM = 'admin/START_EDIT_TEAM';
export const ADMIN_START_EDIT_TEAM_STOP = 'admin/STOP_EDIT_TEAM';
export const NETWORK_UPDATE_USER_ROLE = 'network/UPDATE_USER_ROLE';
export const NETWORK_UPDATE_USER_TEAMS = 'network/UPDATE_USER_TEAMS';
export const NETWORK_UPDATE_USER = 'network/UPDATE_USER';
export const NETWORK_RECEIVE_ADMINS = 'network/RECEIVE_ADMINS';
export const NETWORK_REMOVE_ADMIN = 'network/REMOVE_ADMIN';
export const NETWORK_RECEIVE_FUNCTION = 'network/RECEIVE_FUNCTION';
export const NETWORK_RECEIVE_ROLES = 'network/RECEIVE_ROLES';
export const NETWORK_REINVITE_USERS = 'network/REINVITE_USERS';
export const NETWORK_RECEIVE_USER_COUNTS = 'network/RECEIVE_USER_COUNTS';

export const updateUserRoles = (userId, roles, networkId, allRoles) => {
  return async (dispatch, getState) => {
    const state = getState();

    await Api.put(`/v1/networks/${networkId}/users/${userId}/roles`, roles);

    // do not rely on role.memberships[], see comments on PD-8672
    const networksScopes = state.users.scopes.networks;
    // console.log("debug updateUserRoles networksScopes", networksScopes);

    const currentUserRoles = networksScopes[userId]?.find((network) => {
      return network.id === networkId;
    })?.roles || [];

    const newRoles = [
      ...currentUserRoles.filter((role) => !roles.remove.includes(role.id)),

      // beware that some role objects may lack the new membership object as the
      // API call above does not return them. We should not have it return it
      // as we should instead read that piece of data from the API when we need
      // it in the other parts of the app
      // see comments of https://flex-appeal.atlassian.net/browse/PD-8672
      ...allRoles.filter((role) => roles.add.includes(role.id))
    ];

    const action = {
      type: NETWORK_UPDATE_USER_ROLE,
      userId,
      roles: newRoles,
      networkId
    };
    // console.log("debug updateUserRoles action", action);
    return dispatch(action);
  };
};

export const fetchAdmins = () => async (dispatch, getState) => {
  const { network: { selected } } = getState();

  const response = await Api.get(`/v3/networks/${selected}/admins`);

  dispatch({
    type: NETWORK_RECEIVE_ADMINS,
    items: response.data,
  });

  return response;
};

export const removeAdmin = (userId) => async (dispatch, getState) => {
  const { network: { selected, admins } } = getState();

  const admin = R.find(R.propEq('id', userId), admins);

  if (!admin) throw new Error('Admin not found');

  Api.put(`/v1/networks/${selected}/users/${userId}/roles`, {
    remove: R.pluck('id', admin.roles),
  });

  dispatch({
    type: NETWORK_REMOVE_ADMIN,
    userId,
  });
};

export const fetchFunction = (id) => async (dispatch, getState) => {
  const { network: { selected } } = getState();

  const { data } = await Api.get(`/v2/networks/${selected}/functions/${id}`);

  return dispatch({
    type: NETWORK_RECEIVE_FUNCTION,
    item: data,
  });
};

let promise;

export const fetchRoles = (networkId) => async (dispatch, getState) => {
  if (promise) return promise;

  const { network: { selected } } = getState();

  promise = Api.get(`/v1/networks/${networkId || selected}/roles`);

  const request = await promise;

  dispatch({
    type: NETWORK_RECEIVE_ROLES,
    items: request.data,
  });

  promise = null;

  return request;
};

export const reinviteUsers = () => async (dispatch, getState) => {
  const { network: { selected } } = getState();

  await Api.post(`/v2/networks/${selected}/users/reinvite`);

  dispatch({
    type: NETWORK_REINVITE_USERS,
  });
};

export const fetchUserCounts = () => async (dispatch, getState) => {
  const { network: { selected } } = getState();

  const { data: counts } = await Api.get(`/v2/networks/${selected}/users/counts`);

  dispatch({
    type: NETWORK_RECEIVE_USER_COUNTS,
    counts,
  });
};
