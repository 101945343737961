import React from 'react';
import { useTranslation } from 'react-i18next';
import { reduxForm, InjectedFormProps } from 'redux-form';

import * as validation from '@common/utils/validation';
import {
  Row, Group, Label, TextInput, SelectInput
} from '@common/components/form';
import { Button } from '@common/components/button';
import { useThunkDispatch } from '@common/hooks';
import { createAccessRequest, RequestValues } from '../../actions';

type FormState = {
  first_name: string;
  last_name: string;
  email: string;
  network_id: string | null;
  function_id: string | null;
};

type ErrorResponse = {
  status_code: number;
  type: 'not_found' | 'duplicate_user_error' | 'permission_error';
};

type OwnProps = {
  id: string;
  networks: { id: string, name: string }[];
  functions: { id: string, name: string }[];
  onSuccess: (isNew: boolean) => void;
  onError: (response: ErrorResponse) => void;
};

const reduxFormConnector = reduxForm<FormState, OwnProps, boolean>({
  form: 'signup',
  initialValues: {
    first_name: '',
    last_name: '',
    email: '',
    network_id: null,
    function_id: null,
  },
  validate: (values) => {
    const errors: Record<string, boolean> = {};

    if (values.first_name === '') errors.first_name = true;
    if (values.last_name === '') errors.last_name = true;
    if (!validation.test('EMAIL', values.email)) errors.email = true;
    if (values.network_id === null) errors.network_id = true;
    if (values.function_id === null) errors.function_id = true;

    return errors;
  },
});

type Props = InjectedFormProps<FormState, OwnProps, boolean> & OwnProps;

const SignupFormComponent = ({
  id,
  networks,
  functions,
  pristine,
  invalid,
  submitting,
  onSuccess,
  onError,
  handleSubmit,
}: Props) => {
  const { t } = useTranslation();
  const thunkDispatch = useThunkDispatch();

  const submitFn = async (values: FormState) => {
    try {
      const isNew = await thunkDispatch(createAccessRequest(id, values as RequestValues));

      onSuccess(isNew);
    } catch (response: any) {
      if (response && typeof response === 'object' && 'status_code' in response) {
        onError(response);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(submitFn)} data-testid="signup-form">
      <Row>
        <Group>
          <Label text={t('core:signup_form_label_first_name')} forInput="first_name" />
          <TextInput
            name="first_name"
            placeholder={t('core:signup_form_label_first_name')}
          />
        </Group>
      </Row>
      <Row>
        <Group>
          <Label text={t('core:signup_form_label_last_name')} forInput="last_name" />
          <TextInput
            name="last_name"
            placeholder={t('core:signup_form_label_last_name')}
          />
        </Group>
      </Row>
      <Row>
        <Group>
          <Label text={t('core:signup_form_label_email')} forInput="email" />
          <TextInput
            name="email"
            placeholder={t('core:signup_form_label_email')}
          />
        </Group>
      </Row>
      <Row>
        <Group>
          <Label text={t('core:signup_form_label_network')} forInput="network_id" />
          <SelectInput
            searchable
            clearable={false}
            name="network_id"
            options={networks || []}
            placeholder={t('core:signup_form_label_network_placeholder')}
            valueKey="id"
            labelKey="name"
          />
        </Group>
      </Row>
      <Row>
        <Group>
          <Label text={t('core:signup_form_label_function')} forInput="function_id" />
          <SelectInput
            searchable
            clearable={false}
            name="function_id"
            options={functions || []}
            placeholder={t('core:signup_form_label_function_placeholder')}
            valueKey="id"
            labelKey="name"
          />
        </Group>
      </Row>
      <Button
        size="fill"
        type="primary"
        buttonType="submit"
        disabled={pristine || invalid}
        isLoading={submitting}
      >
        {t('core:signup_request_to_join')}
      </Button>
    </form>
  );
};

export const SignupForm = reduxFormConnector(SignupFormComponent);
