import * as R from 'ramda';
import { combineReducers } from 'redux';
import { SOCIAL_RECEIVE_FEED } from '../../social/actions';
import {
  FLEXCHANGE_RECEIVE_EXCHANGES,
  FLEXCHANGE_ACCEPT_EXCHANGE,
  FLEXCHANGE_DECLINE_EXCHANGE,
  FLEXCHANGE_REJECT_EXCHANGE,
  FLEXCHANGE_APPROVE_EXCHANGE,
} from '../actions';
import * as usersReducer from '../../core/reducers/users';

const isExchange = R.propEq('object_type', 'exchange');
const getResponse = (response) => R.omit(['user'], response);
const addToStore = (acc, response) => R.assoc(response.id, getResponse(response), acc);
const addResponsesToStore = (acc, { source }) => R.reduce(addToStore, acc, source.responses || []);

const items = (state = {}, action) => {
  switch (action.type) {
    case SOCIAL_RECEIVE_FEED:
    case FLEXCHANGE_RECEIVE_EXCHANGES:
      return R.pipe(R.filter(isExchange), R.reduce(addResponsesToStore, state))(action.items);
    case FLEXCHANGE_ACCEPT_EXCHANGE:
    case FLEXCHANGE_DECLINE_EXCHANGE:
    case FLEXCHANGE_REJECT_EXCHANGE:
    case FLEXCHANGE_APPROVE_EXCHANGE:
      return addResponsesToStore(state, action.exchange);
    default: return state;
  }
};

const addByUser = (acc, response) => R.assoc(response.user_id, response.id, acc);
const addExchange = (object) => R.reduce(addByUser, {}, object.source.responses);
const addResponseToExchange = (acc, object) => R.assoc(object.source_id, addExchange(object), acc);

const exchanges = (state = {}, action) => {
  switch (action.type) {
    case SOCIAL_RECEIVE_FEED:
    case FLEXCHANGE_RECEIVE_EXCHANGES:
      return R.pipe(R.filter(isExchange), R.reduce(addResponseToExchange, state))(action.items);
    case FLEXCHANGE_ACCEPT_EXCHANGE:
    case FLEXCHANGE_DECLINE_EXCHANGE:
    case FLEXCHANGE_REJECT_EXCHANGE:
    case FLEXCHANGE_APPROVE_EXCHANGE:
      return addResponseToExchange(state, action.exchange);
    default: return state;
  }
};

export const findById = R.curry((state, id) => {
  const response = state.flexchange.responses.items[id];

  return {
    ...R.omit(['user_id'], response),
    user: usersReducer.findById(state, response.user_id),
  };
});

export const findIdsByExchange = R.curry((state, id) => (
  R.values(state.flexchange.responses.exchanges[id])
));

export default combineReducers({ items, exchanges });
