import { CORE_INITIALISE } from '../actions';
import { SELECT_ORGANISATION, ORGANISATION_INITIALISE } from '../../organisation/actions';
import { NETWORK_INITIALISE } from '../../network/actions';

const initialState = {
  initialised: false,
  organisationInitialised: false,
  networkInitialised: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CORE_INITIALISE:
      return { ...state, initialised: true };
    case ORGANISATION_INITIALISE:
      return { ...state, organisationInitialised: true };
    case SELECT_ORGANISATION:
      return { ...state, organisationInitialised: false };
    case NETWORK_INITIALISE:
      return { ...state, networkInitialised: true };
    default: return state;
  }
};
