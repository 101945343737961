import { combineReducers } from 'redux';
import * as R from 'ramda';
import * as reducerUtils from '../../../common/utils/reducer';
import {
  ORGANISATION_UPDATE_FUNCTION,
  ORGANISATION_DELETE_FUNCTION,
} from '../../organisation/actions';
import {
  NETWORK_INITIALISE,
  NETWORK_UPDATE_FUNCTION_USERS,
  NETWORK_RECEIVE_FUNCTION,
  SELECT_NETWORK,
} from '../actions';

const addToStore = R.curry((state, item) => R.assoc(item.id, item, state));

const items = (state = {}, action) => {
  switch (action.type) {
    case NETWORK_INITIALISE:
      return R.reduce(addToStore, state, action.functions);
    case NETWORK_UPDATE_FUNCTION_USERS:
    case NETWORK_RECEIVE_FUNCTION:
      return addToStore(state, action.item);
    case ORGANISATION_UPDATE_FUNCTION:
      return reducerUtils.update(action.functionId, (item) => R.merge(item, {
        name: action.item.name,
      }), state);
    case ORGANISATION_DELETE_FUNCTION:
      return R.omit([action.id], state);
    case SELECT_NETWORK: return {};
    default: return state;
  }
};

export const findById = R.curry((state, id) => {
  const networkFunction = state.network.functions.items[id];

  if (!networkFunction) return null;

  return networkFunction;
});

export default combineReducers({ items });
