import Pusher from 'pusher-js';
import * as R from 'ramda';
import Api from './api';

const transformChannelName = (name, state) => R.cond([
  [R.equals('me'), () => `user-${state.loggedUser.user.id}`],
  [R.T, R.identity],
])(name);

export default (() => {
  let _store;
  let _socket;
  let _events = {};

  const connectEvent = (event) => {
    const channelName = `private-${transformChannelName(event.channel, _store.getState())}`;

    let channel = _socket.channels.channels[channelName];

    if (_socket && channelName && !channel) {
      channel = _socket.subscribe(channelName);

      // Development logging
      if (process.env.NODE_ENV === 'development') {
        channel.bind('pusher:subscription_succeeded', () => console.log(`Pusher : Subscribed to ${channelName}`));
        channel.bind('pusher:subscription_error', (error) => console.log(`Pusher : failed ${channelName}`, error));
      }
    }

    _events[event.eventName] = event;

    return channel.bind(event.eventName, (payload) => _store.dispatch(event.action(payload)));
  };

  return {
    getPusher() {
      return _socket;
    },
    connect: (store) => (_store = store),
    initialise: async () => {
      if (_socket) return false;

      if (process.env.NODE_ENV === 'development') Pusher.logToConsole = true;

      // Create connection
      _socket = new Pusher(process.env.PUSHER_KEY, {
        cluster: 'eu',
        encrypted: true,
        authorizer: (channel) => ({
          authorize: async (socketId, callback) => {
            try {
              const response = await Api.post('/v2/pusher/auth', {
                socket_id: socketId,
                channel_name: channel.name,
              });

              callback(null, { auth: response.auth });
            } catch (err) {
              callback(true, err.status);
            }
          },
        }),
      });

      // If running on production, add extra logging
      if (process.env.NODE_ENV === 'production' && window.Raven) {
        window.Raven.setExtraContext({ config: _socket.config });
        _socket.connection.bind('failed', (e) => window.Raven.captureException(new Error(e)));
      }

      return true;
    },
    registerEvent: (channel, eventName, action) => {
      const event = { channel, eventName, action };

      if (_socket && _store && !_events[event.eventName]) connectEvent(event);

      return action;
    },
    disconnect: () => {
      if (_socket) _socket.disconnect();
      _socket = undefined;
      _events = {};
    },
    getChannels: () => _socket.channels.channels,
  };
})();
