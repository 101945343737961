import React from 'react';

import { combineClassNames } from '@utils/combineClassNames';

const ModalTabHeader = ({ className, options, active, onChange }) => (
  <div className={combineClassNames('modal-tab-header', className)}>
    {options.map((option) => {
      const tabClassName = combineClassNames('modal-tab-header-item', {
        'modal-tab-header-item--active': option.value === active,
      });

      return (
        <div
          key={option.value}
          className={tabClassName}
          onClick={() => onChange(option.value)}
        >
          {option.title}
          {option.count !== undefined ? <small>{option.count}</small> : null}
        </div>
      );
    })}
  </div>
);

export default ModalTabHeader;
