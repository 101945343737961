import { get } from 'lodash';
/* eslint-disable max-len */
/* eslint-disable prefer-regex-literals */

export const regex = {
  DATE: new RegExp(/\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/),
  PHONE_NUM: new RegExp(/^(00\s*31|\+31|0)\s*[1-9](\s*[0-9]){6,8}$/),
  TIME: /^[0-9]{2}:[0-9]{2}$/,
  YOUTUBE_URL: new RegExp(/^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/),
  URL: new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+$/),
  EMAIL: new RegExp(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/),
  PASSWORD: new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/),
};

type RegexString = keyof typeof regex;

export const test = (name: RegexString, string: string): boolean => !string || regex[name].test(string);

export const showValidationErrorsForStep = <T>(errors: Partial<T>, config: string[], touch: (...field: string[]) => void) => {
  touch(...config.reduce((fields: string[], field: string) => {
    const error = get(errors, field);
    if (Array.isArray(error)) {
      return [
        ...fields,
        ...error.reduce((arrayFields, fieldError, index) => [
          ...arrayFields,
          ...Object.keys(fieldError)
            .filter((arrayField) => !!get(error, `${index}.${arrayField}`))
            .map((arrayField) => `${field}.[${index}].${arrayField}`),
        ], []),
      ];
    }
    if (error) return [...fields, field];
    return fields;
  }, []));
};

export const hasErrorByFields = <T>(errors: Partial<T>, fields: string[]) => {
  const find = fields.find((field) => {
    const error = get(errors, field);
    if (Array.isArray(error)) {
      return error.find((fieldError) => {
        return Object.keys(fieldError).find((arrayField) => {
          return get(fieldError, arrayField);
        });
      });
    }
    return error;
  });
  return find;
};
