import * as R from 'ramda';
import moment, { Moment } from 'moment';

import Api from '@common/services/api';
import * as i18n from '../../../i18n';

import type { Dispatch, StoreState } from '@common/types/store';
import type { PrivacyLevelSettings } from '@common/types/objects';

export const UPDATE_PROFILE = 'core/UPDATE_PROFILE';

const whitelist = [
  'first_name', 'last_name', 'settings', 'player_id', 'language_locale',
  'translation_language_locale', 'bio', 'password'
];

export type Values = {
  first_name?: string,
  last_name?: string,
  phone_num?: string | null,
  password_new?: string,
  date_of_birth?: Moment | string | null,
  profile_img?: any,
  player_id?: string,
  biography?: string | null,
  settings?: PrivacyLevelSettings & {
    enabled_flexchange_notifications: boolean,
    enabled_weekly_update: boolean,
    enabled_access_request_emails: boolean,
  },
  language_locale?: string,
};

export default (values: Values, root: boolean = false) => async (dispatch: Dispatch, getState: () => StoreState) => {
  const { organisation: { selected } } = getState();

  const payload = R.pick(whitelist, values);
  // @ts-expect-error
  if (values.password_new) payload.password = values.password_new;
  if (values.date_of_birth) payload.date_of_birth = moment(values.date_of_birth).format('YYYY-MM-DD');
  // @ts-expect-error
  if (values.biography) payload.bio = values.biography;
  // @ts-expect-error
  if (values.phone?.number) {
    // @ts-expect-error
    payload.dial_code = values.phone.dial_code;
    // @ts-expect-error
    payload.phone_num = values.phone.number;
  } else {
    // @ts-expect-error
    payload.dial_code = null;
    payload.phone_num = null;
  }

  const profileEndpoint = selected && !root
    ? `/v3/organisations/${selected.id}/users/me`
    : '/v1/users/me';

  const promises = [Api.put(profileEndpoint, payload)];

  if (values.profile_img) {
    const formData = new FormData();
    formData.append('image', values.profile_img);
    // @ts-expect-error
    promises.push(Api.post('/v1/users/me/picture', formData));
  }

  const [me]: any = await Promise.all(promises);

  let updatedUser;
  if (selected) {
    const organisations = me?.data?.scopes?.organisations;
    const belongsToSelectedOrg = organisations?.find((org: any) => {
      return selected.id === org.id;
    });

    const id = belongsToSelectedOrg ? selected.id : organisations[0].id;
    ({ data: updatedUser } = await Api.get(`/v4/organisations/${id}/users/me`));
  }

  // Updated language of dashboard if it's been changed in the payload
  if (payload.language_locale) i18n.changeLanguage(payload.language_locale);

  return dispatch({
    type: UPDATE_PROFILE,
    user: updatedUser, // Can be undefined if user isn't initialised yet
  });
};
