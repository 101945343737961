import React, { useCallback, useEffect } from 'react';
import { Switch, Redirect, useHistory } from 'react-router';
import { connect } from 'react-redux';
import Route from './common/components/route';
import LoginTemplate from './modules/authentication/login-template';
import AuthTemplate from './modules/authentication/template';
import FreeTrialContainer from './modules/authentication/containers/free-trial';
import CoreTemplate from './modules/core/template';

const RootRouter = ({ isLoggedIn, user }) => {
  const userId = user?.id;
  const updateIntercomUserLocation = useCallback(() => {
    if (window.Intercom && userId) {
      window.Intercom('update', { user_id: userId });
    }
  }, [userId, window.Intercom]);

  const history = useHistory();
  useEffect(() => {
    updateIntercomUserLocation();
    const unlisten = history.listen(() => {
      updateIntercomUserLocation();
    });
    return unlisten;
  }, [history, updateIntercomUserLocation]);
  return (
    <Switch>
      <Route path={['/auth/login', '/auth/forgot', '/auth/unsubscribe']} component={LoginTemplate} />
      <Route path={['/auth', '/signup']} component={AuthTemplate} />
      <Route path="/free-trial" component={FreeTrialContainer} />
      {isLoggedIn && <Route path="/" component={CoreTemplate} />}
      <Redirect to="/auth/login" />
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: !!state.loggedUser.isLoggedIn,
  user: state.loggedUser?.user
});

export default connect(mapStateToProps)(RootRouter);
