import { useRef, useEffect } from 'react';

export const useDidComponentMount = () => {
  const didMount = useRef(false);

  useEffect(() => {
    didMount.current = true;

    return () => {
      didMount.current = false;
    };
  }, []);

  return didMount.current;
};
