import React, { Component } from 'react';
import * as R from 'ramda';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Observable } from 'rxjs/Observable';
import Icon from '../icon';
import { combineClassNames } from '../../utils/combineClassNames';
// import { Sticky } from 'semantic-ui-react';

type SearchType = string | null;

export type Props = WithTranslation & {
  debounce: boolean;
  filter: boolean;
  onSearch: (search: SearchType) => void;
  filters?: string[];
  removeFilter?: (type: string) => void;
  defaultFocus?: boolean;
  value?: string;
  defaultValue?: string;
  placeholder?: string;
  className?: string;
};

type State = {
  search: string | null,
};

class UnconnectedSearchBar extends Component<Props, State> {
  static props: Props;

  static defaultProps = {
    debounce: true,
    filter: true,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      search: props.defaultValue || '',
    };

    // @ts-expect-error
    this.search$ = null;
  }

  componentDidMount() {
    const { debounce, filter, onSearch } = this.props;

    Observable
      // @ts-expect-error
      .create((observable) => (this.search$ = observable))
      .debounceTime(debounce ? 500 : 0)
      // @ts-expect-error
      .filter((search) => (filter ? search === null || search.length >= 3 : true))
      // @ts-expect-error
      .map((search) => {
        this.setState({ search });

        return search;
      })
      .subscribe(onSearch);
  }

  handleSearch: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const { search: oldSearch } = this.state;
    const { filter } = this.props;
    const search = e.target.value;

    // @ts-expect-error
    this.search$?.next(oldSearch && search.length < 3 && oldSearch.length >= 3 && filter ? null : search);
  };

  render() {
    const {
      t,
      className,
      defaultFocus,
      filters,
      removeFilter,
      placeholder,
      ...props
    } = this.props;

    return (
      <div role="search" className={combineClassNames('Form SearchBar', className)}>
        <Icon type="search" />
        {filters && filters.map((filter) => (
          <div className="SearchBar__Filter" key={filter}>
            {filter}
            <Icon type="close" onClick={removeFilter && (() => removeFilter(filter))} />
          </div>
        ))}
        <input
          ref={defaultFocus ? ((ref) => ref && ref.focus()) : undefined}
          className="Form__control"
          onChange={this.handleSearch}
          placeholder={placeholder || t('common:search_bar_placeholder')}
          onKeyPress={(e) => {
            if (e.key === 'Enter') e.preventDefault();
          }}
          {...R.omit(['onSearch', 'filter', 'debounce', 'onChange', 'tReady', 'i18n'], props)}
        />
      </div>
    );
  }
}

// TODO https://flex-appeal.atlassian.net/browse/PD-8098
// const UnconnectedStickySearchBar = (props: any) => {
//   return <Sticky>
//   </Sticky>;
// };
// export const StickySearchBar = withTranslation()(UnconnectedSearchBar);

export const SearchBar = withTranslation()(UnconnectedSearchBar);
