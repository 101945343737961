import React from 'react';
import moment from 'moment';
import { getTrans } from '@common/utils/i18n';
import i18n from '../../../i18n';
import { EMessageTypes, EConversationActivityTypes } from '../definitions';

const nameWrapper = (userName) => {
  if (userName) {
    return <>arbitrary content that will get ignored</>;
  }
  return <i />; // we want the 'chat:deleted_user' to be in italics
};

const nameLabel = (userName) => {
  if (userName) {
    return userName;
  }
  return i18n.t('chat:deleted_user');
};

const conversationCreated = getTrans((actor, actorId, loggedUserId) => {
  if (actorId === loggedUserId) {
    return { i18nKey: 'chat:activity_text_conversation_created_self' };
  }
  return {
    i18nKey: 'chat:activity_text_conversation_created',
    values: { actorFullName: nameLabel(actor?.full_name) },
    components: [nameWrapper(actor?.full_name)]
  };
});

const changedName = getTrans((actor, activity, actorId, loggedUserId) => {
  const name = activity?.meta_data?.new_name || '';
  if (actorId === loggedUserId) {
    return {
      i18nKey: 'chat:activity_text_changed_name_self',
      values: { name }
    };
  }
  return {
    i18nKey: 'chat:activity_text_changed_name',
    values: {
      name,
      actorFirstName: nameLabel(actor?.first_name)
    },
    components: [nameWrapper(actor?.first_name)]
  };
});

const userLeft = getTrans((userId, loggedUserId, user) => {
  if (userId === loggedUserId) {
    return { i18nKey: 'chat:activity_text_user_left_self' };
  }
  return {
    i18nKey: 'chat:activity_text_user_left',
    values: { actorFirstName: nameLabel(user?.first_name) },
    components: [nameWrapper(user?.first_name)]
  };
});

const userAdded = getTrans((userId, loggedUserId, actor, actorId, user) => {
  if (userId === loggedUserId) {
    return {
      i18nKey: 'chat:activity_text_user_added_you',
      values: { actorFirstName: nameLabel(actor?.first_name) },
      components: [nameWrapper(actor?.first_name)]
    };
  }
  if (actorId === loggedUserId) {
    return {
      i18nKey: 'chat:activity_text_user_added_self',
      values: { userFirstName: nameLabel(user?.first_name) },
      components: [nameWrapper(user?.first_name)]
    };
  }
  return {
    i18nKey: 'chat:activity_text_user_added',
    values: {
      actorFirstName: nameLabel(actor?.first_name),
      userFirstName: nameLabel(user?.first_name)
    },
    components: [
      nameWrapper(actor?.first_name),
      nameWrapper(user?.first_name)
    ]
  };
});

const userRemoved = getTrans((userId, loggedUserId, actor, user) => {
  if (userId === loggedUserId) {
    return {
      i18nKey: 'chat:activity_text_user_removed_you',
      values: { actorFirstName: nameLabel(actor?.first_name) },
      components: [nameWrapper(actor?.first_name)]
    };
  }
  if (actor.id === loggedUserId) {
    return {
      i18nKey: 'chat:activity_text_user_removed_self',
      values: { userFirstName: nameLabel(user?.first_name) },
      components: [nameWrapper(user?.first_name)]
    };
  }
  return {
    i18nKey: 'chat:activity_text_user_removed',
    values: {
      actorFirstName: nameLabel(actor?.first_name),
      userFirstName: nameLabel(user?.first_name)
    },
    components: [
      nameWrapper(actor?.first_name),
      nameWrapper(user?.first_name)
    ]
  };
});

const adminAdded = getTrans((userId, loggedUserId, user) => {
  if (userId === loggedUserId) {
    return { i18nKey: 'chat:activity_text_admin_added_self' };
  }
  return {
    i18nKey: 'chat:activity_text_admin_added',
    values: { userFirstName: nameLabel(user?.first_name) },
    components: [nameWrapper(user?.first_name)]
  };
});

export const createTextForActivity = (activity, loggedUserId) => {
  const {
    actor_id: actorId, user_id: userId, actor, user
  } = activity.meta_data;

  switch (activity.activity_type) {
    case EConversationActivityTypes.CONVERSATION_CREATED:
      return conversationCreated(actor, actorId, loggedUserId);
    case EConversationActivityTypes.CHANGED_NAME:
      return changedName(actor, activity, actorId, loggedUserId);
    case EConversationActivityTypes.USER_LEFT:
      return userLeft(userId, loggedUserId, user);
    case EConversationActivityTypes.USER_ADDED:
      return userAdded(userId, loggedUserId, actor, actorId, user);
    case EConversationActivityTypes.USER_REMOVED:
      return userRemoved(userId, loggedUserId, actor, user);
    case EConversationActivityTypes.ADMIN_ADDED:
      return adminAdded(userId, loggedUserId, user);
    default:
      return activity.activity_type;
  }
};

let tempId = 0;

export const createActivity = (type, metaData) => ({
  type: EMessageTypes.ACTIVITY,
  activity_type: type,
  source_type: null,
  object_id: `o${tempId++}`,
  id: `a${tempId++}`,
  created_at: moment().toISOString(),
  meta_data: metaData,
});
