import PropTypes from 'prop-types';
import React from 'react';

export const Spinner = ({ className, size = 'default', centered = false }) => {
  let containerClassName = 'Spinner__container';
  containerClassName += ` Spinner--size-${size}`;

  if (centered) containerClassName += ' Spinner__container-centered';
  if (className) containerClassName += ` ${className}`;

  return (
    <div className={containerClassName}>
      <div className="Spinner">
        <div className={`Spinner__dot Spinner__dot--first Spinner__dot--size-${size}`} />
        <div className={`Spinner__dot Spinner__dot--second Spinner__dot--size-${size}`} />
        <div className={`Spinner__dot Spinner__dot--third Spinner__dot--size-${size}`} />
      </div>
    </div>
  );
};

Spinner.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  centered: PropTypes.bool,
};

export default Spinner;
