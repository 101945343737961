import React from 'react';

import Icon from '@common/components/icon';
import { Message } from '@common/components/form/message';

import { combineClassNames } from '@utils/combineClassNames';

import './checkbox.scss';

export type CheckboxOwnProps = {
  value: boolean;
  label?: string | JSX.Element;
  description?: string | JSX.Element;
  size?: 'default' | 'large';
  position?: 'left' | 'right';
  values?: string[];
  reverse?: boolean;
  group?: boolean;
  error?: string | boolean;
  disabled?: boolean;
  className?: string;
  onChange?: (value: boolean) => void;
};

export const Checkbox = ({
  value,
  label,
  description,
  size = 'default',
  position = 'left',
  error,
  disabled,
  className,
  onChange,
}: CheckboxOwnProps) => {
  const classNames = combineClassNames('Form__Checkbox', `Form__Checkbox--${size}`, className, {
    'Form__Checkbox--invalid': !!error,
  });
  const inputClassName = combineClassNames('Form__Checkbox__input', {
    'Form__Checkbox__input--checked': value,
  });

  return (
    <>
      <div
        className={classNames}
        disabled={disabled}
        onClick={() => !disabled && onChange && onChange(!value)}
      >
        {position === 'left' && (
          <div className={inputClassName}>
            {value && <Icon type="check" />}
          </div>
        )}
        {label && (
          <div className="Form__Checkbox__label">
            {label}
            {description && <small>{description}</small>}
          </div>
        )}
        {position === 'right' && (
          <div className={inputClassName}>
            {value && <Icon type="check" />}
          </div>
        )}
      </div>
      <Message error={error} />
    </>
  );
};
