import * as React from 'react';
import { Provider } from 'react-redux';
import Toastr from 'react-redux-toastr';
import RootRouter from '../shared/routes';

const fetchInfo = async (store) => {
  try {
    const infoResponse = await fetch(process.env.INFO_URL || 'https://assets.oneteam.io/info.json', {
      method: 'GET',
      cache: 'no-cache',
      headers: {
        Accept: 'application/json',
      },
    });
    const info = await infoResponse.json();

    store.dispatch({
      type: 'INFO',
      ...info,
    });
  } catch (err) {
    // Do nothing
  }
};

const App = ({ store }) => {
  React.useEffect(() => {
    fetchInfo(store);
  });

  return (
    <Provider store={store}>
      <>
        <RootRouter />
        <Toastr
          timeOut={3000}
          position="top-center"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
        />
      </>
    </Provider>
  );
};

export default App;
