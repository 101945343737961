export default (() => ({
  subscribe(userId) {
    window.OneSignal.push(async () => {
      await window.OneSignal.setSubscription(true);

      const externalUserId = await window.OneSignal.getExternalUserId();

      if (!externalUserId || externalUserId !== userId) this.setExternalUserId(userId);
    });
  },
  unsubscribe() {
    window.OneSignal.push(() => {
      window.OneSignal.setSubscription(false);
      window.OneSignal.removeExternalUserId();
    });
  },
  initialise(userId) {
    window.OneSignal.push(async () => {
      // Check if we have permission, if not ask for it
      const permission = await window.OneSignal.getNotificationPermission();

      if (permission !== 'granted') this.askPermission();

      // Subscribe to notifications and update external id
      this.subscribe(userId);
    });
  },
  askPermission() {
    window.OneSignal.push(() => window.OneSignal.showNativePrompt());
  },
  setExternalUserId(userId) {
    window.OneSignal.push(async () => {
      window.OneSignal.setExternalUserId(userId);
    });
  },
  addListenerForOpened(callback) {
    window.OneSignal.push(['addListenerForNotificationOpened', (data) => {
      callback(data);

      // Add a new listener
      this.addListenerForOpened(callback);
    }]);
  },
}))();
