import React, { Component } from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import Api from '../../../../services/api';
import SearchableList from '../../../list/search';
import Item from './item';

class UsersInput extends Component {
  static propTypes = {
    input: PropTypes.shape({
      value: PropTypes.object.isRequired,
      onChange: PropTypes.func.isRequired,
    }),
    isUserSelected: PropTypes.func.isRequired,
    network: PropTypes.object,
    organisation: PropTypes.object,
    disabled: PropTypes.bool,
    isDisabled: PropTypes.func,
  };

  constructor() {
    super();

    this.checkIfUserIsSelected = this.checkIfUserIsSelected.bind(this);
    this.handleRequest = this.handleRequest.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  handleRequest(offset, filter, limit = 15) {
    const { network, organisation } = this.props;

    const query = Api.utils.toQuery({
      offset,
      limit,
      q: filter.query,
    });

    return network
      ? Api.get(`/v2/networks/${network.id}/users?${query}`)
      : Api.get(`/v2/organisations/${organisation.id}/users?${query}`);
  }

  checkIfUserIsSelected(item) {
    const { input: { value }, isUserSelected } = this.props;

    if (R.contains(item.id, value.remove || [])) return false;

    return R.contains(item.id, value.add || []) || isUserSelected(item);
  }

  handleAdd(userId) {
    const value = { ...this.props.input.value };

    if (R.contains(userId, value.remove || [])) {
      // User has been removed before, but it hasn't been saved yet
      value.remove = [...R.reject(R.equals(userId), value.remove)];
    } else {
      value.add = R.append(userId, [...value.add]);
    }

    this.props.input.onChange(value);
  }

  handleRemove(userId) {
    const value = { ...this.props.input.value };

    if (R.contains(userId, value.add || [])) {
      // User has been added before, but it hasn't been saved yet
      value.add = [...R.reject(R.equals(userId), value.add)];
    } else {
      value.remove = R.append(userId, [...value.remove]);
    }

    this.props.input.onChange(value);
  }

  render() {
    const { disabled, isDisabled } = this.props;

    return (
      <div className="Form__UsersInput">
        <SearchableList
          data={{
            onFetch: this.handleRequest,
          }}
          renderRow={Item}
          rowProps={{
            disabled,
            isDisabled,
            isSelected: this.checkIfUserIsSelected,
            onAdd: this.handleAdd,
            onRemove: this.handleRemove,
          }}
        />
      </div>
    );
  }
}

export default (props) => <Field {...props} component={UsersInput} />;
