import {
  ACADEMY_RECEIVE_STATISTICS,
  ACADEMY_OPEN_SEGMENT,
} from '../actions';

export default (state = { filter: null, segment: null }, action) => {
  switch (action.type) {
    case ACADEMY_RECEIVE_STATISTICS:
      return { ...state, filter: action.networkId };
    case ACADEMY_OPEN_SEGMENT:
      return { ...state, segment: action.segment };
    default: return state;
  }
};
