import { combineReducers } from 'redux';
import * as R from 'ramda';
import { SOCIAL_RECEIVE_MEMBERS } from '../../social/actions';
import {
  ADMIN_REINVITE_USERS,
  ORGANISATION_RESEND_INVITATION,
  ADMIN_RECEIVE_USER_COUNTS,
  ADMIN_APPROVE_ACCESS_REQUEST,
  ADMIN_REJECT_ACCESS_REQUEST,
  ORGANISATION_RESTORE_USER,
} from '../../admin/actions';
import {
  ORGANISATION_RECEIVE_USERS,
  ORGANISATION_INVITE_USER,
  ORGANISATION_DELETE_USER,
  ORGANISATION_FILTER_USERS,
} from '../actions';
import { ETimelineTypes } from '../../social/definitions';

const items = (state = [], action) => {
  switch (action.type) {
    case ORGANISATION_RECEIVE_USERS: {
      const concatWithState = action.strategy === 'append' ? R.concat(state) : R.identity;

      return R.pipe(R.pluck('id'), concatWithState, R.uniq)(action.items);
    }
    case ORGANISATION_INVITE_USER:
      if (action.existing) return state;

      return R.prepend(action.user.id, state);
    case ORGANISATION_DELETE_USER:
      return R.reject(R.equals(action.userId), state);
    case ORGANISATION_FILTER_USERS:
    case ADMIN_REINVITE_USERS:
      return [];
    case ORGANISATION_RESEND_INVITATION:
      if (action.removeFromList) return R.reject(R.equals(action.userId), state);

      return state;
    case ORGANISATION_RESTORE_USER:
      return state.filter((id) => id !== action.userId);
    default: return state;
  }
};

const counts = (state = {
  total: 0,
  active: 0,
  inactive: 0,
  not_logged_in: 0,
  not_invited: 0,
  access_requests: 0,
  deleted: 0
}, action) => {
  switch (action.type) {
    case ADMIN_RECEIVE_USER_COUNTS:
      return action.counts;
    case ORGANISATION_INVITE_USER:
      if (action.existing) return state;

      return {
        ...state,
        total: (state.total || 0) + 1,
        not_logged_in: (state.not_logged_in || 0) + 1,
      };
    case SOCIAL_RECEIVE_MEMBERS:
      if (action.channel.type !== ETimelineTypes.ORGANISATION) return state;

      return R.assoc('total', action.pagination.total_count, state);
    case ADMIN_REINVITE_USERS:
      if (action.filter === 'not_logged_in') return state;

      return {
        ...state,
        not_logged_in: state.not_logged_in + state[action.filter],
        [action.filter]: 0,
      };
    case ORGANISATION_RESEND_INVITATION:
      if (!action.removeFromList) return state;

      return {
        ...state,
        not_logged_in: state.not_logged_in + 1,
        not_invited: state.not_invited - 1,
      };
    case ADMIN_APPROVE_ACCESS_REQUEST:
    case ADMIN_REJECT_ACCESS_REQUEST:
      return {
        ...state,
        access_requests: state.access_requests - 1,
      };
    case ORGANISATION_RESTORE_USER:
      const newDeletedCount = state.deleted - 1;
      return {
        ...state,
        deleted: newDeletedCount >= 0 ? newDeletedCount : 0
      };
    default: return state;
  }
};

export default combineReducers({ items, counts });
