import { combineReducers } from 'redux';
import * as R from 'ramda';

import { predicatesToFilters, EPredicateFields } from '@common/utils/predicates';
import * as reducerUtils from '@common/utils/reducer';
import {
  SURVEYS_RECEIVE_SURVEYS,
  SURVEYS_RECEIVE_SURVEY,
  SURVEYS_CREATE_QUESTION,
  SURVEYS_CREATE_SURVEY,
  SURVEYS_UPDATE_SURVEY,
  SURVEYS_UPDATE_SCREEN,
  SURVEYS_DELETE_QUESTION,
  SURVEYS_DELETE_SURVEY,
  SURVEYS_ARCHIVE_SURVEY,
  SURVEYS_UPDATE_SCREEN_ORDER,
  SURVEYS_SAVE_SCREEN_ORDER,
  SURVEYS_COMPLETE_SURVEY,
  SURVEYS_RECEIVE_SURVEY_RESPONSE,
  SURVEYS_RECEIVE_REPORT,
  SURVEYS_RECEIVE_SURVEY_TEMPLATES,
} from '../actions';
import * as usersReducer from '../../core/reducers/users';
import * as screensReducer from '../../learning/reducers/screens';
import { ESurveyStatus } from '../definitions';

const transformObj = (item) => ({
  ...R.omit(['screens'], item),
  screen_ids: item.screens ? R.pluck('id', item.screens) : [],
});

const ids = (state = [], action) => {
  switch (action.type) {
    case SURVEYS_RECEIVE_SURVEYS: {
      const concatWithState = action.strategy === 'append' ? R.concat(state) : R.identity;

      return R.pipe(R.pluck('id'), concatWithState, R.uniq)(action.items);
    }
    case SURVEYS_CREATE_SURVEY:
      return R.prepend(action.item.id, state);
    case SURVEYS_DELETE_SURVEY:
      return R.reject(R.equals(action.id), state);
    case SURVEYS_ARCHIVE_SURVEY:
    case SURVEYS_COMPLETE_SURVEY:
      if (!action.removeFromList) return R.clone(state);

      return R.reject(R.equals(action.id), state);
    default:
      return state;
  }
};

const items = (state = {}, action) => {
  switch (action.type) {
    case SURVEYS_RECEIVE_SURVEYS:
      return R.reduce((acc, item) => R.assoc(item.id, { ...acc[item.id], ...item }, acc), state, action.items);
    case SURVEYS_CREATE_SURVEY:
    case SURVEYS_RECEIVE_SURVEY:
    case SURVEYS_UPDATE_SURVEY:
      return {
        ...state,
        [action.item.id]: transformObj(action.item),
      };
    case SURVEYS_DELETE_QUESTION:
      return reducerUtils.update(
        action.surveyId, (survey) => R.evolve({ screen_ids: R.reject(R.equals(action.id)) }, survey), state);
    case SURVEYS_CREATE_QUESTION:
      return reducerUtils.update(
        action.surveyId, (survey) => R.evolve({ screen_ids: R.append(action.screen.id) }, survey), state);
    case SURVEYS_UPDATE_SCREEN:
      return reducerUtils.update(action.surveyId, R.assoc('updated', new Date()), state);
    case SURVEYS_UPDATE_SCREEN_ORDER:
    case SURVEYS_SAVE_SCREEN_ORDER:
      return R.evolve({ [action.surveyId]: R.clone }, state);
    case SURVEYS_COMPLETE_SURVEY:
      return R.evolve({
        [action.id]: R.evolve({
          status: () => ESurveyStatus.COMPLETED,
        }),
      }, state);
    default:
      return state;
  }
};

const counts = (state = {}, action) => {
  switch (action.type) {
    case SURVEYS_RECEIVE_SURVEYS:
      return R.omit(['pagination', 'related'], action.meta);
    case SURVEYS_ARCHIVE_SURVEY:
      return R.evolve({
        [`${action.filter || 'total'}_count`]: R.dec,
        archived_count: R.inc,
      }, state);
    case SURVEYS_COMPLETE_SURVEY:
      return R.evolve({
        live_count: R.dec,
        completed_count: R.inc,
      }, state);
    case SURVEYS_CREATE_SURVEY:
      return R.evolve({
        total_count: R.inc,
        draft_count: R.inc,
      }, state);
    case SURVEYS_DELETE_SURVEY:
      return R.evolve({
        total_count: R.dec,
        [`${action.filter || 'total'}_count`]: R.dec,
      }, state);
    default:
      return state;
  }
};

const responses = (state = {}, action) => {
  switch (action.type) {
    case SURVEYS_RECEIVE_SURVEY_RESPONSE:
      return R.assoc(action.item.id, action.item, state);
    default:
      return state;
  }
};

const reports = (state = {}, action) => {
  switch (action.type) {
    case SURVEYS_RECEIVE_REPORT:
      return R.assoc(action.surveyId, action.report, state);
    default:
      return state;
  }
};

const filters = (state = {}, action) => {
  switch (action.type) {
    case SURVEYS_RECEIVE_REPORT:
      return R.assoc(action.surveyId, action.filters, state);
    default:
      return state;
  }
};

const templates = (state = [], action) => {
  switch (action.type) {
    case SURVEYS_RECEIVE_SURVEY_TEMPLATES:
      return action.items;
    default:
      return state;
  }
};

const filterValid = (values) => values.filter((v) => !!v);

const getValues = (type, value, state) => (() => {
  switch (type) {
    case EPredicateFields.NETWORK:
      return filterValid(value.map((id) => state.networks.items[id]));
    case EPredicateFields.FUNCTION:
      return filterValid(value.map((id) => state.organisation.functions.items[id]));
    case EPredicateFields.USER:
      return filterValid(value.map((id) => state.users.items[id]));
    case EPredicateFields.LANGUAGE:
      return R.map((locale) => R.find(R.propEq('locale', locale), state.language.available), value);
    default:
      return null;
  }
})();

export const findById = (state, id) => {
  const item = state.survey.items[id];

  if (!item) return null;

  const survey = {
    ...item,
    created_by: usersReducer.findById(state, item.created_by),
    screens: R.pipe(
      R.map(screensReducer.findById(state)),
      R.reject(R.isNil),
      R.sortBy(R.prop('index')),
    )(item.screen_ids || []),
  };

  const predicateFilters = predicatesToFilters(item.audience?.predicates, state);

  return R.evolve({
    audience: {
      predicates: R.map((predicate) => R.assoc('context', getValues(predicate.attribute, predicate.value, state), predicate)),
    },
  }, { ...survey, filters: predicateFilters });
};

export default combineReducers({
  ids,
  items,
  responses,
  counts,
  reports,
  filters,
  templates,
});
