/* eslint-disable global-require, import/first, import/no-import-module-exports */

if (process.env.DEV) {
  require('react-hot-loader/patch');
}

// Polyfills
import '@babel/polyfill';
import 'whatwg-fetch';
import 'blueimp-canvas-to-blob';
import 'url-search-params-polyfill';

import 'moment/locale/nl';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { AppContainer } from 'react-hot-loader';
import * as R from 'ramda';
import cssVars from 'css-vars-ponyfill';
import * as qs from 'qs';
import Storage from '../shared/common/services/storage';
import Socket from '../shared/common/services/socket';
import memoize from '../shared/common/utils/memoize';
import * as i18n from '../shared/i18n';
import configureStore from '../shared/configure-store';
import * as ui from '../shared/modules/core/reducers/ui';

// Add Semantic UI css
// import 'semantic-ui-css/semantic.min.css';

// Add operators to Observable
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/filter';

// Set driver for storage service
Storage.setDriver(window.localStorage);

const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
if (query.refresh_token) Storage.setCookie('refresh_token', query.refresh_token, [10, 'years']);

// Setup locale
const storedLocale = Storage.get('fa_locale');
const browserLocale = (navigator.language || navigator.userLanguage || '').split('-');

// Force language to english when landing on free-trial page, else look at stored or browser locale
i18n.setup(window.location.pathname === '/free-trial' ? 'en' : (storedLocale || browserLocale[0]));

const App = require('./app').default;

require('../shared/common/styles/app.scss');

const hasNativeCssVarsSupport = window.CSS && window.CSS.supports && window.CSS.supports('(--a: 0)');

cssVars({
  onlyVars: true,
  watch: true,
  updateDOM: hasNativeCssVarsSupport,
  include: 'link[rel="stylesheet"][href^="/static"]',
  onComplete: (styles) => {
    const node = document.getElementById('css-var-polyfill') || document.createElement('style');

    node.id = 'css-var-polyfill'; // eslint-disable-line no-param-reassign
    node.appendChild(document.createTextNode(styles));

    document.body.appendChild(node);
  },
});

if (!process.env.NODE_ENV !== 'development') {
  require.ensure(['../shared/common/styles/base.scss'], () => require('../shared/common/styles/base.scss'), 'base');

  // For faster initial styling (background color, font etc.
  const { userAgent } = window.navigator;
  const IE = userAgent.indexOf('MSIE ') > -1 || userAgent.indexOf('Trident/') > -1;

  if (IE) {
    require.ensure(['../shared/common/styles/ie.scss'], () => require('../shared/common/styles/ie.scss'), 'ie');
  }
} else {
  require('../shared/common/styles/base.scss');
}

const network = Storage.getObject('currentNetwork');
const organisation = Storage.getObject('currentOrganisation');
const localState = Storage.getObject('localState');

// Create array from segments in url
const url = R.pipe(R.reject(R.equals('/')), R.drop(1))(window.location.pathname.split('/'));
const networkId = network ? network.id : null;

// If renderer from server set preloaded state, else read from localStorage
const preloadedState = window.__PRELOADED_STATE__ && window.__PRELOADED_STATE__.organisation.selected
  ? window.__PRELOADED_STATE__
  : {
    network: { selected: url[0] === 'networks' ? url[1] : networkId },
    organisation: { selected: organisation || null },
    networks: { items: network ? { [network.id]: network } : {} },
    loggedUser: { isLoggedIn: !!Storage.getCookie('refresh_token') },
    dismissed: {
      survey_release: !!Storage.getCookie('dismiss_survey_release'),
    },
  };

// Garbage collect preloaded state
delete window.__PRELOADED_STATE__;

// Create store
const store = configureStore({
  ...preloadedState,
  ui: ui.mapLocalStorageToState(localState),
});

// Initialise services/utils
memoize.setStore(store);
Socket.connect(store);

ReactDOM.render(
  <AppContainer>
    <BrowserRouter>
      <App store={store} />
    </BrowserRouter>
  </AppContainer>,
  document.getElementById('root'),
);

if (module.hot) {
  module.hot.accept('./app', () => {
    const NextApp = require('./app').default; // eslint-disable-line global-require

    ReactDOM.render(
      <AppContainer>
        <BrowserRouter>
          <NextApp store={store} />
        </BrowserRouter>
      </AppContainer>,
      document.getElementById('root'),
    );
  });
}
