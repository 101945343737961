import * as R from 'ramda';
import { combineReducers } from 'redux';
import * as reducerUtil from '../../../common/utils/reducer';
import { CHAT_RECEIVE_MESSAGES, CHAT_ADD_MESSAGE } from '../actions';

const incrementTotalCount = (pagination) => ({
  ...pagination,
  total_count: pagination.total_count + 1,
  offset: pagination.offset + 1,
});

const messages = (state = {}, action) => {
  switch (action.type) {
    case CHAT_RECEIVE_MESSAGES:
      return R.assoc(action.conversationId, action.meta.pagination, state);
    case CHAT_ADD_MESSAGE:
      return state[action.conversationId]
        ? reducerUtil.update(action.conversationId, incrementTotalCount, state) : state;
    default: return state;
  }
};

export const findByConversation = R.curry((state, id) => state.chat.pagination.messages[id] || {});

export default combineReducers({ messages });
