import { combineReducers } from 'redux';
import * as R from 'ramda';
import { SELECT_NETWORK } from '../../network/actions';
import {
  RECEIVE_SCHEDULES,
  UPLOAD_SCHEDULE,
  DELETE_SCHEDULE,
} from '../actions';

const removeOldSchedule = (schedule, newSchedule) => !(schedule.function_id === newSchedule.week && schedule.week === newSchedule.week);

const items = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_SCHEDULES:
      return action.items;
    case UPLOAD_SCHEDULE:
      return [
        ...state.filter((schedule) => removeOldSchedule(schedule, action.schedule)),
        action.schedule,
      ];
    case DELETE_SCHEDULE:
      return R.reject((schedule) => schedule.id === action.scheduleId, state);
    case SELECT_NETWORK: return [];
    default:
      return state;
  }
};

export default combineReducers({ items });
