import Api from '../../../common/services/api';
import { createActivity } from '../utils/activity';
import { EConversationActivityTypes } from '../definitions';

export const CHAT_ADD_PARTICIPANTS = 'chat/ADD_PARTICIPANTS';

export default (conversationId, users) => async (dispatch, getState) => {
  const { loggedUser: { user: { id: loggedUserId } }, organisation: { selected } } = getState();

  const userIds = users.map((user) => user.id);

  await Api.put(`/v3/organisations/${selected.id}/conversations/${conversationId}/participants`, {
    add: userIds,
  });

  return dispatch({
    type: CHAT_ADD_PARTICIPANTS,
    conversationId,
    userIds,
    activities: userIds.map((userId) => createActivity(EConversationActivityTypes.USER_ADDED, {
      actor_id: loggedUserId,
      user_id: userId,
    })),
    related: {
      users,
    },
  });
};
