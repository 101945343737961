import { combineReducers } from 'redux';
import * as R from 'ramda';
import {
  SOCIAL_RECEIVE_FEED,
  SOCIAL_RECEIVE_SCHEDULED_MESSAGES,
  SOCIAL_DELETE_MESSAGE,
  SOCIAL_POST_MESSAGE,
  SOCIAL_EDIT_MESSAGE,
} from '../actions';

const items = (state = [], action) => {
  switch (action.type) {
    case SOCIAL_RECEIVE_SCHEDULED_MESSAGES:
      return action.append
        ? [...state, ...R.pluck('source_id', action.items)]
        : R.pluck('source_id', action.items);
    case SOCIAL_DELETE_MESSAGE:
      return R.reject(R.equals(action.messageId), state);
    case SOCIAL_EDIT_MESSAGE:
      if (!action.scheduled) return state;

      return [...state];
    default: return state;
  }
};

const count = (state = 0, action) => {
  switch (action.type) {
    case SOCIAL_RECEIVE_FEED:
      return action.scheduledCount || 0;
    case SOCIAL_DELETE_MESSAGE:
      if (!action.scheduled || state === 0) return state;

      return state - 1;
    case SOCIAL_POST_MESSAGE:
      if (!action.scheduled) return state;

      return state + 1;
    default: return state;
  }
};

export default combineReducers({ items, count });
