import React from 'react';
import { parse } from 'qs';
import { Trans, withTranslation } from 'react-i18next';
import { pageWrapper, EEventNames } from '../../../../../client/analytics';
import Icon from '../../../../common/components/icon';
import Tooltip from '../../../../common/components/tooltip';
import { Button } from '../../../../common/components/button';
import Alert from '../../../../common/components/alert';
import { createTrial } from '../../actions';

const testimonials = [{
  text: 'Thanks to Oneteam we have one central platform with which all employees in all 18 stores can communicate together. We love using Oneteam because it is an all-in-one employee platform. Onboarding, realtime schedules, and shift changes: it is all in there!',
  name: 'Eveline Burger',
  title: 'Communication specialist, Goedhart',
  image: '/static/images/testimonials/eveline_burger.png',
}, {
  text: 'Thanks to Oneteam we are fully in control with regards to our internal communications. With one click on a button, we can efficiently reach our 2.200 employees across 15 stores. We have way more clarity now. This would never have been possible with email and WhatsApp. An absolute necessity, espicially in those special times.',
  name: 'Anrico Maat',
  title: 'Maripaan Group (Jumbo)',
  image: '/static/images/testimonials/anrico.png',
}, {
  text: 'We chose Oneteam for the right combination of features. Internal communications and onboarding: It is all in there. Features such as the possibility to instantly view your schedule and change shifts add so much value to both managers and employees. They love using Oneteam.',
  name: 'Nathalie Thuijls',
  title: 'Communication specialist, Humphrey\'s Restaurants',
  image: '/static/images/testimonials/nathalie.png',
}];

const FreeTrialContainer = ({ history, location, t }) => {
  const query = parse(location.search, { ignoreQueryPrefix: true });

  const [value, setValue] = React.useState(query.email || '');
  const [hasFocused, setHasFocused] = React.useState(false);
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [hasError, setError] = React.useState(false);
  const [sliderIndex, setIndex] = React.useState(0);

  const isValid = /@(?!(me|mac|icloud|gmail|googlemail|hotmail|live|msn|outlook|yahoo|ymail|aol)\.)/.test(value.toLowerCase());

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isValid) return;

    setSubmitting(true);

    try {
      await createTrial(value);

      history.replace(`/auth/mail-received?email=${encodeURIComponent(value)}`);
    } catch (err) {
      setError(true);
      setSubmitting(false);
    }
  };

  // Slider loop
  React.useEffect(() => {
    // If redirect by website and known email is available, instanly submit form
    const timer = setInterval(() => setIndex(sliderIndex === testimonials.length - 1 ? 0 : sliderIndex + 1), 4000);

    return () => {
      // Clean up the subscription
      clearInterval(timer);
    };
  });

  return (
    <div id="Login" className="FreeTrial">
      <div className="Authentication__Container">
        <div className="Authentication__Form">
          <img className="Authentication__Form__Logo" src="/static/images/logo.png" alt="Oneteam" />

          <h2><Trans i18nKey="authentication:start_free_trial" /></h2>
          <div className="Authentication__Form__Description"><Trans i18nKey="authentication:enter_business_address" /></div>

          <form className="Form" onSubmit={handleSubmit}>
            {hasError && (
              <Alert type="warning" title={t('authentication:error_creating_trial')}>
                <Trans i18nKey="authentication:error_creating_trial_description" components={[<a onClick={() => window.Intercom && window.Intercom('show')} />]} />
              </Alert>
            )}
            <div className="Form__Row">
              <div className="Form__Group">
                <input
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  onFocus={() => setHasFocused(true)}
                  placeholder={t('authentication:label_email')}
                  type="email"
                  autoComplete="username"
                  className={(isValid || !hasFocused) ? 'Form__control' : 'Form__control Form__control--invalid'}
                />
              </div>
            </div>
            <Button type="primary" size="fill" buttonType="submit" disabled={!isValid} isLoading={isSubmitting}><Trans i18nKey="authentication:start_trial" /></Button>
          </form>
          <div className="Authentication__Form__Consent">
            <Trans i18nKey="authentication:trial_consent" components={[<a href={t('authentication:consent_url')} target="_blank" rel="noreferrer" />]} />
          </div>
          <div className="Authentication__Form__Action">
            <center>
              <Trans i18nKey="authentication:already_have_account" components={[<a href="/auth/login" />]} />
            </center>
          </div>
        </div>
        <Tooltip
          placement="top"
          title={t('authentication:login_encrypted_tooltip')}
        >
          <div className="Authentication__Form__Secure">
            <Icon type="lock" />
            <Trans i18nKey="authentication:secure_connection" />
          </div>
        </Tooltip>
      </div>
      <div className="FreeTrial__Container">
        <div className="FreeTrial__Slider">
          <div className="FreeTrial__Slider__Container">
            {testimonials.map((testimonial, i) => (
              <div
                key={testimonial.name}
                className={`FreeTrial__Slider__Slide${i === sliderIndex ? ' FreeTrial__Slider__Slide--active' : ''}`}
              >
                <div className="FreeTrial__Slider__Slide__Quote">
                  “
                  {testimonial.text}
                  ”
                </div>
                <div className="FreeTrial__Slider__Slide__Author">
                  <div className="FreeTrial__Slider__Slide__Author__Image">
                    <img src={testimonial.image} alt={testimonial.name} />
                  </div>
                  <div>
                    <h5>{testimonial.name}</h5>
                    {testimonial.title}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="FreeTrial__Slider__Index">
            {testimonials.map((item, i) => (
              <div
                key={item.name}
                className={`FreeTrial__Slider__Index__Dot${i === sliderIndex ? ' FreeTrial__Slider__Index__Dot--active' : ''}`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(pageWrapper(EEventNames.VISITED_FREE_TRIAL_PAGE)(FreeTrialContainer));
