import * as React from 'react';
import { connect } from 'react-redux';
import { Switch, Redirect } from 'react-router';
import { Trans } from 'react-i18next';
import Route, { loadable } from '@common/components/route';
import OrganisationsContainer from './containers/organisations';
import { SignupContainer } from './containers/signup';
import * as organisationSelector from '../organisation/selectors/organisation';

const RegistrationContainer = loadable(() => import('./containers/registration'));
const OnboardingContainer = loadable(() => import('./containers/onboarding'));
const MailReceivedContainer = loadable(() => import('./containers/mail-received'));

require('./styles.scss');

const AuthenticationTemplate = ({ history, isLoggedIn, logout, ...props }) => {
  const organisation = window.organisation || (process.env.SERVER && props.organisation);

  const handleLogout = () => {
    logout();
    history.push('/auth/login');
  };

  return (
    <div id="Authentication">
      <div className="Authentication__TopBar">
        <div className="Container__Content Container__Content--horizontal">
          {(organisation) ? (
            <>
              {organisation.brand_icon && <img src={organisation.brand_icon} alt={organisation.name} />}
              <h3>{organisation.name}</h3>
            </>
          ) : (
            <img className="" src="/static/images/logo_large.png" alt="Oneteam" />
          )}
          <div className="Authentication__TopBar__Actions">
            {isLoggedIn && <a onClick={handleLogout}><Trans i18nKey="core:top_bar_logout" /></a>}
            <a onClick={() => window.Intercom && window.Intercom('show')}><Trans i18nKey="authentication:contact_helpdesk" /></a>
          </div>
        </div>
      </div>
      <div className="Authentication__Container">
        <Switch>
          <Route path="/signup" component={SignupContainer} />
          <Route path="/auth/mail-received" component={MailReceivedContainer} />
          {isLoggedIn && ( // Only available when logged in
            <>
              <Route path="/auth/registration" component={RegistrationContainer} />
              <Route path="/auth/onboarding/:organisationId" component={OnboardingContainer} />
              <Route path="/auth/organisations" component={OrganisationsContainer} />
            </>
          )}
          <Redirect to="/auth/login" />
        </Switch>
      </div>
      <div className="Authentication__Footer">
        <div className="Container__Content Container__Content--horizontal">
          Oneteam
          &nbsp;&copy;&nbsp;
          {new Date().getFullYear()}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.loggedUser.isLoggedIn,
  organisation: organisationSelector.selected(state),
});

const mapDispatchToProps = {
  logout: require('./actions/logout').default,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationTemplate);
