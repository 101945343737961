import { combineReducers } from 'redux';
import * as R from 'ramda';
import moment from 'moment';
import type {
  FetchFormsAction,
  FetchFormAction,
  CreateFormAction,
  UpdateFormAction,
  FetchTemplatesAction,
  FetchSubmissionsAction,
  FetchSubmissionAction,
  UpdateSubmissionStatusAction,
  CreateQuestionAction,
  UpdateScreenOrderAction,
  SaveScreenOrderAction,
  DuplicateScreenAction,
  DeleteQuestionAction,
  ArchiveFormAction,
  DuplicateFormAction,
  SaveAsTemplateAction,
  DeleteFormAction,
  DeleteSubmissionAction,
  UpdateModeratorsAction,
  SetUserFormNotificationSettingsAction,
  UnarchiveFormAction
} from './actions';
import {
  FormStored,
  FormCounts,
  FormSubmissionStored,
  FormTemplateStored,
} from './types';

export type Action =
  | FetchFormsAction
  | FetchFormAction
  | CreateFormAction
  | UpdateFormAction
  | FetchTemplatesAction
  | FetchSubmissionsAction
  | FetchSubmissionAction
  | UpdateSubmissionStatusAction
  | CreateQuestionAction
  | UpdateScreenOrderAction
  | DeleteQuestionAction
  | SaveScreenOrderAction
  | DuplicateScreenAction
  | UpdateFormAction
  | ArchiveFormAction
  | DuplicateFormAction
  | SaveAsTemplateAction
  | DeleteFormAction
  | DeleteSubmissionAction
  | UpdateModeratorsAction
  | SetUserFormNotificationSettingsAction
  | UnarchiveFormAction;

const ids = (state: string[] = [], action: Action) => {
  switch (action.type) {
    case 'forms/RECEIVE_FORMS': {
      if (action.strategy === 'append') {
        return [...state, ...action.items.map((item) => item.id)];
      }

      return action.items.map((item) => item.id);
    }
    case 'forms/RECEIVE_FORM':
    case 'forms/CREATE_FORM':
    case 'forms/DUPLICATE_FORM':
      return [action.item.id, ...state];
    case 'forms/ARCHIVE_FORM':
    case 'forms/DELETE_FORM':
    case 'forms/UNARCHIVE_FORM':
      // this logic removes the form from the forms list currently browsed
      return [...state].filter((id) => id !== action.formId);
    default:
      return state;
  }
};

const items = (state: Record<string, FormStored> = {}, action: Action) => {
  switch (action.type) {
    case 'forms/UPDATE_MODERATORS':
      const form = action?.response?.data;
      return {
        ...state,
        [form.id]: {
          ...state?.[form.id],
          settings: {
            ...state?.[form.id]?.settings,
            moderators: form.settings.moderators
          }
        }
      };
    case 'forms/RECEIVE_FORMS':
      return action.items.reduce((acc, item) => ({
        ...acc,
        [item.id]: item,
      }), state);
    case 'forms/RECEIVE_FORM':
    case 'forms/CREATE_FORM':
    case 'forms/UPDATE_FORM':
    case 'forms/DUPLICATE_FORM':
      return {
        ...state,
        [action.item.id]: {
          ...R.omit(['screens'], action.item),
          screens: undefined,
          screen_ids: action.item.screens.map(({ id }) => id) || [],
        },
      };
    case 'forms/CREATE_QUESTION':
      return {
        ...state,
        [action.formId]: {
          ...state[action.formId],
          // @ts-expect-error
          screen_ids: [...state[action.formId].screen_ids, action.screen.id],
        },
      };
    case 'forms/DELETE_QUESTION':
      return {
        ...state,
        [action.formId]: {
          ...state[action.formId],
          // @ts-expect-error
          screen_ids: state[action.formId].screen_ids.filter((id) => id !== action.id),
        },
      };
    case 'forms/ARCHIVE_FORM':
      return {
        ...state,
        [action.formId]: {
          ...state[action.formId],
          status: 'archived',
          archived_at: moment().utc().format(),
        },
      };
    case 'forms/UNARCHIVE_FORM':
      return {
        ...state,
        [action.formId]: {
          ...state[action.formId],
          status: 'draft',
          archived_at: null
        }
      };
    case 'forms/SAVE_SCREEN_ORDER':
      return {
        ...state,
        [action.formId]: {
          ...state[action.formId],
          screen_ids: action.order,
        },
      };
    case 'forms/DUPLICATE_SCREEN': {
      if (!state[action.formId] || !state[action.formId].screen_ids) return state;

      const duplicatedFromIndex = state[action.formId].screen_ids?.indexOf(action.duplicatedFromScreenId);
      if (typeof duplicatedFromIndex === 'undefined' || duplicatedFromIndex === -1) return state;

      const clonedScreenIds = [...(state[action.formId].screen_ids || [])];
      clonedScreenIds.splice(duplicatedFromIndex + 1, 0, action.screen.id);

      return {
        ...state,
        [action.formId]: {
          ...state[action.formId],
          screen_ids: clonedScreenIds,
        },
      };
    }
    case 'forms/DELETE_FORM':
      return R.omit([action.formId], state);
    case 'forms/DELETE_SUBMISSION':
      return {
        ...state,
        [action.formId]: {
          ...state[action.formId],
          responded_count: state[action.formId].responded_count - 1,
        },
      };
    case 'forms/SET_USER_FORM_NOTIFICATION_SETTINGS':
      return setUserFormNotificationSettings(state, action);
    default:
      return state;
  }
};

export function formatNotificationSettings(
  prevForm: any, userId: string, enabled: boolean
) {
  return {
    ...prevForm,
    settings: {
      ...prevForm?.settings,
      moderators: prevForm?.settings?.moderators?.map((user: any) => {
        // console.log("debug user?.user_id", user?.user_id, "userId", userId);
        if (user?.user_id === userId) {
          return { ...user, enabled_notifications: enabled };
        }
        return user;
      }) || []
    }
  };
}

function setUserFormNotificationSettings(
  state: Record<string, FormStored>,
  action: SetUserFormNotificationSettingsAction
) {
  const { formId, userId, enabled } = action;
  return {
    ...state,
    [formId]: formatNotificationSettings(state?.[formId], userId, enabled)
  };
}

const counts = (state: FormCounts = {
  archived_count: 0,
  draft_count: 0,
  live_count: 0,
  total_count: 0,
}, action: Action) => {
  switch (action.type) {
    case 'forms/RECEIVE_FORMS':
      return action.counts;
    case 'forms/CREATE_FORM':
    case 'forms/DUPLICATE_FORM':
      return {
        ...state,
        total_count: state.total_count + 1,
        draft_count: state.draft_count + 1,
      };
    case 'forms/DELETE_FORM':
      return {
        ...state,
        total_count: state.total_count - 1,
        [`${action.filter}_count`]: state[`${action.filter}_count`] - 1,
      };
    case 'forms/ARCHIVE_FORM':
      const countName: `${typeof action.previousStatus}_count` = `${action.previousStatus}_count`;

      return {
        ...state,
        total_count: state.total_count - 1,
        archived_count: state.archived_count + 1,
        [countName]: state[countName] - 1,
      };
    case 'forms/UNARCHIVE_FORM':
      return {
        ...state,
        total_count: state.total_count + 1,
        archived_count: state.archived_count - 1,
        draft_count: state.draft_count + 1
      };
    default:
      return state;
  }
};

const templates = (state: FormTemplateStored[] = [], action: Action) => {
  switch (action.type) {
    case 'forms/RECEIVE_TEMPLATES':
      return action.items;
    case 'forms/SAVE_AS_TEMPLATE':
      return [...state, action.item];
    default: return state;
  }
};

const submissionItems = (state: Record<string, FormSubmissionStored> = {}, action: Action) => {
  switch (action.type) {
    case 'forms/RECEIVE_SUBMISSIONS':
      return action.items.reduce((acc, item) => ({
        ...acc,
        [item.id]: item as FormSubmissionStored,
      }), state);
    case 'forms/UPDATE_SUBMISSION_STATUS':
      if (!state[action.submissionId]) return state;

      return {
        ...state,
        [action.submissionId]: {
          ...state[action.submissionId],
          response_status: action.status,
        },
      };
    case 'forms/DELETE_SUBMISSION':
      return R.omit([action.submissionId], state);
    default:
      return state;
  }
};

const submissionIds = (state: Record<string, string[]> = {}, action: Action) => {
  switch (action.type) {
    case 'forms/RECEIVE_SUBMISSIONS':
      if (action.strategy === 'append') {
        return {
          ...state,
          [action.formId]: [
            ...(state[action.formId] || []),
            ...action.items.map((item) => item.id),
          ],
        };
      }

      return {
        ...state,
        [action.formId]: action.items.map((item) => item.id),
      };
    case 'forms/DELETE_SUBMISSION':
      return {
        ...state,
        [action.formId]: [...state[action.formId]].filter((id) => id !== action.submissionId),
      };
    default:
      return state;
  }
};

const submissionDetails = (state = {}, action: Action) => {
  switch (action.type) {
    case 'forms/RECEIVE_SUBMISSION':
      return {
        ...state,
        [action.item.id]: {
          id: action.item.id,
          answers: action.item.answers,
          attachments: action.item.attachments,
          form: action.item.form,
          response_status_activities: action.item.response_status_activities
        },
      };
    default:
      return state;
  }
};

const submissionMeta = (state = {}, action: Action) => {
  switch (action.type) {
    case 'forms/RECEIVE_SUBMISSION':
      return {
        ...state,
        [action.item.id]: action.meta,
      };
    default:
      return state;
  }
};

const meta = (state = {}, action: Action) => {
  switch (action.type) {
    case 'forms/RECEIVE_FORM':
      // you should not provide the meta object to the dispatched action
      // if your action is not meant to change it, but do provide it when you
      // are fetching the form for the first time or updating some piece
      // of data that will change the meta object (eg form moderators)
      if (action.meta) {
        return {
          ...state,
          [action.item.id]: action.meta,
        };
      }
      return state;
    case 'forms/UPDATE_MODERATORS':
      return {
        ...state,
        [action?.response?.data?.id]: action?.response?.meta
      };
    default:
      return state;
  }
};

const submissions = combineReducers({
  items: submissionItems,
  ids: submissionIds,
  details: submissionDetails,
  meta: submissionMeta
});

export default combineReducers({
  ids,
  items,
  submissions,
  counts,
  templates,
  meta
});
