import { combineReducers } from 'redux';
import { SELECT_ORGANISATION } from '../../organisation/actions';
import messages from './messages';
import imports from './imports';
import accessRequests from './access-requests';

const reducers = combineReducers({ messages, imports, accessRequests });

export default (state, action) => {
  switch (action.type) {
    case SELECT_ORGANISATION: return reducers(undefined, action);
    default: return reducers(state, action);
  }
};
