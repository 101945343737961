import * as R from 'ramda';
import { ORGANISATION_INITIALISE } from '../../organisation/actions';
import { ORGANISATION_ADD_NETWORKS } from '../../admin/actions';

const permissions = (state = { organisation: [], networks: {} }, action) => {
  switch (action.type) {
    case ORGANISATION_INITIALISE:
      return {
        organisation: action.user.permissions,
        networks: reduceNetworksPermissions(action.networks)
      };
    case ORGANISATION_ADD_NETWORKS:
      return {
        ...state,
        networks: reduceNetworksPermissions(action.items)
      };
    default:
      return state;
  }
};

function reduceNetworksPermissions(networks) {
  return R.reduce(
    (acc, network) => R.assoc(network.id, network.permissions, acc),
    {},
    networks
  );
}

export default permissions;
