import { combineReducers } from 'redux';
import { SELECT_ORGANISATION } from '../../organisation/actions';
import conversations from './conversations';
import messages from './messages';
import attachments from './attachments';
import pagination from './pagination';

const reducer = combineReducers({ conversations, messages, attachments, pagination });

export default (state, action) => {
  switch (action.type) {
    case SELECT_ORGANISATION:
      return reducer(undefined, action);
    default: return reducer(state, action);
  }
};
