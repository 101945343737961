import { combineReducers } from 'redux';

import type { GetAllowedEmojisAction } from '../actions/allowed-emojis';
import type { AllowedEmoji } from '@common/types/objects';

export type AllowedEmojisActionTypes =
  | GetAllowedEmojisAction;

const allowedEmojiItemsReducer = (state: AllowedEmoji[] = [], action: AllowedEmojisActionTypes): typeof state => {
  switch (action.type) {
    case 'allowed-emojis/RECEIVE_ALLOWED_EMOJIS':
      return action.items;
    default:
      return state;
  }
};

export const allowedEmojisReducer = combineReducers({
  items: allowedEmojiItemsReducer,
});
