import { ROOT_KEY, PERSONAL_ROOT_KEY, SEARCH_KEY } from '../constants';

import type { FetchDocumentsFilters } from '../actions';

export const resolveFolderKey = (filters: FetchDocumentsFilters, userId?: string | null): string => {
  if (filters.searchTerm) return SEARCH_KEY;

  if (filters.folderId === 'personal' && userId) return `${PERSONAL_ROOT_KEY}-${userId}`;

  return filters.folderId || (userId && `${PERSONAL_ROOT_KEY}-${userId}`) || filters.filter || ROOT_KEY;
};
