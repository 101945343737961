const cssVars = !process.env.SERVER
  ? require('css-vars-ponyfill').default
  : () => false;

const cssVarsUtil = (() => {
  let temp = {};

  return {
    set: (name, value) => {
      temp[name] = value;
    },
    sync: () => new Promise((r) => {
      const resolve = () => r();

      const hasNativeCssVarsSupport = window.CSS && window.CSS.supports && window.CSS.supports('(--a: 0)');

      cssVars({
        onlyVars: true,
        watch: true,
        updateDOM: hasNativeCssVarsSupport,
        include: 'link[rel="stylesheet"][href^="/static"]',
        onComplete: (styles, node) => {
          const styleNode = document.getElementById('css-var-polyfill');

          try {
            if (styleNode) {
              styleNode.innerHTML = styles;
            } else {
              node.appendChild(document.createTextNode(styles));

              if (document.body) document.body.appendChild(node);
            }
          } catch (err) {
            // don't crash
          } finally {
            resolve();
          }
        },
        variables: temp,
      });

      if (hasNativeCssVarsSupport) setTimeout(resolve, 100);

      temp = {};
    }),
    getVariables: () => temp,
  };
})();

export const cssVar = cssVarsUtil;

// : (hex: string) => { h: number, s: number, l: number }
export const hexToHSL = (hex) => {
  /* eslint-disable */
  let r = 0;
  let g = 0;
  let b = 0;

  if (hex.length === 4) {
    r = '0x' + hex[1] + hex[1];
    g = '0x' + hex[2] + hex[2];
    b = '0x' + hex[3] + hex[3];
  } else if (hex.length === 7) {
    r = '0x' + hex[1] + hex[2];
    g = '0x' + hex[3] + hex[4];
    b = '0x' + hex[5] + hex[6];
  }

  // $FlowFixMe
  r /= 255;
  // $FlowFixMe
  g /= 255;
  // $FlowFixMe
  b /= 255;

  const cmin = Math.min(r, g, b);
  const cmax = Math.max(r, g, b);
  const delta = cmax - cmin;
  let h = 0;
  let s = 0;
  let l = 0;

  if (delta === 0) {
    h = 0;
  } else if (cmax === r) {
    h = ((g - b) / delta) % 6;
  } else if (cmax === g) {
    h = (b - r) / delta + 2;
  } else {
    h = (r - g) / delta + 4;
  }

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta === 0 ?
    0
    : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return { h, s, l };
};

const hexToRGB = (hex) => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function(m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

// color: { h: number, s: number, l: number }
export const complementHSL = (color) => ({
  h: color.h + 180 > 360 ? color.h - 180 : color.h + 180,
  s: color.s + 50 > 100 ? color.s - 50 : color.s + 50,
  l: color.l + 50 > 100 ? color.l - 50 : color.l + 50,
});

export const setTheme = async (theme = {}) => {
  // Set theme variables
  const setWithHSL = (name, hex) => {
    const { h, s, l } = hexToHSL(hex);
    const { r, g, b } = hexToRGB(hex);

    cssVarsUtil.set(name, hex);
    cssVarsUtil.set(`${name}-hue`, `${h}`);
    cssVarsUtil.set(`${name}-saturation`, `${s}%`);
    cssVarsUtil.set(`${name}-lightness`, `${l}%`);
    cssVarsUtil.set(`${name}-lightness-light`, `${l + (100 - l) / 2}%`);
    cssVarsUtil.set(`${name}-lightness-verylight`, `${l + (100 - l) / 1.15}%`);
    cssVarsUtil.set(`${name}-lightness-dark`, `${l / 1.25}%`);
    cssVarsUtil.set(`${name}-r`, r);
    cssVarsUtil.set(`${name}-g`, g);
    cssVarsUtil.set(`${name}-b`, b);
  };

  setWithHSL('--color-primary', theme.main_color || '#19214D');
  setWithHSL('--color-button', theme.button_color || '#007AFF');
  cssVarsUtil.set('--color-primary-text', '#FFF');
  cssVarsUtil.set('--color-button-text', '#FFF');
  cssVarsUtil.set('--color-link', theme.link_color || '#007AFF');

  // Improve performance
  if (!process.env.SERVER) await cssVarsUtil.sync();
}
