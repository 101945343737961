import { useEffect } from 'react';

import { Mixpanel } from '../../../client/mixpanel';

export const usePageTracker = (eventName: string | undefined, properties?: Record<string, unknown>) => {
  useEffect(() => {
    if (Mixpanel && eventName) {
      Mixpanel.track(eventName, properties);
    }
  }, [eventName, properties]);
};
