import * as R from 'ramda';
import Api from '@services/api';
import { getApiHeaders } from '@services/api/utils';
import { downloadFile } from '@common/utils/file';
import { BASE_URL } from '@common/constants';

import * as draftReducer from '../../learning/reducers/draft';
import { transformScreenFromAPI, screenToPayload } from '../utils';

export const SURVEYS_RECEIVE_SURVEYS = 'surveys/RECEIVE_SURVEYS';
export const SURVEYS_RECEIVE_SURVEY_TEMPLATES = 'surveys/RECEIVE_SURVEY_TEMPLATES';
export const SURVEYS_RECEIVE_SURVEY = 'surveys/RECEIVE_SURVEY';
export const SURVEYS_CREATE_SURVEY = 'surveys/CREATE_SURVEY';
export const SURVEYS_UPDATE_SURVEY = 'surveys/UPDATE_SURVEY';
export const SURVEYS_CREATE_QUESTION = 'surveys/CREATE_QUESTION';
export const SURVEYS_UPDATE_SCREEN = 'surveys/UPDATE_SCREEN';
export const SURVEYS_DELETE_QUESTION = 'surveys/DELETE_QUESTION';
export const SURVEYS_DELETE_SURVEY = 'surveys/DELETE_SURVEY';
export const SURVEYS_ARCHIVE_SURVEY = 'surveys/ARCHIVE_SURVEY';
export const SURVEYS_UPDATE_SCREEN_ORDER = 'surveys/UPDATE_SCREEN_ORDER';
export const SURVEYS_SAVE_SCREEN_ORDER = 'surveys/SAVE_SCREEN_ORDER';
export const SURVEYS_COMPLETE_SURVEY = 'surveys/COMPLETE_SURVEY';
export const SURVEYS_RECEIVE_SURVEY_RESPONSES = 'surveys/RECEIVE_SURVEY_RESPONSES';
export const SURVEYS_RECEIVE_SURVEY_RESPONSE = 'surveys/RECEIVE_SURVEY_RESPONSE';
export const SURVEYS_RECEIVE_REPORT = 'surveys/RECEIVE_REPORT';

export const fetchSurveys = (nextCursor, filter = {}, limit = 15) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const query = Api.utils.toQuery({
    cursor: nextCursor || true,
    limit,
    status: filter.status,
  });

  const result = await Api.get(`/v1/organisations/${selected.id}/surveys?${query}`);

  dispatch({
    type: SURVEYS_RECEIVE_SURVEYS,
    items: result.data,
    strategy: nextCursor ? 'append' : null,
    meta: result.meta,
  });

  return result;
};

export const fetchTemplates = (nextCursor, filter, limit = 4) => async (dispatch, getState) => {
  try {
    const { organisation: { selected }, loggedUser } = getState();

    const query = Api.utils.toQuery({
      cursor: nextCursor || true,
      limit,
      content_language_locale: loggedUser.user.language.locale === 'nl' ? 'nl' : 'en',
    });

    const result = await Api.get(`/v1/organisations/${selected.id}/surveys/templates?${query}`);

    dispatch({
      type: SURVEYS_RECEIVE_SURVEY_TEMPLATES,
      items: result.data,
    });

    return result;
  } catch (err) {
    console.error(err);
  }
};

export const updateSurvey = (id, payload) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const result = await Api.put(`/v1/organisations/${selected.id}/surveys/${id}`, payload);

  dispatch({
    type: SURVEYS_UPDATE_SURVEY,
    item: result.data,
  });
};

export const fetchSurvey = (id) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const { data: survey, meta: { related } } = await Api.get(`/v1/organisations/${selected.id}/surveys/${id}`);

  survey.screen_ids = R.pluck('id', survey.screens);
  survey.screens = survey.screens.map((screen, i) => {
    const result = screen;
    result.index = i;
    result.initial_index = i;

    return result;
  });

  return dispatch({
    type: SURVEYS_RECEIVE_SURVEY,
    item: survey,
    related,
  });
};

export const createSurvey = (payload, templateId) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const query = templateId ? `?survey_template_id=${templateId}` : '';

  const result = await Api.post(`/v1/organisations/${selected.id}/surveys${query}`, payload || {});

  return dispatch({
    type: SURVEYS_CREATE_SURVEY,
    item: result.data,
  });
};

export const createQuestion = (surveyId, payload) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const result = await Api.post(`/v1/organisations/${selected.id}/surveys/${surveyId}/screens`, payload);

  const screen = transformScreenFromAPI(result.data);
  screen.initial_index = screen.index;

  return dispatch({
    type: SURVEYS_CREATE_QUESTION,
    screen,
    surveyId,
  });
};

export const deleteSurvey = (id, filter) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  await Api.delete(`/v1/organisations/${selected.id}/surveys/${id}`);

  return dispatch({
    type: SURVEYS_DELETE_SURVEY,
    id,
    filter,
  });
};

export const duplicateSurvey = (id) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const result = await Api.post(`/v1/organisations/${selected.id}/surveys/${id}/duplicate`);

  return dispatch({
    type: SURVEYS_CREATE_SURVEY,
    item: result.data,
  });
};

export const archiveSurvey = (id, filter) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  await Api.post(`/v1/organisations/${selected.id}/surveys/${id}/archive`);

  return dispatch({
    type: SURVEYS_ARCHIVE_SURVEY,
    id,
    filter,
    removeFromList: filter !== 'archived',
  });
};

export const completeSurvey = (id, filter) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  await Api.post(`/v1/organisations/${selected.id}/surveys/${id}/complete`);

  return dispatch({
    type: SURVEYS_COMPLETE_SURVEY,
    id,
    filter,
    removeFromList: !!filter,
  });
};

export const fetchResponses = (nextCursor, filter = {}, limit = 10) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const query = Api.utils.toQuery({
    cursor: nextCursor || true,
    limit,
    q: filter.search,
  });

  const result = await Api.get(`/v1/organisations/${selected.id}/surveys/${filter.surveyId}/responses?${query}`);

  dispatch({
    type: SURVEYS_RECEIVE_SURVEY_RESPONSES,
    items: result.data,
    strategy: nextCursor ? 'append' : null,
  });

  return result;
};

export const fetchResponse = (surveyId, id) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const result = await Api.get(`/v1/organisations/${selected.id}/surveys/${surveyId}/responses/${id}`);

  dispatch({
    type: SURVEYS_RECEIVE_SURVEY_RESPONSE,
    item: R.assoc('id', id, result.data),
  });

  return result;
};

export const downloadResponses = (surveyId, fileName) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const options = {
    method: 'GET',
    headers: await getApiHeaders(),
    credentials: 'include',
    cache: 'no-cache',
  };

  return downloadFile(
    `${BASE_URL}/v1/organisations/${selected.id}/surveys/${surveyId}/responses/export`,
    `${fileName}.csv`,
    options,
  );
};

export const updateScreenOrder = (surveyId, order) => ({
  type: SURVEYS_UPDATE_SCREEN_ORDER,
  surveyId,
  items: R.addIndex(R.map)((id, index) => ({ id, index }), order),
});

export const saveScreenOrder = (surveyId, order) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  await Api.put(`/v1/organisations/${selected.id}/surveys/${surveyId}/screens`, {
    screens: order,
  });

  return dispatch({
    type: SURVEYS_SAVE_SCREEN_ORDER,
    surveyId,
    order,
  });
};

export const deleteQuestion = (surveyId, id) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  await Api.delete(`/v1/organisations/${selected.id}/surveys/${surveyId}/screens/${id}`);

  return dispatch({
    type: SURVEYS_DELETE_QUESTION,
    surveyId,
    id,
  });
};

export const duplicateQuestion = (surveyId, screen) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const result = await Api.post(`/v1/organisations/${selected.id}/surveys/${surveyId}/screens/${screen.id}/duplicate`);

  const newScreen = transformScreenFromAPI(result.data);
  newScreen.initial_index = newScreen.index;

  return dispatch({
    type: SURVEYS_CREATE_QUESTION,
    screen: newScreen,
    surveyId,
  });
};

export const saveDraft = (surveyId) => async (dispatch, getState) => {
  const state = getState();
  const { organisation: { selected }, academy: { draft } } = state;

  const screenIds = Object.keys(draft.screens);

  return Promise.all(screenIds.map(async (id) => {
    const screen = draftReducer.getScreen(state, id);
    const payload = screenToPayload(screen);

    const result = await Api.put(`/v1/organisations/${selected.id}/surveys/${surveyId}/screens/${id}`, payload);

    return dispatch({
      type: SURVEYS_UPDATE_SCREEN,
      screen: transformScreenFromAPI(result.data),
      surveyId,
    });
  }));
};

export const fetchReport = (surveyId, { networkIds, functionIds }) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const query = Api.utils.toQuery({
    networkIds,
    functionIds,
  });

  const [{ data }, { data: filters }] = await Promise.all([
    Api.get(`/v1/organisations/${selected.id}/surveys/${surveyId}/report?${query}`),
    Api.get(`/v1/organisations/${selected.id}/surveys/${surveyId}/report/filters`),
  ]);

  return dispatch({
    type: SURVEYS_RECEIVE_REPORT,
    surveyId,
    report: data,
    filters,
  });
};

export const fetchAnswers = (nextCursor, { surveyId, questionId, networkIds, functionIds }, limit = 25) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const query = Api.utils.toQuery({
    cursor: nextCursor || true,
    limit,
    networkIds,
    functionIds,
  });

  return Api.get(`/v1/organisations/${selected.id}/surveys/${surveyId}/questions/${questionId}/answers?${query}`);
};

export const fetchData = (surveyId, questionId, compareType) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const query = Api.utils.toQuery({
    compareType,
  });

  return Api.get(`/v1/organisations/${selected.id}/surveys/${surveyId}/questions/${questionId}/compare?${query}`);
};

export const fetchUsers = (nextCursor, { surveyId, tab, search }, limit = 25) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  let filter;

  if (tab === 'waiting') {
    filter = 'not_completed';
  } else if (tab === 'completed') {
    filter = 'completed';
  }

  const query = Api.utils.toQuery({
    cursor: nextCursor || true,
    filter,
    limit,
    q: search,
  });

  return Api.get(`/v1/organisations/${selected.id}/surveys/${surveyId}/assigned_users?${query}`);
};
