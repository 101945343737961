import { LOGOUT_USER } from '../../authentication/actions';
import { SELECT_ORGANISATION } from '../../organisation/actions';
import {
  CHAT_OPEN_CONVERSATION,
  CHAT_FILTER_CONVERSATIONS,
  CHAT_ARCHIVE_CONVERSATION,
} from '../actions';

const initialState = { selectedConversationId: null, search: { query: '' } };

export default (state = initialState, action) => {
  switch (action.type) {
    case CHAT_OPEN_CONVERSATION:
      return { ...state, selectedConversationId: action.conversationId };
    case CHAT_FILTER_CONVERSATIONS:
      return { ...state, search: { ...state.search, ...action.search } };
    case CHAT_ARCHIVE_CONVERSATION:
      if (state.selectedConversationId !== action.conversationId) return state;

      return {
        ...state,
        selectedConversationId: null,
      };
    case SELECT_ORGANISATION:
      return initialState;
    case LOGOUT_USER:
      return initialState;
    default: return state;
  }
};
