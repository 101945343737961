export const RECEIVE_NOTIFICATION = 'core/RECEIVE_NOTIFICATION';

export default (activity) => (dispatch, getState) => {
  if (!activity?.platform?.is_any_web) {
    return;
  }

  const { organisation: { selected } } = getState();

  if (activity.organisation_id === selected) {
    dispatch({
      type: RECEIVE_NOTIFICATION,
      item: activity,
    });
  }
};
