import * as R from 'ramda';
import fetchConversation from './fetch-conversation';
import { CHAT_REMOVE_PARTICIPANT } from './remove-participant';
import { CHAT_ADD_PARTICIPANTS } from './add-participants';
import { CHAT_UPDATE_CONVERSATION } from './update-conversation';
import { objectToSource } from '../reducers/messages';
import { EConversationActivityTypes } from '../definitions';

export default ({ data: object, meta }) => async (dispatch, getState) => {
  const { chat, loggedUser: { user: { id: loggedUserId } } } = getState();

  try {
    const conversation = R.find(R.equals(object.parent_id), chat.conversations.ids);

    if (!conversation) {
      // Conversation is not yet known in our store
      // Fetching the conversation will also set the unread count
      await dispatch(fetchConversation(object.parent_id));
    } else {
      // Decide what to do for each activity type
      switch (object.source.activity_type) {
        case EConversationActivityTypes.CONVERSATION_CREATED:
          return dispatch(fetchConversation(object.parent_id));
        case EConversationActivityTypes.USER_ADDED:
          if (object.source.meta_data.user_id === loggedUserId) {
            // The logged in user has been added to the conversation
            // This could be for the first time or after the user has been deleted
            // Because we don't know what happened before this we fetch the whole conversation
            return dispatch(fetchConversation(object.parent_id));
          }

          return dispatch({
            type: CHAT_ADD_PARTICIPANTS,
            conversationId: object.parent_id,
            userIds: [object.source.meta_data.user_id],
            activities: [objectToSource(object)],
            related: meta.related,
            isMe: object.source.meta_data.user_id === loggedUserId,
          });
        case EConversationActivityTypes.USER_LEFT:
        case EConversationActivityTypes.USER_REMOVED:
          return dispatch({
            type: CHAT_REMOVE_PARTICIPANT,
            conversationId: object.parent_id,
            userId: object.source.meta_data.user_id,
            activities: [objectToSource(object)],
            isMe: object.source.meta_data.user_id === loggedUserId,
          });
        case EConversationActivityTypes.CHANGED_NAME:
          return dispatch({
            type: CHAT_UPDATE_CONVERSATION,
            conversationId: object.parent_id,
            item: { name: object.source.meta_data.new_name },
            activities: [objectToSource(object)],
          });
        default:
          return false;
      }
    }
  } catch (err) {
    // Do nothing
  }
};
