export const EMessageTypes = {
  DEFAULT_MESSAGE: 'default_message',
  ORGANISATION_MESSAGE: 'organisation_message',
  BIRTHDAY_MESSAGE: 'birthday_message',
  WELCOME_MESSAGE: 'welcome_message',
};

export const EObjectTypes = {
  MESSAGE: 'feed_message',
  ORGANISATION_MESSAGE: 'organisation_message',
  EXCHANGE: 'exchange',
  POLL: 'poll',
  ATTACHMENT: 'attachment',
};

export const ETimelineTypes = {
  FEED: 'feed',
  NETWORK: 'network',
  TEAM: 'team',
  ORGANISATION: 'organisation',
  CHANNEL: 'channel',
  EVENT: 'event',
};

export const EFeedFilterTypes = {
  IMPORTANT: 'important',
  IMPORTANT_UNREAD: 'important_unread',
  NONE: 'none',
};

export const EFeedSortTypes = {
  LAST_ACTIVITY: 'last_activity',
  LAST_CREATED: 'last_created',
};
