import moment from 'moment';
import { SELECT_NETWORK } from '../../network/actions';
import {
  FLEXCHANGE_FILTER_EXCHANGES,
  FLEXCHANGE_GO_PREVIOUS_WEEK,
  FLEXCHANGE_GO_NEXT_WEEK,
  FLEXCHANGE_GO_TODAY,
  FLEXCHANGE_RECEIVE_EXCHANGE,
} from '../actions';

const initialState = {
  currentWeek: moment().startOf('isoweek'),
  search: { team: null, status: null },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FLEXCHANGE_FILTER_EXCHANGES:
      return { ...state, search: { ...state.search, ...action.search } };
    case FLEXCHANGE_GO_PREVIOUS_WEEK: {
      const previousWeek = moment(state.currentWeek).subtract(1, 'weeks').startOf('isoweek');
      return { ...state, currentWeek: previousWeek };
    }
    case FLEXCHANGE_GO_NEXT_WEEK: {
      const nextWeek = moment(state.currentWeek).add(1, 'weeks').startOf('isoweek');
      return { ...state, currentWeek: nextWeek };
    }
    case FLEXCHANGE_GO_TODAY:
      return { ...state, currentWeek: action.week.startOf('isoweek') };
    case FLEXCHANGE_RECEIVE_EXCHANGE:
      return { ...state, currentWeek: moment(action.exchange.source.date).startOf('isoweek') };
    case SELECT_NETWORK: return initialState;
    default: return state;
  }
};
