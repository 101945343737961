import { combineReducers } from 'redux';
import * as R from 'ramda';
import { LEARNING_RECEIVE_ONBOARDING_STATISTICS, LEARNING_RECEIVE_COURSE_STATISTICS } from '../actions';

const initialState = {
  starting: {
    users: [],
  },
  progressing: {
    users: [],
  },
  finished: {
    users: [],
  },
  overdue: {
    users: [],
  },
};

const onboarding = (state = initialState, action) => {
  switch (action.type) {
    case LEARNING_RECEIVE_ONBOARDING_STATISTICS:
      return action.statistics;
    default:
      return state;
  }
};

const courses = (state = {}, action) => {
  switch (action.type) {
    case LEARNING_RECEIVE_COURSE_STATISTICS:
      return R.assoc(action.courseId, action.statistics, state);
    default:
      return state;
  }
};

export default combineReducers({ onboarding, courses });
