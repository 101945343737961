import * as R from 'ramda';
import { combineReducers } from 'redux';
import { ORGANISATION_INITIALISE, ORGANISATION_ADD_NETWORKS } from '../../organisation/actions';
import { NETWORK_INITIALISE, NETWORK_UPDATE_NETWORK } from '../../network/actions';

const storeById = (acc, network) => R.assoc(network.id, {
  ...acc[network.id],
  ...R.omit(['membership'], network),
}, acc);

export const formatNetworks = (networks, state = {}) => {
  return R.reduce(storeById, state, networks);
};

const items = (state = {}, action) => {
  switch (action.type) {
    case NETWORK_INITIALISE:
    case NETWORK_UPDATE_NETWORK:
      return storeById(state, action.network);
    case ORGANISATION_INITIALISE:
      return formatNetworks(action.networks);
    case ORGANISATION_ADD_NETWORKS:
      return R.reduce(storeById, state, action.items);
    case 'admin/DELETE_NETWORK':
      return R.omit([action.id], state);
    default: return state;
  }
};

export const findById = R.curry((state, id) => {
  const network = state.networks.items[id];

  if (!network) return undefined;

  return network;
});

export default combineReducers({ items });
