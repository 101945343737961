import * as React from 'react';
import * as R from 'ramda';

export type Props = {
  name?: (string | { props: { children: any } }),
  size: number,
  color?: string,
};

export const getShortName = (name: string) => {
  if (!name || name === '"Verwijderde gebruiker"' || name === '"Verwijderd"') return '?';

  const parts = name.split(/[\s-]/);

  return R.pipe(
    R.map((string: string) => string.substr(0, 1)),
    R.join(''),
    R.toUpper,
  )(parts.length === 1 ? parts : [parts[0], parts[parts.length - 1]]);
};

const ImagePlaceholder = ({ name, size, color, ...props }: Props) => {
  const shortName = getShortName(name && (typeof name === 'string' ? name : name.props.children));

  return (
    <div
      {...props}
      className="ImagePlaceholder"
      style={{
        width: `${size}px`,
        height: `${size}px`,
        lineHeight: `${size}px`,
        backgroundColor: color,
      }}
    >
      {shortName}
    </div>
  );
};

ImagePlaceholder.defaultProps = {
  name: '?',
};

export default ImagePlaceholder;
