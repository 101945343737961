import * as R from 'ramda';
import memoize from '../../../common/utils/memoize';
import { ELevels } from '../../../common/definitions';
import * as networksReducer from '../../core/reducers/networks';
import * as usersReducer from '../../core/reducers/users';
import * as teamsReducer from '../reducers/teams';

const getFunctions = (state) => state.loggedUser.functions;
const getNetworkTeams = (state) => state.network.teams.items;
const getNetworkUsers = (state) => state.network.users.items;
const getNetworkAdmins = (state) => state.network.admins;
const getRoles = (state) => state.organisation.roles;
const getNetworkRoles = (state) => state.network.roles;
const getNetworkFunctions = (state) => state.network.functions.items;
export const getSelectedNetwork = (state) => state.networks.items[state.network.selected];

export const isInitializing = (state) => state.ui.network.global.initializing;

export const selected = memoize.createSelector(
  [getSelectedNetwork],
  (state, selectedNetwork) => selectedNetwork && networksReducer.findById(state, selectedNetwork.id),
);

export const teams = memoize.createSelector(
  [getNetworkTeams, getFunctions],
  (state, items) => R.pipe(
    R.map(teamsReducer.findById(state)),
    R.sortBy((team) => team.name.toLowerCase()),
  )(R.pluck('id', R.values(items))),
);

export const users = memoize.createSelector(
  [getNetworkUsers],
  (state, items) => R.map(usersReducer.findById(state), items),
);

// this selector needs to be removed as it is used in a place where we should
// read the
export const admins = memoize.createSelector(
  [getNetworkAdmins, getRoles, getNetworkRoles],
  (state, networkAdmins, orgRoles) => R.map((admin) => {
    const roles = admin.roles
      .map(({ id, memberships }) => (orgRoles[id]
        ? R.assoc('memberships', memberships, orgRoles[id])
        : false))
      .filter((r) => !!r);

    const lastAddedRole = R.sortBy(R.descend(R.prop('memberships.added_at')), roles)[0] || null;

    return {
      ...usersReducer.findById(state, admin.id),
      last_modified: lastAddedRole && {
        added_by: usersReducer.findById(state, lastAddedRole?.memberships?.added_by),
        added_at: lastAddedRole?.memberships?.added_at,
      },
      roles,
    };
  }, networkAdmins),
);

export const functions = memoize.createSelector(
  [getNetworkFunctions],
  (state, items) => R.sortBy((item) => item.name.toLowerCase(), R.values(items)),
);

export const roles = memoize.createSelector(
  [getNetworkRoles],
  (state, data) => R.pipe(
    R.map((id) => state.organisation.roles[id]),
    R.filter(R.propEq('level', ELevels.NETWORK)),
    R.values,
    R.map((role) => R.assoc('last_updated_by', usersReducer.findById(state, role.last_updated_by), role)),
    R.sortBy(R.prop('name')),
  )(data),
);
