import * as React from 'react';
import { connect } from 'react-redux';
import { Trans, withTranslation } from 'react-i18next';
import { Button } from '../../../../common/components/button';
import Alert from '../../../../common/components/alert';

const redirect = (url) => () => (window.location.href = url);

const ErrorComponent = ({ error, onRetry, logout, t }) => (
  <>
    {error.type === 'whitelabel_access_error' ? (
      <Alert
        type="warning"
        title={t('authentication:alert_white_label_error')}
      >
        {error && error.error_info && error.error_info.app_name ? (
          <Trans i18nKey="authentication:alert_white_label_redirect_to_app" values={{ appName: error.error_info.app_name }} components={[<b>a</b>]} />
        ) : (
          <Trans i18nKey="authentication:alert_white_label_redirect_to_flex-appeal" />
        )}
      </Alert>
    ) : (
      <Alert type="error" title="Fout tijdens het inladen van deze data." />
    )}
    {(error.type === 'whitelabel_access_error' || process.env.INTERCOM_APP_ID) && (
      <>
        <br />
        <br />
        <div className="Align Align--start">
          {error.type === 'whitelabel_access_error'
            ? error.error_info
              ? error.error_info.urls && error.error_info.urls.web && <Button type="primary" onClick={redirect(error.error_info.urls.web)}>{t('authentication:go_to_app')}</Button>
              : <Button type="primary" onClick={redirect('https://app.oneteam.io')}>{t('authentication:go_to_app')}</Button>
            : onRetry && <Button type="primary" onClick={onRetry}>{t('authentication:try_again')}</Button>}
          {process.env.INTERCOM_APP_ID && error.type !== 'whitelabel_access_error' && (
            <Button type="inverted-primary" onClick={() => typeof window !== 'undefined' && window.Intercom && window.Intercom('show')}>
              <Trans i18nKey="authentication:contact_helpdesk" />
            </Button>
          )}
          {error.type === 'whitelabel_access_error' && (
            <Button onClick={logout}>
              <Trans i18nKey="authentication:log_out" />
            </Button>
          )}
        </div>
      </>
    )}
  </>
);

const mapDispatchToProps = (dispatch, props) => ({
  logout: props.logout || (() => dispatch(require('../../actions/logout').default())),
});

export default connect(undefined, mapDispatchToProps)(withTranslation()(ErrorComponent));
