import * as React from 'react';
import Player from 'react-player';

export type Props = {
  id?: string,
  path: string,
  className?: string,
  thumb?: string,
};

const Video = ({ className, path, thumb, ...props }: Props): JSX.Element => (
  <div className="Video">
    <Player
      playing={!!thumb}
      controls
      width="100%"
      height="100%"
      className={`Video__Player${className ? ` ${className}` : ''}`}
      style={{
        backgroundSize: 'contain',
      }}
      url={path}
      light={thumb}
      {...props}
    />
  </div>
);

export default Video;
